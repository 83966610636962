import React, { useState } from "react";
import Checkbox from "../../../../../../components/topMenu/elements/Checkbox/Checkbox.jsx";
import styles from "./styles.module.css";
import Textarea from "../../../../../../components/topMenu/elements/Textarea/Textarea.jsx";
import { transS } from "../../../../../../../../services/helpers/lang.js";
import Conditional from "../../../../../../components/topMenu/elements/TemplatesUnderMenu/Conditional.js";
import cx from "classnames";
import { useSelector } from "react-redux";

export default function VarItemAdvanced({
  id,
  defaultValue,
  changeVariable,
  moveUp,
  moveDown,
  name,
  isUp,
  isDown,
  cloneVar,
  removeVar,
  type,
  isTracked,
  isApplies,
  checkAndUpdateName,
  conditional,
  readOnly = false
}) {
  const varlist = useSelector(state => state.userReducer.varlist)
  const conditionals = varlist.filter(el => el.id !== id)

  const [isVisibleFormat, setVisibleFormat] = useState(false);
  const [isVisibleDefaultValue, setVisibleDefaultValue] = useState(
    defaultValue && defaultValue.trim().length !== 0
  );
  const [isVisibleConditional, setVisibleConditional] = useState(!!conditional);

  return (
    <div className={styles.container}>
      <Checkbox
        label={"Применяется ко всем связанным документам"}
        checked={isApplies}
        onChange={(e) => changeVariable({ id, key: "isApplies", value: e.target.checked })}
        readOnly={readOnly}
        className={cx({ [styles.readonly]: readOnly })}
      />
      <Checkbox
        label={"Отслеживаемое обязательство"}
        checked={isTracked}
        onChange={(e) => changeVariable({ id, key: "isTracked", value: e.target.checked })}
        readOnly={readOnly}
        className={cx({ [styles.readonly]: readOnly })}
      />

      {type !== 'date' && (
        <>
          {!isVisibleFormat && (
            <button
              className={cx(styles.button, { [styles.readonly]: readOnly })}
              onClick={(e) => {
                e.stopPropagation()
                // setVisibleFormat(true)
              }}
              disabled={readOnly}
            >
              Добавить формат
            </button>
          )}
        </>
      )}

      {!isVisibleDefaultValue && (
        <button
          className={cx(styles.button, { [styles.readonly]: readOnly })}
          onClick={(e) => {
            e.stopPropagation()
            setVisibleDefaultValue(true)
          }}
          disabled={readOnly}
        >
          Добавить значение по умолчению
        </button>
      )}

      {isVisibleDefaultValue && (
        <div>
          <p className={styles.label}>Значение по умолчанию</p>
          <Textarea
            className={cx(styles.textarea, { [styles.readonly]: readOnly })}
            value={defaultValue ?? ""}
            onChange={(value) =>
              changeVariable({ id, key: "defaultValue", value })
            }
            readOnly={readOnly}
          />
        </div>
      )}

      {!isVisibleConditional && (
        <button
          className={cx(styles.button, { [styles.readonly]: readOnly })}
          onClick={(e) => {
            e.stopPropagation()
            setVisibleConditional(true)
          }}
          disabled={readOnly}
        >
          Добавить условие
        </button>
      )}

      {isVisibleConditional && (
        <div>
          <p className={styles.label}>
            {transS("Condition for This Variable")}
          </p>
          <Conditional
            el={{ conditional }}
            varlist={conditionals}
            isAdminPart={true}
            changedVarList={({ res, isCondVar }) => {
              if (readOnly) return

              const data = { ...conditional }
              if (isCondVar) {
                data.condVar = res
                data.condOption = null
              } else {
                data.condOption = res
              }
              changeVariable({ id, key: 'conditional', value: data })
            }}
            kill={(e) => {
              e.stopPropagation()

              if (readOnly) return

              changeVariable({ id, key: 'conditional', value: null })
              setVisibleConditional(false)
            }}
            readOnly={readOnly}
          />
        </div>
      )}

      <div className={styles.nameContainer}>
        <p className={styles.label}>Имя переменной</p>
        <Textarea
          hasUnderline={true}
          value={name ?? ""}
          className={cx(styles.nameVariable, { [styles.readonly]: readOnly })}
          onChange={(value) => changeVariable({ id, key: "name", value })}
          onBlur={() => {
            checkAndUpdateName(id, 'name')
          }}
          readOnly={readOnly}
        />
      </div>

      <div className={styles.advancedTools}>
        <button
          onClick={() => moveUp(id)}
          className={cx(styles.toolsItem, { [styles.readonly]: readOnly })}
          style={{ opacity: (isUp || readOnly) ? 0.3 : 1 }}
          disabled={isUp || readOnly}
        >
          <img
            src="/images/arrow_blue_12x10.svg"
            style={{ rotate: "180deg" }}
          />
          {transS("Up")}
        </button>
        <button
          onClick={() => moveDown(id)}
          className={cx(styles.toolsItem, { [styles.readonly]: readOnly })}
          disabled={isDown || readOnly}
          style={{ opacity: (isDown || readOnly) ? 0.3 : 1 }}
        >
          <img src="/images/arrow_blue_12x10.svg" />
          {transS("Down")}
        </button>
        <button 
          className={cx(styles.toolsItem, { [styles.readonly]: readOnly })}
          style={{ marginRight: "50px", opacity: readOnly ? 0.3 : 1 }} 
          disabled={readOnly}
        >
          <img
            src="/images/groupIcon_blue_15x12.svg"
            style={{ maxHeight: "15px" }}
          />
          {transS("Add to Group")}
        </button>
        <button 
          onClick={() => cloneVar(id)} 
          className={cx(styles.toolsItem, { [styles.readonly]: readOnly })}
          style={{ opacity: readOnly ? 0.3 : 1 }}
          disabled={readOnly}
        >
          <img
            src="/images/copyIcon_blue_11x12.svg"
            style={{ maxHeight: "15px" }}
          />
          {transS("Copy")}
        </button>
        <button
          onClick={() => removeVar(id)}
          className={cx(styles.toolsItem, styles.toolItemRemove, { [styles.readonly]: readOnly })}
          disabled={readOnly}
          style={{ opacity: readOnly ? 0.3 : 1 }}
        >
          <img src="/images/deleteIcon_10x12.svg" />
          {transS("Delete")}
        </button>
      </div>
    </div>
  );
}
