import React from 'react'
import { transS } from '../../../../../../../../../services/helpers/lang'
import styles from './styles.module.css'

export default function Header({ isManyVariables }) {
  return (
    <h2 className={styles.header}>
      {!isManyVariables && transS('Replace Variable') + '?'}
      {isManyVariables && transS('Replace Variables') + '?'}
    </h2>
  )
}
