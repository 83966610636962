import React, { memo, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from '../analysis.css'
import { transS } from '../../../../../services/helpers/lang';

function AnalysisListItemBody({ 
  defaultText, 
  correctionComment, 
  date,  
  policy,
  rule,
  ruleContent,
  isActive,
  moveNext,
  movePrev,
  isFirst,
  isLast
}) {
  const [isVisiblePolicyPopover, setVisiblePolicyPopover] = useState(false)

  return (
    <div styleName={'itemBody'}>
      {isActive && (
        <div styleName={'itemBody__header'}>
          <div styleName={'itemBody__headerAnalysis'}>
            <img src="/images/pad-lock.png" alt="" />
            {transS('Analysis')}
          </div>
          <div styleName="itemBody__mainColNav">
            <div styleName="arrows">
              <img 
                src="/images/arrow_blue_12x10.svg" 
                style={{ 
                  rotate: '90deg', 
                  width: '12px',
                  opacity: isFirst ? 0.3 : 1 
                }} 
                alt="prevElement" 
                onClick={(e) => {
                  e.stopPropagation()

                  if (!isFirst) 
                    movePrev();
                }}
              />
              <img 
                src="/images/arrow_blue_12x10.svg" 
                style={{ 
                  rotate: '-90deg', 
                  width: '12px', 
                  opacity: isLast ? 0.3 : 1 
                }} 
                alt="nextElement" 
                onClick={(e) => {
                  e.stopPropagation()

                  if (!isLast) 
                    moveNext();
                }}
              />
            </div>
            {/* <img src="/images/threeDots_gray_3x12.svg" style={{ width: '3px' }} alt="menu" /> */}
          </div>
        </div>
      )}

      <div styleName={'itemBody__content'}>
        {isActive && (
          <div styleName={'itemBody__leftCol'}>
            <img src="/images/light_white_12x12.svg" alt="light" />
          </div>
        )}

        <div styleName={'itemBody__mainCol'}>

          {isActive && (
            <div styleName={'itemBody__mainColHeader'}>
              <h4>Simplawyer One AI</h4>
              <p>{date}</p>
            </div>
          )}

          <div styleName="itemBody__mainColDefault">
            <p styleName="itemBody__mainColDefaultText">
              {defaultText}
            </p>

            {!isActive && (
              <div styleName="itemBody__mainColNav">
                <div styleName="arrows">
                  <img 
                    src="/images/arrow_blue_12x10.svg" 
                    style={{ 
                      rotate: '90deg', 
                      width: '12px', 
                      opacity: isFirst ? 0.3 : 1 
                    }} 
                    alt="prevElement" 
                    onClick={(e) => {
                      e.stopPropagation()
      
                      if (!isFirst) 
                        movePrev();
                    }}
                  />
                  <img 
                    src="/images/arrow_blue_12x10.svg" 
                    style={{ 
                      rotate: '-90deg', 
                      width: '12px',
                      opacity: isLast ? 0.3 : 1 
                    }} 
                    alt="nextElement" 
                    onClick={(e) => {
                      e.stopPropagation()
      
                      if (!isLast) 
                        moveNext();
                    }}
                  />
                </div>
                {/* <img src="/images/threeDots_gray_3x12.svg" style={{ width: '3px' }} alt="menu" /> */}
              </div>
            )}
          </div>
          
          <p styleName="itemBody__mainColComment">
            {correctionComment}
          </p>

          {isActive && (
            <div 
              styleName={'itemBody__mainColPolicy'}
              onMouseEnter={() => setVisiblePolicyPopover(true)}
              onMouseLeave={() => setVisiblePolicyPopover(false)}
            >
              <p>{policy}</p>
              <p>{rule}</p>

              <div
                styleName="policyPopover"
                style={{
                  opacity: isVisiblePolicyPopover ? 1 : 0,
                  visibility: isVisiblePolicyPopover ? 'visible' : 'hidden',
                }}
              >
                <h5>{policy}</h5>
                <p>{ruleContent}</p>
              </div>
                
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(CSSModules(AnalysisListItemBody, styles, { allowMultiple: true }))
