import moment from 'moment-timezone'
import { getLanguage } from '../services/helpers/lang';

const defaultPreset = {dateFormat: 1, is12hours: true, indexTZ:0, tz: "UTC"};
export function getDateString(timeStamp, mod, dateData) {
    if (!dateData) dateData = defaultPreset;
    const d = moment.tz(timeStamp, dateData.tz), isRus = !getLanguage()

    const hh = dateData.is12hours ? 'hh:mm A' : 'HH:mm',
      invert = dateData.dateFormat === 2;
    let dateIs; 
    if (isRus && mod != -1 && mod != 6)   
      return d.format(mod >= 0 ? `DD.MM.YY ${hh}` : `DD.MM.YY`); 
    switch (mod) {
        case 1: // small
          dateIs = invert ? 'DD.MM.YY' : 'MM/DD/YY';
          return d.format(`${dateIs} ${hh}`);
        case 0:
        case 2: // medium
          dateIs = invert ? 'DD MMM YYYY' : 'MMM DD, YYYY';
          return d.format(`${dateIs} ${hh}`);
        case 3: // long
          dateIs = invert ? 'DD MMMM YYYY' : 'MMMM DD, YYYY';
          return d.format(`${dateIs} ${hh}`);
        case 4: // long wo year
          dateIs = invert ? 'DD MMMM' : 'MMMM DD';
          return d.format(`${dateIs} ${hh}`);
        case 5: // medium wo year
          dateIs = invert ? 'DD MMM' : 'MMM DD';
          return d.format(`${dateIs} ${hh}`);
        case 6: // long with timezone
          dateIs = invert ? 'DD MMMM YYYY' : 'MMMM DD, YYYY';
          return d.format(`${hh} ([GMT] Z), ${dateIs}`);
        case -4: //medium date only
          dateIs = invert ? 'DD MMM YYYY' : 'MMM DD, YYYY';
          return d.format(`${dateIs}`);
        case -3: //long date only
          dateIs = invert ? 'DD MMMM YYYY' : 'MMMM DD, YYYY';
          return d.format(`${dateIs}`);
        case -2: // long date wo year
          dateIs = invert ? 'DD MMMM' : 'MMMM DD';
          return d.format(`${dateIs}`);
        case -1:  // special 4 June<br><span>18:45</span><span class="stampTime_hint">4 June<br>18:45</span>
          dateIs = d.format(invert ? 'DD MMM' : 'MMM DD');
          let hIs = d.format(hh);
          return `${dateIs}<br><span>${hIs}</span><span class="stampTime_hint">${dateIs}<br>${hIs}</span>`
        default:
          dateIs = invert ? 'DD MMM YYYY' : 'MMM DD, YYYY';
          return d.format(`${dateIs} ${hh}`);
    }
}
