import CSSModules from "react-css-modules";
import styles from './Popup.css'
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import classNames from 'classnames';

function Popup({ 
  className, 
  wrapperClassName,
  style, 
  hasCrossIcon = false,
  cancelCallback,
  header,
  body,
  footer,
}) {
  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      // setTimeout(() => {
        if (ref.current && !ref.current.contains(event.target)) {
          cancelCallback?.(event);
        }
      // }, 0)
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cancelCallback]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <div styleName={classNames("wrapper", wrapperClassName)}>
      <div 
        styleName={classNames('container', className)}
        style={style}
        onClick={e => e.stopPropagation()}
        ref={ref}
      >
        {hasCrossIcon && (
          <button 
            styleName="cross"
            onClick={cancelCallback}
          >
            <img src="/images/crossIcons_grey_10x12.svg" alt="cross" />
          </button>
        )}
        {header}
        {body}
        {footer}
      </div>
    </div>
  )
}

Popup.propTypes = {
  className: PropTypes.string, 
  style: PropTypes.any, 
  hasCrossIcon: PropTypes.bool,
  cancelCallback: PropTypes.func,
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
}

export default CSSModules(Popup, styles, {allowMultiple: true});