import { userConstants } from '../constants';
import api from '../api/api';
import { transS } from '../helpers/lang';
import { SET_GLOBAL_VARLIST } from '../../redux/document/constants';

const initialState = {
  user: null,
  externalUserList: null,
  activationInProgress: false,
  isLoaded: false,
  showSidebar: false,
  showPayment: false,
  showExpired: '',
  incorrectPassword: false,
  hasExternals: false,
  knownUsers: {},
  clauseLib: null,
  varlist: [],
  editClause:null,
  closedAddFolders: new Set(),
  dateData: {dateFormat: 1, is12hours: true, indexTZ:0, tz: ""}
};

const levelFolder = (folder, lvl, ids) => {
  folder.lvl = lvl
  folder.subs?.forEach(s => levelFolder(s, lvl+1, ids))
  folder.clauses?.forEach(id => ids.delete(id))
}

const parseClause = (state, clause, oldStamp) => {
  const parsed = clause ? JSON.parse(clause) : {folders:[], clauses:[]},
    ids = new Set(parsed?.clauses?.map(el => {
      if (Array.isArray(el) && el.length) {
        return el[0].id
      }
      
      return el.id
    }) || []),
    folders = parsed.folders
  if (parsed.stamp === oldStamp) {
    return null
  }

  folders?.forEach(f => levelFolder(f, 0, ids))

  const unknown = parsed.clauses?.map(c => c.edited)
    .filter(e => e && e !== state.user._id  && !state.knownUsers[e])
  if (unknown?.length)
    api.findUnknownUsers(Array.from(new Set(unknown)));
    
  if (ids.size)
    folders.push({
      id:1, 
      title: transS('Uncategorized'), 
      lvl: 0, 
      clauses: Array.from(ids)
    })
  return parsed
}

export default (state = initialState, action) => {
  switch (action.type) {
  case userConstants.GET_USER:
    const firstTime = state.firstTime || action.user?.firstTime,
      clause = action.user?.company?.clause,
      { varlist = [], ...clauseLib } = parseClause(
        {user: action.user, knownUsers: {}}, clause, -1)

    return {
      ...state,
      user: action.user,
      clauseLib,
      varlist,
      firstTime,
      activationInProgress: action.activationInProgress,
      isLoaded: action.isLoaded,
      dateData: action.dateData
    };
  case userConstants.SET_CLOSED_FOLDERS:
    return {
      ...state,
      closedAddFolders: action.folders
    }
  case userConstants.SET_EDIT_CLAUSE:
      if (action.zero)
        return {...state, editClause: null}
      return {
        ...state,
        editClause: action.editClause
      }
  case userConstants.DROP_FIRST_TIME:
    if (!state.firstTime) return state;
    api.dropUserFirstTime(action.kind);
    return {
        ...state,
        firstTime: action.kind
    };
  case userConstants.ACTIVATION_IN_PROGRESS:
    return {
      ...state,
      activationInProgress: action.activationInProgress
    };
  case userConstants.GET_USER_GROUP:
    return {
      ...state,
      user: {
        ...state.user,
        userGroup: action.userGroup
      }
    };
  case userConstants.GET_EXTERNAL_USER_LIST:   
    return {
      ...state,
      externalUserList: action.externalUserList,
      internalUserList: action.internalUserList, 
      approverUserList: action.approverUserList,
      hasExternals: action.hasExternals
    };
  case userConstants.GET_APPROVER_USER_LIST:
    return {
      ...state,
      approverUserList: action.approverUserList
    };
  case userConstants.UPDATE_USERINFO:
    let {firstname, lastname, email, avatar} = action.user;
    return {
       ...state,
       user: {...state.user, ...{firstname, lastname, email, avatar}},
       dateData: action.dateData
    };

  case userConstants.RENEW_USER:
    const userGroup1 = state.user.userGroup;
    if (action.user) action.user.userGroup = userGroup1;
    return Object.assign({}, state, { user: action.user });    

  case userConstants.INCORRECT_PASSWORD:
    return {
      ...state,
      incorrectPassword: action.incorrect
    };

  case userConstants.SHOW_SIDEBAR:
    return {
      ...state,
      showSidebar: action.showSidebar
    };
   
  case userConstants.SHOW_PAYMENT:
    return {
      ...state,
      showPayment: action.showPayment
    };
  case userConstants.SHOW_EXPIRED:
    return {
      ...state,
      showExpired: action.showExpired
    }
  case userConstants.INVITE_COMPANY_MEMBERS:
  case userConstants.DELETE_COMPANY_MEMBERS:
  case userConstants.CHANGE_COMPANY:
    return {
      ...state,
      user: {...state.user, company: {...action.payload}}
    }

    case userConstants.CHANGE_CLAUSE: {
      const result = parseClause(state, action.clause, state.clauseLib?.stamp)

      if (!result) return state

      const { varlist = [], ...clauseLib } = result

      return clauseLib ? {
        ...state,
        clauseLib,
        varlist
      } : state
    }

  case userConstants.SET_GLOBAL_VARLIST: {
    // const clauseLib = parseClause(state, action.varlist, state.clauseLib?.stamp)
    const varlist = action.varlist
    return varlist ? {
      ...state,
      varlist 
    } : state
  }

    case userConstants.GET_COMPANY_MEMBERS:
      const company = state.user.company
        company.knownCompanies[0].members = {...action.payload}
      return {
        ...state,
        user: {...state.user, company}
      }
    case userConstants.TOGGLE_COMPANY_LOADER: {
      const company = state.user.company
      company.loader = action.payload
      return {
        ...state,
        user: {...state.user, company}
      }
    }
    case userConstants.UPDATE_UNKNOWN_LIST:
      const knownUsers = {...state.knownUsers}
      action.userList?.forEach(u =>
         knownUsers[u._id] = u)
      return {
        ...state,
        knownUsers
      }
    case userConstants.UPDATE_APPROVERS_LIST:
      return {
        ...state,
        approverUserList: action.approverUserList
      }
    case userConstants.SET_DATE_FORMAT:      
      return {
        ...state,
        dateData: action.dateData
      }
  default:
    return state;
  }
};
