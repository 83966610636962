import React, { memo } from 'react'
import CSSModules from 'react-css-modules'
import styles from '../analysis.css'

function HiddenListItem({
  id,
  setVisibleItem,
  text
}) {
  return (
    <div styleName='hiddenList__item'>
      <img src="/images/light_gray_12x12.svg" alt="" onClick={() => setVisibleItem(id)} />
      {text}
    </div>
  )
}

export default memo(CSSModules(HiddenListItem, styles, {allowMultiple: true}))