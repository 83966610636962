import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CSSModules from "react-css-modules";
import styles from "./templatesUnderMenu.css";
import useEvent from "../../../../../../services/helpers/use-event";
import { transS } from "../../../../../../services/helpers/lang";
import { variableTypes } from "../../../../../../consts/variableTypes";

// type variants:
// text
// number
// date
// 
// der
// if
// group

const DropDownType = (params) => {
  let {
    type,
    container,
    changed,
    func,
    arrow,
    plus,
    faded,
    style,
    types = null,
    hasMargin = true,
    hasDropDownArrow = false,
    readOnly = false
  } = params;
  const [list, setOpen] = useState(null);
  const currentTypes = types ?? variableTypes

  const _open = () => {
    if (!changed || list) {
      setOpen(null);
      return;
    }
    
    if (!func) return changed();

    const keys = Array.from(currentTypes.keys());
    const index = keys.findIndex((el) => el === type);
    keys.splice(0, 0, keys.splice(index, 1)[0]);

    setTimeout(() => {
      setOpen(keys);
    }, 0)
  };

  const click = (el) => {
    if (readOnly) return
    
    setOpen(null);
    if (container.type === el) return;
    container.type = el;
    changed("", container);
  };

  const overlayRef = useRef(null);
  
  const clickOutsideHandler = (e) => {
    e.stopPropagation()
    const target = e?.target
    
    if (
      overlayRef.current &&
      (!target 
        || !overlayRef.current.contains(target)
      )
    ) {
      setOpen(null);
    }
  }
  
  const clickOutsideHandler1 = (e) => {
    e.stopPropagation()
    setOpen(null)
  };

  useEffect(() => {
    window.addEventListener("click", clickOutsideHandler);
    window.addEventListener("blur", clickOutsideHandler1);
    return () => {
      window.removeEventListener("click", clickOutsideHandler);
      window.removeEventListener("blur", clickOutsideHandler1);
    };
  }, []);

  return (
    <>
      <div
        styleName="createVars_btn"
        data-type={type}
        ref={overlayRef}
        data-point={!!changed && (!!func || arrow) && !readOnly}
        data-arrow={arrow}
        data-faded={faded}
        onClick={e => {
          if (!readOnly) {
            _open(e)
          }
        }}
      >
        {currentTypes.get(type)?.iconText && (
          <span style={{ lineHeight: "15px" }} data-type={type}>
            {currentTypes.get(type).iconText}
          </span>
        )}
        {currentTypes.get(type)?.iconLink && (
          <img
            style={{ maxHeight: "10px", marginRight: hasMargin ? "10px" : 0 }}
            src={currentTypes.get(type).iconLink}
          />
        )}

        {!container && transS(currentTypes.get(type)?.transVar)}
        {list && (
          <div styleName="typeSelector">
            {list.map((el) => func(el, container.type === el, () => click(el)))}
          </div>
        )}
      </div>
      {plus && (
        <span styleName="createVars_btn_add">
          {transS(currentTypes.get(type).transVar)}
        </span>
      )}
      {hasDropDownArrow && (
        <img
          src="/images/arrow_10x6.svg"
          style={{ cursor: readOnly ? 'default' : "pointer" }}
          alt="arrow"
          onClick={(e) => {
            // e.stopPropagation();
            if (overlayRef.current) {
              overlayRef.current.click();
            }
          }}
        />
      )}
    </>
  );
};
export default CSSModules(DropDownType, styles, { allowMultiple: true });
