import React from 'react'
import cx from 'classnames'
import styles from './styles.module.css'

export default function Footer({ cancelCallback, replaceCallback }) {
  return (
    <div className={styles.container}>
      <button className={cx(styles.button, styles.red)} onClick={replaceCallback}>Заменить</button>
      <button className={styles.button} onClick={cancelCallback}>Не заменять</button>
    </div>
  )
}
