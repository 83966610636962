import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './clauseLib.css'
import { transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';
import ShowCategories from './ShowCategories';
import Button from '../../../../components/button/Button';


const Categories = params => {
  let {cats, folders, callback, isClause, title,
    closedFolders, setOpenFolders, restriction } = params;

  const categories = folders.filter(el => el.id !== 1)

  const prevCats = new Set(Array.from(cats))

  const ref = useRef(),
    [res, setRes] = useState(new Set(Array.from(cats))),
    restricted = new Set([1])
    if (restriction) restricted.add(restriction)

  const clickOutsideHandler = (e) => {    
    if (!ref.current?.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();

      // callback(res);
      callback(prevCats);
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler)
    return () => {
        window.removeEventListener('click', clickOutsideHandler)
    }
  }, [clickOutsideHandler])

  return (
    <div ref={ref} styleName="cats">
      <div className='h3 marginb20'>{title || transS('Select Category')}</div>
      {categories.length === 0 && (
        <h3>{transS('There are no categories available yet')}</h3>
      )}
      <ShowCategories 
        folders={folders}
        closedFolders={closedFolders}
        setOpenFolders={setOpenFolders} 
        callback={(f, i) => {
          if (res.has(f.id))
              res.delete(f.id)
          else
              res.add(f.id)

          if (isClause)
              setRes(new Set(res))
          else { 
            if (!res.has(f.id)) {
              const corrected = new Set([])
              setRes(corrected)
            } else {
              const corrected = new Set([f.id])
              setRes(corrected)
            }
            // if (title && res.size) {
              // callback(corrected)
            // }
            // else callback(res)        
          }
        } }
        selectedFolders={res} 
        restricted={restricted} 
        isPopup={true}
        hasButtons={true}
      />
      <div styleName={'buttonContainer'}>
        <Button
          style={{ paddingBottom: '2px', marginRight: '0px' }} 
          text={transS('Apply')} 
          mod='blue fill' 
          callback={() => callback(res)} 
          disabled={categories.length === 0}
        />
        <Button 
          style={{ paddingBottom: '2px', marginRight: '0px' }} 
          text={transS('Cancel ')} 
          mod='blue arch' 
          callback={() => callback(prevCats)}
        />
      </div>
    </div>
  )
}

export default CSSModules(Categories, styles, {allowMultiple: true});