import React, { useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import Variables from './Variables';
import DropDownType from './DropdownType.js';
import { addVariableAction } from '../../../../../../redux/document/actions';
import { transS } from '../../../../../../services/helpers/lang';
import Clauses from './Clauses.jsx';

const TemplatesUnderMenu = ({ hideVars }) => {
    // type: 'variables' | 'clauses'
    const [tab, setTab] = useState('variables')
    const dispatch = useDispatch();
    const {insertOn, canPaste, frameProgress} = useSelector(state => state.paragraphReducer);
    const insertVar = () => {
      if (!insertOn || !canPaste) return;
      frameProgress.sendToFrame({c1:'variable', action: 'insert', id: 0});
    }
    const clickHandler = (e) => {
        const type = e.target.dataset?.type;
        if (!type) return;
        if (type === 'if') return insertVar();
        e.preventDefault();
        e.stopPropagation();
        dispatch(addVariableAction(type));
    }
    return (
      <div styleName='container'>

        {tab === 'variables' && (
          <Variables tab={tab} setTab={setTab} clausesCount={1} />  
        )}

        {tab === 'clauses' && (
          <Clauses tab={tab} setTab={setTab} clausesCount={1} />
        )}

        {!hideVars && <div styleName='wrapper'>
            <div styleName='createVars'>
                <div styleName='createVars_title'>{transS('Variables')}:</div>
                <div styleName='createVars_btns' onClick={clickHandler}>
                   <DropDownType type="text" />
                   <DropDownType type="number" />
                   <DropDownType type="date" />
                   <DropDownType type="dropdown" />
                   <div styleName="divider"></div>
                   <DropDownType type="if" faded={!insertOn || !canPaste}/>                   
                </div>
            </div>
        </div> }
      </div>
    )
}

export default CSSModules(TemplatesUnderMenu, styles, {allowMultiple: true})