import React from "react";
import cx from "classnames";
import styles from "./styles.module.css";
import Textarea from "../../../../../../components/topMenu/elements/Textarea/Textarea.jsx";
import Checkbox from "../../../../../../components/topMenu/elements/Checkbox/Checkbox.jsx";

export default function VarItemBody({
  id,
  title,
  hint,
  required,
  isEditVar,
  setEditVar,
  changeVariable,
  isVisibleLeftLine,
  checkAndUpdateName,
  readOnly = false
}) {
  return (
    <div className={styles.body} onClick={() => setEditVar(id)}>
      <div className={styles.inputs}>
        <span style={{ position: 'relative', display: 'inline-flex' }}>
          {isVisibleLeftLine && (
            <div className={styles.leftLine} />
          )}
          <Textarea
            value={title}
            className={styles.title}
            onChange={(value) => changeVariable({ id, key: "title", value })}
            placeholder={"Введите вопрос"}
            hasUnderline={isEditVar}
            readOnly={readOnly}
            onBlur={() => {
              checkAndUpdateName(id, 'title')
            }}
          />
        </span>
        <Textarea
          value={hint}
          className={styles.hint}
          onChange={(value) => changeVariable({ id, key: "hint", value })}
          placeholder={"Добавить подсказку"}
          hasUnderline={isEditVar && hint.length > 0}
          readOnly={readOnly}
        />
      </div>
      {isEditVar && (
        <Checkbox
          checked={required}
          onChange={(e) =>
            changeVariable({ id, key: "required", value: e.target.checked })
          }
          label={"Обязательное*"}
          color={"blue"}
          size={"md"}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}
