import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewDoc from './views//paragraphList/ViewDoc';
import ProjectList from './views/projectList/ProjectList';
import PageNotFound from '../pageNotFound/PageNotFound';
import PubSubServer from '../../services/PubSub/PubSub';
import TopMenu from "./components/topMenu/topMenu";
import {TempUserView} from "./views/tempUserView/TempUserView"
import HelpMenu from './components/help/HelpMenu'
import api from '../../services/api/api';
import { matchPath, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
//import { TopDocMenu } from './components/topMenu/TopDocMenu';

const TermsUse = lazy(() => import("../publicArea/legal/TermsUse"))
const DataProtection = lazy(() => import("../publicArea/legal/DataProtection"))
const DataProtectionEU = lazy(() => import("../publicArea/legal/DataProtectionEU"))
const DataProtectionGlobal = lazy(() => import('../publicArea/legal/DataProtectionGlobal'))
const TemplatesList = lazy(() => import("./views/TemplatesList/TemplatesList"));
const ParagraphList = lazy(() => import('./views/paragraphList/ParagraphList'));
const ClauseLib = lazy(() => import('./views/clauseLib/ClauseLib'))
const CompanySettings = lazy(() => import('./views/companySettings/companySettings'))

class PrivateArea extends Component {
  constructor (props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props.location.pathname)
    console.log(123, !!matchPath(this.props.location.pathname, {
      path: "/:projectId/docview/:documentId/:selector/:docName(.*)",
      exact: true,
    }) )
  }

  onClickArea = () => {
    let event = new Event('clickOnArea');
    window.dispatchEvent(event);
  }

  render () {
    const isTempUser = this.props.user.temporary
    return (
      <div onMouseDown={this.onClickArea} style={{position: 'relative', padding: '0 40px', minHeight: '100vh'}}>
        
        <TopMenu />

        <HelpMenu />
        <Suspense fallback="">
          <Switch>
            <Route exact path='/legal/terms-of-use' component={TermsUse} />
            <Route exact path='/legal/data-protection' component={DataProtection} />
            <Route exact path='/legal/data-protection/eu' component={DataProtectionEU} />
            <Route exact path='/legal/data-protection/global' component={DataProtectionGlobal} />
            {isTempUser && <Route exact path='/:projectId/documents/:documentId' component={ParagraphList}/>}
            {isTempUser && <Redirect exact from='/:projectId/documents' to='/'/>}
            {isTempUser && <Route path='/' component={TempUserView}/>}
            <Route exact path='/' component={ProjectList}/>
            <Route exact path='/#/:projectId' component={ProjectList}/>
            <Redirect from="/login" to={`/${window.location.search ? '#' + window.location.search : ''}`}/>
            <Redirect from="/new" to={`/#?redirect=newProject`}/>
            <Route exact path='/sign-in' component={ProjectList}/>
            <Route exact path='/company' component={CompanySettings}/>
            <Route exact path='/templates' component={TemplatesList}/>
            <Route exact path='/clauses' component={ClauseLib}/>
            <Route exact path='/:projectId/documents' component={ProjectList}/>
            <Route exact path='/template/:templateId' component={ParagraphList}/>
            <Route exact path='/:projectId/documents/:documentId' component={ParagraphList}/>
            <Route exact path='/:projectId/docview/:documentId/:selector/:docName' component={ViewDoc}/>
            <Route exact path='/:projectId/documents/:documentId/:itemId' component={ParagraphList}/>
            <Route exact path='/:projectId/documents/:documentId/history/:historyDocumentId' component={ParagraphList}/>
            <Route exact path='/:projectId/documents/:documentId/history/:historyDocumentId/:itemId' component={ParagraphList}/>
            <Route exact path='/not-found/:error' component={PageNotFound}/>
            {/*<Route path='*' component={PageNotFound}/>*/}
          </Switch>
        </Suspense>
      </div>
    );
  }
};

export default withRouter(PrivateArea);

export const pubSub = new PubSubServer();
