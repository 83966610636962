import React, { useState } from 'react'
import Checkbox from '../../../../../../../../../components/topMenu/elements/Checkbox/Checkbox.jsx'
import Textarea from '../../../../../../../../../components/topMenu/elements/Textarea/Textarea.jsx'
import Conditional from '../../../../../../../../../components/topMenu/elements/TemplatesUnderMenu/Conditional'
import { transS } from '../../../../../../../../../../../services/helpers/lang.js'
import { variableTypes } from '../../../../../../../../../../../consts/variableTypes.js'
import cx from 'classnames'
import styles from './styles.module.css'
import { useSelector } from 'react-redux'
import DropDownType from '../../../../../../../../../components/topMenu/elements/TemplatesUnderMenu/DropdownType.js'

const currentTypes = new Map([
  ['text', variableTypes.get('text')],
  ['number', variableTypes.get('number')],
  ['date', variableTypes.get('date')],
])

const _getDropDown = (el, arrow, func) => {
  if (el === "if") return false;

  return (
    <DropDownType
      key={el}
      type={el}
      hasMargin={false}
      container={false}
      changed={func}
      arrow={arrow}
    />
  );
};

export default function Advanced({ 
  variableId,
  optionId,
  defaultValue, 
  conditional,
  isInput,
  type,
  changeVariable,
  isUp,
  isDown,
  moveUp,
  moveDown,
  removeOption,
  readOnly = false,
}) {
  const varlist = useSelector(state => state.userReducer.varlist)
  const conditionals = varlist.filter(el => el.id !== variableId)

  const [isShowDefaultValue, setShowDefaultValue] 
    = useState(defaultValue && defaultValue.trim().length !== 0)
  const [isShowConditional, setShowConditional] = useState(!!conditional)

  return (
    <div className={styles.container}>
      <Checkbox 
        label={'Сделать полем'}
        checked={isInput}
        onChange={(e) => {
          changeVariable({
            id: variableId, 
            key: 'isInput', 
            isOption: true, 
            value: e.target.checked,  
            optionId
          })
        }}
        className={cx({ [styles.readonly]: readOnly })}
        readOnly={readOnly}
      />

      {isInput && (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DropDownType
              type={type}
              container={{ type }}
              types={currentTypes}
              changed={(_, data) => {
                if (readOnly) return

                changeVariable({
                  id: variableId, 
                  key: 'type', 
                  isOption: true, 
                  value: data.type,  
                  optionId
                })
              }}
              func={readOnly ? () => {} : _getDropDown}
              hasMargin={false}
              hasDropDownArrow={true}
              readOnly={readOnly}
            />
          </div>

          {type !== 'date' && (
            <button 
              className={styles.button} 
              onClick={e => {
                e.stopPropagation()
              }}
              disabled={readOnly}
            >
              Добавить формат
            </button>
          )}

          {!isShowDefaultValue && (
            <button 
              className={styles.button}
              onClick={(e) => {
                e.stopPropagation()
                setShowDefaultValue(true)
              }}
              disabled={readOnly}
            >
              Добавить значение по умолчанию
            </button>
          )}
          {isShowDefaultValue && (
            <div>
              <p className={styles.label}>Значение по умолчанию</p>
              <Textarea
                className={cx(styles.textarea, { [styles.readonly]: readOnly })}
                value={defaultValue ?? ""}
                onChange={(value) => {
                  changeVariable({
                    id: variableId, 
                    key: 'defaultValue', 
                    isOption: true, 
                    value,  
                    optionId
                  })
                }}
                readOnly={readOnly}
              />
            </div>
          )}

          {!isShowConditional && (
            <button 
              className={styles.button} 
              onClick={(e) => {
                e.stopPropagation()
                setShowConditional(true)
              }}
              disabled={readOnly}
            >
              Добавить условие
            </button>
          )}
          {isShowConditional && (
            <div>
              <p className={styles.label}>
                {transS("Condition for This Variable")}
              </p>
              <Conditional
                el={{ conditional }}
                varlist={conditionals}
                isAdminPart={true}
                changedVarList={({ res, isCondVar }) => {
                  if (readOnly) return 

                  const data = { ...conditional }
                  if (isCondVar) {
                    data.condVar = res
                    data.condOption = null
                  } else {
                    data.condOption = res
                  }

                  changeVariable({ 
                    id: variableId, 
                    key: 'conditional', 
                    value: data,
                    isOption: true,
                    optionId
                  })
                }}
                kill={(e) => {
                  if (readOnly) return 

                  e.stopPropagation()
                  changeVariable({
                    id: variableId, 
                    key: 'conditional', 
                    isOption: true, 
                    value: null,  
                    optionId
                  })
                  setShowConditional(false)
                }}
                readOnly={readOnly}
              />
            </div>
          )}
        </>
      )}

      <div className={styles.advancedTools}>
        <button
          onClick={e => {
            e.stopPropagation()
            moveUp({ variableId, optionId })
          }}
          className={styles.toolsItem}
          style={{ opacity: (isUp || readOnly) ? 0.3 : 1 }}
          disabled={readOnly}
        >
          <img
            src="/images/arrow_blue_12x10.svg"
            style={{ rotate: "180deg" }}
          />
          {transS("Up")}
        </button>
        <button
          onClick={e => {
            e.stopPropagation()
            moveDown({ variableId, optionId })
          }}
          className={styles.toolsItem}
          style={{ opacity: (isDown || readOnly) ? 0.3 : 1 }}
          disabled={readOnly}
        >
          <img src="/images/arrow_blue_12x10.svg" />
          {transS("Down")}
        </button>
        <button
          onClick={(e) => {
            e.stopPropagation()
            removeOption({ variableId, optionId })
          }}
          className={cx(styles.toolsItem, styles.toolItemRemove)}
          style={{ opacity: readOnly ? 0.3 : 1 }}
          disabled={readOnly}
        >
          <img src="/images/deleteIcon_10x12.svg" />
          {transS("Delete")}
        </button>
      </div>
    </div>
  )
}
