import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './ResetPassword.css';

import { inputValidation, blurValidation, submitValidation } from '../../../../common/validation';
import api from '../../../../../src/services/api/api';


import Button from '../../../../components/button/Button';
import { transEL, transLoc } from '../../../../services/helpers/lang';

// @connect(null, {
//   logInAction
// })
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class ResetPassword extends Component {
    state = {
      email: {
        value: '',
        dirty: false,
        invalid: false
      },
      success: false,
      message: ''
    };

    _input = e => {
      this.setState({ message: '' });
      inputValidation(this, e.currentTarget);
    };

    _blur = e => blurValidation(this, e.currentTarget);

    _submit = async e => {
      e.preventDefault();

      if (submitValidation(this, [ 'email'])) return;

      let { email } = this.state;

      let message = '';

      try {
        await api.sendEmailToResetPassword(email.value);
        this.setState({ success: true });
      } catch (err) {
        switch (err.response.status) {
        case 400:
          message = 'Please try again!';
          break;
        case 404:
          message = 'Email is not registered';
          break;
        default:
          message = '';
        }
        this.setState({ message });
        return;
      }

      this.setState({
        email: { ...email, value: '', message: '' }
      });
    };

    render() {
      let { email, message, success } = this.state;

      return (
        <main styleName='wrapper'>
          
          {!success

            ? <div styleName='inner'>
              <h1 styleName='title'>{transLoc('Recover your password')}</h1>
              <form onSubmit={this._submit} noValidate>
                <div styleName='form-inner'>
                  <label styleName='row'>
                    <div styleName='error-message'>
                      {
                        (() => {
                          if (email.invalid === 'required') return transEL('requiredEmail');
                          else if (email.invalid === 'email') return transEL('errorEmail');
                          else if (email.invalid === 'maxLength') return transEL('maxLengthEmail');
                        })()
                      }
                    </div>
                    <input styleName='input'
                      type='email'
                      name='email'
                      value={email.value}
                      onChange={this._input}
                      onBlur={this._blur}
                      autoComplete='off'
                      placeholder={transLoc('Email address')} />
                  </label>
                </div>
                {
                  message && <div styleName='message-wrapper'>{message}</div>
                }
                <div styleName='button-row'>
                  <Button text={transLoc('Send instructions')} mod='promoMainBtn freePad' type='submit' />
                </div>
              </form>
              <NavLink styleName='link' to='/login'>{transLoc('Return to the login screen')}</NavLink>
            </div>

            : <div styleName='inner'>
              <h1 styleName='title _success'>We will send you an instruction in 1 minute.</h1>
              <p styleName='slog _success'>Check your email</p>
            </div>
          }

        </main>
      );
    }
}
