import React from 'react'
import styles from './styles.module.css'
import Textarea from '../../../../../../../../../components/topMenu/elements/Textarea/Textarea.jsx'
import Checkbox from '../../../../../../../../../components/topMenu/elements/Checkbox/Checkbox.jsx'
import cx from 'classnames'

export default function Body({ 
  isDefault, 
  option, 
  tip, 
  changeVariable,
  variableId,
  optionId,
  readOnly = false,
}) {
  return (
    <div className={styles.container}>
      <div>
        <Textarea 
          value={option}
          onChange={(value) => {
            changeVariable({
              id: variableId, 
              key: 'option', 
              isOption: true, 
              value,  
              optionId
            })
          }}
          placeholder={'Назовите опцию'}
          hasUnderline={true}
          className={cx(
            styles.option, 
            { isDefault }, 
            { isEmpty: option.length === 0 },
            { [styles.readonly]: readOnly }
          )}
          readOnly={readOnly}
        />
        <Textarea 
          value={tip}
          onChange={(value) => {
            changeVariable({
              id: variableId, 
              key: 'tip', 
              isOption: true, 
              value,  
              optionId
            })
          }}
          placeholder={'Добавить подсказку'}
          hasUnderline={tip.length !== 0}
          className={cx(
            styles.hint,
            { isEmpty: tip.length === 0 },
            { [styles.readonly]: readOnly }
          )}
          readOnly={readOnly}
        />
      </div>
      <Checkbox 
        label={'По умолчанию'}
        checked={isDefault}
        onChange={(e) => {
          changeVariable({
            id: variableId, 
            key: 'isDefault', 
            isOption: true, 
            value: e.target.checked,  
            optionId
          })
        }}
        className={cx(styles.checkbox, { [styles.readonly]: readOnly })}
        readOnly={readOnly}
      />
    </div>
  )
}
