import React, { Component } from 'react';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './projectList.css';
import ProjectLines from './projectLines';
import { oldPermissionCheck } from '../../../../common/validation';
import { transS } from '../../../../services/helpers/lang';

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class CompanyLines extends Component {
   state = {
      expanded: true
   };
   
    render() {
      let { expanded } = this.state;
      let { projectList, projectDocs, projectFunc, company, 
         findFilter, openChangeNamePopup, user } = this.props;
      return (
        <div styleName='companyWrapper' >
           <div className="flex" onClick={() => this.setState({expanded: !expanded})}>
              <span styleName='companyTitle'>{company ? company : transS('Unspecified Companies')}</span>
              <div styleName={cx("expander", {'_expanded': expanded})} ></div>                
           </div>
           { expanded && projectList.filter(p => company ? p.company === company : !p.company)
             .map(p => 
                <ProjectLines project={p} shifted="1" 
                  findFilter={findFilter}
                  projectDocs = {projectDocs.filter(d => d.project === p)}
                  openChangeNamePopup={openChangeNamePopup} user={user} 
                  dragHandler={this.props.dragHandler} dropHandler={this.props.dropHandler}
                  isCreator ={((user._id === p.creator) && (!oldPermissionCheck() || user.ticks > 0))} addFileAction={this.props.addFileAction}
                  projectFunc={projectFunc} key={p._id} isDragActive={this.props.isDragActive} plusSvg={this.props.plusSvg}/>
             )}
        </div>
      );
    }
}
