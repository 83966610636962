import React, { memo, useCallback } from 'react'
import CSSModules from 'react-css-modules';
import styles from '../analysis.css';
import cx from 'classnames'
import AnalysisListItemBody from './AnalysisListItemBody.jsx';
import AnalysisListItemFooter from './AnalysisListItemFooter.jsx';
import AnalysisListItemAdvanced from './AnalysisListItemAdvanced.jsx';

// id={el.id}
// isResolve={el.isResolve}
// notResolveButRisk={el.notResolveButRisk}
// defaultText={el.defaultText}
// correctionText={el.correctionText}
// correctionComment={el.correctionComment}
// policyId={el.policyId}
// policy={el.policy}
// ruleId={el.ruleId}
// rule={el.rule}
// date={el.date}
// activeToggler={setActiveItem}
// isActive={openItemId === el.id}
// deleteItem={deleteItem}
// hideElement={hideElement}
// setNotCriticalRisk={setNotCriticalRisk}
// isFirstElement={list[0].id === el.id}
// isLastElement={list.at(-1).id === el.id}
// moveNext={moveNext}
// movePrev={movePrev}

function AnalysisListItem({
  id,
  isResolve,
  notResolveButRisk,
  defaultText,
  correctionText,
  correctionComment,
  policyId,
  policy,
  ruleId,
  rule,
  ruleContent,
  date,
  isActive,
  activeToggler,
  deleteItem,
  hideElement,
  moveNext,
  movePrev,
  isFirstElement,
  isLastElement,
  setResolve,
  setRiskButNotResolve
}) {

  const resolveCurrentItem = useCallback(() => setResolve(id), [id, setResolve])
  const setRiskButNotResolveCurrentItem = useCallback(() => setRiskButNotResolve(id), [id, setRiskButNotResolve])
  const setActive = useCallback(() => activeToggler(id), [id, activeToggler])
  const deleteCallback = useCallback(() => deleteItem(id), [id, deleteItem])
  const hideCallback = useCallback(() => hideElement(id), [id, hideElement])
  const moveNextCallback = useCallback(() => moveNext(id), [id, moveNext])
  const movePrevCallback = useCallback(() => movePrev(id), [id, movePrev])

  const isResolved = isResolve || notResolveButRisk
  const hasCorrectionText = !!correctionText && correctionText.length !== 0

  return (
    <div 
      styleName={cx(
        'listItem', 
        {active: isActive, resolved: isResolved}
      )}
      onClick={() => {
        if (isResolved) setActive()
      }}
      data-analysis-item={true}
    >
      <AnalysisListItemBody 
        defaultText={defaultText}
        correctionComment={correctionComment} 
        date={date}
        policy={policy}
        rule={rule}
        ruleContent={ruleContent}
        isActive={isActive}
        isFirst={isFirstElement}
        isLast={isLastElement}
        moveNext={moveNextCallback}
        movePrev={movePrevCallback}
      />

      {isActive && hasCorrectionText && (
        <AnalysisListItemAdvanced 
          defaultText={defaultText}
          correctionText={correctionText}
          correctCallback={resolveCurrentItem}
        />
      )}
      
      {(!isResolved || isActive) && (
        <AnalysisListItemFooter
          isActive={isActive}
          setRiskButNotResolve={setRiskButNotResolveCurrentItem}
          correctCallback={resolveCurrentItem}
          deleteCallback={deleteCallback}
          setActive={setActive}
          hideCallback={hideCallback}
        />
      )}
    </div>
  )
}

export default memo(CSSModules(AnalysisListItem, styles, {allowMultiple: true}));