import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import CSSModules from 'react-css-modules';
import styles from './scrollBar.css';

let invisible = false;
export class ScrollBar extends Component {
  constructor(props) {
    super(props);
    invisible = props.invisible;
  }

  renderThumb({ style, ...props }) {
    return (
      <div {...props} style={{ ...style, width: '7px' }} 
      className={invisible ? styles.invisible : styles.thumb} />
    );
  }

  renderTrack({ style, ...props }) {
    return (
      <div {...props} style={{ ...style, width: '7px' }} className={styles.track} />
    );
  }
  
  blank({ style, ...props }) {
    return (
       <div></div>
    );
  }

  render() {
    return (
      <Scrollbars
        renderTrackVertical={this.props.invisible ? this.blank : this.renderTrack}
        renderTrackHorizontal={this.blank}
        renderThumbVertical={this.props.invisible  ? this.blank : this.renderThumb}
        renderThumbHorizontal={this.blank}
        style={this.props.style}
        
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflow: (this.props.overflowVisible ? "visible" : "scroll") }} />
        )}
      >
        {this.props.children}
      </Scrollbars>
    );
  }
}

export default new CSSModules(ScrollBar, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });
