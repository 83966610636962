import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './popup.css'
import { transS } from '../../../../../services/helpers/lang'

// export default 
function PopupBody() {
  return (
    <div styleName='popupBody'>
      <h4>{transS('Delete Risk') + '?'}</h4>
      <p>{transS('This cannot be undone.')}</p>
    </div>
  )
}

export default CSSModules(PopupBody, styles, {allowMultiple: true})