import React, {Component} from 'react';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './projectList.css';
import Dropzone from "react-dropzone"
import Button from "../../../../components/button/Button"
import { transS } from '../../../../services/helpers/lang';

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class ProjectLines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            semihide: true,
        }
    }

    render() {
        let {expanded, semihide, transferNames} = this.state;
        let {project, projectDocs, projectFunc, shifted, isDragActive, 
            findFilter, plusSvg, dragHandler, dropHandler} = this.props;
            
        if (findFilter && !projectDocs?.length && !findFilter.has(project._id))
            return false
        return (
            <div styleName={cx('projectWrapper', {'_shifted': shifted})} key={project._id}>
                <div style={{position: 'relative'}}>
                    <div className="flex">
                      <span styleName='projectTitle'
                            onClick={() => projectFunc('project', project)}>{project.title}</span>
                        {projectDocs && projectDocs.length > 0
                        && <div styleName={cx("expander", {'_expanded': expanded})}
                                onClick={() => this.setState({expanded: !expanded, semihide: true})}/>}
                    </div>
                    {projectDocs?.length > 0 && expanded &&
                    projectDocs.map((el, i) => (!semihide || i < 5) ?
                        projectFunc('doc', el, project) : '')}
                    {projectDocs?.length > 0 && expanded && semihide
                    && projectDocs.length > 5
                    && <div styleName={cx('seeAll', {'_shifted': shifted})} key={project._id}
                            onClick={() => this.setState({semihide: !semihide})}>{transS('See All for This Project')}</div>}

                    <Dropzone styleName={cx('dragScreen pj', {'small': !projectDocs || projectDocs.length < 3, 'vis': isDragActive})} activeClassName={styles.dragActive}
                              onDrop={(x,y) => dropHandler(x,y, project)} 
                              onDragOver={dragHandler} accept='.doc, .docx'>
                        {plusSvg()}{transS('Upload Document')}
                    </Dropzone>
                </div>

                {/*{!!transferNames.length && <React.Fragment>*/}
                {/*    <div className='h3' style={{lineHeight: 'normal', marginTop: '20px'}}>Documents to upload</div>*/}
                {/*    {transferNames.map((el, index) =>*/}
                {/*        <div ref={e => this[`${el}-${index}`] = e} styleName='acme' key={el}>*/}
                {/*            <img src="../../../../images/icons-doc.png" alt='doc-icon'/><span>{el}</span>*/}
                {/*            <div onClick={e => this.deleteDocumentFromDownload(e)} className='close-cross'*/}
                {/*                 styleName='disDoc'>+*/}
                {/*            </div>*/}
                {/*        </div>)}*/}
                {/*    <div styleName='oneProject-controls'>*/}
                {/*        <Button text='Confirm Upload' mod='blue fill'*/}
                {/*                callback={() => this._uploadDocuments()}/>*/}
                {/*    </div>*/}
                {/*</React.Fragment>}*/}
            </div>
        );
    }
}
