import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './formatting.css';
import { setPreviousColor } from '../../../../redux/paragraph/actions';
import { shdColors, standartTextColors } from '../../../../../server/configs/appConfig';
import { transS } from '../../../../services/helpers/lang';
import ColorTooltip from './ColorTooltip';
import { setIsColorsOpened } from '../../../../redux/paragraph/actions';

const BIH = ({sendToFrame, isBold, isItalic}) => {
  const {formatting, previousColor} = useSelector(state => state.paragraphReducer)
  const [showColors, setShowColors] = useState(false)
  const [showTextColors, setShowTextColors] = useState(false)
  const 
    selected = b => b ? " selected" : "",
    formatable = !!formatting?.fontFamily,
    dispatch = useDispatch(),
    colorRef = useRef(null),
    textColorRef = useRef(null)

    const outsideClickHandler = e => {
        if (colorRef.current && colorRef.current.style.display !== 'none'
             && !colorRef.current.parentElement.contains(e.target)) 
            {
            setShowColors(false)
        } else if (textColorRef.current && textColorRef.current.style.display !== 'none'
          && !textColorRef.current.parentElement.contains(e.target)) 
         {
          setShowTextColors(false)
     }
    }
      
    useEffect(() => {
        window.addEventListener('click', outsideClickHandler);
        return () => {
          window.removeEventListener('click', outsideClickHandler);
        };
    }, []);
    

  const setHighlight = (number, formatable) => {
    dispatch(setPreviousColor(number))
    sendColorObj(number, formatable)
    setShowColors(false)
  }

  const setTextColor = (number) => {
    sendToFrame({ c1: "format", format: {color: standartTextColors[number]}})
    setShowTextColors(false)
  }

  function rgbToHex(rgb) {
    const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (!match) return null; // Проверка на корректность формата
    const [_, r, g, b] = match;
    return `#${[r, g, b].map(x => Number(x).toString(16).padStart(2, '0')).join('')}`.toLowerCase();
  }

  const sendColorObj = (number, formatable) => sendToFrame({ c1: "format", 
    format: {backgroundColor: shdColors[number]},
    color: formatable ? null : number })

  useEffect(() => setShowColors(false), [formatting])

  useEffect(() => {
    dispatch(setIsColorsOpened(showColors || showTextColors))
    console.log(showColors, showTextColors)
  }, [showColors, showTextColors])

  return <div styleName="BIH">
    <span
      styleName={"sign bold clickable" + selected(isBold)}
      onClick={() => sendToFrame({
          c1: "format", 
          format: { fontWeight: isBold ? "normal" : "bold" },
          color: formatable ? null : 'b'
        } )}>B</span>
    <span
      styleName={"sign italic clickable" + selected(isItalic)}
      onClick={() => sendToFrame({
          c1: "format",
          format: { fontStyle: isItalic ? "normal" : "italic" },
          color: formatable ? null : 'i'
        } )}>I</span>
    {/* Пока что отключаем !!!FALSE!!! изменение цвета текста */}
    {!!formatting && <div styleName={"color-picker clickable"} style={{position: "relative"}} onClick={() => {setShowTextColors(!showTextColors)}}>
      <span styleName={"sign color" + (showTextColors ? " zindex3" : "")} style={{color: formatting.color}}>A</span>
      <div styleName={"arrow-container" + (showTextColors ? " zindex3" : "")}>
      <div className={"arrow" + (showTextColors ? " arrowOpen" : "")}></div>
      </div>
      <div style={{display: showTextColors ? 'block' : 'none'}}
        styleName='highlights' ref={textColorRef}>
          {/* <ColorTooltip> */}
          {/* <div styleName='noHighlight clickable'
            onClick={() => setTextColor(0)}>{transS('No Highlight')}</div> */}
          <div style={{display: 'flex'}}>
            <div styleName={'highlightBorder' + (rgbToHex(formatting.color) === standartTextColors[0] ? ' active' : '')}>
              <div styleName={'mainHighlight clickable' + (rgbToHex(formatting.color) === standartTextColors[0] ? ' active' : '')} 
                style={{backgroundColor: standartTextColors[0]}} 
                onClick={() => setTextColor(0)}><span>{transS("Auto")}</span></div>
            </div>
            <div styleName='smallHighlights'>
              {standartTextColors.map((el, i) => (i > 0) && 
                <div key={i} styleName={(rgbToHex(formatting.color) === el ? 'active' : (el === "#ffffff" ? 'white': ''))}>
                  <div styleName={'clickable'} 
                    style={{backgroundColor: el}} 
                    onClick={() => setTextColor(i)}></div>
                </div>)}
            </div>
          </div>
          {/* </ColorTooltip> */}
        </div>
    </div>}
    {!!formatting && <div styleName="back-picker clickable" onClick={() => setShowColors(!showColors)}>
      {/* <svg onClick={() => sendColorObj(previousColor, formatable)}
        width="15" height="15" viewBox="0 0 40 40">
        <g fill="none" fillRule="evenodd">
          <path fill="#157EFB" d="M10 0 V40 H40 V0" />
          <path fill="#157EFB" d="M0 5 V35 H6 V5" />
          <path fill="#157EFB" d="M0 17 H10 V24 H0" />
          <path fill="#FFFFFF" d="M10 17 H18 V24 H10" />
          <path fill="#FFFFFF" d="M17 5 V35 H23 V5" />
        </g>
      </svg> */}
      <div styleName={"sign bgColor" + (showColors ? " zindex3" : "")} style={{ backgroundColor: formatting.backgroundColor, marginBottom: "2px"}}>A</div>
      {/* <span onClick={() => sendColorObj(previousColor, formatable)} // Полоска с предыдущим цветом
        styleName="pickColor"
        style={{ backgroundColor: shdColors[previousColor] }}
      ></span> */}
      <div styleName={"arrow-container" + (showColors ? " zindex3" : "")}>
      <div className={"arrow" + (showColors ? " arrowOpen" : "")}></div>
        </div>
      <div style={{display: showColors ? 'block' : 'none'}}
        styleName='highlights' ref={colorRef}>
          {/* <div styleName='noHighlight clickable'
            onClick={() => setHighlight(0, formatable)}>{transS('No Highlight')}</div> */}
          <div style={{display: 'flex'}}>
            <div styleName={'noHighlight clickable ' + (formatting.backgroundColor === "rgba(0, 0, 0, 0)" ? 'active' : '')} 
              style={{backgroundColor: shdColors[0]}} 
              onClick={() => setHighlight(0, formatable)}><span>{transS('None')}</span></div>
            <div styleName='smallHighlights'>
              {shdColors.map((el, i) => (i > 0) && 
                <div key={i} styleName={(rgbToHex(formatting.backgroundColor) === el ? 'active' : (el === "#ffffff" ? 'white': ''))}>
                  <div styleName='clickable' 
                    style={{backgroundColor: el}} 
                    onClick={() => setHighlight(i, formatable)}></div>
                </div>
                )}
            </div>
          </div>
        </div>
    </div>}
  </div>
}
export default new CSSModules(BIH, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});