import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './accountComponent.css';
import ScrollBar from '../../../../components/scrollBar/ScrollBar';
import { logout, showSidebarAction, setDateFormat,
  showPaymentAction, updateUserInfoAction, setIncorrectPasswordStateAction,
  deleteCompanyMemberAction, cancelCompanyInviteRequestAction } from '../../../../services/actions/userActions';
import { inputValidation, accountValidation} from '../../../../common/validation';
import Button from "../../../../components/button/Button"
import {Link, NavLink, Redirect} from "react-router-dom"
import JoinCompanyPopup from "./joinCompanyPopup"
import momentTZ from 'moment-timezone'
import moment from 'moment'
import { getDateString } from "../../../../common/getDateString"
import checkForMac from       "../../../../common/macCheck"
import { canTariffed, transF, transL, transS } from '../../../../services/helpers/lang';
import { validFileExtensions } from '../../../../../server/configs/appConfig';


const zonezzz = [
    [
        "(GMT-11:00)", "local time",
        ""
    ],
    [
        "(GMT-11:00)", "Midway Island, Samoa",
        "Pacific/Midway"
    ],
    [
        "(GMT-11:00)", "Pago Pago",
        "Pacific/Pago_Pago"
    ],
    [
        "(GMT-10:00)", "Hawaii",
        "US/Hawaii"
    ],
    [
        "(GMT-9:00)", "Alaska",
        "US/Alaska"
    ],
    [
        "(GMT-9:00)", "Juneau",
        "America/Juneau"
    ],
    [
        "(GMT-8:00)", "Vancouver",
        "America/Vancouver"
    ],
    [
        "(GMT-8:00)", "Pacific Time (US and Canada)",
        "Canada/Pacific"
    ],
    [
        "(GMT-8:00)", "Tijuana",
        "America/Tijuana"
    ],
    [
        "(GMT-7:00)", "Edmonton",
        "America/Edmonton"
    ],
    [
        "(GMT-7:00)", "Mountain Time (US and Canada)",
        "Canada/Mountain"
    ],
    [
        "(GMT-7:00)", "Arizona",
        "US/Arizona"
    ],
    [
        "(GMT-7:00)", "Mazatlan",
        "America/Mazatlan"
    ],
    [
        "(GMT-7:00)", "Chihuahua",
        "America/Chihuahua"
    ],
    [
        "(GMT-7:00)", "Yukon",
        "Canada/Yukon"
    ],
    [
        "(GMT-6:00)", "Winnipeg",
        "America/Winnipeg"
    ],
    [
        "(GMT-6:00)", "Saskatchewan",
        "Canada/Saskatchewan"
    ],
    [
        "(GMT-6:00)", "Central Time (US and Canada)",
        "Canada/Central"
    ],
    [
        "(GMT-6:00)", "Mexico City",
        "America/Mexico_City"
    ],
    [
        "(GMT-6:00)", "Guatemala",
        "America/Guatemala"
    ],
    [
        "(GMT-6:00)", "El Salvador",
        "America/El_Salvador"
    ],
    [
        "(GMT-6:00)", "Managua",
        "America/Managua"
    ],
    [
        "(GMT-6:00)", "Costa Rica",
        "America/Costa_Rica"
    ],
    [
        "(GMT-6:00)", "Tegucigalpa",
        "America/Tegucigalpa"
    ],
    [
        "(GMT-6:00)", "Monterrey",
        "America/Monterrey"
    ],
    [
        "(GMT-5:00)", "Montreal",
        "America/Montreal"
    ],
    [
        "(GMT-5:00)", "Eastern Time (US and Canada)",
        "Canada/Eastern"
    ],
    [
        "(GMT-5:00)", "Indiana (East)",
        "US/East-Indiana"
    ],
    [
        "(GMT-5:00)", "Panama",
        "America/Panama"
    ],
    [
        "(GMT-5:00)", "Bogota",
        "America/Bogota"
    ],
    [
        "(GMT-5:00)", "Lima",
        "America/Lima"
    ],
    [
        "(GMT-5:00)", "Acre",
        "America/Porto_Acre"
    ],
    [
        "(GMT-4:00)", "Halifax",
        "America/Halifax"
    ],
    [
        "(GMT-4:00)", "Puerto Rico",
        "America/Puerto_Rico"
    ],
    [
        "(GMT-4:00)", "Caracas",
        "America/Caracas"
    ],
    [
        "(GMT-4:00)", "Atlantic Time (Canada)",
        "Canada/Atlantic"
    ],
    [
        "(GMT-4:00)", "La Paz",
        "America/La_Paz"
    ],
    [
        "(GMT-4:00)", "Guyana",
        "America/Guyana"
    ],
    [
        "(GMT-3:30)", "Newfoundland and Labrador",
        "Canada/Newfoundland"
    ],
    [
        "(GMT-3:00)", "Santiago",
        "America/Santiago"
    ],
    [
        "(GMT-3:00)", "Montevideo",
        "America/Montevideo"
    ],
    [
        "(GMT-3:00)", "Recife",
        "America/Recife"
    ],
    [
        "(GMT-3:00)", "Buenos Aires, Georgetown",
        "America/Argentina/Buenos_Aires"
    ],
    [
        "(GMT-3:00)", "Greenland",
        "America/Recife"
    ],
    [
        "(GMT-3:00)", "Sao Paulo",
        "America/Sao_Paulo"
    ],
    [
        "(GMT-2:00)", "Fernando de Noronha",
        "America/Noronha"
    ],
    [
        "(GMT-1:00)", "Azores",
        "Atlantic/Azores"
    ],
    [
        "(GMT-1:00)", "Cape Verde Islands",
        "Atlantic/Azores"
    ],
    [
        "(GMT+0:00)", "Universal Time UTC",
        "Universal"
    ],
    [
        "(GMT+0:00)", "Greenwich Mean Time",
        "Greenwich"
    ],
    [
        "(GMT+0:00)", "Reykjavik(",
        "Atlantic/Reykjavik"
    ],
    [
        "(GMT+0:00)", "Dublin",
        "Europe/Dublin"
    ],
    [
        "(GMT+0:00)", "London",
        "Europe/London"
    ],
    [
        "(GMT+0:00)", "Lisbon",
        "Europe/Lisbon"
    ],
    [
        "(GMT+0:00)", "Nouakchott",
        "Africa/Nouakchott"
    ],
    [
        "(GMT+1:00)", "Belgrade, Bratislava, Ljubljana",
        "Europe/Belgrade"
    ],
    [
        "(GMT+1:00)", "Sarajevo, Skopje, Zagreb",
        "Europe/Sarajevo"
    ],
    [
        "(GMT+1:00)", "Casablanca",
        "Africa/Casablanca"
    ],
    [
        "(GMT+1:00)", "Oslo",
        "Europe/Oslo"
    ],
    [
        "(GMT+1:00)", "Copenhagen",
        "Europe/Copenhagen"
    ],
    [
        "(GMT+1:00)", "Brussels",
        "Europe/Brussels"
    ],
    [
        "(GMT+1:00)", "Amsterdam, Berlin, Rome, Stockholm, Vienna",
        "Europe/Amsterdam"
    ],
    [
        "(GMT+1:00)", "Amsterdam",
        "Europe/Amsterdam"
    ],
    [
        "(GMT+1:00)", "Rome",
        "Europe/Rome"
    ],
    [
        "(GMT+1:00)", "Stockholm",
        "Europe/Stockholm"
    ],
    [
        "(GMT+1:00)", "Vienna",
        "Europe/Vienna"
    ],
    [
        "(GMT+1:00)", "Luxembourg",
        "Europe/Luxembourg"
    ],
    [
        "(GMT+1:00)", "Paris",
        "Europe/Paris"
    ],
    [
        "(GMT+1:00)", "Zurich",
        "Europe/Zurich"
    ],
    [
        "(GMT+1:00)", "Madrid",
        "Europe/Madrid"
    ],
    [
        "(GMT+1:00)", "West Central Africa",
        "Africa/Algiers"
    ],
    [
        "(GMT+1:00)", "Algiers",
        "Africa/Algiers"
    ],
    [
        "(GMT+1:00)", "Tunis",
        "Africa/Tunis"
    ],
    [
        "(GMT+1:00)", "Warsaw",
        "Europe/Warsaw"
    ],
    [
        "(GMT+1:00)", "Prague Bratislava",
        "Europe/Prague"
    ],
    [
        "(GMT+1:00)", "Budapest",
        "Europe/Budapest"
    ],
    [
        "(GMT+2:00)", "Helsinki",
        "Europe/Helsinki"
    ],
    [
        "(GMT+2:00)", "Harare, Pretoria",
        "Africa/Harare"
    ],
    [
        "(GMT+2:00)", "Sofia",
        "Europe/Sofia"
    ],
    [
        "(GMT+2:00)", "Athens",
        "Europe/Athens"
    ],
    [
        "(GMT+2:00)", "Bucharest",
        "Europe/Bucharest"
    ],
    [
        "(GMT+2:00)", "Nicosia",
        "Asia/Nicosia"
    ],
    [
        "(GMT+2:00)", "Beirut",
        "Asia/Beirut"
    ],
    [
        "(GMT+2:00)", "Damascus",
        "Asia/Damascus"
    ],
    [
        "(GMT+2:00)", "Jerusalem",
        "Asia/Jerusalem"
    ],
    [
        "(GMT+2:00)", "Amman",
        "Asia/Amman"
    ],
    [
        "(GMT+2:00)", "Tripoli",
        "Africa/Tripoli"
    ],
    [
        "(GMT+2:00)", "Cairo",
        "Africa/Cairo"
    ],
    [
        "(GMT+2:00)", "Johannesburg",
        "Africa/Johannesburg"
    ],
    [
        "(GMT+2:00)", "Khartoum",
        "Africa/Khartoum"
    ],
    [
        "(GMT+2:00)", "Kiev",
        "Europe/Kiev"
    ],
    [
        "(GMT+2:00)", "Chisinau",
        "Europe/Chisinau"
    ],
    [
        "(GMT+3:00)", "Nairobi",
        "Africa/Nairobi"
    ],
    [
        "(GMT+3:00)", "Istanbul",
        "Asia/Istanbul"
    ],
    [
        "(GMT+3:00)", "Moscow",
        "Europe/Moscow"
    ],
    [
        "(GMT+3:00)", "Baghdad",
        "Asia/Baghdad"
    ],
    [
        "(GMT+3:00)", "Kuwait",
        "Asia/Kuwait"
    ],
    [
        "(GMT+3:00)", "Riyadh",
        "Asia/Riyadh"
    ],
    [
        "(GMT+3:00)", "Bahrain",
        "Asia/Bahrain"
    ],
    [
        "(GMT+3:00)", "Qatar",
        "Asia/Qatar"
    ],
    [
        "(GMT+3:00)", "Aden",
        "Asia/Aden"
    ],
    [
        "(GMT+3:00)", "Djibouti",
        "Africa/Djibouti"
    ],
    [
        "(GMT+3:00)", "Mogadishu",
        "Africa/Mogadishu"
    ],
    [
        "(GMT+3:00)", "Minsk",
        "Europe/Minsk"
    ],
    [
        "(GMT+3:30)", "Tehran",
        "Asia/Tehran"
    ],
    [
        "(GMT+4:00)", "Dubai",
        "Asia/Dubai"
    ],
    [
        "(GMT+4:00)", "Muscat",
        "Asia/Muscat"
    ],
    [
        "(GMT+4:00)", "Baku, Tbilisi, Yerevan",
        "Asia/Baku"
    ],
    [
        "(GMT+4:30)", "Kabul",
        "Asia/Kabul"
    ],
    [
        "(GMT+5:00)", "Yekaterinburg",
        "Asia/Yekaterinburg"
    ],
    [
        "(GMT+5:00)", "Islamabad, Karachi, Tashkent",
        "Asia/Karachi"
    ],
    [
        "(GMT+5:30)", "India",
        "America/Indiana/Indianapolis"
    ],
    [
        "(GMT+5:30)", "Mumbai, Kolkata, New Delhi",
        "America/Indiana/Indianapolis"
    ],
    [
        "(GMT+5:30)", "Colombo",
        "Asia/Colombo"
    ],
    [
        "(GMT+5:45)", "Kathmandu",
        "Asia/Kathmandu"
    ],
    [
        "(GMT+6:00)", "Almaty",
        "Asia/Almaty"
    ],
    [
        "(GMT+6:00)", "Dacca",
        "Asia/Dacca"
    ],
    [
        "(GMT+6:00)", "Astana, Dhaka",
        "Asia/Almaty"
    ],
    [
        "(GMT+6:30)", "Rangoon",
        "Asia/Rangoon"
    ],
    [
        "(GMT+7:00)", "Novosibirsk",
        "Asia/Novosibirsk"
    ],
    [
        "(GMT+7:00)", "Krasnoyarsk",
        "Asia/Krasnoyarsk"
    ],
    [
        "(GMT+7:00)", "Bangkok",
        "Asia/Bangkok"
    ],
    [
        "(GMT+7:00)", "Vietnam",
        "Asia/Bangkok"
    ],
    [
        "(GMT+7:00)", "Jakarta",
        "Asia/Jakarta"
    ],
    [
        "(GMT+8:00)", "Irkutsk, Ulaanbaatar",
        "Asia/Irkutsk"
    ],
    [
        "(GMT+8:00)", "Beijing, Shanghai",
        "Asia/Hong_Kong"
    ],
    [
        "(GMT+8:00)", "Hong Kong SAR",
        "Asia/Hong_Kong"
    ],
    [
        "(GMT+8:00)", "Taipei",
        "Asia/Taipei"
    ],
    [
        "(GMT+8:00)", "Kuala Lumpur",
        "Asia/Kuala_Lumpur"
    ],
    [
        "(GMT+8:00)", "Singapore",
        "Asia/Singapore"
    ],
    [
        "(GMT+8:00)", "Perth",
        "Australia/Perth"
    ],
    [
        "(GMT+9:00)", "Yakutsk",
        "Asia/Yakutsk"
    ],
    [
        "(GMT+9:00)", "Seoul",
        "Asia/Seoul"
    ],
    [
        "(GMT+9:00)", "Osaka, Sapporo, Tokyo",
        "Asia/Tokyo"
    ],
    [
        "(GMT+9:30)", "Darwin",
        "Australia/Darwin"
    ],
    [
        "(GMT+10:00)", "Vladivostok",
        "Asia/Vladivostok"
    ],
    [
        "(GMT+10:00)", "Guam, Port Moresby",
        "Pacific/Guam"
    ],
    [
        "(GMT+10:00)", "Brisbane",
        "Australia/Brisbane"
    ],
    [
        "(GMT+10:30)", "Adelaide",
        "Australia/Adelaide"
    ],
    [
        "(GMT+11:00)", "Canberra, Melbourne, Sydney",
        "Australia/Canberra"
    ],
    [
        "(GMT+11:00)", "Hobart",
        "Australia/Hobart"
    ],
    [
        "(GMT+11:00)", "Magadan",
        "Asia/Magadan"
    ],
    [
        "(GMT+11:00)", "Solomon Islands",
        "Australia/Lord_Howe"
    ],
    [
        "(GMT+11:00)", "New Caledonia",
        "Australia/Lord_Howe"
    ],
    [
        "(GMT+11:00)", "Lord Howe IsIand",
        "Australia/Lord_Howe"
    ],
    [
        "(GMT+12:00)", "Kamchatka",
        "Asia/Kamchatka"
    ],
    [
        "(GMT+12:00)", "Fiji Islands, Marshall Islands",
        "Pacific/Fiji"
    ],
    [
        "(GMT+13:00)", "Auckland, Wellington",
        "Pacific/Auckland"
    ],
    [
        "(GMT+13:00)", "Independent State of Samoa",
        "Pacific/Samoa"
    ]
];

@connect(state => ({
  user: state.userReducer.user,
  dateData: state.userReducer.dateData,
  incorrectPassword: state.userReducer.incorrectPassword,
  projectList: state.projectReducer.projectList
}), { updateUserInfoAction, logout,
  showSidebarAction, showPaymentAction, setDateFormat,
  setIncorrectPasswordStateAction,
  deleteCompanyMemberAction,
  cancelCompanyInviteRequestAction
})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Account extends Component {
  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);
    this._onFileUploadChange = this._onFileUploadChange.bind(this);
    this._onFileReaderLoad = this._onFileReaderLoad.bind(this);
    this._removeAvatar = this._removeAvatar.bind(this);
    this.state = {
      firstname: {
        value: this.props.user.firstname,
        dirty: true,
        invalid: false
      },
      lastname: {
        value: this.props.user.lastname,
        dirty: true,
        invalid: false
      },
      email: {
        value: this.props.user.notConfirmedEmail || this.props.user.email,
        dirty: true,
        invalid: false
      },
      password: {
        value: '',
        dirty: true,
        invalid: false
      },
      newPassword: {
        value: '',
        dirty: true,
        invalid: false

      },
      confirmPassword: {
        value: '',
        dirty: true,
        invalid: false
      },
      currentImage: this.props.user.avatar,
      file: {
        value: null,
        dirty: true,
        invalid: false
      },
      openSidebar: false,
      showJoinCompanyPopup: false,
      isFormatPopupOpen: false,
      isTimezonePopupOpen: false,
    };
  }
  dateFormatClickHandler = (action, value) => {
    let chg = {isFormatPopupOpen: false, isTimezonePopupOpen: false}
    const { localTZ } = this.state;
    switch (action) {
      case 'date':
        chg.dateFormat = value;
        break;
      case 'time':
        chg.is12hours = value;
        break;
      case 'tz':
        chg.indexTZ = value;
        chg.tz = value ? zonezzz[value][2] : localTZ;
        break;
    }
    this.setState(chg);
  }
  /**
   * That function sends data to backend after successful validation
   * @param {Event} e - React Proxy for events
   * @return {boolean} - If data is invalid it returns false
   * @private
   */
  _submit(e) {
    e.stopPropagation();
    e.preventDefault();

    if (accountValidation(this, ['lastname', 'firstname', 'email','password', 'newPassword', 'confirmPassword'])) {
      return false;
    }
    const { updateUserInfoAction, showSidebarAction, showPaymentAction } = this.props;
    let {tz, dateFormat, is12hours, indexTZ} = this.state;

    const formData = new FormData();
    formData.append('avatar', this.state.file.value);
    formData.append('firstname', this.state.firstname.value);
    formData.append('lastname', this.state.lastname.value);
    formData.append('email', this.state.email.value);
    formData.append('password', this.state.password.value);
    formData.append('newPassword', this.state.newPassword.value);
    formData.append('confirmPassword', this.state.confirmPassword.value);
    formData.append('currentImage', this.state.currentImage);
    formData.append('dateData', JSON.stringify({tz, dateFormat, is12hours, indexTZ}));
    updateUserInfoAction(formData)
        .then(data => {
          if (data.emailChangeError === 'emailTaken') this.setState({email: {...this.state.email, invalid: 'taken'}})
          else if (data.emailChangeError === 'sendError') this.setState({email: {...this.state.email, invalid: 'sendError'}})
        });
    this._close();
  }

  /**
   * Handler for inputs
   * @param {Event} e - React Proxy for events
   * @private
   */
  _onChange(e) {
    if (e.currentTarget.name === 'password') {
      let { setIncorrectPasswordStateAction } = this.props;
      setIncorrectPasswordStateAction(false);
    }
    e.stopPropagation();
    e.preventDefault();
    inputValidation(this, e.currentTarget);
  }

  /**
   * Handles new avatar upload
   * @param {Event} e - React Proxy for event
   * @private
   */
  _onFileUploadChange(e) {
    const file = e.target.files[0];
    if (!file) return;
    let valid = this._validateAvatar(file);
    if (valid) {
      this.setState({
        file: {
          value: null,
          dirty: true,
          invalid: valid
        }
      });
      return;
    }
    const fr = new FileReader();
    fr.onload = this._onFileReaderLoad;
    fr.readAsDataURL(file);
    this.setState({
      file: {
        value: file,
        dirty: true,
        invalid: false
      }
    });
  }

  /**
   * Event handler for avatar load
   * @param {Event} e - Event Proxy from React
   * @private
   */
  _onFileReaderLoad(e) {
    this.setState({
      currentImage: e.target.result
    });
  };

  /**
   * It validates avatar to have a suitable type and size
   * @param {File} file - File object (avatar)
   * @return {*}
   * @private
   */
  _validateAvatar(file) {
    if (!this._validateExtension(file)) {
      return 'invalidFormat';
    }
    if (file.size > 2 * 1024 * 1024) {
      return 'overSize';
    }
    return false;
  }

  /**
   * It removes avatar from everywhere in state but don't saves that in database
   * @private
   */
  _removeAvatar() {
    this.setState({
      file: {
        value: null,
        dirty: true,
        invalid: false
      },
      currentImage: ''
    });
  }

  /**
   * It checks if file has a suitable extension
   * @param {File} file - A file object (avatar)
   * @return {boolean} - True if extension is suitable
   * @private
   */
  _validateExtension(file) {
    if (!file) return;
    const _validFileExtensions = validFileExtensions;
    const fileName = file.name;
    let valid = false;
    _validFileExtensions.forEach(ext => {
      if (fileName.substr(fileName.length - ext.length, ext.length).toLowerCase() === ext.toLowerCase()) {
        valid = true;
      }
    });
    return valid;
  }
  
   /**
   * It closes the popup and removes invalid password message if any
   * @param {Event} e - React Proxy for events
   * @private
   */
  _close(e) {
    const { showSidebarAction, setIncorrectPasswordStateAction, 
      showPaymentAction, dateData, setDateFormat } = this.props;
    let {tz, dateFormat, is12hours, indexTZ} = this.state;
    showSidebarAction(false);
    showPaymentAction(false);
    setIncorrectPasswordStateAction(false);
    document.body.style.overflowY = '';
    if (dateData.tz !== tz || dateData.dateFormat != dateFormat 
       || dateData.is12hours != is12hours || dateData.indexTZ != indexTZ ) {
       setDateFormat({tz, dateFormat, is12hours, indexTZ});
   }
  }
  
  _setState(e, state) {
     e.preventDefault();
     e.stopPropagation();
     this.setState(state);
  }

  _cancelCompanyInviteRequest = () => {
    this.props.cancelCompanyInviteRequestAction()
  }
  
  _nameTZ (tz) {
     const zone = zonezzz.find(el => el[2] === tz);
     return zone ? zone[1] : tz;
  }
  
  componentDidMount() {
     const {dateData} = this.props; 
     document.body.style.overflowY = 'hidden';
     let localTZ = momentTZ.tz.guess(true);
     let {tz, dateFormat, is12hours, indexTZ} = dateData;
     this.setState({localTZ, tz, dateFormat, is12hours, indexTZ});
  }

  render() {
    const { user, incorrectPassword, showPaymentAction, showSidebarAction, logout, projectList, dateData } = this.props;
    let letters = '';
    if (this.state.firstname.value.length && this.state.lastname.value.length) {
      letters = this.state.firstname.value[0] + this.state.lastname.value[0];
    }
    const { firstname, lastname, email, password, newPassword, confirmPassword, currentImage, 
      file, showJoinCompanyPopup, isFormatPopupOpen, isTimezonePopupOpen, 
      localTZ, tz, dateFormat, is12hours, indexTZ } = this.state;

    const plan = user.totalPaying ? transS('Basic') : 
      ((user.ticks <= 0) ? transS('Free') : transS('Demo'))
    const isMac = checkForMac()
    let msecs = user.ticks - ((new Date()) - user.loginTime);
    let daysLeft = Math.trunc(msecs/1000/60/60/24);
    let daysLeftString = daysLeft ? daysLeft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : daysLeft
    let expString = getDateString(user.accessExpires, -3, dateData);
    let docsLeft, docsCount = 0, sampleDate, localTimeZone, localTimeOffset, sampleMoment;
    if (localTZ) {
       sampleDate =  moment(Date.now());      
       localTimeOffset = `Auto: (GMT${sampleDate.format('Z')})`; 
       localTimeZone = this._nameTZ(localTZ);
       sampleMoment = moment.tz(Date.now(), tz)
    }
    if (!user.totalPaying && user.ticks <= 0) {
      projectList.forEach(p => {
        if (p.docs) docsCount += p.docs.length
      })
      if (docsCount < 5) docsLeft = 5 - docsCount
    }

    const isCompany = !!user.company
    let isCompanyAdmin = false, companyName, isRequested = false, requestTo = ''
    if (isCompany && user.company.knownCompanies[0] && user.company.knownCompanies[0].members) {
      companyName = user.company.knownCompanies[0].name
      let checkUser = user.company.knownCompanies[0].members._general.members.find(m => user._id === m._id)

      if (checkUser) {
        isCompanyAdmin = checkUser.accessStatus > 0
      } else if (!checkUser && user.company.knownCompanies[0].members._invited) {
        let userInCompany = user.company.knownCompanies[0].members._invited.find(m => user._id === m._id)
        isRequested = !!userInCompany
        requestTo = userInCompany.requestTo
      }
    }

    return (
      <div styleName='wrapper'>
        <ScrollBar style={{
          height: '100%',
          width: 'calc(100% + 15px)'
        }}>
          <div styleName='sidebar__wp' onClick={this._close} ></div>
          <div styleName='sidebar' onClick={() => this.setState({isTimezonePopupOpen: false, 
                         isFormatPopupOpen: false })}>
            <span className='close-cross' onClick={this._close} style={{right: '52px'}}>+</span>
            <div styleName='sidebar__header'>
              <h2 styleName='sidebar__title'>{transS('Account')}</h2>
            </div>
            <div className='h2'>{transS('You')}</div>
            <form styleName='account-form'>
              <div styleName='flex'>
                <div styleName=''>
                  <div styleName={cx('img-wrapper', { 'img-wrapper_isBg': currentImage.length })} style={{ backgroundImage: 'url(' + currentImage + ')' }}>
                    <p styleName='img-wrapper__text'>{letters}</p>
                  </div>
                  <p styleName='img-btn' onClick={this._removeAvatar}>{transS('Delete')}</p>
                </div>
                <div styleName='file-wrapper'>
                  <input type='file' name='file' id='file' styleName='inp-file' onChange={this._onFileUploadChange} />
                  <label htmlFor='file' styleName='inp-file__label'>
                    <Button text={transS('Upload an image')} mod='blue arch margin0'/></label>
                  <p styleName={cx('inp-file__text', { 'text_isError': this.state.file.invalid.length })} > { 
                     transS('Square image 80х80 px') }
                  </p>
                </div>
              </div>
              <div styleName='account-fields' style={{marginTop: '0px'}}>
                <div styleName='account-fields-name'>
                <div styleName='input-area inp-name'>
                  <input placeholder='First Name' styleName='account-inp' onChange={this._onChange} name='firstname' value={this.state.firstname.value} />
                  {firstname.invalid && <div styleName='error-message'>
                    {
                      (() => {
                            return {
                              'required': transL('requiredFirstName'),
                              'minLength': transL('minLengthName'),
                              'maxLength': transL('projectNameMaxLength')
                            }[firstname.invalid];
                          }
                      )()
                    }
                  </div>}
                </div>
                <div styleName='input-area inp-name'>
                  <input style={{marginBottom: '0'}} placeholder='Last Name' styleName='account-inp' onChange={this._onChange} name='lastname' value={this.state.lastname.value} />
                  {lastname.invalid &&  <div styleName='error-message'>
                    {
                      (() => {
                            return {
                              'required': transL('requiredLastName'),
                              'minLength': transL('minLengthName'),
                              'maxLength': transL('projectNameMaxLength')
                            }[lastname.invalid];
                          }
                      )()
                    }
                  </div>}
                </div>
                </div>
                <div styleName='input-area' style={{marginBottom: '20px'}}>
                  <input placeholder='Email' styleName='account-inp' onChange={this._onChange} name='email' value={this.state.email.value} />
                  <div styleName='error-message'>
                    {
                      (() => {
                            return {
                              'required': transL('requiredEmail'),
                              'maxLength': transL('maxLengthEmail'),
                              'email': transL('errorEmail'),
                              'taken': transL('alreadyTakenEmail'),
                              'sendError': transL('errorSendingEmail'),
                            }[email.invalid];
                          }
                      )()
                    }
                  </div>
                  {user.notConfirmedEmail && <div styleName='email-message'
                    dangerouslySetInnerHTML={{__html: transL('Still') + user.email}} />}
                </div>

                <Button text={transS('Save Changes')} mod='blue fill' callback={this._submit} />
              </div>

              { (isCompany && !isRequested) ?
                  <div styleName='account-company'>
                    <div className='h2' style={{marginBottom: '20px'}}>{transS('Your Company')}</div>
                    <div styleName='account-company-name'>{companyName}</div>
                    {isCompanyAdmin &&
                    <Link onClick={(e) => this._close(e)} styleName='account-company-link' to='/company'>
                      {transS('Go to Company Administration')}</Link>}
                  </div>
                  :
                  <div styleName='account-company'>
                    <div className='h2' style={{marginBottom: '20px'}}>{transS('Your Company')}</div>
                    <div style={{marginBottom: '20px', fontSize: '14px'}}>
                      {transS('Your account is not linked to any company yet.')}
                    </div>
                    <div styleName='account-company-manage'>
                      <div styleName='account-company-manage-create'>
                        { (user.ticks < 0 || isRequested) ?
                            <Button text={transS('Create Company')} mod='blue arch disabled'/>
                            :
                            <Link onClick={() => this._close()} styleName='account-company-link'
                                  to='/company'>
                              <Button text={transS('Create Company')} mod='blue arch'/></Link>
                        }
                        <div styleName='account-company-manage-desc'>
                          {transL('Create a company account')}
                        </div>
                        {(user.ticks < 0) && <div styleName='account-company-manage-create-plan'>
                          <div styleName='account-company-manage-desc'>
                            {transS('To create a company:')}
                          </div>
                          <Button callback={() => {
                            showSidebarAction(false);
                            showPaymentAction(true);
                          }}
                                  text={transS('Upgrade to Basic Plan')}
                                  mod='blue arch'/>
                        </div>}
                      </div>
                      <div styleName='account-company-manage-join'>
                        {isRequested ?
                            <Button text={transS('Cancel Request')} mod='blue arch' callback={() => this._cancelCompanyInviteRequest()}/>
                            :
                            <Button text={transS('Join Company')} mod='blue arch' callback={() => this.setState({showJoinCompanyPopup: true})}/>
                        }
                        <div styleName='account-company-manage-desc'>
                          {isRequested ? transF('sent a request')(requestTo)
                              : transS('Send a request to join an existing company.')}
                        </div>
                      </div>
                    </div>
                  </div>}

              <div styleName='account-paying'>
                <div className='h2' style={{paddingBottom: '20px'}}>{transS('Your Plan')}</div>

                <div styleName={cx('account-paying-card', {'free': plan !== transS('Basic'), 'basic': plan === transS('Basic')})}>

                  <div styleName='account-paying-card-title'>{plan}</div>

                  <div styleName='account-paying-card-desc'>
                    {(user.ticks >= 0 || user.totalPaying) ?

                        user.totalPaying ?

                            user.ticks >= 0 ?

                                daysLeft <= 14 ?
                                    <div styleName='red'>
                                      {transS('Paid until')} {expString}
                                      <div>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                                    </div>
                                    :
                                    <React.Fragment>
                                      {transS('Paid until')} {expString}
                                      <div
                                          styleName='gray'>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                                    </React.Fragment>

                                :
                                <React.Fragment>
                                  <div styleName='red' style={{fontWeight: 'bold'}}>Expired!</div>
                                  <div styleName='gray'>(on {expString})</div>
                                </React.Fragment>

                            :
                            <React.Fragment>
                              {transS('Available until')} {expString}
                              <div styleName='gray'>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                            </React.Fragment>

                        :
                        docsLeft ?
                            <div>
                              {transF('You can add')(docsLeft)}
                              <div styleName='gray'>{transF('You already have')(docsCount)}</div>
                            </div>
                            :
                            <div>
                              <div styleName='red'>You cannot add document for free.</div>
                              <div styleName='gray'>{transF('You already have')(docsCount)}</div>
                            </div>
                    }
                  </div>
                  {canTariffed() &&
                  <Button mod='orange fill'
                          callback={() => {showSidebarAction(false);showPaymentAction(true);}}
                          text={user.totalPaying ? transS('Renew Plan') : transS('Upgrade Plan')}/>
                  }
                </div>

              </div>

              <div styleName='account-fields'>
                <div className='h2' style={{paddingBottom: '10px'}}>{transS('Password')}</div>

                <div styleName='input-area'>
                  <input placeholder={transS('Current Password')} styleName='account-inp' type='password' onChange={this._onChange} name='password' value={this.state.password.value} />
                  <div styleName='error-message'>
                    {
                      (() => {
                            if (incorrectPassword) {
                              return transL('incorrectPassword');
                            } return {
                              'required': transL('requiredPassword'),
                              'minLength': transL('minLengthPassword'),
                              'maxLength': transL('maxLengthPassword')
                            }[password.invalid];
                          }
                      )()
                    }

                  </div>
                </div>

                <div styleName='input-area'>
                  <input placeholder={transS('New Password')} styleName='account-inp' type='password' onChange={this._onChange} name='newPassword' value={this.state.newPassword.value} />
                  <div styleName='error-message'>

                    {
                      (() => {
                            return {
                              'required': transL('requiredPassword'),
                              'minLength': transL('minLengthPassword'),
                              'maxLength': transL('maxLengthPassword')
                            }[newPassword.invalid];
                          }
                      )()
                    }
                  </div>
                </div>

                <div styleName='input-area'  style={{marginBottom: '20px'}}>
                  <input placeholder={transS('Repeat New Password')} type='password' styleName='account-inp' onChange={this._onChange} name='confirmPassword' value={this.state.confirmPassword.value} />
                  <div styleName='error-message'>
                    {
                      (() => {
                            return {
                              'required': transL('requiredPassword'),
                              'minLength': transL('minLengthPassword'),
                              'maxLength': transL('maxLengthPassword'),
                              'passMatch': transL('passMatch')
                            }[confirmPassword.invalid];
                          }
                      )()
                    }
                  </div>
                </div>

                <Button text={transS('Save Changes')} mod='blue fill' callback={this._submit} />
              </div>

            </form>



            {localTZ && <div styleName='timeBlock' style={{marginBottom: '50px'}}>
              <div className='h2' style={{lineHeight: 'normal', marginBottom: '20px', position: 'relative'}}>
                  {transS('Time Settings')}</div>
              
              <div className='h4black' style={{lineHeight: 'normal', marginBottom: '20px'}}>
                  {transS('Time Zone')}</div>
              <div styleName={cx('timeBlock_timezone', {'_open': isTimezonePopupOpen})}>
                <div styleName='timeBlock_timezone_current'
                     onClick={(e) => this._setState(e, {isTimezonePopupOpen: !isTimezonePopupOpen, 
                         isFormatPopupOpen: false })}>

                  <span styleName='arrow'><span className={`arrow ${isTimezonePopupOpen ? 'arrowOpen' : ``}`}></span></span>
                  <span styleName='grayPad'>{indexTZ ? zonezzz[indexTZ][0] : localTimeOffset}</span>
                  <span>{indexTZ ? zonezzz[indexTZ][1] : localTimeZone}</span>
                </div>
                { isTimezonePopupOpen && <div styleName='timeBlock_timezone_popup'>
                 <ScrollBar style={{height: '100%', width: 'calc(100% + 15px)'}}>
                   { zonezzz.map((el, i) => (i === indexTZ) ? false : 
                     <div styleName='tzPad' key={i} onClick={() => this.dateFormatClickHandler('tz', i)}>
                       <span styleName='grayPad'>{i ? el[0] : localTimeOffset }</span>{el[1]}
                     </div>      
                   )}
                 </ScrollBar>
                </div> }
              </div>
                <div className="space20"></div>
                <div className='h4black' style={{lineHeight: 'normal', marginBottom: '20px'}}>
                  {transS('Time Format')}</div>
                <div styleName='timeBlock_12h'>
                  <div styleName={cx({'_sel': is12hours})} onClick={() => this.dateFormatClickHandler('time', true)}>12h</div>
                  <div styleName={cx({'_sel': !is12hours})} onClick={() => this.dateFormatClickHandler('time', false)}>24h</div>
                </div>
                <div styleName={cx('timeBlock_formatBlock', {'_open': isFormatPopupOpen})}>
                  <div styleName='timeBlock_formatBlock_current'
                       onClick={(e) => this._setState(e, {isFormatPopupOpen: !isFormatPopupOpen})}>
                     <span><span className={`arrow ${isFormatPopupOpen ? 'arrowOpen' : ``}`}></span></span>
                    {dateFormat === 1
                        ?  <div>
                          <div>{sampleMoment.format('MMMM DD, YYYY')}</div>
                          <div>{sampleMoment.format('MM/DD/YYYY')}</div>
                        </div>
                        : <div>
                          <div>{sampleMoment.format('DD MMMM, YYYY')}</div>
                          <div>{sampleMoment.format('DD.MM.YYYY')}</div>
                        </div>}
                  </div>
                  <div styleName='timeBlock_formatBlock_popup'>
                    {dateFormat === 1
                        ?  <div onClick={() => this.dateFormatClickHandler('date', 2)}>
                          <div>{sampleMoment.format('DD MMMM, YYYY')}</div>
                          <div>{sampleMoment.format('DD.MM.YYYY')}</div>
                        </div>
                        : <div onClick={() => this.dateFormatClickHandler('date', 1)}>
                          <div>{sampleMoment.format('MMMM DD, YYYY')}</div>
                          <div>{sampleMoment.format('MM/DD/YYYY')}</div>
                        </div>}
                  </div>
                </div>
            </div>}

            <div styleName='logoutBtn-row'><Button mod='blue arch' text={transS('Log Out')} callback={() => { this._close(); logout()}} /></div>

          </div>
        </ScrollBar>
        {showJoinCompanyPopup && <JoinCompanyPopup cancel={() => this.setState({showJoinCompanyPopup: false})}/>}
      </div>
    );
  }
}
