import React from 'react'
import Popup from '../../../../../../../components/popup/Popup.jsx'
import Header from './parts/Header/Header.jsx'
import Body from './parts/Body/Body.jsx'
import Footer from './parts/Footer/Footer.jsx'
import styles from './styles.module.css'

export default function ReplaceVariablesPopup({cancelCallback, replaceCallback, duplicates}) {
  return (
    <Popup 
      wrapperClassName={'whiteBackground'}
      hasCrossIcon={true}
      cancelCallback={cancelCallback}
      header={<Header isManyVariables={duplicates.length > 1}/>}
      body={<Body duplicates={duplicates}/>}
      footer={<Footer replaceCallback={replaceCallback} cancelCallback={cancelCallback}/>}
    />
  )
}
