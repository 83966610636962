import keyMirror from 'keymirror';

export const validFileExtensions = ['.jpg', '.jpeg', '.png'];

export const standartTextColors = [
  "#000000", // Черный
  "#000001",
  "#3f3f3f",
  "#595959",
  "#7f7f7f",
  "#7f7f7f",
  "#a5a5a5",
  "#bfbfbf",
  "#d8d8d8",
  "#f2f2f2",
  "#ffffff",
  "#c00000",
  "#ff0000",
  "#ffc000",
  "#ffff00",
  "#92d051",
  "#06b050",
  "#06b0f0",
  "#0070c0",
  "#002060",
  "#7030a0"
];

export const shdColors = ["#ffffff", ...standartTextColors.slice(1)];

export const socketConstants = keyMirror({
  JOIN_TO_GROUP: null,
  STOP_PUBLICATION_TIMER: null,
  
  START_EDITING_PARAGRAPH: null,
  UPDATE_EDITING_PARAGRAPH: null,
  UPDATE_TEMPLATE: null,
  
  ADD_DOCUMENT: null,
  NEW_DOCUMENT_SUBVERSION: null,
  NEW_DOCUMENT_VERSION: null,
  GET_DOCUMENT_BY_FINALIZE: null,
  GET_DOCUMENT_BY_REQUEST: null,
  GET_DOCUMENT_BY_FORCE: null,
  DOCUMENT_DELETED: null,
  ENVELOPE_CHANGED: null,
  ENTER_THE_DOCUMENT: null,
  APPROVE_DOCUMENT: null,
  ENTER_THE_PROJECT_LIST: null,
  ENTER_THE_PROJECT: null,
  PROJECT_DELETED: null,
  PROJECT_NAME_UPDATE: null,
  USER_EXPELLED: null,
  USER_TRANSFERRED_TO_COUNTER_PARTY: null,
  INTRODUCTION: null,
  CONCLUSION: null,
  UPDATE_APPROVE_MESSAGE: null,
  UPDATE_REPLY_APPROVE_MESSAGE: null,
  UPDATE_DOCUMENT_MESSAGES: null,
  UPDATE_USER_INTERFACE_DATA: null,
  GET_UNKNOWNS: null,
  WRITING_COVER_MESSAGE: null,
  DOCUMENT_CHANGE: null,
  UPDATE_PROJECT: null,
  UPDATE_CLAUSE: null,
});

export const cookiesConstants = {
  COOKIE_TOKEN_KEY: 'COOKIE_TOKEN_KEY',
  COOKIE_HEADER_KEY: 'X-Auth-Token'
};

