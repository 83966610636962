import * as actionTypes from './constants';

export const turnInsertModeAction = (insertOn, canPaste) => ({
  type: actionTypes.TURN_INSERT_MODE,
  insertOn, canPaste,
});

export const turnFormattingAction = (formatting) => ({
  type: actionTypes.TURN_FORMATTING,
  formatting
});

export const setPreviousColor = previousColor => ({
  type: actionTypes.SET_PREVIOUS_COLOR,
  previousColor
});


export const turnCommunicationVis = (communicationVis) => ({
  type: actionTypes.TURN_COMMUNICATION_VIS,
  communicationVis
});

export const blockParagraphsAction = block => ({
  type: actionTypes.BLOCK_PARAGRAPHS,
  block
});

export const setParagraphStyles = paragraphStyles => ({
  type: actionTypes.SET_PARAGRAPH_STYLES,
  paragraphStyles
})

export const setIsColorsOpened = isColorsOpened => ({
  type: actionTypes.SET_IS_COLORS_OPENED,
  isColorsOpened
})