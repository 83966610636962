import React, { useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./paragraphOptions.css"
import { useSelector } from 'react-redux';
import SizeChanger from "./SizeChanger.js"
import { transS } from '../../../../services/helpers/lang';
import AdjustChanger from './AdjustChanger.js';

const ParagraphOptions = ({params, sendToFrame, sendRems, withAdjust = false}) => {

    const {formatting, isInch} = useSelector(state => state.paragraphReducer)

    const [mouseMove, setMouseMove] = useState(null)


    const handleMouseMove = (name) => {
        setMouseMove(name)
    }

    const handleMouseLeave = () => {
        setMouseMove(null)
    }

    const pxToTwips = px => Math.round(px*15)
    const cmToTwips = cm => isInch ? Math.round(cm*96*15) : Math.round(cm*38*15)
    const ptToTwips = pt => Math.round(pt*20)
    const twipsToRem = twips => {
        if (sendRems) {
            // const size = Math.round(twips/20)
            const size = twips/20
            const remSize = (size/7.5).toFixed(3)
            return `${remSize}rem`
        }
        return twips
    }

    const makeLineSpacing = (fontSize, lineHeight) => Math.round(100*(lineHeight.slice(0, lineHeight.length - 2)/fontSize.slice(0, fontSize.length - 2)))/100

    const doSpacing = (spacingType, value) => {
        let styleValue = {}
        switch (spacingType) {
            case 'before':
                // styleValue = {before: twipsToRem(ptToTwips(value))}
                styleValue = {marginTop: twipsToRem(ptToTwips(value))}
                break
            case 'after':
                // styleValue = {after: twipsToRem(ptToTwips(value))}
                styleValue = {marginBottom: twipsToRem(ptToTwips(value))}
                break
            case 'left':
                // styleValue = {left: twipsToRem(cmToTwips(value))}
                styleValue = {marginLeft: twipsToRem(cmToTwips(params.hanging + value))}
                break
            case 'right':
                // styleValue = {right: twipsToRem(cmToTwips(value))}
                styleValue = {marginRight: twipsToRem(cmToTwips(value))}
                break
            case 'hanging':
                // styleValue = {hanging: twipsToRem(cmToTwips(value))}
                styleValue = {textIndent: twipsToRem(-cmToTwips(value))}
                sendToFrame({c1: "format", paraStyle: {marginLeft: twipsToRem(cmToTwips(params.left + value))}}) // Так как для отображения hanging нужен сдвиг слева
                break
            case 'firstLine':
                // styleValue = {firstLine: twipsToRem(cmToTwips(value))}
                styleValue = {textIndent: twipsToRem(cmToTwips(value))}
                break
            case 'line': // Line spacing
                // styleValue = {line: value}
                styleValue = {lineHeight: value}
                break
        }
        console.log(styleValue)
        sendToFrame({c1: "format", paraStyle: styleValue})
    }

    return (
        <>
            <div styleName='row marg10'>
                {withAdjust ? <AdjustChanger sendToFrame={sendToFrame}/> : <div styleName='withoutAdjust'></div>}
                <div styleName='headingContainer'
                    onMouseMove={() => {handleMouseMove("Before")}}
                    onMouseLeave={handleMouseLeave}
                >
                    <div styleName='heading auto'>{transS('Before')}</div>
                    <SizeChanger defaultValue={params.before} spacingType={'before'} onClick={doSpacing}/>
                </div>
            </div>
            <div styleName='row marg20'>
                <div styleName='column'>
                    <div>
                        <div styleName='headingContainer'
                            onMouseMove={() => {handleMouseMove("Hanging")}}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div styleName='heading'>{transS('Hanging')}</div>
                            <SizeChanger type={isInch ? "″" : "cm"} defaultValue={params.hanging} spacingType={'hanging'} onClick={doSpacing}/>
                        </div>
                        <div styleName='headingContainer marg10'
                            onMouseMove={() => {handleMouseMove("First Line")}}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div styleName='heading'>{transS('First Line')}</div>
                            <SizeChanger type={isInch ? "″" : "cm"} defaultValue={params.firstLine} spacingType={'firstLine'} onClick={doSpacing}/>
                        </div>
                    </div>
                    <div styleName='headingContainer'
                        onMouseMove={() => {handleMouseMove("Left")}}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div styleName='heading'>{transS('Left')}</div>
                        <SizeChanger type={isInch ? "″" : "cm"} defaultValue={params.left} spacingType={'left'} onClick={doSpacing}/>
                    </div>
                </div>
                <div styleName="textContainer">
                    <p styleName="textPart gap">ut a me liberetur et incipiat tibi teneri. quae dicitur nouatio obligationis.</p>
                    <p styleName="textPart indentMinus">Sine hac uero nouatione non</p>
                    <p styleName="textPart gap">poteris tuo nomine agere, sed debes ex persona mea quasi cognitor aut procurator meus experiri.</p>
                    <p styleName="textPart indentPlus">Idem iuris est, si cui post factum testamentum uxor in manum conueniat, uel quae in manu fuit, nubat: nam eo modo</p> {/* filiae loco esse incipit et quasi sua. */}
                    {mouseMove === "Left" && <div styleName='hilighter left'></div>}
                    {mouseMove === "Right" && <div styleName='hilighter right'></div>}
                    {mouseMove === "Before" && <div styleName='hilighter before'></div>}
                    {mouseMove === "After" && <div styleName='hilighter after'></div>}
                    {mouseMove === "Hanging" && <div styleName='hilighter hanging'></div>}
                    {mouseMove === "First Line" && <div styleName='hilighter firstline'></div>}
                    {mouseMove === "Line Spacing" && <div styleName='linespacing'>
                            <div styleName='hilighter line'></div>
                            <div styleName='hilighter line'></div>
                            <div styleName='hilighter line'></div>
                            <div styleName='hilighter line'></div>
                    </div>}
                </div>
                <div styleName='column margl10'>
                    <div styleName='headingContainer'
                        onMouseMove={() => {handleMouseMove("Line Spacing")}}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div styleName='heading'>{transS('Line Spacing')}</div>
                        <SizeChanger type="" defaultValue={params.line} spacingType={'line'} onClick={doSpacing}/>
                    </div>
                    <div styleName='headingContainer'
                        onMouseMove={() => {handleMouseMove("Right")}}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div styleName='heading auto'>{transS('Right')}</div>
                        <SizeChanger type={isInch ? "″" : "cm"} defaultValue={params.right} spacingType={'right'} onClick={doSpacing}/>
                    </div>
                </div>
            </div>
            <div styleName="row marg10">
                <div styleName='headingContainer after'
                    onMouseMove={() => {handleMouseMove("After")}}
                    onMouseLeave={handleMouseLeave}
                >
                        <div styleName='heading auto'>{transS('After')}</div>
                        <SizeChanger defaultValue={params.after} spacingType={'after'} onClick={doSpacing}/>
                    </div>
            </div>
        </>
    );
};

export default new CSSModules(ParagraphOptions, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
