import React from 'react';
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'

// Заготовленные классы для стилизации чекбокса:
// checkboxSize12 - размер 12px
// checkboxSize18 - размер 18px
// mtm4 - margin-top: -4px;
// checkboxGreenFilled - зеленая заливка 
// relative - position: relative
// resetMargin - обнуление марджинов
// flex - display: flex
// gap8 - gap: 8px
// minWidth100Percent - min-width: 100%
// translateY1 - transform: translateY(1px)

const CheckBox = params => {
  let {filled, text, ronly, changed, big, className, style} = params;
  return (
    <div 
      styleName={
        (big ? "reqCheckboxBig" : "reqCheckbox") 
        + (className ? ` ${className}` : '')
      } 
      style={style}
      onClick={changed} 
      data-read={ronly}
    >          
      <span styleName={big ? "chkBig" : "chk"} data-fill={filled} >
        <svg viewBox="0 0 11 8" width="100%" height="100%">
            <path d="M4.243 4.778L2.115 2.65a.993.993 0 0 0-1.408.007 1 1 0 0 0-.006 1.408l2.841 2.841c.193.193.446.29.7.29.258-.004.512-.101.706-.295l4.953-4.953A.996.996 0 0 0 9.9.536.999.999 0 0 0 8.487.534L4.243 4.778z" fill="#FFF" fillRule="evenodd"></path>
          </svg>
      </span>
      <label>
        {text}
      </label>
    </div>
  )};
export default CSSModules(CheckBox, styles, {allowMultiple: true});

