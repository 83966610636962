import React, { useCallback } from 'react'
import CSSModules from 'react-css-modules'
import Popup from '../../../../../components/popup/Popup.jsx'
import styles from './popup.css'
import PopupBody from './PopupBody.jsx'
import PopupFooter from './PopupFooter.jsx'

function RemoveItemPopup({
  closePopupCallback,
  removeItemCallback,
}) {

  const deleteItem = () => {
    removeItemCallback()
    closePopupCallback()
  }

  return (
    <Popup 
    hasCrossIcon={true}
    cancelCallback={closePopupCallback}
    body={<PopupBody />}
    footer={
      <PopupFooter 
        deleteCallback={deleteItem}
        cancelCallback={closePopupCallback}
      />
    }
    />
  )
}

export default CSSModules(RemoveItemPopup, styles, {allowMultiple: true})