import React from 'react'
import {Link, NavLink} from 'react-router-dom'

import styles from '../../topMenu.css';
import CSSModules from "react-css-modules";
import cx from 'classnames';

import Button from "../../../../../../components/button/Button";
import Hint from "../../../../../../components/button/Hint";
import ScrollBar from "../../../../../../components/scrollBar/ScrollBar";
import Logo from "../../../../../../components/logo/Logo";
import ProgressAndTasks from "../../../progressAndTasks/ProgressAndTasks";
import Spinner from "../../../../../../components/spinner/Spinner"
import { transF, transS } from '../../../../../../services/helpers/lang';
import DownloadingButton from '../DownloadingButton/DownloadingButton';

class ScrollBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documentName: '',
            textAreaHeight: 21,
            isMessageOpen: false,
            isWaitDownload: false,
        }
    }

    componentDidMount() {
        this.setState({documentName: this.props.actualDocument.title})
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.actualDocument.title !== this.props.actualDocument.title) this.setState({documentName: this.props.actualDocument.title})
        this.documentNameCalcHeight()
    }

    documentNameChangeHandler = (e) => {
        this.setState({documentName: e.target.value})
        this.setState({textAreaHeight: 21})
    }

    documentNameCalcHeight = () => {
        if (this.refName && this.state.textAreaHeight !== this.refName.scrollHeight) {
            let height = this.refName.scrollHeight
            this.setState({textAreaHeight: height})
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.refMessage && !this.refMessage.contains(event.target) && event.target !== this.refMessage) {
            this.setState({isMessageOpen: false})
        }
    }

    download = async (adds) => {
        this.setState({isWaitDownload: true});
        this.props.download('docx', null, null, null, 
          () => this.setState({isWaitDownload: false}), adds);
    }


    render() {
        let {sideBarOpenHandler, freezeFunc, approversCount, approvedsCount, isDocumentBlocked, isExportForbidden, isProjectAdmin,isCompanyAdmin,
            renameDocumentAction, projectName, tasksCount, actualDocument, toggleBar, openInvitePopup, user, inDoc, setPopupMode, isDocTransferToFinalize, toggleNavigationVisAction} = this.props
        let {documentName, textAreaHeight, isMessageOpen, isWaitDownload} = this.state
        let isVarDefined = actualDocument.orig === 302 || window.location.pathname.includes('template');

        return <div styleName='sidebar'>
            <div styleName='sidebar-header'>
                <div className='scaffold'></div>
                <div styleName="logoplace">  <Logo />  </div>
                <img styleName='sidebar-header-close' src="../../../../../../images/burgerMenuOpen.png"
                     onClick={() => sideBarOpenHandler(false)} alt='sidebar open'/>
            </div>

            {!(window.location.pathname === '/' && window.location.hash === '') && <div styleName='sidebar-toAllProjects'>
                <Link onClick={() => sideBarOpenHandler(false)} to='/'><Button text={transS('Go to all projects')} mod='blue arch'/></Link>
                {!!tasksCount && <div styleName='counter' style={tasksCount > 99 ? {fontSize: '8px', transform: 'translateY(0px)'} : {transform: 'translateY(0px)'}}>
                    {tasksCount > 99 ? '99+' : tasksCount}</div>}
            </div>}
            {inDoc && !isVarDefined && <React.Fragment>
                <div styleName='sidebar-document'>
                    <div className='h2' style={{marginBottom: '10px'}}>{transS('This Document')}</div>
                    <div styleName='document'>
                        <img src="../../../../../../images/icons-doc.png" alt='doc'/>
                        {isProjectAdmin ?
                            <React.Fragment>
                          <textarea value={documentName}
                                    style={{height: textAreaHeight}}
                                    ref={(e) => {this.refName = e}}
                                    styleName='sidebar-documentName'
                                    onBlur={() => renameDocumentAction(actualDocument.coreDocument, documentName)}
                                    onChange={this.documentNameChangeHandler}/>
                                <div styleName='sidebar-document-hintPlace'>
                                    <Hint text={transS('Click to rename')}/>
                                </div>
                            </React.Fragment>
                            :
                            <div styleName='sidebar-documentNameDiv'>{documentName}</div>
                        }
                    </div>
                    <div styleName='sidebar-document-desc'>
                        {[12,13,14].includes(actualDocument.orig) ?
                            (actualDocument.dsEnvelope ?
                                (JSON.parse(actualDocument.dsEnvelope).status === 'completed' 
                                  ? transS('Signed Version') 
                                  : transS('Signing in Progress'))
                                :
                                <div>{transS('Final Version')}</div>
                            ) : ( actualDocument.orig === 21 ? <div>Frozen</div> 
                            : actualDocument.orig === 50 ? <div>Preview of imported Version</div> 
                            : <div>{`${transS('Being edited by')} ${
                                (actualDocument.orig === 6 || actualDocument.orig === 7) ? transS('both teams') :
                                (!isDocumentBlocked || isDocTransferToFinalize) ? transS('Your team')
                                 : transS('Counterparty')}`}</div>)}

                        {!isDocumentBlocked && !!approversCount 
                          && !(actualDocument.orig === 21 || actualDocument.orig === 50)
                          && <span>
                            {transF('approvedBy')(approvedsCount, approversCount)}
                            </span>}
                    </div>
                    <div styleName='sidebar-document-buttons'>
                        { actualDocument.orig === 21 && <React.Fragment>
                          <Button text={transS('Import as New Version')} mod='blue nowrap margin0' callback={() => freezeFunc(true)}/>
                          <Button text={transS('Unfreeze without Import')} mod='blue nowrap margin0' callback={() => freezeFunc(false)}/>
                        </React.Fragment>}
                        { actualDocument.orig === 50 && <React.Fragment>
                          <Button text={transS('Continue with This Version')} mod='blue nowrap margin0' callback={() => freezeFunc(true, true)}/>
                          <Button text={transS('Return to Previous Version')} mod='blue nowrap margin0' callback={() => freezeFunc(false, true)}/>
                        </React.Fragment>}
                        <Button text={transS('Show Stats')}  mod='blue nowrap margin0' callback={setPopupMode}/>
                        <div onClick={() => toggleBar(true)} style={{transform: 'translateY(-1px)'}}>
                            <Button text={transS('Mentions')} mod='blue margin0' callback={() => null}/>
                            {!!tasksCount && <div styleName='counter' style={tasksCount > 99 ? {fontSize: '8px'} : null}>
                                {tasksCount > 99 ? '99+' : tasksCount}</div>}
                        </div>
                        <Button text={transS('Navigation')} mod='blue nowrap margin0' callback={() => {sideBarOpenHandler(false);toggleNavigationVisAction(true, true)}}/>
                        <Button text={transS('History & Versions')} mod='blue nowrap margin0' callback={() => toggleBar(true)}/>
                        {isWaitDownload ?
                            <div styleName='expDocSpinner'><Spinner withoutLogo={true} />{transS('Exporting')}...</div>
                            :
                            <DownloadingButton callback={(adds) => {this.download(adds);this.setState({isMessageOpen: true})}} />
                            
                        }
                        {isExportForbidden && isMessageOpen &&
                        <div ref={ e=> this.refMessage = e} styleName='sidebar-export'><div styleName='sidebar-export-message'>Exports rights are available for PAID plan users only</div></div>}
                    </div>
                </div>
                <div styleName='sidebar-thisProject'>
                    <div className='h2' style={{marginBottom: '10px'}}>{transS('This project')}</div>
                    <div styleName='sidebar-thisProject-title'>{projectName}
                    </div>
                    <div styleName='sidebar-thisProject-buttons'>
                        <NavLink to={`/#/${actualDocument.projectId}`}><Button text={transS('Go to Project')} mod='blue arch' callback={() => null}/></NavLink>

                        <Button text={transS('Teams')} mod='blue' callback={openInvitePopup} style={{transform: 'translateY(1px)'}}/>
                    </div>
                </div>
            </React.Fragment>}

            {!window.location.pathname.includes('templates') 
             && !user.temporary
             && <div styleName='sidebar-company'>
                <div className='h2' style={{marginBottom: '20px'}}>{transS('Templates')}</div>
                <Link onClick={() => sideBarOpenHandler(false)} to='/templates'>{transS('Go to Templates')}</Link>
            </div>}

            {isCompanyAdmin && <div styleName='sidebar-company'>
                <div className='h2' style={{marginBottom: '20px'}}>{transS('Company Administration')}</div>
                <Link onClick={() => sideBarOpenHandler(false)} 
                  to='/company'>{transS('Go to Company Administration')}</Link>
            </div>}


            {/*<div styleName='sidebar-recentDocument'>*/}
            {/*    <div className='h3' style={{marginBottom: '20px'}}>Recent Docuements</div>*/}
            {/*    <div styleName='sidebar-recentDocumentWrapper'>*/}
            {/*        <div styleName='document'>*/}
            {/*            <img src="../../../../images/icons-doc.png"/>*/}
            {/*            <span>Construction Management and …</span>*/}
            {/*        </div>*/}
            {/*        <div styleName='sidebar-document-desc'>*/}
            {/*            <div>Finalized</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div styleName='sidebar-recentDocumentWrapper'>*/}
            {/*        <div styleName='document'>*/}
            {/*            <img src="../../../../images/icons-doc.png"/>*/}
            {/*            <span>Construction Management and General  …</span>*/}
            {/*        </div>*/}
            {/*        <div styleName='sidebar-document-desc'>*/}
            {/*            <div>External Review & Approval</div>*/}
            {/*            <span>5 days. Opened 3 days ago</span>*/}
            {/*        </div>*/}
            {/*        <div styleName='sidebar-document-actions'>*/}
            {/*            <div styleName='counter' style={{marginTop: '2px'}}>2</div>*/}
            {/*            <div>*/}
            {/*                <div styleName='sidebar-document-tasks'>2 Tasks</div>*/}
            {/*                <div styleName='sidebar-document-approval'>Your Approval Required</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div styleName='sidebar-recentDocumentWrapper'>*/}
            {/*        <div styleName='document'>*/}
            {/*            <img src="../../../../images/icons-doc.png"/>*/}
            {/*            <span>Construction Management and General  …</span>*/}
            {/*        </div>*/}
            {/*        <div styleName='sidebar-document-actions'>*/}
            {/*            <div styleName='counter' style={{marginTop: '2px'}}>18</div>*/}
            {/*            <div>*/}
            {/*                <div styleName='sidebar-document-comments'>5 Comments</div>*/}
            {/*                <div styleName='sidebar-document-changes'>11 Changes</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<NavLink to={`/${actualDocument.projectId}/documents`}>*/}
            {/*    <Button text='See All Documents for This Project' mod='blue' callback={() => null}/></NavLink>*/}
        </div>
    }
}

ScrollBlock = new CSSModules(ScrollBlock, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});


function Sidebar(props) {
    const {
        sideBarOpenHandler, freezeFunc, renameDocumentAction, approversCount, approvedsCount, isDocumentBlocked, isExportForbidden, isCompanyAdmin,
        projectName, download, user, tasksCount, actualDocument, versionBarIsOpen, openInvitePopup, isProjectAdmin, inDoc, setPopupMode, isWaitDownload,
        isDocTransferToFinalize, toggleNavigationVisAction
    } = props

    return (<div style={{width: '100%', height: '100%', backgroundColor: '#ffffff', position: 'relative', padding: '40px 0 40px 0'}}>

            <ScrollBar style={{
                height: '100%',
                width: 'calc(100% - 8px)',
                zIndex: '10',
                backgroundColor: '#ffffff'
            }}>
                <ScrollBlock actualDocument={actualDocument}
                             isWaitDownload={isWaitDownload}
                             isDocTransferToFinalize={isDocTransferToFinalize}
                             user={user}
                             inDoc={inDoc}
                             isCompanyAdmin={isCompanyAdmin}
                             isProjectAdmin={isProjectAdmin}
                             isExportForbidden={isExportForbidden}
                             approversCount={approversCount}
                             approvedsCount={approvedsCount}
                             isDocumentBlocked={isDocumentBlocked}
                             toggleNavigationVisAction={toggleNavigationVisAction}
                             tasksCount={tasksCount}
                             download={download}
                             projectName={projectName}
                             openInvitePopup={openInvitePopup}
                             toggleBar={props.toggleBar}
                             renameDocumentAction={renameDocumentAction}
                             sideBarOpenHandler={sideBarOpenHandler}
                             freezeFunc={freezeFunc}
                             setPopupMode={setPopupMode}
                            />
            </ScrollBar>

            <div styleName={cx('sidebar_progressAndTasks', {'sidebar_progressAndTasks_vis': versionBarIsOpen})}>
            {versionBarIsOpen && <ProgressAndTasks
                versionToggleBar={props.toggleBar} // Progress & Tasks close handler
                toggleBar={sideBarOpenHandler} // SideBar close handler
                download={download}
                dragHistory={props.dragHistory}
                dragTasks={props.dragTasks}
                jumpDoc={props.jumpDoc}
                freezeFunc={freezeFunc}
                prohibitNewVersion={props.prohibitNewVersion}/>}
            </div>
        </div>
    )
}


export default new CSSModules(Sidebar, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
