import React, { Component } from 'react';
import cx from 'classnames';
import cssModules from 'react-css-modules';
import {cloneDeep, isEqual} from 'lodash';
import styles from './projectPage.css';
import { isEmail } from '../../../../common/validation';

import InputBox from '../../../../components/input/InputBox';
import Button from '../../../../components/button/Button';
import { transL, transS } from '../../../../services/helpers/lang';

@cssModules(styles, {
  allowMultiple: true,
  handleNotFoundStyleName: 'throw' })

class AddTeam extends Component {
    state = {
        name: '',
        mail: '', 
        fname: '', 
        lname: '', 
        mailError: '',
        isShowAllAddedUsers: false,
        isShowAllCandidates: false,
    };
    constructor (props) {
      super(props);
      this._name = this._name.bind(this);
      this._mail = this._mail.bind(this);
      this._fname = this._fname.bind(this);
      this._lname = this._lname.bind(this);
    }

    _cancel = (e, param) => {
      let { func, number } = this.props;
      let {name} = this.state;
      e.preventDefault();
      e.stopPropagation();
      func(number, 'addteamclose', param ? 
        (number === -1 ? {name : name ? name : 'new team'} : '') : {saved: this.saved} );
    }
    
    _name = e => {
       this.setState({name: e.target.value});
    }
    _mail = e => {
       this.setState({mail: e.target.value, mailError: ''});
    }
    _fname = e => {
       this.setState({fname: e.target.value});
    }
    _lname = e => {
       this.setState({lname: e.target.value});
    }
    _addMail = () => {
       let {mail, fname, lname} = this.state;
       let { flow, func, number, user } = this.props;
       if (!isEmail(mail)) {
           if (func(number, 'addbymail', {mail, fname, lname}))
               this.setState({mailError: transL('invitationExistsError')});
           else this.setState({mail: '', fname: '', lname: ''});
       } else {
           this.setState({mailError: transL('errorEmail')});
       }       
    }
    
    componentDidMount() {
        let { flow, number, func} = this.props;
        func(number, 'getCandidates');
        this.saved = cloneDeep(flow.members[number]);

        /*let scrollIntoViewOptions = {
            behavior: 'smooth',
            block: 'center'
        }
        this.divRef.scrollIntoView(scrollIntoViewOptions);*/

        document.body.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        //document.body.style.overflowY = 'auto';
    }

    render () {
      let { flow, func, number, user, showTeamInCompany, isShowUpdateProjBtn, updateProjBtnCallback, errorString,
          isShowCreateProjBtn, createProjBtnCallback } = this.props;
      let { name, mail, mailError, fname, lname, isShowAllAddedUsers, isShowAllCandidates } = this.state;
      let isNew = (number === -1), 
        isInternal = isNew || flow.internals & (1 << number),
        companyName = func(number, 'getCompany');

      let isYourFirm = (showTeamInCompany === 0)

      let addedUsers = func(number, 'getMembers', {type: 'listUser', isYourFirm})
      addedUsers = addedUsers.props ? addedUsers.props.children.reverse() : ''
      const addedUsersCount = addedUsers.length
      addedUsers = (addedUsers.length > 10 && !isShowAllAddedUsers) ? addedUsers.slice(0, 10) : addedUsers

      let candidates = func(number, 'getCandidates', { list: true, isInternal })
      candidates = candidates.props ? candidates.props.children : ''
      const candidatesCount = candidates.length
      candidates = (candidates.length > 5 && !isShowAllCandidates) ? candidates.slice(0, 5) : candidates

      return (
        <div styleName='addTeamPosition'>
        <div styleName={cx('addTeam', {'_number':(!!number && !isInternal) && (number > 1) })}
          ref={(f) => { this.divRef = f; }} >
          <span className='close-cross' onClick={e => this._cancel(e)} >+</span>
          <div className="h1" styleName={cx('title', {'_woMargin':!isNew })}>
            {isNew ? 'Create a New Team' : 'Edit the Team'}</div>
          { isNew ? 
             <InputBox placeholder="Team Name"  func={this._name} value={name} inputWidth='320px' />
             : <div styleName='pages'>{ 
             (isInternal ? flow.abstractTeams[0] + ' — ' : '')
             + (companyName || flow.abstractTeams[number]) + 
             ((!number && flow.internals && !isInternal) ? ' — Representatives' : '')}</div> }
          <div styleName='addTeamsCont'>
              <div styleName='addTeamsPart'>
                <div className='h2'>Invite a New Participant</div>
                <InputBox placeholder="Email"  func={this._mail} value={mail} error={mailError}/>
                <InputBox placeholder="First Name"  func={this._fname} value={fname} 
                    inputWidth='155px' margin='10px 10px 20px 0' display="inline-block"/>
                <InputBox placeholder="Last Name"  func={this._lname} value={lname} 
                    inputWidth='155px' margin='10px 0 20px 0' display="inline-block"/>
                <Button text="Add a new person to the team"
                  mod='blue arch' callback = {() => this._addMail()} />
                {/*<div className='space50'></div>*/}

                  {!!candidatesCount && <React.Fragment>
                      <div style={{lineHeight: '1', margin: '60px 0 20px'}}
                           className='h2'>{func(number, 'getCandidates', {list: false, isInternal}) ?
                          'Or choose from people in this company' : 'Possible candidates'}</div>
                      <div styleName='listUser'>
                          {candidates}
                          {candidatesCount > 5 && <span styleName='teamCommand'
                                                         onClick={() => this.setState({isShowAllCandidates: !isShowAllCandidates})}>
                                         {isShowAllCandidates ? transS('Hide') : transS('Show All')}
                       </span>}
                      </div>
                  </React.Fragment>}

              </div>    
              <div styleName='addTeamsPart'>
                <div className='h2'>People added to Team</div>

                  <div styleName='listUser'>
                      {addedUsers}
                      {addedUsersCount > 10 && <span styleName='teamCommand'
                                          onClick={() => this.setState({isShowAllAddedUsers: !isShowAllAddedUsers})}>
                                         {isShowAllAddedUsers ? transS('Hide') : transS('Show All')}
                       </span>}

                  </div>

                  {isShowUpdateProjBtn && <Button mod='blue fill' style={{marginTop: '40px'}} text='Update Project' callback={e => updateProjBtnCallback(e)}/>}
                  {isShowCreateProjBtn && <Button mod='blue fill' style={{marginTop: '40px'}} 
                    text={transS('Create Project')} callback={createProjBtnCallback}/>}
                  {!!errorString && <span style={{marginTop: '40px'}} styleName='errorString'>{errorString}</span>}

              </div>
          </div>
          {/*<Button text="Add to Team"*/}
          {/*  mod='blue fill' callback = {e => this._cancel(e, 1)} />*/}
        </div>
        </div>
      );
    }
    
}

export default AddTeam;
