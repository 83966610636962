import React, {Component} from "react"
import styles from "./accountComponent.css"
import Button from "../../../../components/button/Button"
import {inputValidation} from "../../../../common/validation"
import CSSModules from "react-css-modules"
import api from "../../../../services/api/api"
import {getPopUpAction} from "../../../../services/actions/dataActions"
import {connect} from "react-redux"
import {popUpReset} from "../../../../services/helpers"
import {checkLogin} from "../../../../services/actions/userActions"
import Spinner from "../../../../components/spinner/Spinner"
import { transL, transS } from "../../../../services/helpers/lang"

@connect(state => ({}), {getPopUpAction, popUpReset, checkLogin})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class JoinCompanyPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: {
                value: '',
                dirty: true,
                invalid: false
            },
            loader: false,
        }
    }

    _onChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        inputValidation(this, e.currentTarget);
    }

    _request = () => {
        const {email} = this.state
        const {getPopUpAction, popUpReset, cancel} = this.props

        if (email.invalid) return

        this.setState({loader: true})

        const popup = {
            name: 'confirm',
            text: transS('The request has been sent.'),
            confirm: {
                name: transS('Got it'),
                event: () => getPopUpAction(popUpReset)
            }
        }

        api.requestJoinCompany(email.value)
            .then(data => { this.setState({loader: false});cancel(); getPopUpAction(popup); this.props.checkLogin() })
            .catch(err => this.setState({email: {...email, invalid: 'notFound'}, loader: false}))
    }

    render() {
        const {email, loader} = this.state

        return <div styleName='JC-popup-wrapper'>
            <div styleName='JC-popup-card'>
                <span className='close-cross' onClick={this.props.cancel}>+</span>
                {loader && <Spinner withoutLogo={true} />}
                <div styleName='JC-popup-card-title'>{transS('Join Company')}</div>
                <div styleName='JC-popup-card-text'>{transS("Please insert the company administrator's email to request joining the company.")}
                    
                </div>
                <div styleName='JC-popup-card-inputArea'>
                    <input type="text" placeholder='Email' name="email" value={email.value}
                           onChange={this._onChange}/>
                    <div styleName='error-message' style={{padding: '0', marginTop: '-5px'}}>
                        {
                            (() => {
                                    return {
                                        'required': transL('requiredEmail'),
                                        'maxLength': transL('maxLengthEmail'),
                                        'notFound': transS('It seems there is no company administrator with such email.'),
                                        'email': transL('errorEmail'),
                                    }[email.invalid];
                                }
                            )()
                        }
                    </div>
                </div>
                <div styleName='JC-popup-card-btnRow'>
                    <Button text={transS('Cancel')} mod='blue fill big'
                            callback={this.props.cancel}/>
                    <Button text={transS('Request')} mod='blue fill big' callback={this._request}/>
                </div>
            </div>
        </div>
    }
}

export default JoinCompanyPopup