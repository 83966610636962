import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './button.css';

const Button = props => {
  let { text, mod, type = 'button', callback = () => {}, number, style, ...rest } = props;

  return (
    <button 
      style={style || null} 
      styleName={cx('wrapper', { [mod]: mod })} 
      type={type} 
      onClick={callback}
      {...rest} 
    >
      {text}
      {!(number === undefined) && (number ? 
        <span styleName="circle red">{number}</span> 
        : 
        '')
      }
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  mod: PropTypes.string.isRequired,
  type: PropTypes.string,
  callback: PropTypes.func,
  number: PropTypes.number,
  style: PropTypes.object
};

export default new CSSModules(Button, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });
