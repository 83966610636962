import React from "react";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import cx from "classnames";

export default function Textarea({
  value,
  onChange,
  readOnly,
  placeholder,
  className,
  style,
  hasUnderline,
  ...props
}) {
  const ref = useRef(null);

  const adjustHeight = () => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.height = 0;
      textarea.style.height = textarea.scrollHeight + 1 + "px";
    }
  };

  const handleChangeTitle = (e) => {
    adjustHeight(ref);
    onChange(e.target.value);
  };

  useEffect(adjustHeight, []);

  return (
    <textarea
      className={cx(
        styles.textarea,
        { [styles.underline]: hasUnderline },
        className
      )}
      style={style}
      value={value}
      onChange={handleChangeTitle}
      ref={ref}
      readOnly={readOnly}
      placeholder={placeholder}
      {...props}
    />
  );
}
