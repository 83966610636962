import React, { useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './OptionAdvancedSettings.css'
import CheckBox from '../../topMenu/elements/TemplatesUnderMenu/CheckBox'
import DropdownType from '../../topMenu/elements/TemplatesUnderMenu/DropdownType'
import { transS } from '../../../../../services/helpers/lang'
import TextArea from '../../topMenu/elements/TemplatesUnderMenu/TextArea'
import Conditional from '../../topMenu/elements/TemplatesUnderMenu/Conditional'
import { useSelector } from 'react-redux'

const OptionAdvancedSettings = ({ 
  defaultValue, 
  conditional, 
  removeOption, 
  moveUp, 
  moveDown, 
  rOnly, 
  option, 
  changedVarList,
  getDropDown,
  isUpOption,
  isDownOption,
  canDeleted
}) => {
  const [showDefaultValue, setShowDefaultValue] = useState(false)
  const [showConditional, setShowConditional] = useState(false)
  const { varlist } = useSelector(state => state.document);

  return (
    <div styleName='container'>
      <div styleName='settingsContainer'>
        <CheckBox 
          filled={option.isInput}
          changed ={() => {
            if (rOnly) return

            option.isInput = !option.isInput 
            changedVarList();
          }} 
          ronly={false} 
          text={transS('Make Input')} 
        />
        {option.isInput && (
          <>
            <div styleName='typeContainer'>
              <DropdownType
                type={option?.type || 'text'} 
                container={true} 
                changed={() => {
                  if (rOnly) return

                  // changedVarList('', el)
                }}
                func={getDropDown}
                readOnly
              />
              <img src='/images/arrow_10x6.svg' alt='arrow' />
            </div>
            <div>
              <button 
                styleName='blueButton'
              >
                {transS('Add Format')}
              </button>
            </div>
            <div>
              {option["defaultValue"] || showDefaultValue ? (
                <div>
                  <div styleName="textareaTitle">
                    {transS('Default Value')}
                  </div>
                  <TextArea
                    name="defaultValue" 
                    big="2" 
                    defValue={defaultValue || ''}
                    container={option} 
                    changed={changedVarList} 
                    ronly={false}
                    check={option?.type === "number" ? (a) => isNaN(a) : false} 
                  />
                </div> 
              ) : (
                <button   
                  styleName='blueButton' 
                  onClick={() => !rOnly && setShowDefaultValue(true)}
                >
                  {transS('Add Default Value')}
                </button> 
              )}
            </div>
            {(conditional || showConditional) ? (
              <div>
                <div styleName="textareaTitle">
                  {transS('Condition for This Variable')}
                </div>
                <Conditional
                  el={option}
                  varlist={varlist}
                  changedVarList={changedVarList}
                  kill={() => { 
                    if (option.conditional) {
                      delete option.conditional
                      changedVarList()
                    } 
                    setShowConditional(false)
                  }}
                  readOnly = {rOnly}
                />      
              </div>
            ) : (
              <button 
                styleName='blueButton'
                onClick={() => !rOnly && setShowConditional(true)}
              >
                {transS('Add Condition')}
              </button>
            )}
          </>
        )}
      </div>
      <div styleName='toolsContainer'>
        <button 
          onClick={() => !rOnly && moveUp()} 
          styleName={'toolsItem'}
          style={{ opacity: isUpOption ? '0.3' : 1 }}
        >
          <img src='/images/arrow_blue_12x10.svg' style={{ rotate: '180deg' }} />
          {transS('Up')}
        </button>
        <button 
          onClick={() => !rOnly && moveDown()} 
          styleName={'toolsItem'}
          style={{ opacity: isDownOption ? '0.3' : 1 }}
        >
          <img src='/images/arrow_blue_12x10.svg' />
          {transS('Down')}
        </button>
        <button 
          onClick={() => {
            if (!rOnly) removeOption()
          }}
          styleName={'toolsItem toolItemRemove'}
          style={{ opacity: !canDeleted ? '0.3' : 1 }}
        >
          <img src='/images/deleteIcon_10x12.svg' />
          {transS('Delete')}
        </button>
      </div>
    </div>
  )
}

export default CSSModules(OptionAdvancedSettings, styles, {allowMultiple: true});