import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './userListItem.css';

import Checkbox from '../../../../components/checkbox/Checkbox';
import UserLogo from '../../../../components/userLogo/UserLogo';
import Hint from "../../../../components/button/Hint";
import Button from "../../../../components/button/Button"
import { getDateString } from "../../../../common/getDateString"
import {connect} from "react-redux"
import { transS } from '../../../../services/helpers/lang';

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

class UserListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEmailCut: false,
            isNameCut: false,
        }
    }

    componentDidMount() {
        const {maxNameWidth} = this.props
        const width = maxNameWidth || 144

        if (this.emailRef) {
            const emailWidth = this.emailRef.offsetWidth
            if (emailWidth >= width) this.setState({isEmailCut: true})
        }
        if (this.nameRef) {
            const nameWidth = this.nameRef.offsetWidth
            if (nameWidth >= width) this.setState({isNameCut: true})
        }
    }

    _callback = () => {
        const {disabled, locked, checked, _checkUser, user} = this.props
        !disabled && !(locked && !checked) && _checkUser(user._id);
    };

    _prv = () => {
        const {_proved} = this.props
        !disabled && !(locked) && !checked && _proved(user._id);
    };

    render() {
        const {user, checked, disabled, locked, _proved, prove, rep, func, number, userId,
            isYourFirm, noCheck, noActivated, maxNameWidth, remindProve, adminCheck, admin, dateData} = this.props;
        const {isEmailCut, isNameCut} = this.state

        const nameWidth = maxNameWidth ? (maxNameWidth + 'px') : '144px'

        let lastEmailChangeTime
        if (user.lastEmail) {
            lastEmailChangeTime = getDateString(Date.parse(user.lastEmailChangeTime),0, dateData)
        }

        return (

            <div styleName={cx('wrapper', {'_disabled': disabled || (locked && !checked)})}>
                <label>
                    
                    <div styleName='left'>
                       {!noCheck && <Checkbox checked={checked || disabled} callback={() => console.log('TEST')}
                                  disabled={disabled || (locked && !checked)} /> }
                    </div>

                    <div styleName='right'>
                        {!user.companyTeam && <UserLogo mod='_user-list' firstname={user.firstname} lastname={user.lastname}
                                                        email={user.email}
                                                        avatar={user.avatar}/>}
                        {user.companyTeam ?
                            <div styleName='user-company'>{user.companyTeam}</div>
                            :
                            <div styleName='user-data'>
                                {user.firstname ?
                                    <React.Fragment>
                                        <div ref={(e) => {this.nameRef = e}}
                                             style={{maxWidth: nameWidth}}
                                             styleName='user-name'>
                                            {user.firstname} {user.lastname} {!user.isActivated && '(invited)'} </div>
                                        <div ref={(e) => {this.emailRef = e}}
                                             style={{maxWidth: nameWidth}}
                                             styleName={cx('user-email', {'user-email_red': !!user.lastEmail})}>
                                            {user.email}</div>
                                    </React.Fragment>
                                    :
                                    <div ref={(e) => {this.nameRef = e}}
                                         style={{maxWidth: nameWidth}}
                                         styleName='user-name'>
                                        {user.email} {!user.isActivated && '(invited)'} </div>
                                }
                                {user.lastEmail && <div styleName="user_hintPlace user_hintPlace-lastEmail" style={(isEmailCut || isNameCut) ? {top: '-25px'} : null}>
                                    <Hint text={<div>Changed email at {lastEmailChangeTime}<br/>(from {user.lastEmail})</div>}/></div>}

                                {(isEmailCut || isNameCut) && <div styleName="user_hintPlace" >
                                    <Hint text={
                                        <div>{`${user.firstname ? `${user.firstname} ${user.lastname}\n` : ''}${user.email}${user.isActivated ? '' : ' (invited)'}`}</div>}
                                    /></div>}
                            </div>
                        }
                    </div>
                </label>
                {!!_proved && !noCheck && checked && !disabled && !locked && isYourFirm &&(
                    <div styleName='SU_buttons'>
                        <div styleName="SU_check" onClick={_proved}>
                            <img
                                src={prove ? "../../../../images/approvalTrue.svg" : "../../../../images/approvalFalse.svg"}/>
                            <div styleName="SU_hintPlace"><Hint
                                text={transS('Approval required for sending the document to the next team')}
                            /></div>
                        </div>
                        {(rep !== null) && <div styleName="SU_check" onClick={()=> func(number, 'repUser', user)}>
                            <img
                                src={rep ? "../../../../images/representativeTrue.svg" : "../../../../images/representativeFalse.svg"}/>
                            <div styleName="SU_hintPlace"><Hint
                                text="The team member can take part in external discussions (and is a team representative)."
                            /></div> 
                        </div> }
                    </div>
                )}
                {remindProve &&
                <div styleName='SU_remindBtn'>
                    <Button text='Remind' mod='blue' callback={this.props.remindUserDocumentApproveAction}/>
                </div>
                }
                { checked && adminCheck && <div styleName={cx('SU_admin', {'_active': admin, '_untouchable': user._id === userId})}>
                    <span onClick={() => func(number, 'adminUser', {adminUser: user, changeTo: !admin})}>Admin</span>
                    <div styleName='SU_admin_hintPlace'>
                        <Hint text={transS('Project Admin can add and delete users and send the document without approvals.')} 
                    /></div>
                </div>}
            </div>
        );
    }
}

UserListItem.propTypes = {
    user: PropTypes.object,
    checked: PropTypes.bool,
    _checkUser: PropTypes.func,
    disabled: PropTypes.bool,
    locked: PropTypes.bool,
    _proved: PropTypes.func,
    proveState: PropTypes.bool
};


export default UserListItem;