let currentItem = null

export const dragAndDropHandlers = (list, updateList, cardId) => {
  const container = document.querySelector('#scrollContainer')
  const scrollContainer = container ? container.parentElement : null

  const scrollContainerFunc = (event) => {
    if (!scrollContainer) return

    const scrollRect = scrollContainer.getBoundingClientRect();
    const scrollSpeed = 5;

    if (event.clientY < scrollRect.top + 150) {
      scrollContainer.scrollBy(0, -scrollSpeed);
    }

    if (event.clientY > scrollRect.bottom - 150) {
      scrollContainer.scrollBy(0, scrollSpeed);
    }

  }

  const dragStartHandler = (event, card) => {
    event.stopPropagation()
    window.addEventListener('dragover', scrollContainerFunc)
    currentItem = card
    const cards = document.querySelectorAll('.cardWrapperZ')

    for (const el of cards) {
      el.style.display = 'block'
    }
  }
  
  const dragEndHandler = (event) => {
    const target = event.target;
    const parentContainer = target.closest('.cardContainer');
    
    if (parentContainer) {
      const cardLineElements = parentContainer.querySelectorAll('.cardLine');

      if (cardLineElements) {
        const cardId = cardLineElements[0].id;

        cardLineElements.forEach(el => {
          if (el.id === cardId) {
            el.classList.remove('dragOverBottom');
            el.classList.remove('dragOverTop');
          }
        })
      }
    }

    window.removeEventListener('dragover', scrollContainerFunc)
  }

  const dragOverHandler = (event) => {
    event.preventDefault()
    const target = event.target;

    const parentContainer = target.closest('.cardContainer');

    if (parentContainer) {
      const cardTopLineElement = parentContainer.querySelector('.cardTopLine');
      const cardBottomLineElement = parentContainer.querySelector('.cardBottomLine');

      if (cardTopLineElement && cardBottomLineElement) {
        const cardId = cardTopLineElement.id;
        const id = cardId.split('_').pop()

        if (id != currentItem.id) {
          const rect = parentContainer.getBoundingClientRect();
          const middleY = rect.top + (rect.height / 2);
          
          if (event.clientY < middleY) {
            cardTopLineElement.classList.add('dragOverTop');
            cardBottomLineElement.classList.remove('dragOverBottom');
          } else {
            cardBottomLineElement.classList.add('dragOverBottom');
            cardTopLineElement.classList.remove('dragOverTop');
          }
        }
      }
    }
  }
  
  const dropHandler = (event, card) => {
    event.stopPropagation()
    event.preventDefault()

    if (card.id !== currentItem.id) {
      const filteredList = list.filter(el => el.id !== currentItem.id)
      const sortedList = [] 
  
      const cardElement = document.getElementById(`dnd_wrapper_${card.id}`);
      const rect = cardElement.getBoundingClientRect();
      const middleY = rect.top + (rect.height / 2);
  
      const isBeforeCurrentCard = event.clientY < middleY
  
      for (const el of filteredList) {
        if (el.id === card.id && isBeforeCurrentCard) sortedList.push(currentItem)
          
        sortedList.push(el)
  
        if (el.id === card.id && !isBeforeCurrentCard) sortedList.push(currentItem)
      }
  
      list = sortedList
      updateList(list)
    }


    const cards = document.querySelectorAll('.cardWrapperZ')
    for (const el of cards) {
      el.style.display = 'none'
    }

    const cardLines = document.querySelectorAll('.cardLine') 
    for (const el of cardLines) {
      el.classList.remove('dragOverTop');
      el.classList.remove('dragOverBottom');
    }

  }

  return {
    dragStartHandler,
    dragEndHandler,
    dragOverHandler,
    dropHandler
  }
}