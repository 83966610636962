import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import CSSModules from 'react-css-modules';
import styles from './progressAndTasks.css';
import Radio from '../../../../components/radio/Radio';
import Comment from './Comment';
import { getDateString } from "../../../../common/getDateString";
import { transF, transS } from '../../../../services/helpers/lang';

@connect(state => ({
  user: state.userReducer.user,
  actualDocument: state.document.actualDocument,
  dateData: state.userReducer.dateData,
  currentProject: state.projectReducer.currentProject
}), {} )
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Tasks extends Component {
  state = {
      isExpanded: -2,
      intervalTimer: null,
      ticks: 0,
      expandedCompleted: 0,
      invisibleItems: 0,
      showMoreVisible: 1,
  };
  clickCount = 0;
  completed = 0;
  unvisibles = 0;
  constructor (props) {
    super(props);
    this._collapse = this._collapse.bind(this);
    this._timer = this._timer.bind(this);
    this._expandCompleted = this._expandCompleted.bind(this);
    this._updateInvisibleItems = this._updateInvisibleItems.bind(this);
    this._showMore = this._showMore.bind(this);
    this._showMoreElement = this._showMoreElement.bind(this);
  }
  
  _timer = () => {
      this.setState({ ticks:  Date.now() });
  }
  
  _updateInvisibleItems = (n) => {
      this.unvisibles++;
      this.setState(prevState => prevState.invisibleItems 
      ? ( prevState.invisibleItems < n 
        ? {...prevState} : {...prevState, invisibleItems : n} )
      : {...prevState, invisibleItems : n} )
  }
  
  _expandCompleted = () => {
      const {isExpanded, expandedCompleted } = this.state;
      if (expandedCompleted <= isExpanded) 
        this.setState({ expandedCompleted: isExpanded + 3 - 2*(isExpanded % 2) });
      else
        this.setState({ expandedCompleted: expandedCompleted - 1 + 2*(expandedCompleted % 2)})
  }
  
  _collapse = e => {
      let button = parseInt(e.currentTarget.value, 10);
      this.setState({ isExpanded: this.clickCount*4 + button, 
         expandedCompleted: this.clickCount*4 + button,
        showMoreVisible: !(button) });
      this.clickCount++;
  };
  
  componentDidMount() {
    const { dragTasks } = this.props;
    this.setState({ ticks:  Date.now(), intervalTimer: setInterval(this._timer, 60000) });
    this.completed = dragTasks.reduce((ac,el)=>ac + ((el.due === -1) ? 1 : 0), 0);
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalTimer);
  }
  
  _showMore = () => {
      this.setState({showMoreVisible: 0});
  }
  
  _showMoreElement = () => {
     const {dragTasks} = this.props;
     const {invisibleItems, showMoreVisible} = this.state;
     let remains = dragTasks.length - this.completed - invisibleItems;
     return (
      this.unvisibles ? 
      <div key="sm" styleName={cx('showMore', { '_visible': showMoreVisible})} onClick={this._showMore}>
          {transF('showMore')(this.unvisibles)}  
      </div>
      : ''
     );
  }
  
  _traverseHistory = () => {
    const { actualDocument, dragTasks, jumpDoc, dateData } = this.props;
    const {ticks, expandedCompleted, isExpanded, showMoreVisible} = this.state;
    let result = [], nonNegative = true, isShowMore = false, halfLine = window.innerHeight/2 - 140;
    for (let i = 0; i < dragTasks.length; i++) {
       if (nonNegative && dragTasks[i].due < 0) {           
           result.push(this._showMoreElement());
           isShowMore = true;
           result.push(
              <div styleName="h3 mt-15" key="h3" onClick={this._expandCompleted}>Completed              
              <span styleName={cx('arrow', { '_expanded':  expandedCompleted % 2})}></span>
              </div>
           );
           nonNegative = false;           
       }
       result.push(
          <Comment
            key={dragTasks[i].comment+'-'+dragTasks[i].time}
            isFreeze={dragTasks[i].freeze !== undefined}
            isPrivate={dragTasks[i].isPrivate}
            firstname={dragTasks[i].firstname}
            invisible={dragTasks[i].due < 0 && !(expandedCompleted % 2)}
            checkLine={isExpanded %2 || !showMoreVisible ? 0 : halfLine}
            updateInvisibleItems={() => this._updateInvisibleItems(i)}
            lastname={dragTasks[i].lastname}
            avatar={dragTasks[i].avatar}
            isThread={dragTasks[i].time === dragTasks[i].comment}
            thTitle={dragTasks[i].thTitle}
            ctime={getDateString(dragTasks[i].time, 4, dateData)}
            title={dragTasks[i].title}
            content={dragTasks[i].content} 
            jumpId={dragTasks[i].comment+'-'+(dragTasks[i].upd ? dragTasks[i].upd : dragTasks[i].time)}
            jumpDoc={id => jumpDoc(id,
              actualDocument._id, actualDocument.coreDocument, actualDocument.blocked, '') }
            due={dragTasks[i].due > 0 
             ? transF('Due')((dragTasks[i].due > ticks 
                ? 'Due ' : 'Overdue ') + moment(dragTasks[i].due).from(ticks) )
             : ''}
          />
       );  
    }      
    if (!isShowMore) result.push(this._showMoreElement());           
    return result;
  }

  render() {
    const {isExpanded} = this.state;
    const { dragTasks } = this.props;
    return (
      <div styleName='partCommon' ref={(f) => { this.element = f; }}>
        <div styleName='title'>{transS('Mentions')}</div>
        {dragTasks.length ?
        <div styleName='neutral'>
          <div styleName="radioField">
            <Radio text0={transS('Collapse All')} text={transS('Expand All')} radioBtn={ isExpanded % 2} 
                   name='tasks' callback={this._collapse} />
          </div>
          <div styleName="h2">{transS('Mentions')}</div>
          { this._traverseHistory() }            
        </div>
        :
        <div styleName='neutral'>{transS('Nothing yet.')}</div>
        }
      </div>
    );
  }
  
 
}
