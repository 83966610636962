import { useSelector } from "react-redux"
import React, { useEffect, useRef } from "react";
import cx from 'classnames';
import CSSModules from "react-css-modules";
import styles from './templatesUnderMenu.css'
import { transS } from "../../../../../../services/helpers/lang";
import ScrollBar from "../../../../../../components/scrollBar/ScrollBar";
import useEvent from "../../../../../../services/helpers/use-event";
import ClauseLibTemplate from "../../../../views/clauseLib/ClauseLibTemplate.jsx";

function Clauses(props) {
  const { tab, setTab, clausesCount } = props
  const clauseLib = useSelector(store => store.userReducer.clauseLib)
  const containerRef = useRef(null);

  const handleResize = (_) => {
    if (!containerRef?.current) return
    let top = containerRef.current.getBoundingClientRect().top;
    if (containerRef?.current?.style)
      containerRef.current.style.height = 
        // communicationVis ?
        (window.innerHeight - top) + 'px' 
        // : '0px'
  }; 
  useEvent('resize', handleResize);
  useEffect(() => handleResize(), [tab]);

  const {insertOn, frameProgress} = useSelector(state => state.paragraphReducer);

  return (
    <div styleName="varContainerExtAdapter">
      <div styleName='varContainerExt template'>
        {!!clausesCount && (
          <div styleName="toggles">
            <div 
              styleName={cx('toggle', { _sel: tab === 'variables' })}
              onClick={() => setTab('variables')}
            >
              {transS('Variables')}
            </div>
            <div 
              styleName={cx('toggle', { _sel: tab === 'clauses' })}
              onClick={() => {
                setTab('clauses')
              }}
            >
              {transS('Clause Library')}            
            </div>
          </div>
        )}
        <div styleName={'scrollContent'} ref={containerRef}>

          <ScrollBar
            style={{
              height: '100%',
              width: 'calc(100% - 8px)',
              zIndex: '10',
              backgroundColor: '#ffffff'
            }} 
            invisible="true"
          >
            <ClauseLibTemplate />
          </ScrollBar>
        </div>
      </div>
    </div>
  )
}

export default CSSModules(Clauses, styles, {allowMultiple: true});
