import React, { useCallback, useEffect, useState } from 'react'
import CSSModules from 'react-css-modules';
import styles from './analysis.css';
import AnalysisListItem from './AnalysisListItem/AnalysisListItem.jsx';
import RemoveItemPopup from './AnalysisPopup/RemoveItemPopup.jsx';

// props
// analysis: any[]

function AnalysisList({ 
  analysis, 
  hideElement,
  deleteItem,
  moveNext,
  movePrev,
  setActiveItem,
  openItemId,
  setResolve,
  setRiskButNotResolve,
  date
}) {
  const [list, setList] = useState(analysis)
  const [removeItemId, setRemoveItemId] = useState(null)

  const closePopupCallback = useCallback(() => setRemoveItemId(null), [])
  const removeItemCallback = useCallback(() => deleteItem(removeItemId), [removeItemId, deleteItem])

  useEffect(() => setList(analysis), [analysis])

// correctionComment: 1734468769496
// correctionText: "IA-A-A-A не должно"
// date: 1734468769496
// defaultText: "AI не должно"
// id: "0-1734468769496"
// isHidden: false
// isResolve: false
// notResolveButRisk: false
// policy: "Глобальная договорная политика"
// policyId: 1
// rule: "Политика договоров поставки"
// ruleContent: "По договорам поставки, услуг и агентирования максимальная цена не может быть больше 4 500 000 RUB"
// ruleId: 12

  return (
    <>
      {removeItemId && (
        <RemoveItemPopup 
          closePopupCallback={closePopupCallback}
          removeItemCallback={removeItemCallback}
        />
      )}
      {/* <div styleName="list" data-analysis-list={true}>
        {list.map(el => (
          <AnalysisListItem 
            id={el.id}
            isResolve={el.state === 'resolve'}
            notResolveButRisk={el.state === 'notResolveButRisk'}
            defaultText={el.defaultText}
            correctionText={el.correctionText}
            correctionComment={el.correctionComment}
            policyId={el.policyId}
            policy={el.policy}
            ruleId={el.ruleId}
            rule={el.rule}
            ruleContent={el.ruleContent}
            date={date}
            activeToggler={setActiveItem}
            isActive={openItemId === el.id}
            deleteItem={setRemoveItemId}
            hideElement={hideElement}
            isFirstElement={list[0].id === el.id}
            isLastElement={(list?.at(-1)?.id ?? 0) === el.id}
            moveNext={moveNext}
            movePrev={movePrev}
            setResolve={setResolve}
            setRiskButNotResolve={setRiskButNotResolve}
            key={el.id}
          />
        ))}
      </div> */}
    </>
  )
}

export default CSSModules(AnalysisList, styles, {allowMultiple: true});