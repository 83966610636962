import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import cssModules from 'react-css-modules';
import cx from 'classnames';
import moment from 'moment';
import styles from './projectList.css';
import api from '../../../../services/api/api';
import {
    getInvitePopupAction,
    getProjectDocuments,
    getProjectList,
    updateProjectAction,
    setCurrentProject,
    deleteProjectAction
} from '../../../../services/actions/projectActions';
import {getPopUpAction, getpopUpWithInputAction} from '../../../../services/actions/dataActions';
import {
    addFileAction,
    setCurrentDocumentAction,
    setDisplayOnlyAction,
    setActualDocumentAction
} from '../../../../redux/document/actions';
import Lock from '../../../../components/lock/Lock';
import Button from '../../../../components/button/Button';
import {PlusSign} from '../../../../components/plusSign/PlusSign';
import ProjectLines from './projectLines';
import CompanyLines from './companyLines';
import {popUpReset, popUpWithInputReset, 
    showPopupManyFiles, universalDrop} from '../../../../services/helpers';
import {showPaymentAction} from '../../../../services/actions/userActions';
import {folderAction } from '../../../../services/actions/documentActions';
import Hint from "../../../../components/button/Hint";
import Dropzone from "react-dropzone";
import FirstTime from "../../components/firstTime/FirstTime";
import { block5PermissionCheck, docs5PermissionCheck, oldPermissionCheck } from '../../../../common/validation';
import DocumentListItemDropDown from "../../components/DocumentListItemDropDown/DocumentListItemDropDown";
import DocumentListMenuDropDown from "../../components/DocumentListItemDropDown/DocumentListMenuDropDown";
import ProjectPage from "../../components/projectPage/projectPage";
import UploadPopup from '../TemplatesList/UploadPopup';
import { getDateString } from "../../../../common/getDateString"
import ListHeader from "../../components/ListHeader/ListHeader";
import { transF, transL, transS } from '../../../../services/helpers/lang';
import SearchFields from './SearchFields';
import { socketConstants } from '../../../../../server/configs/appConfig';

@connect(state => ({
  projectList: state.projectReducer.projectList,
  invitePopup: state.projectReducer.invitePopup,
  user: state.userReducer.user,
  firstTime: state.userReducer.firstTime,
  dateData: state.userReducer.dateData,
  currentProject: state.projectReducer.currentProject,
}), {
  setCurrentProject, getInvitePopupAction,
  getProjectList, getProjectDocuments,
  setDisplayOnlyAction,
  getpopUpWithInputAction,
  updateProjectAction,
    addFileAction,
  folderAction,
  showPaymentAction,
    deleteProjectAction,
  setCurrentDocumentAction, setActualDocumentAction,
    getPopUpAction
})
@cssModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPage: 0,
            transferFiles: [],
            transferNames: [],
            title: '',
            isDragActive: false,
            isUploadPopupOpen: false,
            isAbsolute: null,
            absoluteX: 0,
            absoluteY: 0,
            collapsedDocs: new Set(),
            findFilter: null,
            findNotFound: false,
            searchFields: false,
        }
        this.dropzoneTimeout = null
    }

  _extractTokenFromLink = queryString => queryString ? queryString.slice(1) : false;
  async componentDidMount() {
    let { setCurrentProject, user, getProjectDocuments, match,
        location, setDisplayOnlyAction, projectList, currentProject, getInvitePopupAction } = this.props;
    let { sortView } = this.state;
    let { projectId } = match.params;
    let proj;
    const token = this._extractTokenFromLink(this.props.location.search);

    try {
        if (token) {
            let linkValidationInfo = await api.validateInvitationLink(token);
            if (linkValidationInfo && linkValidationInfo.data && linkValidationInfo.data.data) 
                projectId = linkValidationInfo.data.data.projectId;
            if (!projectId) {
                projectId = 'notFound';
                this.props.history.push(`/not-found/402/`);
                return;
            }
            this.props.history.push(`/${projectId}/documents/`);
        }
    }
    catch (err) {
      this.props.history.push('/not-found/404/');
      return;
    }


    
    let myProjects = []
    if (!currentProject._id || !currentProject.docs !== projectList.find(el => el._id === currentProject._id).docs) {
        myProjects = await getProjectDocuments();
    }

    if (location.hash) {
       projectId = location.hash.split('/')[1];
    }
    this.props.setCurrentDocumentAction()
    this.props.setActualDocumentAction({})
    if (!sortView) this._sortBy(projectId ? 'd' : 'p', !!projectId);

    if (myProjects) proj = myProjects.find(el => el._id === projectId);

    if (projectId) {
        if (proj) {
            window.socket.emit(socketConstants.ENTER_THE_PROJECT, { projectId });
            setCurrentProject({projectId: projectId, projectList: myProjects});
        }
        let tab = location.hash.split('/')[3];
        if (tab) this._page(tab);
        if (currentProject.title) this.setState({title: currentProject.title})
    } else {
        window.socket.emit(socketConstants.ENTER_THE_PROJECT_LIST, {});
        setCurrentProject({});
        setDisplayOnlyAction(false, '');
    }

    window.scrollTo(0, 0)
    window.addEventListener('dragenter', this.dragHandler)
    window.addEventListener('dragover', this.dragHandler)
    window.addEventListener('drop', this.dragHandler)
  }

  componentWillUnmount() {
      window.removeEventListener('dragenter', this.dragHandler)
      window.removeEventListener('dragover', this.dragHandler)
      window.removeEventListener('drop', this.dragHandler)
  }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        let {setCurrentProject, getProjectDocuments, match, getInvitePopupAction,
            projectList, currentProject, invitePopup } = this.props
        let { projectId } = match.params;
        if (!projectId && location.hash) {
            projectId = location.hash.split('/')[1];       
        }

        if (prevProps.currentProject?.title !== currentProject?.title && currentProject.title) {
            this.setState({title: currentProject.title})
        }
        
        if (prevProps.currentProject?._id !== currentProject?._id)
            this.setState({findFilter: null})

        if (!projectId && currentProject._id) {
            this.setState({selectedPage: 0})
            setCurrentProject({});
            this._sortBy('p')
            window.scrollTo(0, 0)
        } 

    }

  _nodragEnter = (e) => {
     e.dataTransfer.dropEffect = 'none';
     e.preventDefault(); e.stopPropagation();
  }

  _dragEnter = (e) => {
     const { user, addFileAction } = this.props;
     if (oldPermissionCheck() && user.ticks <= 0)
       e.dataTransfer.dropEffect = 'none';
     else {
         e.target.style.backgroundColor = '#fbfbfb';
         e.dataTransfer.dropEffect = 'copy';
     }
     e.preventDefault(); e.stopPropagation();
  }

  _dragLeave = (e) => {
      if (e.relatedTarget.parentElement !== e.target)
        e.target.style.backgroundColor = '';
      e.preventDefault(); e.stopPropagation();
  }

    _drop = (accepted, rejected, project) => {
        const { user, projectList, getPopUpAction, showPaymentAction, 
            addFileAction, currentProject } = this.props;
        this.setState({isUploadPopupOpen: false, isDragActive: false, isDragFile: 0});
        let files = [...accepted, ...rejected], 
            projectId = project?._id ? project._id: currentProject._id,
            count = docs5PermissionCheck(projectList);
        if ((user.ticks <= 0) && block5PermissionCheck(count+files.length+this.state.transferNames.length)) 
            return getPopUpAction(showPopupManyFiles(getPopUpAction, showPaymentAction));
        universalDrop(files, 
          (tfiles, tnames) => addFileAction(tfiles, tnames, projectId, null) );
    }
    
    _createDocumentFromTemplate = async (projectId, templateId, title) => {
        await api.createDocumentFromTemplate(projectId, templateId, title)
        await this.props.getProjectDocuments()
        return 1
    }

  /**
   * Opens popup with input to edit name of the project
   * @param {string} projectId - It is id of the project from backend
   * @param {string} currentName - Current title of the project
   */
  openChangeNamePopup = (projectId, currentName) => {
    let { getpopUpWithInputAction, updateProjectAction } = this.props;
    const popup = {
      name: 'rename',
      text: transS('Project name'),
      inputText: currentName,
      projectId: projectId,
      fieldName: 'projectName',
      placeholder: transS('Project name'),
      companyInput: '',
      confirm: {
        name: transS('Rename'),
        event: (id, doc) => {
          return () => {
            if (doc.invalid) return;
            const newName = doc.value;
            updateProjectAction(id, newName);
          };
        }
      },
      cancel: {
        name: transS('Cancel'),
        event: () => { getpopUpWithInputAction(popUpWithInputReset); }
      }
    };
    getpopUpWithInputAction({ popup });
  }

  plusSvg = () => 
    <PlusSign />
  ;

  _orders = {
     p: [0,1,2,3],
     d: [7,8,1,2,3],
     s: [9,1,2,3],
     n: [9,10,1,2,3],
  };

   _ordersOneProject = {
      p: [0,1,2,3],
      d: [7,1,2,3],
      s: [7,1,2,3]
    };

  _viewHeaders = [transS('Companies, Projects and Documents'),  //0
    transS('Requires Attention'),                              //1
    transS('Activity in the Document'),                        //2
    transS('Current Stage'),                                   //3
    transS('What Next'),                               //4
     '',                                                //5
     '',                                                //6
     transS('Documents'),                                       //7
     transS('Company, Project'),                                //8
     transS('Project and Documents'),                           //9
     transS('Company'),                                         //10
  ];

  _showDoc = [
     (d, p) => this._docTitle(d, p, 2),  //Companies, Projects and Documents
     (d) => this._docAttention(d),
     (d) => this._docActivity(d),        //Activity in the Document
     (d) => this._docStage(d),
     (d) => d.side ? transS('External Review')+' ' : transS('Internal Review'),
     (d) => d.version+'.'+d.subVersion,
     () => '',
     (d, p) => this._docTitle(d, p, 0),   //Documents
     (d, p) => this._docCompanyProject(p),
     (d, p) => this._docTitle(d, p, 1),   //Project and Documents
     (p) => p.company ? p.company : ''
  ];

  _colStyles = [
     '_pdColFirst',
     '_pdCol200',
     '_pdCol145',
     '_pdCol200',  //3
     '_pdCol145',
     '_pdCol10',
     '_pdCol10',
     '_pdColFirst',
     '_pdCol240',
     '_pdColFirst',
     '_pdCol180',
  ];
  _docTitle = (d, p, s) => {
      let visibleTitle = d.title, visibleExtension = '', 
        isFolder = d.contentType === 'folder';
      if (!isFolder && d.contentType) {
          let name = visibleTitle.split('.');
          visibleExtension = name.pop().toUpperCase();
          visibleTitle = name.join('.');
      }

    return (
      <div styleName="pdTitleAft">
        {visibleExtension ?
        <span styleName={cx('pdDocExt',{ '_shifted': s})}>{visibleExtension}</span>
        :
        isFolder ? <img src="/images/icons-folder.png" width="13"  height="13"
            style={{translate: '0 2px', flexShrink: 0}}
            styleName={cx('pdDocSym',{ '_shifted': s })} />
        : <svg styleName={cx('pdDocSym',{ '_shifted': s })}
          style={{translate: '0 3px', flexShrink: 0}}
          width="11" height="13" viewBox="0 0 11 13"
          dangerouslySetInnerHTML={{ __html: `<path fill="none" fill-rule="evenodd" stroke="#ACACAC" d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5"/>`}}>
        </svg>
        }
        <span styleName="pdTitleCont" onClick={(e) => this._projectFunc('doclink',d, p)}>
            {visibleTitle}          
        </span>
        {!!d.isParent && <div styleName={cx("expander", {'_expanded': !this.state.collapsedDocs.has(d.coreDocument || d._id)})}
            onClick={() => this._collapseDoc(d.coreDocument || d._id)}
            style={{left:0, flexShrink: 0}}></div>}                
      </div>
  )};
  
    _collapseDoc = (id, skipAdd) => {
      const s = this.state.collapsedDocs;
      if (!s.delete(id) && !skipAdd) s.add(id);
      this.setState({collapsedDocs: s});
    }
    
    _notHidden = el => {
       const s = this.state.collapsedDocs;
       if (el.level = 0 || !s.size) return true;
       while (el.parent) {
         if (s.has(el.parent)) return false;
         el = el.parentRef;
       }
       return true;
    }

    _docStage = (d) => {
        let cnt = d.approved ? d.approved.length : 0;
        if (d.contentType) {
            if (d.contentType === 'folder') return false;        
            return <div styleName="pdStage">
              <div>{d.shared ? transS('Shared') : transS('Internal Review')}</div>
            </div>
        }
        const envelope = d.dsEnvelope && JSON.parse(d.dsEnvelope)
        if (envelope && envelope.status !== 'created' && envelope.status !== 'declined') {
            const envelopeObj = {}
            let {user} = this.props;
            if (envelope.status === 'completed')
                envelopeObj.isSignedVersion = true;
            else {
                envelopeObj.isProcessSigning = true;
                let u = envelope.signee.find(c => c.userId === user._id || c.email && c.email.toLowerCase() === user.email.toLowerCase());
                if (u) {
                    envelopeObj.isMySignNeed = true;
                    envelopeObj.isMySign = !!u.signed;
                }
            }
            let {isProcessSigning, isMySign, isMySignNeed, isSignedVersion} = envelopeObj;
            let text, info
            //let greenText = isSignedVersion || isMySign || isProcessSigning
            if (isSignedVersion) text = transS('Signed Version')
            else if (isMySign) text = transS('You signed')
            else if (isProcessSigning) text = transS('Signing in Progress')

            const days = moment().diff(moment(envelope.updatedAt), 'days');
            info = envelope.updatedAt ? '' : (days + ` day${days > 1 ? 's' : ''}`);

            return <div styleName={cx("pdStage", {'pdStage_finalized': isSignedVersion})}>
                <div styleName={cx('pdStage-text', {'pdStage-text_green': 1})}>{text}</div>
                <div styleName="pdStageInfo">{info}</div>
            </div>
        }
        if (d.orig === 9 || d.orig === 10 || d.orig === 12 || d.orig === 13 || 
            d.orig === 14) {
            let text, info
            let greenText = d.orig === 9 || d.orig === 10 || d.orig === 12 || 
              d.orig === 13 || d.orig === 14

            const days = moment().diff(moment(d.createdAt), 'days')
            let lastOpened = (d.orig === 12 || d.orig === 13 || d.orig === 14) ?
                '' : 
                d.wasOpened ? 
                  `. Opened ${moment(d.wasOpened).fromNow()}` : '. Not opened yet'

            if (d.orig === 9 || d.orig === 10) 
                text = d.side ? transS('Finalised on your side') : 
                    transS('The other team requested to finalise this document')
            if (d.orig === 12 || d.orig === 13 || d.orig === 14) days < 5 
              ? text = transS('Finalised by everyone') : text = transS('Finalised')

            info = transF('SentOpen')((days > 5 && (d.orig === 12 || d.orig === 13 || d.orig === 14)) 
              ? '' : 
              days < 1 ? `Today${lastOpened}` 
                : `${days} day${days > 1 ? 's' : ''}${lastOpened}`)
            return (
                <div
                    styleName={cx("pdStage", {'pdStage_finalized': days < 5 && (d.orig === 12 || d.orig === 13 || d.orig === 14)})}>
                    <div styleName={cx('pdStage-text', {'pdStage-text_green': greenText})}>{text}</div>
                    <div styleName="pdStageInfo">
                        {(d.orig === 13 || d.orig === 14 || d.orig === 12 || d.orig === 9 || d.orig === 10) && info}
                    </div>
                </div>
            )
        }

        const days = moment().diff(moment(d.createdAt), 'days')
        return <div styleName="pdStage">
            <div>{d.orig == 302 ? transS('Draft') : ((d.orig == 6 || d.orig == 7) 
              ? transS('Collaboration') :
                d.side ? (d.orig == 21 ? `External Review (Sent via Email)`
                  : d.orig == 50 ? `Imported Version (Preview)`
                  : transS('Internal Review & Approval') )
                : transS('External Review'))}</div>
            {d.orig !== 302 && d.orig !== 21 && d.orig !== 50 
              && <div styleName="pdStageInfo">
                {d.side ? (cnt < d.provers ?
                   transF('approvedBy')(cnt, d.provers) : transS('Approved'))
                 : transF('days')(days)}
            </div>}
        </div>
    };
  _docAttention = (d) => {
    return ( (d.side || d.orig === 13 && !d.side) && d.myApprove && 
      (!d.approved || !d.approved.includes(this.props.user._id) && 
      d.orig !== 21 && d.orig !== 50 &&
      d.orig !== 9 && d.orig !== 302 && d.orig !== 14 && !d.contentType) &&
      <div styleName="pdAttention">
         {transS('Your Approval Required')}
      </div>
  )};
  _docActivity = (d) => {
    let len, s;
    const { dateData } = this.props;
    if (d.contentType) return;
    if (d.dochanges) {
       len = Object.keys(d.dochanges).length - 1;
       if (!len) s = getDateString(+d.dochanges.max, 0, dateData);
    }
    if (d.orig == 13 || d.orig == 14) {
        let text = d.side ? transS('Your team requested to cancel Final Version') 
          : transS('The other team requested to cancel Final Version')
        return <div styleName="pdActivity">
            <div styleName="counter" style={{transform: 'translateY(5px)'}}>{1}</div>
            <div style={{color: '#ff6c6c'}}>{text}</div>
        </div>
    } else if (d.orig == 11 && !d.side) {
        return <div styleName="pdActivity">
            <div styleName="counter" style={{transform: 'translateY(5px)'}}>{1}</div>
            <div style={{color: '#ff6c6c'}}>
              {transS('The External Team did not finalise the document')}
            </div>
        </div>
    } else if (d.orig == 9 || d.orig == 10 || d.orig == 12) {
        return
    }
    return (( s ) ?
      <div styleName="pdInActivity" 
        dangerouslySetInnerHTML={{__html: transF('Nothing new')(s)}} />
      : ((len) ? <div styleName="pdActivity">
        <div styleName="counter" style={len > 99 ? {fontSize: '8px'} : null}>{len > 99 ? '99+' : len}</div>
        <div>{transF('changes')(len)}</div>
      </div>
      : '')
  )};
  _docCompanyProject = p => {
      return (
      <div styleName="pdCompanyProject" onClick={() => this._projectFunc('project', p)}>
        <div>{ p.company ? p.company : ''}</div>
        <div styleName="pdStageInfo"> {p.title}</div>
      </div>
  )}

  _sortBy = (o, isOneProject = false) => {
    let { sortView } = this.state;
    if (sortView === o) return;
    if (isOneProject) {
        this.setState({sortView: o, sortOrder: this._ordersOneProject[o]});
    } else {
        this.setState({sortView: o, sortOrder: this._orders[o]});
    }
  };
  
  _makeArrayFromProjects = () => {
     let { projectList, currentProject } = this.props;
     let associative = {}, arr = [];
     const fillArrays = p => p?.docs && 
         p.docs.forEach(d => {
             let newDoc = {...d, project: p, level:0, parentTime: moment(d.updatedAt)};    
             associative[d.coreDocument || d._id] = newDoc;
             arr.push(newDoc);
         });
     const shift = (a, b) => {
        let shifted = arr.splice(b, a - b);
        arr.splice(b+1, 0, ...shifted);
     };
     const shiftOne = (a, b) => {
        let shifted = arr.splice(b, 1);
        arr.splice(a, 0, shifted[0]);
     };
     const inParents = (el, sample) => {
         if (!el.parent) return false;
         if (el.parent === sample) return true;
         return inParents(el.parentRef, sample);
     }
     const processParent = i=> {
       let parentIndex = arr.findIndex(el => el === associative[arr[i].parent]);
       if (parentIndex === -1) {
           arr[i].parent = 0;
           return 0;
       }
       arr[i].parentRef = arr[parentIndex];
       arr[parentIndex].isParent = 1;
       if (parentIndex < i) {
           arr[i].level = arr[parentIndex].level + 1;
           let j = i-1;
           while (j > parentIndex && !inParents(arr[j], arr[i].parent)) j--;
           if (j < i-1) {
               shift(i,j+1);
               return 0;
           }
           return 0;
       } 
       shiftOne(i, parentIndex);
       return -1;
     };
     
     if (currentProject._id)
       fillArrays(currentProject);
     else 
       projectList.forEach( p => fillArrays(p) );
     arr.sort((a,b) => b.parentTime - a.parentTime);
     for (let i = 0; i< arr.length; i++) {
       if (!arr[i].parent || arr[i].parent === arr[i].coreDocument) continue;
       i += processParent(i);       
     } 
     return this.state.findFilter ? arr.filter(el => this.state.findFilter.has(el._id)) 
     : arr;
  };
  
  _sortedByDateUnstructured = ()=> {
     let { currentProject } = this.props;
     let arr = currentProject.docs.map(d => { 
        let newDoc = {...d, project: currentProject, level:0, parentTime: moment(d.updatedAt)};
        return newDoc;}).filter(el => el.contentType !== 'folder');
     arr.sort((a,b)=> b.parentTime - a.parentTime); 
     return arr.map(el => this._projectFunc('doc', el, el.project));
  }
  
  _sortedByDate = (isDraggable) => {
      return this._makeArrayFromProjects().map(el => this._projectFunc(isDraggable ? 'docDraggable' : 'doc', el, el.project));
  };

  _sortedByProjectName = (onName = true) => {
    let { projectList } = this.props
    const sortFunc = onName ?
      (a, b) => a.title.localeCompare(b.title)
      : (a,b) => (a.createdAt < b.createdAt)-0.5;
    let arr = projectList.slice(), docs = this._makeArrayFromProjects();    

    return arr
      .sort(sortFunc).map(p => <ProjectLines project={p} 
        findFilter={this.state.findFilter}
        isDragActive={this.state.isDragActive} 
        projectDocs={docs.filter(d => d.project === p)} plusSvg={this.plusSvg} 
        addFileAction={this.props.addFileAction} dropHandler={this._drop}
        projectFunc={this._projectFunc}  key={p._id} user={this.props.user} 
        dragHandler={this.dragHandler}/>
      )
   }

  _sortedByCompany = () => {
      let { projectList, user } = this.props;
      let st = new Set(), docs = this._makeArrayFromProjects();
      projectList.forEach( p => st.add(p.company ? p.company : ''));
      return Array.from(st).sort().map(c =>
        <CompanyLines projectList={projectList} projectDocs={docs} key={c ? c : '1'} openChangeNamePopup={this.openChangeNamePopup}
           findFilter={this.state.findFilter}
           user={user} isDragActive={this.state.isDragActive} plusSvg={this.plusSvg} 
           dragHandler={this.dragHandler} dropHandler={this._drop}
           projectFunc={this._projectFunc}  company={c} addFileAction={this.props.addFileAction}/>
      );
  };

  _projectFunc = (action, el, p) => {
    let { sortOrder } = this.state;
    let {setCurrentProject, projectList, currentProject } = this.props;
    let isDraggable;
    switch (action) {
        case 'project':
          // this.props.history.push(`/${el._id}/documents/`);
          this.props.history.push(`/#/${el._id}`);
          this.props.setCurrentDocumentAction()
          this.props.setActualDocumentAction({})
          setCurrentProject({projectId: el._id, projectList});
          this._sortBy('d', true);
          window.scrollTo(0, 0)
          return;
      case 'doclink':
          if (el.coreDocument) {
              if (el.contentType) {
                let selector = el.hasPdf ? 'docpdf' 
                  : el.contentType === 'application/pdf' ? 'pdf'
                    : el.contentType.substr(0,5) === 'image' ? 'img' : 'none';
                this.props.history.push(`/${p._id}/docview/${el.coreDocument}/${selector}/${el.title}`) 
              }
              else
                this.props.history.push(`/${p._id}/documents/${el.coreDocument}`)
          } else {
              if (el.contentType) 
                this._collapseDoc(el._id);
              else
              this.props.history.push(`/${p._id}/documents/${el._id}`);
          }
          return;
      case 'docDraggable':
          isDraggable = true;
      case 'doc':
          const editable = (!oldPermissionCheck() || this.props.user.ticks > 0) && this.props.currentProject._id &&
          ( (this.props.user._id === el.addedBy && el.version === 1) ||
              this.props.user._id === this.props.currentProject.creator ||
              ( this.props.currentProject.admins && this.props.currentProject.admins.find(i => i === this.props.user._id) ));
          const level = el.level;
          return this._notHidden(el) && (             
            <div key={el._id} styleName={cx(currentProject._id ? 'onDoc' : 'pdDoc', 
                {'dragover': this.state.isDragActive && this.state.dragOver === (el.coreDocument || el._id)})} 
               draggable={isDraggable && editable}
               onDragStart={isDraggable && editable ? e => 
                 this._dragFunc('start', el.coreDocument || el._id, e, el.parent) : null}
               onDragEnter={isDraggable ? (e) => this._dragFunc('enter', el.coreDocument || el._id, e) : null}
               onDrop={(e) => this._dragFunc('drop', el.coreDocument || el._id, e)}
               onMouseDown = { e => this._docMouse(e, editable && e.button ===2, el) }
               onContextMenu={e => {
                   event.stopPropagation();
                   event.preventDefault();
                   return false;}}
               className="unselectable"
               data-hidden=""
               data-expanded={el.isParent ? 1 : 0}
               data-level={level} >
               {
               sortOrder.map((order, index) =>
                   <div styleName={cx('pdColumn',{ [this._colStyles[order]]: 1 })} 
                       key={el._id + index}>
                       {this._showDoc[order](el, p)}
                   </div>
               )}
               {editable && <div styleName='onDocControls'>
                 <DocumentListItemDropDown 
                   callback={e => this._docMouse(e, true, el)}
                 /></div>}
            </div>            
          );
    }
    return;
  }
  
  _docMouse = (event, toShow, el) => {
      const xShift=0, yShift=0;  
      if (!toShow) return;
      this.setState({isAbsolute: el, 
         absoluteX: event.pageX - xShift, absoluteY: event.pageY - yShift});
      event.stopPropagation();
      event.preventDefault();
      return false;
  }

  _searchIndex = []

  _checkListFilter = (docPart, projectPart) => {
     docPart = docPart.trim().toLowerCase()
     projectPart = projectPart.trim().toLowerCase()
     const findFilter = new Set()
     this.props.projectList.forEach(el => {
      if (!projectPart || el.title.toLowerCase().includes(projectPart)) 
        el.docs && el.docs.forEach(d => {
          if (!docPart || d.title.toLowerCase().includes(docPart))
            findFilter.add(d._id)     
        })
     })
     return findFilter
  }

  _clearFindFilter = (e) => {
    this.setState({findFilter: null, findNotFound: false})
    this.refFind.textContent = ''
  }

  _checkFindFilter = el => {
    let { projectList } = this.props;
    const text = el.textContent
    if (el && el.childElementCount) {
      el.innerHTML = text
      el.blur()
    }
    if (!text.length) {
      this.setState({findFilter: null})
    } else {
      if (!this._searchIndex.length) {
        projectList.forEach(el => {
          this._searchIndex.push([el._id, el.title.toLowerCase()])
          el.docs && el.docs.forEach(d => {
            this._searchIndex.push([d._id, el.title.toLowerCase() + 
              '~' + d.title.toLowerCase() ])
          })
        })
      }
      const ftext = text.toLowerCase().replaceAll(' ',' '),
        findFilter =  new Set()
      this._searchIndex.forEach(el => 
          el[1].includes(ftext) && findFilter.add(el[0]))
      this.setState({findNotFound: !findFilter.size, findFilter:
        findFilter.size ? findFilter : null})
    }
  }
  
  _dragFunc = (action, id, event, parent) => {
      switch (action) {
          case 'start':
            event.dataTransfer.setData('application/c1', id);
            event.dataTransfer.effectAllowed = "move";
            this.setState({dragParent: parent});
            break
          case 'enter':
            if (event.dataTransfer.types.includes('application/c1')) {
              event.stopPropagation();
              event.preventDefault(); 
              this.setState({dragOver: id}); 
            }
            break;
          case 'drop':
             let dragId = event.dataTransfer.getData('application/c1');
             if (dragId) {
               if (id === "c1") id = null;
               if (dragId !== id && this.state.dragParent !== id) {
                 this._collapseDoc(id, true);               
                 this.props.folderAction(dragId, null, id);
               }
               this.setState({dragOver: 0});
             } else {
                event.stopPropagation();
                event.preventDefault();
                this._drop(event.dataTransfer.files, []);
             }
      }
  }

  _flopSearchFind = () => 
      this.setState({searchFields: !this.state.searchFields, 
        findFilter: null, findNotFound: false})

  _page = (page) => {
      const {currentProject} = this.props
      switch (page) {
          case 0:
          case 'documents':
              this.props.history.push(`/#/${currentProject._id}/#/documents`)
              if (this.state.title !== this.props.currentProject.title) this.setState({title: this.props.currentProject.title})
              this.setState({selectedPage: 0})
              return
          case 2:
          case 'companies&teams':
              this.props.history.push(`/#/${currentProject._id}/#/companies&teams`)
              if (this.state.title !== this.props.currentProject.title) this.setState({title: this.props.currentProject.title})
              this.setState({selectedPage: 2})
              return
          case 3:
          case 'projectsettings':
              this.props.history.push(`/#/${currentProject._id}/#/projectsettings`)
              this.setState({selectedPage: 3})
              return
      }
  }

  openInvitePopup = (show, projectId) => {
      this.props.getInvitePopupAction(show, projectId)
  }

  renameProject = (newName) => {
      let {updateProjectAction, currentProject} = this.props

      if (!newName || newName === currentProject.title) return this.setState({title: currentProject.title})

      updateProjectAction(currentProject._id, newName.trim())
  }

  dragHandler = e => {
      const {isDragActive} = this.state
      if (!this.refWrapper) return;
      let isInside = e.target === this.refWrapper || this.refWrapper.contains(e.target)
        switch (e.type) {
            case 'dragover':
            case 'dragenter':
                if (!isDragActive && isInside ) {
                    this.setState({isDragActive: true, isDragFile: e.dataTransfer.types.includes('Files') })
                } else if (isDragActive && !isInside) {
                    this.setState({isDragActive: false, dragOver: 0, isDragFile: 0})
                }
                clearTimeout(this.dropzoneTimeout)
                break;
            case 'drop':
                this.setState({isDragActive: false, dragOver: 0, isDragFile: 0})
                break;
        }
      this.dropzoneTimeout = setTimeout(() => this.setState({isDragActive: false, isDragFile: 0}), 200)
  }

  render() {
    let { projectList, user, invitePopup, showPaymentAction, getInvitePopupAction, 
        currentProject, deleteProjectAction, getPopUpAction, firstTime} = this.props;
    let { sortView, sortOrder, selectedPage, transferNames, title, isDragActive, isUploadPopupOpen } = this.state;
    let suitedUser = user && user._id, //@ && user.plan && user.plan.includes('suite'),
       suiteCase = invitePopup && invitePopup.show && suitedUser;

      const isProjectAdmin = currentProject.admins && !!currentProject.admins.find(i => i === user._id)
      let addDocPermission = true
      if (user.ticks <= 0 && projectList) {
          let count = docs5PermissionCheck(projectList);
          if (!count || block5PermissionCheck(count+1)) addDocPermission = false
      }

      let pageTitle = currentProject._id ?
          isProjectAdmin ?
              <div style={{position: 'relative'}}>
                                 <input styleName='oneProject-title'
                                        value={title}
                                        placeholder={transS('Project name')}
                                        maxLength='256'
                                        onChange={e => this.setState({ title: e.target.value}) }
                                        onBlur={() => this.renameProject(title)}
                                 />
              <div styleName='oneProject-title-hint'>
                <Hint text={transS('Click to rename')} /></div>
          </div> : <div>{currentProject.title}</div>
    :
        transS('Projects and Documents');

      const popup = {
          name: 'confirm',
          text: transS('Do you want to remove the project?'),
          confirm: {
              name: transS('Delete'),
              danger: true,
              event: () => { deleteProjectAction(currentProject._id); }
          },
          cancel: {
              name: transS('Cancel'),
              event: () => { getPopUpAction(popUpReset); }
          }
      };

    return (
      (invitePopup && invitePopup.show && !invitePopup.showInSidebar) ?
        <ProjectPage transfer={invitePopup.transfer}/>               
      : projectList && !projectList.length
      ? <FirstTime />
      : <>
      {!currentProject._id && <ListHeader currentOption={0} user={user} /> }
      {(suiteCase && false) ?
        (
                <div styleName={cx('wrapper',{ '_unvisible': suiteCase })} ></div>
        ) :
        !sortOrder ? false :
        (
           <div styleName='pdwrapper' ref={e => this.refWrapper = e}>
               <div className='h1'>{pageTitle}</div>
               <div className='space50' />
               
               {(firstTime === 2 && !currentProject._id) && 
                       <FirstTime isSmall="1" forbidFiles={!addDocPermission} />}

               <div className='flex'>
                   {!currentProject._id ?
                       addDocPermission ?
                           <NavLink to={suitedUser ? '#' : '/create-project'}>
                               <Button
                                   text={transS('New Project')}
                                   mod='blue arch' callback={() => suitedUser ? getInvitePopupAction(true, null) : ''}/>
                           </NavLink>
                           :
                           <div styleName='pdDisabledCreateProject'>
                               <Button
                                   text={transS('New Project')}
                                   mod='blue arch disabled' callback={() => null}/>
                               <div styleName='pdDisabledCreateProject_hint'>
                                   {transL('5less')}
                                   <div onClick={() => showPaymentAction(true)}>{transS('Upgrade Now!')}</div>
                               </div>
                           </div>

                       : ''}
               </div>

               {currentProject._id && <div styleName='pages-block'>
                   <div styleName={cx('pages', {'_isSelected': selectedPage === 0})}
                        onClick={() => this._page(0)}>{transS('Documents')}
                   </div>
                    <div styleName={cx('pages', {'_isSelected': selectedPage === 2})}
                        onClick={() => {
                            this._page(2);
                        }}>{transS('Teams')}
                   </div>
                   {isProjectAdmin &&
                      <div styleName={cx('pages', {'_isSelected': selectedPage === 3})}
                           onClick={() => this._page(3)}>{transS('Project Settings')}
                       </div>}

               </div>}
               {selectedPage !== 2 && <div className='space50' />}
               { (currentProject._id && selectedPage === 0 ) ?
                   (
                      currentProject?.docs?.length ?
                           <div style={{marginBottom: '40px'}} className='h2'>{transS('Documents')}</div>
                           :
                           <div style={{marginBottom: '40px'}} className='h2'>{transS('No Documents Yet')}</div>
                   )
                   : ''
               }
               {(!currentProject._id || ( selectedPage === 0 && currentProject.docs && !!currentProject.docs.length ) ) &&
               <React.Fragment>
                   { (!currentProject._id || currentProject.docs.length > 1  ) &&
               <div styleName='marg20' className="flex">
                  {!currentProject._id && !this.state.searchFields && 
                    <> <div styleName='pdOptions'>{transS('Find:')}</div>                  
                     <div 
                       ref={e => this.refFind = e}
                       styleName={`pdOptions pdFind${
                       this.state.findNotFound ? ' pdNfound' :''}`}
                       onInput={e => this._checkFindFilter(e.target)}
                       contentEditable="true">
                     </div>
                     <span styleName='crossPlace' onClick={e => this._clearFindFilter(e)}>
                        <span className='close-cross'>+</span>
                     </span>
                     <div styleName='pdOptions pdSearch'
                        onClick={this._flopSearchFind}
                       >{transS('Search Fields')}</div>
                   </>}
                   
                   <div styleName='pdOptions'>{transS('Sort by:')}</div>
                   {!currentProject._id && <div styleName={'pdOptions ' + cx('pdSel',{ '_selected': sortView === 'p' })}
                                        onClick = {() => this._sortBy('p')}>{transS('Party')}</div>}
                   {!currentProject._id && <div styleName={'pdOptions ' + cx('pdSel',{ '_selected': sortView === 'n' })}
                                                onClick = {() => this._sortBy('n')}>{transS('Project')}</div>}
                   <div styleName={'pdOptions ' + cx('pdSel',{ '_selected': sortView === 'd' })}
                        onClick = {() => this._sortBy('d', !!currentProject._id)}>{transS('Date Modified')}</div>
                   <div styleName={'pdOptions ' + cx('pdSel',{ '_selected': sortView === 's' })}
                        onClick = {() => this._sortBy('s', !!currentProject._id)}>{transS('Unstructured')}</div>
               </div>
                   }
                   {!currentProject._id &&                     
                    this.state.searchFields && <SearchFields 
                      clear={this._flopSearchFind}
                      check={(docPart, projectPart) => this._checkListFilter(docPart, projectPart)}
                      result={findFilter => this.setState({findFilter})}
                    />}
                   
                   <div styleName={cx(currentProject._id ? 'onHeadCont' : 'pdHeadCont',
                     {'dragover': this.state.isDragActive && this.state.dragOver === "c1"})}
                      onDragEnter={(e) => this._dragFunc('enter', "c1", e) }
                      onDrop={(e) => this._dragFunc('drop', "c1", e)}
                      className="unselectable" >
                   {sortOrder && sortOrder.map(el =>
                       <div styleName={'pdHead ' + cx('pdColumn',{ [this._colStyles[el]]: 1 })} key={el}>
                           {this._viewHeaders[el]}
                       </div>
                   )}
                   </div>
               </React.Fragment>
               }

               {currentProject._id && selectedPage === 0 &&
                   <div styleName='oneProject-docs'>
                       { currentProject.docs && !!currentProject.docs.length  ?
                           <div styleName={cx('dragFrame', {'isOver': this.state.isDragFile})}>
                               {sortView === 'd' ?
                                   this._sortedByDate(true)
                                   :
                                   // sortView === 's' ?
                                   // this._sortedByStatus()
                                   // :
                                   this._sortedByDateUnstructured()
                               }
                           </div>
                           :
                           <React.Fragment>
                               { addDocPermission && // in project without documents
                               <Dropzone styleName={cx('add-document', {'active': this.state.isDragDZActive})} 
                                         onDrop={this._drop}
                                         activeClassName="greyback"
                                         onDragEnter={() => this.setState({isDragDZActive:true})}
                                         onDragLeave={() => this.setState({isDragDZActive:false})}>
                                       {this.plusSvg()}
                                       {transS('Upload Document')}
                               </Dropzone>}
                           </React.Fragment>

                       }

                       <div styleName='oneProject-controls'>
                           { addDocPermission ? // button "Upload Document" in project
                               <React.Fragment>
                                   <div className='space20' />
                                   {isUploadPopupOpen && <UploadPopup closeHandler={() => this.setState({isUploadPopupOpen: false})}
                                                                      dropHandler={this._drop}
                                                                      createTemplateFromTemplate={({_id, title}) => this._createDocumentFromTemplate(currentProject._id, _id, title)}
                                                                      transferNames={transferNames}
                                   />}
                                   {isDragActive
                                       ? <Dropzone styleName='uploadDocument' onDrop={this._drop} accept='.doc, .docx' activeClassName={styles.dragActive}>
                                           {transS('Upload or Create from Another Template')}
                                       </Dropzone>
                                       : <Button mod='blue arch margin0'
                                                 text={transS('Upload or Create from Another Template')}
                                                 callback={() => this.setState({isUploadPopupOpen: true})}
                                   />
                                   }
                                   {/*<Dropzone styleName='uploadDocument' onDrop={this._drop} accept='.doc, .docx' activeClassName={styles.dragActive}>*/}
                                   {/*    Upload Another Document*/}
                                   {/*    <div styleName={cx('dragScreen', {'vis': isDragActive && (currentProject.docs && !!currentProject.docs.length)})}*/}
                                   {/*            onDragOver={this.dragHandler}>{this.plusSvg()}Upload Document</div>*/}
                                   {/*</Dropzone>*/}
                                   {/*{!!transferNames.length &&*/}
                                   {/*<Button text='Confirm Upload' mod='blue fill'*/}
                                   {/*        callback={() => this._uploadDocuments()}/>}*/}
                                </React.Fragment>
                                :
                                <div styleName='pdDisabledUploadDocument'>
                                   <Button
                                       text={transS('Upload Document')}
                                       mod='blue arch disabled' callback={() => null}/>
                                   <div styleName='pdDisabledUploadDocument_hint'>
                                        {transL('5less')}
                                        <div onClick={() => showPaymentAction(true)}>{transS('Upgrade Now!')}</div>
                                   </div>
                                </div>
                           }
                       </div>
                   </div>

               }
               {currentProject._id && selectedPage === 1 &&
                   <div>Project Communication</div>
               }
               {currentProject._id && selectedPage === 2 &&
               <React.Fragment>
                   <div styleName='opCompaniesTeam'>
                       <ProjectPage inOneProject={true}/>
                   </div>
               </React.Fragment>

               }
               { (currentProject._id && selectedPage === 3 && isProjectAdmin) &&
                     <div styleName='op-settings'>
                        <div styleName='op-rename'>
                            <div className='h2'>{transS('Rename Project')}</div>
                            <input styleName='oneProject-title'
                                   value={title}
                                   placeholder={transS('Project name')}
                                   maxLength='256'
                                   onChange={e => this.setState({ title: e.target.value}) } />
                                   <Button text={transS('Rename Project')} mod='blue fill' callback={() => this.renameProject(title)} />
                        </div>
                         <div styleName='op-delete'>
                            <div className='h2' style={{marginBottom: '20px'}}>{transS('Permanently Delete Project')}</div>
                             <Button text={transS('Delete Project')} mod='red arch' callback={() => getPopUpAction(popup)}/>
                         </div>
                     </div>
               }

            {!currentProject._id && projectList?.length && sortOrder ? (
            sortView === 'd' ?
              this._sortedByDate()
            : sortView === 'p' ?
              this._sortedByCompany()
            : sortView === 'n' ?
              this._sortedByProjectName()
            :
              this._sortedByProjectName(false)            
            ) : false}
               <DocumentListMenuDropDown 
                 item={this.state.isAbsolute}
                 top={this.state.absoluteY} 
                 left={this.state.absoluteX} 
                 close={() => this.setState({isAbsolute:null})}
               />
           </div>
        )
   }
   </> )
  }
};

ProjectList.propTypes = {
  projectList: PropTypes.array,
  getpopUpWithInputAction: PropTypes.func,
  setCurrentProject: PropTypes.func,
  updateProjectAction: PropTypes.func,
  getProjectList: PropTypes.func,
  setDisplayOnlyAction: PropTypes.func
};

export default ProjectList;
