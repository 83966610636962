import React, { useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./adjustChanger.css"
import { useSelector } from 'react-redux';
import { transS } from '../../../../services/helpers/lang';

const leftAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g styleName={`svg ${active ? 'active' : ''}`} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M1 12h7M1 3h12M1 6h10M1 9h12"/>
  </g></svg>

const centerAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g styleName={`svg ${active ? 'active' : ''}`} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M4 12h7M1 3h12M2 6h10M1 9h12"/>
  </g></svg>

const rightAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  < g styleName={`svg ${active ? 'active' : ''}`} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M6 12h7M1 3h12M3 6h10M1 9h12"/>
  </g></svg>

const justifyAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g styleName={`svg ${active ? 'active' : ''}`} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M1 3h12M1 6h12M1 9h12M1 12h12"/>
  </g></svg>

const AdjustChanger = ({sendToFrame, withHeading = false}) => {

    const {formatting} = useSelector(state => state.paragraphReducer)

    const textAlign = formatting?.textAlign,
    textAlignNumber = !textAlign ? 0 :
    textAlign === 'center' ? 1 :
    textAlign.includes('justify') ? 3 :
    (textAlign === 'right' || textAlign === 'end') ? 2 : 0

    const doAdjust = no => {
        const aligns = ['start', 'center', 'end', 'justify']
        if (no === textAlignNumber) return;
        sendToFrame({c1: "format", paraStyle: {textAlign: aligns[no]}})
    }

    return (
        <div styleName='container'>
            {withHeading ? <div styleName='heading'>{transS('Align')}</div> : <></>}
            <div styleName="svgIcons">
                <div onClick={() => doAdjust(0)}>{leftAlign(textAlignNumber === 0)}</div>
                <div onClick={() => doAdjust(1)}>{centerAlign(textAlignNumber === 1)}</div>
                <div onClick={() => doAdjust(2)}>{rightAlign(textAlignNumber === 2)}</div>
                <div onClick={() => doAdjust(3)}>{justifyAlign(textAlignNumber === 3)}</div>
            </div>
        </div>
    );
};

export default new CSSModules(AdjustChanger, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
