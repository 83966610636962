import { useCallback, useEffect, useState } from "react"
import api from "../api/api"
import { useSelector } from "react-redux"

// Формат комментариев для использования в компонентах
// {
//   id: string,
//   defaultText: string,
//   correctionText: string,
//   correctionComment: string,
//   policyId: number,
//   policy: string,
//   ruleId: number,
//   rule: string,
// },

// const updateServerAi = ({ allAnalysis, state, projectId, documentId, id, stamp, tokens }) => {
//   const formattedAiList = convertAiListToFirstFormat(allAnalysis)
//     const updatesAiList = formattedAiList.map(el => {
//       if (el.quote.mark === id) {
//         el.state = state
//       }
//       return el
//     })

//     const aiBodyRequest = JSON.stringify({
//       comments: updatesAiList,
//       stamp,
//       tokens
//     })

//     api.updateAiItem({
//       projectId, 
//       documentId, 
//       data: {
//         ai_: aiBodyRequest,
//         mark: id,
//         state: 'notResolveButRisk'
//       }
//     })
// }

const convertAiListToRightFormat = (aiList) => {
  const policyName = 'Глобальная договорная политика'
  const rule = 'Политика договоров поставки'
  const ruleContent = 'По договорам поставки, услуг и агентирования максимальная цена не может быть больше 4 500 000 RUB'

  const correctedList = aiList.map(el => {
    return {
      id: el.quote.mark,
      defaultText: el.quote.quote,
      correctionText: el.suggest,
      correctionComment: el.content,
      policyId: el.policy, 
      policy: policyName,
      ruleId: el.rule,
      rule: rule,
      ruleContent: ruleContent,
      state: el.state ?? 'default',
      quotePara: el.quote.para,
      comment: el.comment,
      para: el.para,
      paraEnd: el.paraEnd,
    }
  })

  return correctedList
}

const convertAiListToFirstFormat = (aiList) => {
  const correctedList = aiList.map(el => {
    return {
      content: el.correctionComment,
      suggest: el.correctionText,
      quote: {
        quote: el.defaultText,
        mark: el.id,
        para: el.quotePara
      },
      policy: el.policyId,
      rule: el.ruleId,
      state: el.state,
      comment: el.comment,
      para: el.para,
      paraEnd: el.paraEnd,
    }
  })

  return correctedList
}

export const useAnalysisSidebar = ({ 
  analysisList, 
  documentId, 
  projectId, 
  analyzeTokens,
  stamp
}) => {
  const [allAnalysis, setAllAnalysis] = useState([])
  const [openItemId, setOpenItemId] = useState(null);
  const { frameProgress } = useSelector(state => state.paragraphReducer);
  const sendToFrame = frameProgress?.sendToFrame
  
  const setActiveItem = useCallback((id) => {
    setOpenItemId(id);
  }, []);

  const updateServerAi = useCallback(({ allAnalysis, state, id }) => {
    const formattedAiList = convertAiListToFirstFormat(allAnalysis)
    const updatesAiList = formattedAiList.map(el => {
      if (el.quote.mark === id) {
        el.state = state
      }
      return el
    })

    const aiBodyRequest = JSON.stringify({
      comments: updatesAiList,
      stamp,
      tokens: analyzeTokens
    })

    api.updateAiItem({
      projectId, 
      documentId, 
      data: {
        ai_: aiBodyRequest,
        mark: id,
        state
      }
    })
  }, [projectId, documentId, stamp, analyzeTokens])

  const clickOutsideCardAnalysis = (e) => {
    if (openItemId !== null && !e.target.closest('[data-analysis-item="true"]')) {
      setOpenItemId(null)
    }
  }

  const clickIntoFrame = (e) => {
    let data;

    if (typeof e.data === 'string') {
      try {
        data = JSON.parse(e.data);
      } catch (error) {
        data = {};
      }
    } else {
      data = {};
    }

    const isClick = data?.c1 === 'click'

    if (openItemId !== null && isClick) {
      setOpenItemId(null)
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickOutsideCardAnalysis);
    window.addEventListener('message', clickIntoFrame);

    return () => {
      window.removeEventListener('click', clickOutsideCardAnalysis);
      window.removeEventListener('message', clickIntoFrame);
    }
  }, [openItemId])

  useEffect(() => {
    const convertedList = convertAiListToRightFormat(analysisList)
    setAllAnalysis(convertedList)
  }, [documentId])
  
  const visibleAnalysisList = allAnalysis.filter(el => el.state !== 'hidden' && el.state !== 'deleted')
  const hiddenAnalysisList = allAnalysis.filter(el => el.state === 'hidden')

  const hideElement = useCallback((id) => {
    setAllAnalysis((prevAllAnalysis) => {
      const updatedList = prevAllAnalysis.map(el => {
        if (el.id === id) {
          return { ...el, state: 'hidden' };
        }
        return el;
      });
      return updatedList;
    });

    updateServerAi({ allAnalysis, state: 'hidden', id })
  }, [allAnalysis])

  const setVisibleElement = useCallback((id) => {
    setAllAnalysis((prevAllAnalysis) => {
      const updatedList = prevAllAnalysis.map(el => {
        if (el.id === id) {
          return { ...el, state: 'default' };
        }
        return el;
      });
      return updatedList;
    });

    setActiveItem(id)
    updateServerAi({ allAnalysis, state: 'default', id })
  }, [allAnalysis])

  const deleteItem = useCallback((id) => {
    setAllAnalysis((prevAllAnalysis) => {
      const filteredList = prevAllAnalysis.map(el => {
        if (el.id === id) {
          el.state = 'deleted'
        }

        return el
      })
      return filteredList
    })

    updateServerAi({ allAnalysis, state: 'deleted', id })
  }, [allAnalysis])

  const setResolve = useCallback((id) => {
    setAllAnalysis((prevAllAnalysis) => {
      const updatedList = prevAllAnalysis.map(el => {
        if (el.id === id) {
          el.state = 'resolve'
        }
        
        return el;
      })
      return updatedList
    })

    if (openItemId === id) {
      setOpenItemId(null)
    }

    const formattedAiList = convertAiListToFirstFormat(allAnalysis)
    const updatesAiList = formattedAiList.map(el => {
      if (el.quote.mark === id) {
        el.state = 'resolve'
      }
      return el
    })

    const aiBodyRequest = {
      comments: updatesAiList,
      stamp,
      tokens: analyzeTokens
    }

    // api.updateAiItem({
    //   projectId, 
    //   documentId, 
    //   data: {
    //     ai_: aiBodyRequest,
    //     mark: id,
    //     state
    //   }
    // })

    sendToFrame({
      c1: "aiResolve", 
      ai_: aiBodyRequest,
      mark: id,
      state: 'resolve'
    })
  }, [openItemId, allAnalysis]) 

  const setRiskButNotResolve = useCallback((id) => {
    setAllAnalysis((prevAllAnalysis) => {
      const updatedList = prevAllAnalysis.map(el => {
        if (el.id === id) {
          el.state = 'notResolveButRisk'
        }
        
        return el;
      })

      return updatedList
    })

    if (openItemId === id) {
      setOpenItemId(null)
    }

    updateServerAi({ allAnalysis, state: 'notResolveButRisk', id })
  }, [openItemId, allAnalysis]) 

  const moveNext = useCallback((id) => {
    setAllAnalysis(prevAllAnalysis => {
      const visible = prevAllAnalysis.filter(el => !el.isHidden)
      const hidden = prevAllAnalysis.filter(el => el.isHidden)

      const index = visible.findIndex(el => el.id === id);

      if (index !== -1 && index < visible.length - 1) {
        const nextIndex = index + 1;
        [visible[index], visible[nextIndex]] = [visible[nextIndex], visible[index]];
      }

      return visible.concat(hidden)
    })
  }, [])

  const movePrev = useCallback((id) => {
    setAllAnalysis(prevAllAnalysis => {
      const visible = prevAllAnalysis.filter(el => !el.isHidden)
      const hidden = prevAllAnalysis.filter(el => el.isHidden)

      const index = visible.findIndex(el => el.id === id);

      if (index !== -1 && index > 0) {
        const prevIndex = index - 1;
        [visible[index], visible[prevIndex]] = [visible[prevIndex], visible[index]];
      }

      return visible.concat(hidden)
    })
  }, [])

  return {
    allAnalysis,
    visibleAnalysisList,
    hiddenAnalysisList,
    hideElement,
    setVisibleElement,
    deleteItem,
    setResolve,
    setRiskButNotResolve,
    moveNext,
    movePrev,
    openItemId,
    setActiveItem
  }
}