import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './clauseLib.css';
import { transL, transS } from '../../../../services/helpers/lang';

import ShowCategories from './ShowCategories';
import userConstants from '../../../../services/constants/userConstants';
import { tagsList } from '../../../../services/constants/tagsList';
import Tip from '../../../../components/tip/Tip';

const ClauseLibUser = ()  => {
  const {user, clauseLib} 
    = useSelector(state => state.userReducer)
  const {insertOn, frameProgress} = useSelector(state => state.paragraphReducer);
  const refFind = useRef(),
    dispatch = useDispatch()
  const [findNotFound, setFindNotFound] = useState(false);
  const [founded, setFounded] = useState(null)
  const [openedFolders, setOpenFolders] = useState(new Set())
  const [filteredFolders, setFilteredFolders] = useState(null)
  const [srchPattern, setSrchPattern] = useState(null)
  const [visibleTip, setVisibleTip] = useState(null)
  
  const [viewClause, setViewClause] = useState(null)

  useEffect(() => {
    const clickOutsideCardHandler = (e) => {
      if (viewClause !== null && !e.target.closest('[data-clause-card="true"]')) {
        setViewClause(null)
      }
    }

    const clickIntoFrame = (e) => {
      let data;
  
      if (typeof e.data === 'string') {
        try {
          data = JSON.parse(e.data);
        } catch (error) {
          data = {};
        }
      } else {
        data = {};
      }
  
      const isClick = data?.c1 === 'click'
  
      if (viewClause !== null && isClick) {
        setViewClause(null)
      }
    }

    
    window.addEventListener('message', clickIntoFrame);
    window.addEventListener('click', clickOutsideCardHandler)

    return () => {
      window.removeEventListener('message', clickIntoFrame);
      window.removeEventListener('click', clickOutsideCardHandler)
    }
  }, [viewClause])
  
  const setAll = (NotFound, FilteredFolders, SrchPattern, Founded) => {
    setFindNotFound(NotFound)
    setFilteredFolders(FilteredFolders)
    setSrchPattern(SrchPattern)
    setFounded(Founded)
  }

  const expandAll = isExpand => {
    const s = new Set()
    if (isExpand)     
      clauseLib.folders.forEach(f => traverseFolders(f,
        folder => s.add(folder.id)))
    setOpenFolders(s)
  }
  
  const checkFindFilter = el => {    
    if (el && el.childElementCount) {
      el.innerHTML = el.textContent
      el.blur()
    }
    let foundFolders = false
    const found = new Set(), badFolders = new Set(),
      pattern = refFind.current.textContent.trim().toLowerCase()

    if (!pattern) 
      return setAll(false, null, null, null)
    
    clauseLib.folders.forEach(f => traverseFolders(f,
      folder => { if (!folder.title.toLowerCase().includes(pattern))
       badFolders.add(folder.id)
       else foundFolders = true
    }))

    clauseLib.clauses.forEach(c => {
      if ((c.title+'$'+c.hint+'$'+c.content).toLowerCase().includes(pattern))
        found.add(c.id)      
    });
    if (found.size || foundFolders) {
      clauseLib.folders.forEach(f => traverseFolders(f,
        (folder, callStack) => {
            const isGood = !badFolders.has(folder.id)
            if (isGood || folder.clauses.some(id => found.has(id))) {
              callStack.forEach(id => badFolders.delete(id))
              if (isGood)
                folder.clauses.forEach(id => found.add(id))
            }
        }, [] ) )
      expandAll(true)
      setAll(false, badFolders, new RegExp(pattern, "gi"), found)
      return
    }
    setAll(true, null, null, null)
  }

  const clearFindFilter = (e) => {
    setAll(false, null, null, null)
    refFind.current.textContent = ''
  }
  
  //const reverse = /<span data-x="1">([^<]*)<\/span>/gi
  const drawFind = s => srchPattern ? 
    s.replace(srchPattern, '<span data-x="1">$&</span>') 
    : s

  const traverseFolders = (folder, func, callStack) => {
      const locStack = callStack ? [...callStack, folder.id] : null
      func(folder, locStack)
      if (folder.subs)
        folder.subs.forEach(f => traverseFolders(f, func, locStack))
    }

  const copyClause = (clause, e) => {
    // const newClause = {...clause,
    //   id: Date.now(),
    //   edited: user._id,
    //   isDraft: true,
    // }
    // setViewClause(newClause)
    // setEditClause(newClause, e)

    e.stopPropagation()

    navigator.clipboard.writeText(clause.content).then(function() {
      console.log('Текст скопирован в буфер обмена!');
    }, function(err) {
        console.error('Ошибка при копировании текста: ', err);
    });
  }

  const setEditClause = (clause, e) => {
    const shift = e.target.parentElement.parentElement.getBoundingClientRect().top
    dispatch({type: userConstants.SET_EDIT_CLAUSE, 
      editClause: {clause, shift,
      rOnly: !clause.isDraft || clause.edited !== user._id,
      kind: "clause",
      modifyClause: () => dispatch(
        {type: userConstants.SET_EDIT_CLAUSE}
      )}})
  }

  const insertClause = (clause) => {
    if (!insertOn) return;
    frameProgress.sendToFrame({c1:'clause', clause});
  }

  const showClause = (id, fid, lvl) => {
    // [{id:10, title: 'first clause', hint: 'its hint', content: 'content of it',
    //   addedBy: 1231324, addedAt: 23423424, isDraft: true]
    // const clause = clauseLib.clauses.find(x => x.id === id)
    let clause = null

    const flatClauses = clauseLib.clauses.flat()
    const allClauseVersion = flatClauses.filter(x => x.id === id)
    
    const hasPublishVersion = allClauseVersion.find(el => el.isDraft === false)

    const myClauses = allClauseVersion.filter(el => el.edited === user._id)
    let myClausesLastVersion = myClauses.length > 0 ? myClauses[0] : null

    for (const item of myClauses) {
      const itemVersion = item.version

      if (!itemVersion) continue

      const currentVersion = myClausesLastVersion.version || 0

      if (itemVersion > currentVersion) 
        myClausesLastVersion = item
    }

    if (!!myClausesLastVersion) 
      clause = myClausesLastVersion

    if (!!hasPublishVersion) 
      clause = hasPublishVersion

    if (!clause) return false
    if (
      // clause.isDraft 
      // && clause.edited !== user._id 
      // || 
      founded && !founded.has(clause.id)
    )
      return false

    return (
      <div 
        key={''+fid+id} 
        styleName={
          `card${clause.isDraft ? ' draft' :''}${viewClause === clause ? ' edited' : '' }`
        }
        data-clause-card='true'
        className={viewClause === clause ? 'pointable' : ''}
        style={{marginLeft: (40*lvl)+"px", width: (360-lvl*40)+"px"}}
        onClick={() => setViewClause(clause)} 
      > 

        <div styleName='sideCardHeader'>
            {clause.isDraft && (
              <div styleName='isDraft'>
                <img 
                  src='/images/eyeHide_gray_12x12.svg' 
                  onMouseEnter={() => setVisibleTip({id: clause.id, type: 'draft'})}
                  onMouseLeave={() => setVisibleTip(null)}
                />
                {visibleTip?.id === clause.id && visibleTip.type === 'draft' && (
                  <Tip
                    text={transS('Clause is not published and is not visible to most users')} 
                    style={{ 
                      bottom: 'calc(100% + 9px + 2px)', 
                      left: '-14px', 
                      whiteSpace: 'break-spaces',
                      width: '200px',
                    }}
                    arrow='left bottom'
                  />
                )}
              </div>
            )}
            {clause.tagId && (
              <div styleName='tagContainer'>
                <div 
                  styleName={'tag ' + (tagsList.find(el => el.id === clause.tagId)?.color ?? '')}
                  onMouseEnter={() => setVisibleTip({id: clause.id, type: 'tag'})}
                  onMouseLeave={() => setVisibleTip(null)} 
                />
                {visibleTip?.id === clause.id && visibleTip.type === 'tag' && (
                  <Tip
                    text={transS(tagsList.find(el => el.id === clause.tagId)?.description)} 
                    style={{ 
                      bottom: 'calc(100% + 9px + 2px)', 
                      left: '-14px', 
                      whiteSpace: 'break-spaces',
                      width: '200px',
                    }}
                    arrow='left bottom'
                  />
                )}
              </div>
            )}

          <div>
            <div styleName='title' dangerouslySetInnerHTML={{ __html:drawFind(clause.title)}}></div>
            <div styleName='sideCardHint' dangerouslySetInnerHTML={{ __html:drawFind(clause.hint)}}></div>
          </div>
        </div>
        <div styleName={'clause ' + (viewClause === clause ? 'active' : '')} dangerouslySetInnerHTML={{ __html:drawFind(clause.content)}}></div>
      
        {/* {viewClause === clause && ( */}
          <div 
            styleName={`subclause`}
          >
            <span 
              data-off={!insertOn} 
              onClick={(e) => { 
                insertClause(clause);
                e.stopPropagation()
              }}
            >
              <img src='/images/arrow_left_11x12.svg' style={{ width: '9px' }} />
              {transS('Insert')}
            </span>
            <span 
              onClick={e=>copyClause(clause, e)} 
              styleName={lvl ? 'lvl' : ''}
            >
              <img src='/images/copyIcon_blue_11x12.svg' style={{ width: '9px' }} />
              {transS('Copy')}
            </span>
            {/* <span 
              onClick={e=>setEditClause(clause, e)} 
            >
              {transS('Expand / Edit')}
            </span>       */}
          </div>
         {/* )} */}
      </div>
    )
  }

  return <div styleName="wrapperUser">
    <div className='flex'>      
      <div styleName='pdOptions'>{transS('Find:')}</div>
      <div 
        styleName={`pdOptions dFind${findNotFound ? ' nfound' :''}`}
        ref={refFind}
        onInput={e => checkFindFilter(e.target)}
        contentEditable="true">        
      </div>
      <span styleName='crossPlace corrected' onClick={e => clearFindFilter(e)}>
        <span className='close-cross'>+</span>
      </span>      
    </div>
    <div className='space20'></div>

    <ShowCategories 
        clausesCallback={showClause}
        folders={clauseLib.folders}
        closedFolders={openedFolders}
        drawFind={drawFind}
        restricted={filteredFolders}
        setOpenFolders={setOpenFolders} 
        hideLines="1"
        callback={() => null} />              
  </div>   
}

export default CSSModules(ClauseLibUser, styles, {allowMultiple: true});

