import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './clauseLib.css'
import { transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';
// import CheckBox from '../../components/topMenu/elements/TemplatesUnderMenu/CheckBox';
import Checkbox from '../../components/topMenu/elements/Checkbox/Checkbox.jsx';
import Button from '../../../../components/button/Button.js';


const ShowCategories = params => {
  let {
    clausesCallback, 
    folders, 
    closedFolders, 
    setOpenFolders,
    drawFind, 
    selectedFolders, 
    callback, 
    restricted, 
    hideLines, 
    isPopup = false, 
  } = params;

  const showFolder = (folder) => {
    //{title: "first category", id: 13, subs:[folder11, folder22], clauses:[10, 12, 34]}
    const isOpen = closedFolders.has(folder.id), 
        lvl = folder.lvl

    return (
      <div 
        // style={{ borderBottom: 'solid 1px var(--color_lightGray)' }} 
        styleName={'categoryWrapper'}
        key={folder.id}
      >    
        <div  style={{paddingLeft: (40*lvl)+"px"}} styleName="preline">
          <div 
            styleName={`line${hideLines && false ? ' lhidden' : ''}`} 
            style={{ borderBottom: isOpen ? '1px solid var(--color_lightGray)' : '' }}
          >
            {isPopup ? (
              <Checkbox 
                checked={selectedFolders?.has(folder.id)}
                onChange={e => {
                  e.stopPropagation()
                  callback(folder, e.target.offsetTop)
                }}
                size='lg'
              />
            ) : (
              <img src="/images/icons-folder.png" width="13" height="13" />
            )}
            <span  
              onClick={e => callback(folder, e.target.offsetTop)}
              className='pointable'
              style={{maxWidth: `calc(100% - ${40*lvl+40}px)`, marginLeft: isPopup ? '12px' : ''}}
              styleName={'show-folder' 
                // + (selectedFolders?.has(folder.id) ? ' selected-folder' : '')
              }
            >
              {drawFind ? (
                <span 
                  dangerouslySetInnerHTML={{
                    __html: drawFind(folder.title)
                  }}
                >
                </span>
                ) : (
                  folder.title
                )}
            </span>
            {!!(
              folder.subs?.length 
              || clausesCallback 
              && folder.clauses?.length
            ) && (
              <span 
                styleName="arrowShift"           
                className={`arrow${isOpen ? ' arrowOpen' : ''}`}
                onClick={() => {
                  if (isOpen)
                    closedFolders.delete(folder.id)
                  else
                    closedFolders.add(folder.id)

                  setOpenFolders(new Set(closedFolders))
                }} 
              >
              </span>
            )}
          </div>
        </div>
        {isOpen && !!folder.subs && (
          restricted ? (
            folder.subs.filter(f => !restricted.has(f.id)).map(f => showFolder(f))
          ) : (
            folder.subs.map(f => showFolder(f))
          ))
        }
        {/* отображение формулировок внутри папки */}
        {isOpen && clausesCallback &&
          folder.clauses
            .map(no => clausesCallback(no, folder.id, lvl+1))
        }
      </div>
    )}
  
  
  return (
    <>
      <div styleName='maskLine'> 
        {restricted 
          ? (
            folders
              .filter(f => !restricted.has(f.id))
              .map((folder) => showFolder(folder))
          ) : (
            folders.map((folder) => showFolder(folder))
          )
        }
      </div>
    </>
  )
}  

export default CSSModules(ShowCategories, styles, {allowMultiple: true});