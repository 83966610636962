import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CSSModules from "react-css-modules";
import { cloneDeep } from "lodash";
import styles from "./templatesUnderMenu.css";
import TextArea from "./TextArea";
import CheckBox from "./CheckBox";
import DropDownType from "./DropdownType.js";
import ScrollBar from "../../../../../../components/scrollBar/ScrollBar";
import useEvent from "../../../../../../services/helpers/use-event";
import { getPopUpAction } from "../../../../../../services/actions/dataActions";
import api from "../../../../../../services/api/api";
import { popUpReset } from "../../../../../../services/helpers";
import {
  setVarList,
  setVarClick,
  showDateControl,
} from "../../../../../../redux/document/actions";
import { transliterate } from "../../../../../../common/transliterate";
import { getLanguage, transS } from "../../../../../../services/helpers/lang";
import Conditional from "./Conditional";
import DateControl from "../../../dateControl/DateControl";
import InvalidFieldAlert from "../../../document-sidebar/InvalidFieldAlert/InvalidFieldAlert.jsx";
import { InsertButton } from "../../../document-sidebar/InsertButton/InsertButton.jsx";
import OptionAdvancedSettings from "../../../document-sidebar/OptionAdvacedSettings/OptionAdvancedSettings.jsx";
import { dragAndDropHandlers } from "../../../../../../services/helpers/dragAndDrop.js";
import cx from "classnames";
import GlobalVariables from "./GlobalVariables/GlobalVariables.jsx";

const Variables = (params) => {
  const { tab, setTab, clausesCount } = params;
  const [viewVars, setViewVars] = useState("project");
  const [lastViewed, setLastViewed] = useState(0);
  const [editedVar, setEditedVar] = useState(0);
  const [hiVar, setHiVar] = useState(0);
  const [posVar, setPosVar] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [optionAdvanced, setOptionAdvanced] = useState(null);
  const setHeadRadio = (x) => setViewVars(x);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const _getDropDown = (el, arrow, func) => {
    if (el === "if") return false;

    return (
      <DropDownType
        key={el}
        type={el}
        hasMargin={false}
        container={false}
        changed={func}
        arrow={arrow}
      />
    );
  };

  const handleResize = (_) => {
    let top = containerRef.current.getBoundingClientRect().top;
    if (containerRef?.current?.style)
      containerRef.current.style.height = window.innerHeight - top + "px";
  };
  useEvent("resize", handleResize);
  useEffect(() => handleResize(), []);
  const {
    varlist,
    actualDocument,
    viewedNew,
    usedVars,
    click,
    clickPosition,
    dateControl,
  } = useSelector((state) => state.document);
  const { insertOn, frameProgress } = useSelector(
    (state) => state.paragraphReducer
  );
  const rOnly = actualDocument.orig !== 1;
  const isDraftDoc = actualDocument.orig === 302;
  const [showDefault, setShowDefault] = useState(false);
  const [showConditional, setShowConditional] = useState(false);
  useEffect(() => handleClickVar(), [click]);
  useEffect(() => handleClickPositionVar(), [clickPosition]);
  useEffect(() => {
    if (editedVar && rOnly && !isDraftDoc) setEditedVar(0);
  }, [rOnly]);
  let uncheckUsed = !usedVars || !("has" in usedVars) || !usedVars.has(1);
  const [condParts, setCondParts] = useState(new Set());

  // тестовые данные. Удалить после добавления функционала
  const [isChecked, setIsChecked] = useState({
    first: false,
    second: false,
  });

  const clearEdited = () => {
    setEditedVar(0);
    setShowAdvanced(false);
    setShowDefault(false);
    setShowConditional(false);
    setHiVar(0);
    setPosVar(0);
    setOptionAdvanced(null);
  };

  const setEdited = (id) => {
    setShowAdvanced(false);
    setShowDefault(false);
    setShowConditional(false);
    setEditedVar(id);
  };
  const clickOutsideHandler = ({ target }) => {
    if (!containerRef.current || !target || !document.contains(target)) return;
    if (!containerRef.current.contains(target)) return clearEdited();
    while (target && target !== containerRef.current) {
      if (target.dataset.edited == "true") return;
      target = target.parentElement;
    }
    clearEdited();
  };
  useEffect(() => {
    window.addEventListener("click", clickOutsideHandler);
    window.addEventListener("blur", clearEdited);
    return () => {
      window.removeEventListener("click", clickOutsideHandler);
      window.removeEventListener("blur", clearEdited);
    };
  }, []);

  useEffect(() => {
    if (!varlist) return;
    const condParts = new Set();
    let checkFull = 1;
    if (isDraftDoc) {
      varlist.forEach((v) => {
        if (!v.conditional || checkCondition(v.conditional)) {
          condParts.add(v.id);
        }
      });
      while (checkFull) {
        checkFull = 0;
        varlist.forEach((v) => {
          if (v.conditional && condParts.has(v.id))
            if (!condParts.has(v.conditional.condVar)) {
              condParts.delete(v.id);
              checkFull = 1;
            }
        });
      }
    } else {
      varlist.forEach(
        (v) => v.conditional && condParts.add(v.conditional.condVar)
      );
    }
    setCondParts(condParts);
  }, [varlist]);

  const radioElement = (name, text) => (
    <div
      key={name}
      onClick={() => {
        setHeadRadio(name);
        clearEdited();
      }}
      data-current={name === viewVars}
    >
      {text}
    </div>
  );

  const chgVarList = (list, el) => {
    api.updateTemplate(
      actualDocument.coreDocument,
      "c1 send vars" + (el ? " and doc" : ""),
      undefined,
      actualDocument._id,
      list
    );
    dispatch(setVarList(list));
    if (el && frameProgress)
      frameProgress.sendToFrame({
        c1: "variable",
        action: "list",
        varlist: list,
      });
    return null;
  };
  const overVar = (id, on) => {
    dispatch(setVarClick(Date.now(), on ? id : 0, 0));
    if (frameProgress)
      frameProgress.sendToFrame({ c1: "variable", action: "click", id, on });
  };
  const cloneVar = (item) => {
    let newItem = cloneDeep(item),
      mark = Date.now(),
      id = mark,
      list = [...[newItem], ...varlist];
    newItem.name = checkUnique(newItem.title || newItem.name, newItem);
    newItem.id = mark++;
    if (newItem.dropdown)
      newItem.dropdown.forEach((opt) => {
        opt.id = mark++;
      });
    chgVarList(list);
    clearEdited();
    setEdited(id);
  };
  const checkUnique = (arg, el) => {
    const convert = (text) =>
      transliterate(text)
        .match(/\b\w+\b/g)
        .join("_")
        .substr(0, 40);
    let checkName = arg ? convert(arg) : "Variable",
      varnumber = 0;
    while (checkName) {
      let name = checkName + (varnumber ? "_" + varnumber : "");
      if (varlist.find((e) => e !== el && e.name === name)) varnumber++;
      else return name;
    }
  };

  const handleClickVar = () => {
    if (!click) return;
    setHiVar(click.record);
  };
  const handleClickPositionVar = () => {
    if (!clickPosition) return;
    if (!clickPosition.record) setPosVar(0);
    else {
      let el = containerRef.current.querySelector(
        `[data-id="${clickPosition.record}"]`
      );
      if (!el) return;
      let top = el.getBoundingClientRect().top;
      containerRef.current.firstElementChild.firstElementChild.scrollBy(
        0,
        top - clickPosition.y - 114
      ); // need calc
      setPosVar(clickPosition.record);
    }
  };

  const varItem = ({ el, isGlobal, isUp, isDown }) => {
    let {
      id,
      title,
      hint,
      required,
      type,
      defaultValue,
      name,
      realname,
      conditional,
      dropdown,
      value,
    } = el;

    //   const usingVarList = varlist.filter(el =>
    //     (viewVars === "project") &&
    //       (!isDraftDoc || !el.conditional || condParts.has(el.id))
    // || (viewVars === "used") &&
    //     !uncheckUsed && (usedVars.has(el.id) || condParts.has(el.id))
    // || (viewVars === "Unanswered") && !el.value &&
    //       (!isDraftDoc || !el.conditional || condParts.has(el.id)))

    //       console.log(usingVarList)

    const changedVarList = (item, container) => {
      if (item === "title" && !realname)
        el.name = name = checkUnique(el.title, el);
      else if (item === "name") {
        el.name = name = checkUnique(el.name, el);
        el.realname = true;
      }
      return chgVarList(varlist, container);
    };

    const removeVar = () => {
      dispatch(
        getPopUpAction({
          name: "confirm",
          text: `Do you want to remove the variable <br><span style="word-break:break-all;">${el.name}</span>?`,
          confirm: {
            name: transS("Delete"),
            danger: true,
            event: () => {
              chgVarList(
                varlist.filter((e) => e !== el),
                el
              );
              dispatch(getPopUpAction(popUpReset));
            },
          },
          cancel: {
            name: transS("Cancel"),
            event: () => dispatch(getPopUpAction(popUpReset)),
          },
        })
      );
    };

    const moveUp = (isUp) => {
      let i = varlist.findIndex((e) => e === el),
        j = i;
      if (isUp) {
        j--;
        if (viewVars === "used") while (j >= 0 && !varlist.used) j--;
        if (j === -1) return;
      } else {
        j++;
        if (viewVars === "used")
          while (j < varlist.length && !varlist.used) j++;
        if (j === varlist.length) return;
      }
      varlist.splice(j, 0, varlist.splice(i, 1)[0]);
      changedVarList();
    };

    const insertVar = (option) => {
      if (!insertOn) return;
      frameProgress.sendToFrame({
        c1: "variable",
        action: "insert",
        id,
        option,
      });
    };

    const dropSelect = (dd) => {
      if (value === dd.id) {
        el.value = null;
      } else el.value = dd.id;
      clearEdited();
      changedVarList(varlist, el);
    };

    const dropdownItem = ({ dd, id, title }) =>
      isDraftDoc ? (
        (dd.option || dd.tip || value === dd.id) && (
          <div
            styleName="variableField_dropdownPart_option"
            data-draft-doc={true}
            key={dd.id}
            data-option={dd.id}
            style={{ display: "block" }}
          >
            <div
              styleName="questionDrop"
              data-selected={value === dd.id}
              onClick={() => dropSelect(dd)}
            >
              <CheckBox filled={value === dd.id} text="" ronly={true} big="1" />
              {!dd.isInput && dd.option}
              {dd.isInput && (
                <span onClick={(e) => e.stopPropagation()}>
                  <TextArea
                    name="inputVal"
                    big="2"
                    placeholder={transS("Fill value")}
                    container={dd}
                    changed={textAreaChanged}
                    styleCorrect=" customTextarea"
                  />
                </span>
              )}
            </div>
            {dd.tip && <div styleName="hintDrop">{dd.tip}</div>}
          </div>
        )
      ) : (
        <div
          data-draft-doc={false}
          styleName="variableField_dropdownPart_option"
          key={dd.id}
        >
          <div styleName="variableField_dropdownPart_option_content">
            <div styleName="variableField_dropdownPart_option_left">
              <TextArea
                name="option"
                placeholder={transS("Enter Option Title")}
                big="1"
                container={dd}
                ronly={rOnly}
                changed={changedVarList}
                required={el.defaultValue === dd.id}
                styleCorrect={getLanguage() ? "" : " correctWidth title"}
              />
              <TextArea
                name="tip"
                placeholder={transS("Add Hint")}
                container={dd}
                ronly={rOnly}
                changed={changedVarList}
              />
            </div>
            <div styleName="variableField_dropdownPart_option_right">
              <CheckBox
                filled={defaultValue === dd.id}
                text={transS("Default")}
                ronly={rOnly}
                changed={() => {
                  if (rOnly) return;
                  el.defaultValue = defaultValue === dd.id ? null : dd.id;
                  changedVarList();
                }}
              />
            </div>
          </div>
          {optionAdvanced === dd.id && (
            <OptionAdvancedSettings
              removeOption={() => deleteOption(dd.id)}
              isUpOption={el.dropdown[0].id === dd.id}
              isDownOption={el.dropdown[el.dropdown.length - 1].id === dd.id}
              moveUp={() => {
                for (let i = 0; i < el.dropdown.length; i++) {
                  if (i === 0) continue;

                  if (el.dropdown[i].id === dd.id) {
                    const prevElement = el.dropdown[i - 1];
                    el.dropdown[i - 1] = el.dropdown[i];
                    el.dropdown[i] = prevElement;

                    break;
                  }
                }
                changedVarList();
              }}
              moveDown={() => {
                for (let i = 0; i < el.dropdown.length; i++) {
                  if (i === el.dropdown.length - 1) continue;

                  if (el.dropdown[i].id === dd.id) {
                    const nextElement = el.dropdown[i + 1];
                    el.dropdown[i + 1] = el.dropdown[i];
                    el.dropdown[i] = nextElement;

                    break;
                  }
                }
                changedVarList();
              }}
              rOnly={rOnly}
              changedVarList={changedVarList}
              option={dd}
              getDropDown={_getDropDown}
              canDeleted={el.dropdown?.length > 1}
            />
          )}
          <div styleName="variableField_dropdownPart_option_footer">
            <InsertButton title={title} id={id} option={dd.id} />
            <button>
              {optionAdvanced === dd.id ? (
                <div styleName="advancedButton" style={{ cursor: "default" }}>
                  <img
                    src={"/images/setting_blue_12x12.svg"}
                    style={{ opacity: "0.3", rotate: "180deg" }}
                    alt="setting"
                  />
                  <img
                    src="/images/arrow_10x6.svg"
                    style={{ rotate: "180deg", cursor: "pointer" }}
                    alt="arrow"
                    onClick={() => {
                      setOptionAdvanced(null);
                    }}
                  />
                </div>
              ) : (
                <div
                  styleName="advancedButton"
                  onClick={() => {
                    setOptionAdvanced(dd.id);
                  }}
                >
                  <img src={"/images/setting_blue_12x12.svg"} alt="setting" />
                  <img src="/images/arrow_10x6.svg" alt="arrow" />
                </div>
              )}
            </button>
          </div>
        </div>
      );

    const deleteOption = (id) => {

      if (!el.dropdown || el.dropdown?.length <= 1) return;
      let index = el.dropdown.findIndex((el) => el.id === id);
      el.dropdown.splice(index, 1);
      changedVarList("", el);
    };

    const addOption = () => {
      const newEl = { id: Date.now(), option: "", tip: "" };
      if (!el.dropdown) el.dropdown = [newEl];
      else {
        el.dropdown.push(newEl);
      }
      changedVarList();
    };

    const dropdownPart = (dropdown) => {
      return (
        <div styleName="variableField_dropdownPart">
          <div styleName="variableField_dropdownPart_options">
            {dropdown &&
              dropdown.map((el) =>
                dropdownItem({ dd: el, id, title, allItems: dropdown })
              )}
          </div>
          {!rOnly && (
            <button
              styleName="variableField_dropdownPart_btn"
              onClick={addOption}
            >
              <img src="/images/boxQuestion_blue_12x12.svg" />
              {transS("Add Option")}
            </button>
          )}
        </div>
      );
    };

    const dropdownNeutral = () => {
      const dd = dropdown.find((d) => d.id == value);
      return (
        <div styleName="valueDropdown">
          <img
            styleName="optionsArrow"
            src="/images/arrow_10x6.svg"
            alt="arrow"
          />
          {dd.inputVal ?? dd.option}
        </div>
      );
    };

    const getFromDropdown = (index) => {
      let x = el.dropdown.find((e) => e.id === index);
      return x?.option ? x.option : "not defined";
    };

    const advancedPart = () => {
      return (
        <div styleName="variableField_advanced">
          <div>
            <CheckBox
              text={transS("Applies to all related documents")}
              filled={isChecked.first}
              changed={() => {
                setIsChecked((prev) => ({ ...prev, first: !prev.first }));
              }}
            />
          </div>
          <div>
            <CheckBox
              text={transS("A traceable commitment")}
              filled={isChecked.second}
              changed={() => {
                setIsChecked((prev) => ({ ...prev, second: !prev.second }));
              }}
            />
          </div>
          {type !== "dropdown" && (
            <div>
              <button>{transS("Add Format")}</button>
            </div>
          )}
          {type !== "date" && type !== "dropdown" && (
            <div>
              {defaultValue || showDefault ? (
                <>
                  <div styleName="variableField_advanced_paramName">
                    {transS("Default Value")}
                  </div>
                  <TextArea
                    name="defaultValue"
                    big="2"
                    defValue={
                      type === "dropdown" ? getFromDropdown(defaultValue) : ""
                    }
                    container={el}
                    changed={changedVarList}
                    ronly={rOnly || type === "dropdown"}
                    check={type === "number" ? (a) => isNaN(a) : false}
                    styleCorrect={" correctHeight"}
                  />
                </>
              ) : (
                !rOnly && (
                  <button onClick={() => setShowDefault(true)}>
                    {transS("Add Default Value")}
                  </button>
                )
              )}
            </div>
          )}
          <div>
            {conditional || showConditional ? (
              <>
                <div styleName="variableField_advanced_paramName">
                  {transS("Condition for This Variable")}
                </div>
                <Conditional
                  el={el}
                  varlist={varlist}
                  changedVarList={changedVarList}
                  kill={() => {
                    if (el.conditional) {
                      delete el.conditional;
                      changedVarList();
                    }
                    setShowConditional(false);
                  }}
                  readOnly={rOnly}
                />
              </>
            ) : (
              !rOnly && (
                <button
                  onClick={() => {
                    setShowConditional(true);
                  }}
                >
                  {transS("Add Condition")}
                </button>
              )
            )}
          </div>
          <div styleName="variableField_advanced_paramName">
            {transS("Variable Name")}
          </div>
          <TextArea
            name="name"
            big="2"
            defValue={name}
            container={el}
            ronly={rOnly}
            inFocus={true}
            changed={changedVarList}
            styleCorrect={" oldinputNameVar"}
          />
          <div styleName={"advancedTools"}>
            <button
              onClick={() => moveUp(true)}
              styleName={"toolsItem"}
              style={{ opacity: isUp ? 0.3 : 1 }}
            >
              <img
                src="/images/arrow_blue_12x10.svg"
                style={{ rotate: "180deg" }}
              />
              {transS("Up")}
            </button>
            <button
              onClick={() => moveUp(false)}
              styleName={"toolsItem"}
              style={{ opacity: isDown ? 0.3 : 1 }}
            >
              <img src="/images/arrow_blue_12x10.svg" />
              {transS("Down")}
            </button>
            <button styleName={"toolsItem"} style={{ marginRight: "50px" }}>
              <img
                src="/images/groupIcon_blue_15x12.svg"
                style={{ maxHeight: "15px" }}
              />
              {transS("Add to Group")}
            </button>
            <button onClick={() => cloneVar(el)} styleName={"toolsItem"}>
              <img
                src="/images/copyIcon_blue_11x12.svg"
                style={{ maxHeight: "15px" }}
              />
              {transS("Copy")}
            </button>
            <button onClick={removeVar} styleName={"toolsItem toolItemRemove"}>
              <img src="/images/deleteIcon_10x12.svg" />
              {transS("Delete")}
            </button>
          </div>
        </div>
      );
    };

    const textAreaChanged = (item, container) => {
      clearEdited();
      if (item) changedVarList(item, container);
    };

    let dataUsed =
      (isDraftDoc && (!!value || !required)) ||
      (!isDraftDoc && (uncheckUsed || usedVars.has(id) || condParts.has(id)));

    const dropDownFilled = () => {
      const x = dropdown.find((dd) => dd.id === value);
      return !!x && (!x.isInput || !!x.inputVal);
    };

    const { dragEndHandler, dragOverHandler, dragStartHandler, dropHandler } =
      dragAndDropHandlers(varlist, chgVarList, `card_${id}`);

    const isError = false;

    return (
      <span
        style={{ position: "relative" }}
        onDragOver={dragOverHandler}
        onDragEnd={dragEndHandler}
        onDragLeave={dragEndHandler}
        onDragStart={(e) => dragStartHandler(e, el)}
        onDrop={(e) => dropHandler(e, el)}
        draggable
        key={id}
        className="cardContainer"
      >
        {/* <span className='dragOver' /> */}
        <span className="cardLine cardTopLine" id={`card_${id}`} />
        <span className="cardWrapperZ" id={`dnd_wrapper_${id}`} />
        {isError && !isDraftDoc && <InvalidFieldAlert error="number" />}
        <div
          styleName="variableField"
          data-edited={editedVar === id}
          data-used={dataUsed}
          data-id={id}
          data-hi={hiVar === id && editedVar !== id}
          data-pos={posVar === id && editedVar !== id}
          data-filled={isDraftDoc && !!value && (!dropdown || dropDownFilled())}
          onMouseEnter={() => overVar(id, true)}
          onMouseLeave={() => overVar(id, false)}
        >
          {isDraftDoc ? (
            <>
              <div
                styleName="variableField_docHead"
                onClick={() => setEdited(id)}
              >
                <div styleName="variableField_head_inputs" data-used={dataUsed}>
                  <div styleName="questionDiv" data-req={required}>
                    {(title ? title : name) + (required ? " *" : "")}
                  </div>
                  {hint && (editedVar === id || !value) && (
                    <div styleName="hintDiv">{hint}</div>
                  )}
                </div>
                {editedVar === id ? (
                  type === "date" ? (
                    <div
                      styleName="valueDate"
                      onClick={(e) =>
                        dispatch(showDateControl(0, 0, el, false))
                      }
                    >
                      {value ? value : transS("Еnter date")}
                      {!!(
                        dateControl?.fromParagraph === false &&
                        dateControl?.dateVar.id === el.id
                      ) && (
                        <DateControl
                          callback={(val) => {
                            if (val) {
                              el.value = val;
                              chgVarList(varlist, el);
                            }
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    type !== "dropdown" && (
                      <>
                        <div className="space20" />
                        <TextArea
                          name="value"
                          big="2"
                          defValue={false}
                          saved="1"
                          check={type === "number" ? (a) => isNaN(a) : false}
                          container={el}
                          ronly={false}
                          changed={textAreaChanged}
                        />
                      </>
                    )
                  )
                ) : (
                  value &&
                  (type === "dropdown" ? (
                    dropdownNeutral()
                  ) : (
                    <div styleName="valueDiv">{value}</div>
                  ))
                )}
              </div>
              {editedVar === id &&
                type === "dropdown" &&
                dropdownPart(dropdown)}
            </>
          ) : (
            <div
              styleName="variableField_head"
              onClick={() => (editedVar !== id ? setEdited(id) : null)}
            >
              <div
                styleName="variableField_head_inputs"
                data-used={dataUsed}
                style={{ width: editedVar === id ? "190px" : "auto" }}
              >
                <span styleName={"questionContainer"} data-req={required}>
                  <TextArea
                    name="title"
                    placeholder={transS("Enter Question")}
                    // big="1"
                    inFocus={editedVar === id}
                    container={el}
                    ronly={rOnly}
                    changed={changedVarList}
                    required={required}
                    used={!!el.name}
                    styleCorrect={
                      getLanguage() ? "" : " correctWidth correctPlaceholder"
                    }
                  />
                </span>
                {/* {!!hint &&  */}
                <TextArea
                  inFocus={editedVar === id}
                  name="hint"
                  placeholder={transS("Add Hint")}
                  styleCorrect={getLanguage() ? "" : " correctWidth"}
                  container={el}
                  ronly={rOnly}
                  changed={changedVarList}
                />
                {/* } */}
              </div>
              {editedVar === id && (
                <div styleName="variableField_head_right">
                  {/* {(editedVar === id || rOnly ) ?  */}
                  <CheckBox
                    filled={required}
                    text={`${transS("Required")} *`}
                    ronly={rOnly}
                    changed={() => {
                      if (rOnly) return;
                      el.required = !required;
                      changedVarList("required", el);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {!isDraftDoc && editedVar === id && (
            <>
              {type === "dropdown" && !isDraftDoc && dropdownPart(dropdown)}
              {showAdvanced && editedVar === id && advancedPart()}
              <div styleName="variableField_footer">
                <InsertButton title={title} id={id} />
                <button>
                  {editedVar === id && showAdvanced ? (
                    <div
                      styleName="advancedButton"
                      style={{ cursor: "default" }}
                    >
                      <img
                        src={"/images/setting_blue_12x12.svg"}
                        style={{ opacity: 0.3 }}
                        alt="setting"
                      />
                      <img
                        src="/images/arrow_10x6.svg"
                        style={{ rotate: "180deg", cursor: "pointer" }}
                        onClick={() => {
                          setShowAdvanced(false);
                        }}
                        alt="arrow"
                      />
                    </div>
                  ) : (
                    <div
                      styleName="advancedButton"
                      onClick={() => {
                        setShowAdvanced(true);
                      }}
                    >
                      <img
                        src={"/images/setting_blue_12x12.svg"}
                        alt="setting"
                      />
                      <img src="/images/arrow_10x6.svg" alt="arrow" />
                    </div>
                  )}
                </button>

                <div styleName="footer_typeContainer">
                  <DropDownType
                    type={type}
                    container={el}
                    changed={
                      defaultValue || dropdown || rOnly
                        ? null
                        : () => changedVarList("", el)
                    }
                    func={_getDropDown}
                    hasMargin={false}
                    hasDropDownArrow={true}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <span className="cardLine cardBottomLine" id={`card_${id}`} />
      </span>
    );
  };

  const checkCondition = (cond) => {
    const c = cond.condVar,
      o = cond.condOption,
      vr = varlist.find((el) => el.id === c);
    return vr?.value === o;
  };

  // main part
  if (viewedNew && viewedNew != lastViewed && !rOnly) {
    setLastViewed(viewedNew);
    if (varlist && varlist[0]) {
      if (!varlist[0].name) {
        varlist[0].name = checkUnique("");
        setEdited(varlist[0].id);
        // chgVarList(varlist, varlist[0]);
      }
    }
  }

  return (
    <div styleName="varContainerExtAdapter">
      <div styleName="varContainerExt template" ref={containerRef}>
        {/* {!!clausesCount || !isDraftDoc && ( */}
          <div styleName="toggles">
            <div
              styleName={cx("toggle", { _sel: tab === "variables" })}
              onClick={() => setTab("variables")}
            >
              {transS("Variables")}
            </div>
            {/* {!!clausesCount || !isDraftDoc && ( */}
              <div
                styleName={cx("toggle", { _sel: tab === "clauses" })}
                onClick={() => {
                  setTab("clauses");
                }}
              >
                {transS("Clause Library")}
              </div>
            {/* )} */}
          </div>
        {/* )} */}
        <ScrollBar
          style={{
            height: "100%",
            width: "calc(100% - 8px)",
            zIndex: "10",
            backgroundColor: "#ffffff",
          }}
          invisible="true"
        >
          <div styleName="varContainer" id="scrollContainer">
            <div styleName="head">
              {isDraftDoc && (
                <div styleName="head_title">
                  {transS("Questions")}
                </div>
              )}
              <div styleName="head_radio">
                {radioElement(
                  "project",
                  isDraftDoc ? transS("All") : transS("Created for Project")
                )}
                {isDraftDoc
                  ? radioElement("Unanswered", transS("Unanswered"))
                  : radioElement("global", transS("Global"))}
                {/* {!isDraftDoc && radioElement("used", transS("Used in Project"))} */}
              </div>
            </div>

            {(viewVars === 'project' || viewVars === "used") && (
              varlist &&
                varlist
                  .filter(
                    (el) =>
                      (viewVars === "project" &&
                        (!isDraftDoc ||
                          !el.conditional ||
                          condParts.has(el.id))) ||
                      (viewVars === "used" &&
                        !uncheckUsed &&
                        (usedVars.has(el.id) || condParts.has(el.id))) ||
                      (viewVars === "Unanswered" &&
                        !el.value &&
                        (!isDraftDoc || !el.conditional || condParts.has(el.id)))
                  )
                  .map((el, _, arr) =>
                    varItem({
                      el,
                      isGlobal: true,
                      isUp: el.id === arr[0].id,
                      isDown: el.id === arr?.at(-1).id,
                    })
                  )
            )}

            {viewVars === 'global' && (
              <GlobalVariables setViewVars={setViewVars} />
            )}
            <div className="space50" />
          </div>
        </ScrollBar>
      </div>
    </div>
  );
};

export default CSSModules(Variables, styles, { allowMultiple: true });