import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import Tip from "../../../../../../components/tip/Tip";
import { transS } from '../../../../../../services/helpers/lang';

const resizeTextarea = (taRef, big) => {
  let height

  switch (big) {
    case 1: {
      height = 22
      break
    }
    case 2: {
      height = 34
      break;
    }
    default: {
      height = 18
      break;
    }
  }

  taRef.current.style.minHeight = height + 'px'
  taRef.current.style.height = 0;  

  // if (big === 1)
    taRef.current.style.height = taRef.current.scrollHeight + 1  + "px";
  // else
  //   taRef.current.style.height = taRef.current.scrollHeight + 4 + "px";
  taRef.current.style.maxHeight = taRef.current.scrollHeight + 4 + "px";
}

const TextArea = params => {
  let {name, placeholder, big, container, ronly, changed, inFocus,
      defValue, check, saved, required, used, enterMode,
      styleCorrect} = params;
  const taRef = useRef(null); 
  const [isEmpty, setIsEmpty] = useState(false)
  const [saveVisible, setSaveVisible] = useState(false);
  const discardValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    taRef.current.value = container[name];
    checkSize();
    if (saved) changed(false);
  }
  const checkValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //if (saved && e.target !== 'save') discardValue(e);
    //else 
        saveValue()
  }
  const makeNumInput = s => {
    if (!s) return '';
    let sym = s.includes(' ') ? ' ' :
      (s.includes(',') ? ',' : ''), x;
    s = s.replace(/[\s,]/g, '');
    if (isNaN(s)) return '';
    let res = s.split('.'), r = +res[0], m;
    s = r ? '' : 0;
    if (sym)
    while (r) {
      m = r % 1000;
      r = (r - m) / 1000;
      if (r) m = sym + ('00' +m).slice(-3)
      s = m + s;
    }
    else s = res[0];
    return s + (res[1] ? '.' + res[1] : '');
  }
  const saveValue = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (taRef.current.value === container[name]) {
        if (saved) changed(false);
        return;
    }
    container[name] = check ? makeNumInput(taRef.current.value) : taRef.current.value;
    let result = changed(name, container);
    if (result) {
        taRef.current.value = result;        
        changed(name, container);
    }
  };    
  
  const keyDown = (e) => {
    if (!taRef.current) return;
    if (e.keyCode === 27) return discardValue(e);
    if (e.keyCode === 13 && !enterMode ) {
      e.preventDefault();
      e.stopPropagation();
      if (saved) saveValue();
      taRef.current.blur();
    } else if (check) {
      let s = taRef.current.value;
      if (/[0-9]/.test(e.key) || e.key === ' ' && !s.includes(',')
          || e.key === ',' && !s.includes(' ') ||  e.key === '.' && !s.includes('.')) return;
      if (e.key.length === 1) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  const checkSize = () => {
    if (taRef?.current.value.length === 0) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }

    resizeTextarea(taRef, big)
  };

  useEffect(() => {
    resizeTextarea(taRef, big)
  }, [required, inFocus])
  
  useEffect(checkSize, []);
  return (
    <>
      <textarea readOnly={ronly} autoComplete="off" ref={taRef}
        data-is-empty={isEmpty}
        data-height={big ? "21px" : "18px"}
        styleName={(big ? (big == 2 ? "oldinput" : "question") : "hint") 
          + (styleCorrect ? styleCorrect : '')}
        data-req={required}
        data-used={used}
        onBlur={checkValue}
        onInput={checkSize}
        onKeyDown={keyDown}
        style={{
          textDecoration: !isEmpty && !inFocus ? 'none' : '',
          color: (name === 'hint' && !isEmpty && !inFocus) ? 'var(--color_gray)' : ''
        }}
        placeholder={((ronly || !placeholder) ? "" : placeholder) + (required ? '*' : '')} 
        defaultValue={defValue || container[name]}
      />
      {/* {saved && <div styleName="saveBlock" onMouseDown={discardValue}>
        <div onMouseDown={saveValue}>{transS('Save')}</div>
        <span styleName="cross"><span>
            <Tip arrow='top right' text={`Cancel<br>and close`} />
        </span></span>
      </div>} */}
    </>
  ) 
};
export default CSSModules(TextArea, styles, {allowMultiple: true});