import React from 'react'
import { transS } from '../../../../../services/helpers/lang';
import { useSelector } from 'react-redux'
import styles from './InsertButton.module.css'

export const InsertButton = ({ option, title, id }) => {
  const {insertOn, frameProgress} = useSelector(state => state.paragraphReducer);
  const { actualDocument } = useSelector((state) => state.document);

  const readOnly = actualDocument.orig !== 1

  const insertVar = (option) => {
    if (!insertOn) return;
    frameProgress.sendToFrame({c1:'variable', action: 'insert', id, option});
  }

  return (
    <button 
      className={styles.button} 
      data-off={!insertOn || !title || readOnly} 
      disabled={!insertOn || !title || readOnly}
      onClick={(e) => { 
        e.stopPropagation()
        insertVar(option);
      }}
    >
      <img src='/images/arrow_left_11x12.svg' alt='arrow' />
      {transS('Insert')}
    </button>
  )
}