import React from "react";
import DropDownType from "../../../../../../components/topMenu/elements/TemplatesUnderMenu/DropdownType.js";
import styles from "./styles.module.css";

const _getDropDown = (el, arrow, func) => {
  if (el === "if") return false;

  return (
    <DropDownType
      key={el}
      type={el}
      hasMargin={false}
      container={false}
      changed={func}
      arrow={arrow}
    />
  );
};

export default function VarItemFooter({
  showAdvanced,
  setShowAdvanced,
  type,
  changeVariable,
  id,
  readOnly = false,
}) {
  return (
    <div className={styles.container} style={{ cursor: "default" }}>
      <button className={styles.advancedButton}>
        <img
          src={"/images/setting_blue_12x12.svg"}
          style={{ opacity: showAdvanced ? 0.3 : 1 }}
          alt="setting"
        />
        <img
          src="/images/arrow_10x6.svg"
          style={{
            rotate: showAdvanced ? "180deg" : "0deg",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowAdvanced((prev) => !prev);
          }}
          alt="arrow"
        />
      </button>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <DropDownType
          type={type}
          container={{ type }}
          changed={(_, data) => {
            if (readOnly) return 

            changeVariable({ id, key: "type", value: data.type });
          }}
          func={readOnly ? () => {} : _getDropDown}
          hasMargin={false}
          hasDropDownArrow={true}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
