import cx from 'classnames'
import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './ReviewedExternal.css'
import Button from '../../../../../components/button/Button'
import Changes from "../elements/Changes/Changes"
import RequestBackMenu from '../elements/RequestBackMenu/RequestBackMenu'
import { transS } from '../../../../../services/helpers/lang'
import ChangesNew from '../elements/Changes/ChangesNew'


@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class ReviewedExternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTimeCut: false,
        }
    }

    componentDidUpdate() {
        if (this.props.frameProgress && !this.state.isTimeCut) {
            if (this.refTime && this.refTime.offsetWidth >= 206 && !this.state.isTimeCut) this.setState({isTimeCut: true})
        }
    }

    render() {
        const {actualDocument, isProjectAdmin, getPopUpAction, popup, calcReviewedAndOpened, stateMenu, frameProgress, setMenuAction, jumpChanges, openInvitePopup} = this.props
        const {isTimeCut} = this.state;
        const isDoc = !actualDocument.contentType;
        const shared = actualDocument.shared;

        return (
            <React.Fragment>
              {isDoc ? <React.Fragment>
                <div styleName={cx('reviewed', {'cut': isTimeCut})} >
                    <div styleName='reviewed-text'>{transS('Being reviewed by Counterparty')}</div>
                    <div styleName='reviewed-time'
                         ref={e => this.refTime = e}>{calcReviewedAndOpened()}</div>
                    {isTimeCut ? <div styleName='reviewed-hintPlace'>
                        <div styleName='reviewed-text'>{transS('Being reviewed by Counterparty')}</div>
                        <div styleName='reviewed-time'>{calcReviewedAndOpened()}</div>
                    </div> : ''}
                </div>
                <div className="borderHeader" />
                <RequestBackMenu isProjectAdmin={isProjectAdmin} getPopUpAction={getPopUpAction} popup={popup} />
                <div className="borderHeader" />
                {/* <Changes setStateMenu={arg => setMenuAction(arg)} jumpChanges={jumpChanges} stateMenu={stateMenu} frameProgress={frameProgress} /> */}
                <ChangesNew
                    setStateMenu={arg => setMenuAction(arg)} 
                    jumpChanges={jumpChanges} 
                    stateMenu={stateMenu} 
                    frameProgress={frameProgress} 
                />
               </React.Fragment> :
                <a href={`/api/documents/getpure/${actualDocument.projectId}/${actualDocument.coreDocument}/${actualDocument.title}`} download> 
                  <Button mod='blue margin0' text={transS('Download')} style={{marginRight: '40px', marginLeft: '40px'}} /> 
                </a>
               }
               <Button mod='blue margin0' callback={openInvitePopup} text={transS('Teams')} />
            </React.Fragment>
        )
    }
}
