import React, { memo, useEffect, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './analysis.css'
import HiddenListItem from './AnalysisListItem/HiddenListItem.jsx'

function AnalysisHiddenList({
  analysis, 
  setVisibleElement
  // hideElement,
  // deleteItem,
  // setNotCriticalRisk,
}) {
  const [list, setList] = useState(analysis)
  useEffect(() => setList(analysis), [analysis])

  return (
    <div styleName='hiddenList'>
      {/* {analysis.map(el => (
        <div styleName='hiddenList__item' key={el.id}>
          {el.correctionComment}
        </div>
      ))} */}
      {list.map(el => (
        <HiddenListItem 
          text={el.correctionComment}
          id={el.id}
          setVisibleItem={setVisibleElement}
          key={el.id}
        />
      ))}
    </div>
  )
}

export default memo(CSSModules(AnalysisHiddenList, styles, {allowMultiple: true}))