import React from 'react'
import styles from './AuthPopup.css'
import CSSModules from 'react-css-modules'
import {connect} from "react-redux"
import cx from "classnames"
import Button from "../button/Button"
import Checkbox from "../checkbox/Checkbox"
import {blurValidation, inputValidation, submitValidation} from "../../common/validation"
import {temporaryUserLoginAction, temporaryUserRegisterAction} from "../../services/actions/userActions"
import {getPopUpAction} from "../../services/actions/dataActions"
import {popUpReset} from "../../services/helpers"
import { transEL, transLoc } from '../../services/helpers/lang'

@connect(state => ({
    user: state.userReducer.user,
}), {temporaryUserRegisterAction, temporaryUserLoginAction, getPopUpAction})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class AuthPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: {
                value: '',
                dirty: false,
                invalid: false
            },
            lastname: {
                value: '',
                dirty: false,
                invalid: false
            },
            email: {
                value: '',
                dirty: false,
                invalid: false,
            },
            password: {
                value: '',
                dirty: false,
                invalid: false
            },
            company: {
                value: '',
                dirty: false,
                invalid: false
            },
            message: '',
            isAgree: false,
            isSignIn: false,
            isTimeOut: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.clickOutsideHandler)

        if (+this.props.user.email + 3600000 - Date.now() < 0)
            this.setState({isTimeOut: true})
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickOutsideHandler)
    }

    clickOutsideHandler = e => {
        if (e.target === this.refScreen && !this.state.isTimeOut)
            this.props.getPopUpAction(popUpReset())
    }

    inputOnChangeHandler = e => {
        this.setState({message: ''})
        inputValidation(this, e.currentTarget)
    }

    inputOnBlurHandler = e => blurValidation(this, e.currentTarget)

    fromSubmitHandler = e => {
        const {firstname, lastname, email, password, isSignIn, company} = this.state
        e.preventDefault();

        let validationList = isSignIn ?
            ['email', 'password'] : ['firstname', 'lastname', 'email', 'company', 'password']

        if (submitValidation(this, validationList)) return false

        if (isSignIn) {
            this.props.temporaryUserLoginAction({
                userId: this.props.user._id,
                email: email.value,
                password: password.value,
            }).then(res => res ? this.setState({message: res}) : null)
        } else {
            this.props.temporaryUserRegisterAction({
                userId: this.props.user._id,
                firstname: firstname.value,
                lastname: lastname.value,
                email: email.value,
                password: password.value,
                company: company.value,
            }).then(res => res ? this.setState({message: res}) : null)
        }
    }

    render() {
        const {firstname, lastname, email, password, isAgree, message, isTimeOut, isSignIn, company} = this.state

        return (
            <React.Fragment>
                <div styleName={cx('screen', {'_op': isTimeOut})} ref={e => this.refScreen = e} />
                <div styleName={cx('popup', {'_freeze': isTimeOut})}>
                    <form onSubmit={this.fromSubmitHandler} noValidate>
                        {!isTimeOut &&
                        <div className='close-cross' onClick={() => this.props.getPopUpAction(popUpReset())}>+</div>}

                        <div className='h1' style={{
                            marginBottom: '40px',
                            lineHeight: 'normal'
                        }}>{transLoc(isSignIn ? 'Sign In' : 'Sign Up')}</div>

                        <div styleName='loginLink'>
                            <Button text={isSignIn ? "Don't have an account?" : 'Already have an account?'}
                                    mod='blue margin0' callback={() => this.setState({isSignIn: !isSignIn, email: {...email, corporate: !email.corporate, invalid: false}})}/>
                        </div>

                        {isTimeOut &&
                        <div style={{marginBottom: '20px', color: '#FF6C6C'}}>If you don't sign up now the document will
                            be removed from Contract.one</div>}

                        {!isSignIn &&
                        <React.Fragment>
                            <label styleName='row border'>
                                <div styleName='error-message'>
                                    {(() => ({
                                        'required': transEL('requiredName'),
                                        'minLength': transEL('minLengthName'),
                                        'maxLength': transEL('maxLengthName')
                                    })[firstname.invalid])()}
                                </div>
                                <input styleName='input' type='text' name='firstname' value={firstname.value}
                                       onChange={this.inputOnChangeHandler} onBlur={this.inputOnBlurHandler}
                                       autoComplete='off' placeholder='First Name' required/>
                            </label>

                            <label styleName='row border'>
                                <div styleName='error-message'>
                                    {(() => ({
                                        'required': transEL('requiredSurname'),
                                        'minLength': transEL('minLengthSurname'),
                                        'maxLength': transEL('maxLengthSurname')
                                    })[lastname.invalid])()}
                                </div>
                                <input styleName='input' type='text' name='lastname' value={lastname.value}
                                       onChange={this.inputOnChangeHandler} onBlur={this.inputOnBlurHandler}
                                       autoComplete='off' placeholder='Last Name' required/>
                            </label>
                        </React.Fragment>}

                        <label styleName='row border'>
                            <div styleName='error-message'>
                                {(() => ({
                                    'required': transEL('requiredEmail'),
                                    'email': transEL('errorEmail'),
                                    'maxLength': transEL('maxLengthEmail'),
                                    'corporate': transEL('corporateEmail'),
                                })[email.invalid])()}
                            </div>
                            <input styleName='input' type='text' name='email' value={email.value}
                                   onChange={this.inputOnChangeHandler} onBlur={this.inputOnBlurHandler}
                                   autoComplete='off' placeholder='Email' required/>
                        </label>

                        {!isSignIn && <label styleName='row border'>
                            <div styleName='error-message'>
                                {(() => ({
                                    'required': 'Company Name is required',
                                    'minLength': 'Company name seems too short',
                                    'maxLength': 'Company name seems too long'
                                })[company.invalid])()}
                            </div>
                            <input styleName='input' type='text' name='company' value={company.value}
                                   onChange={this.inputOnChangeHandler} onBlur={this.inputOnBlurHandler}
                                   autoComplete='off' placeholder='Company' required/>
                        </label>}


                        <label styleName='row'>
                            <div styleName='error-message'>
                                {(() => ({
                                    'required': transEL('requiredPassword'),
                                    'minLength': transEL('minLengthPassword'),
                                    'maxLength': transEL('maxLengthPassword')
                                })[password.invalid])()}
                            </div>
                            <input styleName='input' type='text' name='password' value={password.value}
                                   onChange={this.inputOnChangeHandler} onBlur={this.inputOnBlurHandler}
                                   autoComplete='off' placeholder={transLoc('Password')} required/>
                        </label>

                        {!isSignIn && <div styleName='row checkbox'>
                            <Checkbox checked={isAgree} callback={() => this.setState({isAgree: !isAgree})}
                                      text={`<div class="${styles['checkbox-text']}">I agree with Contract.one’s <a target='_blank' href='/legal/terms-of-use'>
                                            Terms of Use</a> and <a target='_blank' href='/legal/data-protection'>Data Protection Policy</a></div>`}/>
                        </div>}


                        {message && <div styleName='message-wrapper'>{message}</div>}

                        <div styleName='button-row'>
                            {(isAgree || isSignIn) ?
                                <Button text={isSignIn ? transLoc('Sign in') : transLoc('Create Account')} mod='promoMainBtn'
                                        type='submit'/>
                                :
                                <Button text={transLoc('Create Account')} mod='promoMainBtn disabled' callback={() => null}/>}
                        </div>

                    </form>
                </div>
            </React.Fragment>
        )
    }
}

export default AuthPopup