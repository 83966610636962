import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import LogIn from './auth/LogIn';
import Header from '../../components/header/Header';
import Activation from './auth/Activation';
import ActivationComplete from './auth/activationComplete/ActivationComplete';
import ActivationError from './auth/activationError/ActivationError';
import PageNotFound from '../pageNotFound/PageNotFound';
import ResetPassword from './auth/ResetPassword/ResetPassword';
import EnterNewPassword from './auth/EnterNewPassword/EnterNewPassword';
import Cookies from "../../components/cookies/Cookies"
import UnregisterNew from "./UnregisterNew"
import { allocSimplawyer, setLanguage } from '../../services/helpers/lang';

class PublicArea extends Component {
  constructor (props) {
    super(props);
    if (allocSimplawyer)
      setLanguage('ru-Ru')
  }
  
  render() {
    let { activationInProgress, location } = this.props;


    const isWithoutHeader = location.pathname.includes('/sign-up') ||
        location.pathname.includes('/login') ||
        location.pathname.includes('/schedule') ||
        location.pathname.includes('/sign-in') ||
        location.pathname.includes('/documents/')
    const isHideBanner = location.pathname === '/new' || location.pathname === '/new/'

    return (
      <div className='wrapper'>
        {!isWithoutHeader && !activationInProgress && <Header />}
        {!isWithoutHeader && <Cookies />}
        {/*{!isWithoutHeader && !isHideBanner && <EventBanner />}*/}
        {
          activationInProgress
            ? <div>
              <Activation />
            </div>
            : <Switch>
              <Route exact path='/login' component={LogIn} />
              <Route exact path='/login/:token' component={LogIn} />
              <Route exact path='/:projectId/documents/:documentId' component={LogIn} />
              <Route exact path='/:projectId/documents/:documentId/history/:history' component={LogIn} />
              <Route exact path='/sign-up' component={SignUp} />
              <Route exact path='/sign-in' component={SignIn} />
              <Route exact path='/activated' component={ActivationComplete} />
              <Route exact path='/activationError' component={ActivationError} />
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/new-password/:token' component={EnterNewPassword} />
              <Route exact path='/not-found/:error' component={PageNotFound} />
              <Route exact path='/new' component={UnregisterNew} />
              {/*<Redirect exact from="/" to="/remote-work" />*/}
              <Redirect exact from="/" to={"/login"} />
              <Redirect exact from="/company" to="/login" />
              <Route component={PageNotFound} />
            </Switch>
        }
      </div>
    );
  }
}

PublicArea.propTypes = {
  activationInProgress: PropTypes.bool
};

export default withRouter(connect(state => ({
  activationInProgress: state.userReducer.activationInProgress
}))(PublicArea));
