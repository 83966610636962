import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getLanguage, transS } from '../../../../../../../services/helpers/lang'
import cx from 'classnames'
import styles from './styles.module.css'
import Checkbox from '../../Checkbox/Checkbox'
import { useSelector } from 'react-redux'

export const ChangesPopup = ({ stateMenu, setStateMenu, isOpen }) => {
    const [versionRadioBtn, setVersionRadioBtn] = useState(2)
    const { frameProgress } = useSelector(state => state.paragraphReducer);
    const sendToFrame = frameProgress?.sendToFrame
    const menuRef = useRef(null)

    const changeShowChanges = (val, statRedLine) => {
        let result = {showChanges: val};
        if (val === 4) result.filterParagraphs = true;
        else if (stateMenu.showChanges === 4) result.filterParagraphs = false;
        if (statRedLine != null) result.redline = statRedLine

        setStateMenu(result);
    }

    const versionRadioCallback = value => {
        if (versionRadioBtn === value) return;

        if (value === 0) changeShowChanges(3)
        else changeShowChanges(stateMenu.showChanges === 3 ? 0 : stateMenu.showChanges, value === 1)

        setVersionRadioBtn(value)
    }

    const currentLang = useMemo(() => {
        if (getLanguage() === 0) {
            return 'ru'
        }

        return 'en'
    }, [getLanguage])

    const filterParagraphs = value => {
        if (value === 0 && stateMenu.filterParagraphs) {
            if (stateMenu.showChanges === 4) changeShowChanges(0);
            else setStateMenu({filterParagraphs: false});
        } else if (value === 1 && !stateMenu.filterParagraphs) {
            setStateMenu({filterParagraphs: true});
        } else {
            return
        }
        window.scrollTo(0, 0);
    }

    const commentsRadio = value => {
        setStateMenu({radioBtn: value})
    }

    const changeVisibleComments = (action) => {
        sendToFrame({
            c1: 'toggleVisibleComments',
            action
        })
    }

    useEffect(() => {
        if (stateMenu.redline) {
            setVersionRadioBtn(1);
        }
    }, [stateMenu]);

    return (
        <div 
            className={cx(styles.popup, { [styles.visible]: isOpen })}
            ref={menuRef}
        >
            <div className={styles.textViews}>
                <div className={styles.tabs}>
                    <button 
                        className={cx(styles.tabItem, { [styles.active]: versionRadioBtn === 2 })}
                        onClick={() => versionRadioCallback(2)}
                    >
                        {transS('Smart Redlines')}
                    </button>
                    <button 
                        className={cx(styles.tabItem, { [styles.active]: versionRadioBtn === 1 })}
                        onClick={() => versionRadioCallback(1)}
                    >
                        {transS('Classic Redlines')}
                    </button>
                    <button 
                        className={cx(styles.tabItem, { [styles.active]: versionRadioBtn === 0 })}
                        onClick={() => versionRadioCallback(0)}
                    >
                        {transS('Hide Redlines')}
                    </button>
                </div>

                <div className={styles.tabs}>
                    <button 
                        className={cx(styles.tabItem, { [styles.active]: !stateMenu.filterParagraphs })}
                        onClick={() => filterParagraphs(0)}
                    >
                        {transS('All Paragraphs')}
                    </button>
                    <button 
                        className={cx(styles.tabItem, { [styles.active]: stateMenu.filterParagraphs })}
                        onClick={() => filterParagraphs(1)}
                    >
                        {transS('Only with Edits & Comments')}
                    </button>
                </div>
            </div>

            <div className={styles.columnsContainer}>
                <div className={styles.fixes}>
                    <h5 className={styles.title}>{transS('Edits')}</h5>
                    <button 
                        className={cx(styles.button, { [styles.active]: stateMenu.showChanges === 4 })}
                        onClick={() => changeShowChanges(4)}
                        disabled={!stateMenu.hadChanges}
                    >
                        {transS('Only New')}
                        {!!stateMenu.changesCount && 
                            <div 
                                className={styles.counter} 
                                style={stateMenu.changesCount > 99 ? {fontSize: '8px'} : null}
                            >
                                {stateMenu.changesCount > 99 ? '99+' : stateMenu.changesCount}
                            </div>
                        }
                    </button>
                    <button 
                        className={cx(styles.button, { [styles.active]: stateMenu.showChanges === 1 })}
                        onClick={() => changeShowChanges(1)}
                    >
                        {transS('In This Version')}
                    </button>
                    <button 
                        className={cx(styles.button, { [styles.active]: stateMenu.showChanges === 2 })}
                        onClick={() => changeShowChanges(2)}
                    >
                        {transS('In the Last 2 Versions')}
                    </button>
                    <button 
                        className={cx(styles.button, { [styles.active]: stateMenu.showChanges === 0 })}
                        onClick={() => changeShowChanges(0)}
                    >
                        {transS('In All Versions')}
                    </button>
                </div>
                <div className={styles.comments}>
                    <h5 className={styles.title}>{transS('Comments')}</h5>
                    <button 
                        className={cx(styles.button, { [styles.active]: false })}
                        onClick={() => changeVisibleComments('hide')}
                        disabled={true}
                    >
                        <img src="/images/eyeHide_blue_12x12.svg" alt="" />
                        {transS('Hide All')}
                    </button>
                    <button 
                        className={cx(styles.button, { [styles.active]: false })}
                        onClick={() => changeVisibleComments('rollUp')}
                    >
                        <img src="/images/arrow_blue_10x6.svg" style={{ rotate: '180deg' }} alt="" />
                        {transS('Collapse All')}
                    </button>
                    <button 
                        className={cx(styles.button, { [styles.active]: false })}
                        onClick={() => changeVisibleComments('visible')}
                    >
                        <img src="/images/arrow_blue_10x6.svg" alt="" />
                        {transS('Expand All')}
                    </button>
                </div>
            </div>

            <div className={cx(styles.checkboxContainer, styles.disabled)}>
                <Checkbox />
                <p>{transS('Display Edits with Party Colors')}</p>
            </div>
            <div className={cx(styles.checkboxContainer, styles.disabled)}>
                <Checkbox />

                {currentLang === 'ru' && (
                    <div className={styles.label}>
                        <p>Показать правки</p>
                        <div style={{ margin: '0 10px 0 5px' }} className={cx(styles.box, styles.internal)}>{transS('Internal ')}</div>
                        <img style={{ cursor: 'pointer' }} src="/images/arrow_10x6.svg" alt="" />
                    </div>
                )}

                {currentLang === 'en' && (
                    <div className={styles.label}>
                        <p>{transS('Display')}</p>
                        <div style={{ margin: '0 4px' }} className={cx(styles.box, styles.internal)}>{transS('Internal ')}</div>
                        <p>{transS('Edits')}</p>
                        <img style={{ cursor: 'pointer', marginLeft: '10px' }} src="/images/arrow_10x6.svg" alt="" />
                    </div>
                )}
            </div>
            <div className={cx(styles.checkboxContainer, styles.disabled)}>
                <Checkbox />
                {currentLang === 'ru' && (
                    <div className={styles.label}>
                        <p>Показать правки</p>
                        <div style={{ margin: '0 10px 0 5px' }} className={cx(styles.box, styles.external)}>{transS('External ')}</div>
                    </div>
                )}

                {currentLang === 'en' && (
                    <div className={styles.label}>
                        <p>{transS('Display')}</p>
                        <div style={{ margin: '0 4px' }} className={cx(styles.box, styles.internal)}>{transS('External ')}</div>
                        <p>{transS('Edits')}</p>
                    </div>
                )}
            </div>

            <div className={styles.textContainer}>
                <h5 className={styles.title}>
                    {transS('Text')}
                </h5>
                <div className={cx(styles.checkboxContainer, styles.disabled)}>
                    <Checkbox />
                    <p>{transS('Simplified Reading Mode')}</p>
                </div>
                <div className={cx(styles.checkboxContainer, styles.disabled)}>
                    <Checkbox />
                    <p>{transS('Show Hidden Elements')}</p>
                </div>
            </div>
        </div>
    )
}
