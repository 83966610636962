import React, { useState } from 'react'
import { transF, transS } from '../../../../../../services/helpers/lang';

import styles from './DownloadingButton.css';
import CSSModules from "react-css-modules";
import Checkbox from '../../../../../../components/checkbox/Checkbox';
import Button from '../../../../../../components/button/Button';

const DownloadingButton = ({callback = () => {}}) => {

    const [isExportOpened, setIsExportOpened] = useState(false)
    const [isShowSummarizedEdits, setIsShowSummarizedEdits] = useState(false)
    const [isShowExternalComments, setIsShowExternalComments] = useState(false)

    // wldmr - callback возвращает чекбоксы
    return (<>
            <div styleName='sidebar-export-container' style={{display: "flex"}}>
                <Button text={transS('Export in .docx')} mod='blue nowrap margin0' callback={() =>
                    callback({exportComments: isShowExternalComments, 
                    exportAnonomizedMods: isShowSummarizedEdits, 
                    exportNamedMods: false})}/>
                <div styleName='arrow-container'>
                    <div onClick={() => setIsExportOpened(prev => !prev)} className={isExportOpened ? 'arrow arrowOpen' : 'arrow'}></div>
                </div>
            </div>
            {isExportOpened && <div>
                <div styleName='sidebar-export-params info'>{transS('Export with Edits')}</div>
                <Checkbox checked={isShowSummarizedEdits} callback={() => {setIsShowSummarizedEdits(prev => !prev)}} text={transS('Show summarized edits')} style={{marginLeft: "20px", marginBottom: "12px"}} mod='small gray'/>
                <Checkbox checked={isShowExternalComments} callback={() => {setIsShowExternalComments(prev => !prev)}} text={transS('Show external comments')} style={{marginLeft: "20px", marginBottom: "12px"}} mod='small gray'/>
            </div>}
    </>)
}


export default new CSSModules(DownloadingButton, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
