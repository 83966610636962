import React, { memo, useState, useMemo, useEffect } from 'react'
import CSSModules from 'react-css-modules';
import styles from './analysis.css'
import AnalysisList from './AnalysisList.jsx';
import { useAnalysisSidebar } from '../../../../services/hooks/useAnalysisSidebar.js';
import AnalysisHiddenList from './AnalysisHiddenList.jsx';
import { useSelector } from 'react-redux';
import { transS } from '../../../../services/helpers/lang.js';
import api from '../../../../services/api/api.js';
import { getDateString } from '../../../../common/getDateString.js';
import Spinner from '../../../../components/spinner/Spinner.js';

const formatDate = (timestamp, dateData) => {
  const formattedDateWithTime = getDateString(timestamp, 1, dateData).split(' ')
  const [date, ...timeParts] = formattedDateWithTime;
  const time = timeParts.join(' ')

  return { date, time }
};

const formatTokens = (tokens) => {
  if (!tokens) {
    return null
  }

  if (tokens < 1000) {
    return tokens.toString();
  }

  const shortNumber = (tokens / 1000).toFixed(1); 
  return `${shortNumber}к`;
}

function AnalysisSidebar({ aiData }) {
  // typeof full || risk
  const [activeTab, setActiveTab] = useState('full')
  const [isLoading, setLoading] = useState(false)
  const [isOpenRulesInput, setOpenRulesInput] = useState(false)
  const [rules, setRules] = useState(`Исполнитель вправе оказать услуги досрочно.
Цена Договора включает в себя все расходы Исполнителя, связанные с исполнением Договора в полном объеме.
Услуги должны быть оказаны до 04.01.2026
Место оказания услуг: г. Москва.
Исполнитель может потребовать у Заказчика копию платежного поручения с отметкой банка об исполнении.
Заказчик вправе проверять ход и качество оказываемых Услуг в период действия настоящего Договора.
Общая цена Договора составляет 1 000 000 рублей.`)

  const aiList = aiData?.comments ?? []
  const analysisDateNotFormatted = aiData?.stamp ?? null
  const totalTokens = aiData?.tokens ?? null

  const tokensFormatted = useMemo(() => formatTokens(totalTokens), [totalTokens])
  
  const [documentId, documentUpdatedAt, projectId, dateData, sendToFrame] = useSelector(store => [
    store.document.currentDocument.coreDocument,
    store.document.currentDocument.updatedAt,
    store.projectReducer.currentProject._id,
    store.userReducer.dateData,
    store.paragraphReducer?.frameProgress?.sendToFrame
  ])

  const notDeletedAiItems = useMemo(() => aiList.filter(el => el.state !== 'deleted'), [aiList])
  const analysisDate = useMemo(() => formatDate(analysisDateNotFormatted, dateData), [documentUpdatedAt])

  const analysisCallback = async () => {
    setLoading(true)

    try {
      const rulesArray = rules.split('\n')
      const filteredRules = rulesArray.filter(el => el.trim().length > 0)
  
      const response = await api.analysisDocument({
        policies: [
          {
            id: 1, 
            description: "Глобальная договорная политика", 
            rules: filteredRules
          }
        ],
        projectId,
        documentId
      })
    } catch (e) {
      console.error('Analyze error: ', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (sendToFrame) {
      if (activeTab === 'full') {
        sendToFrame({ c1: 'communication', communicationVis: 4})
      } else {
        sendToFrame({ c1: 'communication', communicationVis: 4.1 })
      }
    }
  }, [activeTab])
  
  const {
    allAnalysis,
    visibleAnalysisList,
    hiddenAnalysisList,
    hideElement,
    setVisibleElement,
    deleteItem,
    moveNext,
    movePrev,
    setActiveItem,
    openItemId,
    setResolve,
    setRiskButNotResolve
  } = useAnalysisSidebar({
    analysisList: notDeletedAiItems, 
    documentId, 
    projectId,
    analyzeTokens: totalTokens,
    stamp: analysisDateNotFormatted
  })

  return (
    <div styleName="container">
      <div styleName="togglerVisible">
        <div 
          styleName={`togglerVisible_item ${activeTab === 'full' ? 'active' : ''}`}
          onClick={() => setActiveTab('full')}
        >
          Весь документ
        </div>
        <div 
          styleName={`togglerVisible_item ${activeTab === 'risk' ? 'active' : ''}`}
          onClick={() => setActiveTab('risk')}
        >
          Только риски
        </div>
      </div>
      {isLoading && (
        <div styleName='expDocSpinner'><Spinner withoutLogo={true} />{transS('Analysis')}...</div>
      )}

      {!isLoading && (
        <button 
          styleName="analysesButton"
          onClick={analysisCallback}
          disabled={isLoading}
        >
          <img src="/images/light_blue_12x12.svg" alt="" />
          <p>Проанализировать документ</p>
          <img 
            src="/images/arrow_10x6.svg"
            alt="" 
            style={{ rotate: isOpenRulesInput ? '180deg' : '0deg' }}
            onClick={(e) => {
              e.stopPropagation()
              setOpenRulesInput(prev => !prev)
            }}
          />
        </button>
      )}

      {!!aiData && (
        <p styleName='analyzeInfo'>{transS('Analyzed')} {analysisDate.date} {transS('to')} {analysisDate.time} ({transS('used')} {tokensFormatted} {transS('tokens')})</p>
      )}

      {isOpenRulesInput && (
        <textarea 
          styleName='rulesInput' 
          onChange={(e) => setRules(e.target.value)}
          value={rules}
          placeholder='Введите правила для анализа. Каждое новое правило должно начинаться с новой строки'
        />
      )}

      {/* <AnalysisList 
        analysis={visibleAnalysisList} 
        hideElement={hideElement} 
        deleteItem={deleteItem}
        moveNext={moveNext}
        movePrev={movePrev}
        setActiveItem={setActiveItem}
        openItemId={openItemId}
        setResolve={setResolve}
        setRiskButNotResolve={setRiskButNotResolve}
        date={analysisDate.date + ' ' + analysisDate.time}
      />
      <AnalysisHiddenList 
        analysis={hiddenAnalysisList}
        setVisibleElement={setVisibleElement}
      /> */}
    </div>
  )
}

export default memo(CSSModules(AnalysisSidebar, styles, {allowMultiple: true}));