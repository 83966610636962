import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './popup.css'
import { transS } from '../../../../../services/helpers/lang'

function PopupFooter({
  deleteCallback,
  cancelCallback
}) {
  return (
    <div styleName='popupFooter'>
      <button 
        styleName='popupFooter__button red'
        onClick={deleteCallback}
      >
        <img src="/images/deleteIcon_10x12.svg" alt="remove" /> 
        {transS('Delete')}
      </button>
      <button 
        styleName='popupFooter__button blue'
        onClick={cancelCallback}
      >
        {transS('Do Not Delete')}
      </button>
    </div>
  )
}

export default CSSModules(PopupFooter, styles, {allowMultiple: true})