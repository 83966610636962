import React, { useMemo } from 'react'
import styles from './InvalidFieldAlert.module.css'
import { transS } from '../../../../../services/helpers/lang'

// error variants
// number

export default function InvalidFieldAlert({ error }) {

  const errorText = useMemo(() => {
    switch (error) {
      case 'number': {
        return transS('Required: only numbers')
      }
      default: {
        return ''
      }
    }
  }, [])

  return (
    <div className={styles.container}>
      <img style={{height: '10px', aspectRatio: '22 / 20'}} src='/images/danger_icon_22x20.svg' alt='danger' />
      <div>
        <p>{transS('Wrong format for this input.')}</p>
        <p>{errorText}</p>
      </div>
    </div>
  )
}