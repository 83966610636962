import React, { memo, useEffect, useState } from "react";
import VarItemBody from "./parts/BodyVarItem/VarItemBody.jsx";
import VarItemFooter from "./parts/VarItemFooter/VarItemFooter.jsx";
import VarItemAdvanced from "./parts/VarItemAdvanced/VarItemAdvanced.jsx";
import OptionsPart from "./parts/OptionsPart/OptionsPart.jsx";
import VarItemBodyWithCheckbox from "./parts/VarItemBodyWithCheckbox/VarItemBodyWithCheckbox.jsx";
import styles from "./styles.module.css";

export default memo(function TemplateVarItem({
  id,
  isEditVar,
  setEditVar,
  required,
  title,
  hint,
  defaultValue,
  name,
  changeVariable,
  type,
  dropdown,
  isApplies,
  isTracked,
  checkAndUpdateName,
  isUp,
  isDown,
  moveUp,
  moveDown,
  conditional,
  addOption,
  moveUpOption,
  moveDownOption,
  cloneVariable,
  removeVariable,
  removeOption,
  onChangeChecked,
  checked,
  readOnly
}) {
  const [showAdvanced, setShowAdvanced] = useState(false);

  const isReady = title.length !== 0
  const isVisibleFooter = isEditVar || (!isEditVar && !isReady)

  const onChange = () => {
    if (readOnly) return

    onChangeChecked(id)
  }

  useEffect(() => {
    if (!isEditVar) {
      setShowAdvanced(false)
    }
  }, [isEditVar])

  return (
    <div 
      data-is-var='true' 
      className={styles.container} 
      onClick={(e) => {
        if (e.target.tagName !== 'INPUT') {
          setEditVar(id);
        }
      }}
    >
      <VarItemBodyWithCheckbox
        id={id}
        title={title}
        hint={hint}
        required={required}
        isEditVar={isEditVar}
        changeVariable={changeVariable}
        setEditVar={setEditVar} 
        isVisibleLeftLine={isVisibleFooter}
        checkAndUpdateName={checkAndUpdateName}
        readOnly={true}
        onChange={onChange}
        checked={checked}
      />
      
      {isEditVar && type === 'dropdown' && (
        <OptionsPart 
          dropdown={dropdown ?? []}
          changeVariable={changeVariable}
          id={id}
          addOption={addOption}
          moveUpOption={moveUpOption}
          moveDownOption={moveDownOption}
          removeOption={removeOption}
          readOnly={true}
        />
      )}

      {showAdvanced && isEditVar && (
        <VarItemAdvanced
          defaultValue={defaultValue}
          changeVariable={changeVariable}
          id={id}
          name={name}
          type={type}
          isApplies={isApplies}
          isTracked={isTracked}
          checkAndUpdateName={checkAndUpdateName}
          isUp={isUp}
          isDown={isDown}
          moveUp={moveUp}
          moveDown={moveDown}
          conditional={conditional}
          cloneVar={cloneVariable}
          removeVar={removeVariable}
          readOnly={true}
        />
      )}
      {isEditVar && (
        <VarItemFooter
          showAdvanced={showAdvanced}
          setShowAdvanced={setShowAdvanced}
          type={type}
          changeVariable={changeVariable}
          id={id}
          readOnly={true}
        />
      )}
    </div>
  );
});
