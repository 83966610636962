import React, { useState } from 'react'
import styles from './styles.module.css'
import { transS } from '../../../../../../../../services/helpers/lang'
import OptionItem from './OptionItem/OptionItem.jsx'

export default function OptionsPart({ 
  dropdown, 
  changeVariable, 
  id,
  addOption,
  moveUpOption,
  moveDownOption,
  removeOption,
  readOnly = false,
}) {
  const [showAdvanced, setShowAdvanced] = useState(null)

  return (
    <div className={styles.container}>
      {dropdown?.length !== 0 && (
        <div className={styles.list}>
          {dropdown.map(el => (
            <OptionItem 
              idVariable={id}
              idOption={el.id}
              option={el.option}
              tip={el.tip}
              isDefault={el.isDefault}
              isInput={el.isInput}
              type={el.type}
              conditional={el.conditional}
              defaultValue={el.defaultValue}
              isUp={dropdown[0].id === el.id}
              isDown={dropdown[dropdown.length - 1].id === el.id}
              isShowAdvanced={showAdvanced === el.id}
              setShowAdvanced={setShowAdvanced}
              changeVariable={changeVariable}
              moveUpOption={moveUpOption}
              moveDownOption={moveDownOption}
              removeCallback={removeOption}
              readOnly={readOnly}
              key={el.id}
            />
          ))}
        </div>
      )}
      <button
        className={styles.button}
        onClick={() => addOption(id)}
        disabled={readOnly}
      >
        <img src="/images/boxQuestion_blue_12x12.svg" />
        {transS("Add Option")}
      </button>
    </div>
  )
}
