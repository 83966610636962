import React from 'react'
import styles from '../analysis.css'
import CSSModules from 'react-css-modules';
import { transS } from '../../../../../services/helpers/lang';

function AnalysisListItemAdvanced({
  defaultText,
  correctionText,
  correctCallback
}) {
  return (
    <div styleName='advanced'>
      <div styleName='advanced__title'>{transS('Is it a risk?')}{' '}{transS('Fix it?')}</div>
      <div styleName='advanced__content'>
        <span styleName='default'>{defaultText}</span>
        <span styleName='correction'>{correctionText}</span>
      </div>
      <div styleName='advanced__button' onClick={correctCallback}>
        <img 
          src="/images/approveIcon_green_11x12.svg" 
          alt="" 
          style={{
            position: 'relative',
            top: '-2px'
          }}
        />
        {transS("It's a risk") + '. ' + transS('Fix')}
      </div>
    </div>
  )
}

export default CSSModules(AnalysisListItemAdvanced, styles, {allowMultiple: true});