import React from 'react'
import Textarea from '../../../../../../components/topMenu/elements/Textarea/Textarea.jsx'
import Checkbox from '../../../../../../components/topMenu/elements/Checkbox/Checkbox.jsx'
import cx from 'classnames' 
import styles from './styles.module.css'

export default function VarItemBodyWithCheckbox({
  id,
  title,
  hint,
  required,
  isEditVar,
  setEditVar,
  changeVariable,
  isVisibleLeftLine,
  checkAndUpdateName,
  checked,
  onChange,
  readOnly = false
}) {
  return (
    <div 
      className={styles.body} 
      onClick={(e) => {
        if (e.target.tagName !== 'INPUT') {
          setEditVar(id);
        }
      }}
    >
      <div className={styles.inputs}>
        <span className={styles.header}>
          {isVisibleLeftLine && (
            <div className={styles.leftLine} />
          )}
          <Checkbox 
            size='lg'
            checked={checked}
            onChange={onChange}
          />
          <Textarea
            value={title}
            className={cx(styles.title, { [styles.readonly]: readOnly })}
            onChange={(value) => changeVariable({ id, key: "title", value })}
            placeholder={"Введите вопрос"}
            hasUnderline={isEditVar}
            readOnly={readOnly}
            onBlur={() => {
              checkAndUpdateName(id, 'title')
            }}
          />
        </span>

        {isEditVar && (
          <Textarea
          value={hint}
          className={cx(styles.hint, { [styles.readonly]: readOnly })}
          onChange={(value) => changeVariable({ id, key: "hint", value })}
          placeholder={"Добавить подсказку"}
          hasUnderline={isEditVar && hint.length > 0}
          readOnly={readOnly}
          />
        )}
      </div>
      {isEditVar && (
        <Checkbox
          checked={required}
          onChange={(e) =>
            changeVariable({ id, key: "required", value: e.target.checked })
          }
          label={"Обязательное*"}
          color={"blue"}
          size={"md"}
          className={cx({ [styles.readonly]: readOnly })}
          readOnly={readOnly}
        />
      )}
    </div>
  )
}
