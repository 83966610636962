import React from 'react'
import styles from './styles.module.css'

export default function Footer({ idOption, showAdvanced, setShowAdvanced }) {
  return (
    <div className={styles.container}>
      <button className={styles.advancedButton}>
        <img
          src={"/images/setting_blue_12x12.svg"}
          style={{ opacity: showAdvanced ? 0.3 : 1 }}
          alt="setting"
        />
        <img
          src="/images/arrow_10x6.svg"
          style={{
            rotate: showAdvanced ? "180deg" : "0deg",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowAdvanced((prev) => {
              if (prev === idOption) {
                return null
              } else {
                return idOption
              }
            });
          }}
          alt="arrow"
        />
      </button>
    </div>
  )
}
