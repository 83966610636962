import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TemplateVarItem from '../../../../../views/companySettings/AdminVariables/VarItem/TemplateVarItem.jsx'
import styles from './styles.module.css'
import { setVarList } from '../../../../../../../redux/document/actions.js';
import ReplaceVariablesPopup from '../ReplaceVariablesPopup/ReplaceVariablesPopup.jsx';
import api from '../../../../../../../services/api/api.js';
import { createPortal } from 'react-dom';

const duplicateVariables = (globalVariables, templateVariables) => {
  const duplicateVariables = [];
  const templateVariablesName = new Set(templateVariables.map(el => el.name));

  for (const variable of globalVariables) {
    if (templateVariablesName.has(variable.name)) {
      duplicateVariables.push(variable);
    }
  }

  return duplicateVariables.map(el => el.name);
};

export default function GlobalVariables({ setViewVars }) {
  const [selectedVariables, setSelectedVariables] = useState(new Set([]))
  const [openVar, setOpenVar] = useState(false)
  const [isViewPopup, setViewPopup] = useState(false)
  const [duplicatesForPopup, setDuplicatesForPopup] = useState([])
  const templateVarlist = useSelector(store => store.document.varlist) ?? []
  const actualDocument = useSelector((state) => state.document.actualDocument);
  const varlist = useSelector(store => store.userReducer.varlist)
  const frameProgress = useSelector(store => store.paragraphReducer.frameProgress);
  const rOnly = actualDocument.orig !== 1
  const dispatch = useDispatch()

  const onlyReadyVariables = varlist.filter(el => el.title?.trim().length !== 0)

  const checkDuplicates = () => {
    const onlySelectedVariables = varlist.filter(el => selectedVariables.has(el.id))
    const duplicateNames = duplicateVariables(onlySelectedVariables, templateVarlist)

    if (duplicateNames.length > 0) {
      const variableForReplace = templateVarlist.filter(el => duplicateNames.includes(el.name))
      setDuplicatesForPopup(variableForReplace)
      setViewPopup(true)
    } else {
      convertToUnitVariable()
    }
  }

  const convertToUnitVariable = () => {
    const onlySelectedVariables = varlist.filter(el => selectedVariables.has(el.id))
    const duplicateNames = duplicateVariables(onlySelectedVariables, templateVarlist)
    const templateVarlistWithoutDuplicate = templateVarlist.filter(el => !duplicateNames.includes(el.name))
    const updatedTemplateVarlist = [...onlySelectedVariables, ...templateVarlistWithoutDuplicate]

    api.updateTemplate(
      actualDocument.coreDocument,
      "c1 send vars",
      undefined,
      actualDocument._id,
      updatedTemplateVarlist
    );

    dispatch(setVarList(updatedTemplateVarlist))

    if (frameProgress)
      frameProgress.sendToFrame({
        c1: "variable",
        action: "list",
        varlist: updatedTemplateVarlist,
    });
    
    setViewVars('project')
    setSelectedVariables(new Set([]))
  }

  const handleCheckedVariable = (id) => {
    setSelectedVariables((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }

  const clickOutsideHandler = (e) => {
    const target = e.target;
    const isInside = target.closest("[data-is-var='true']");

    if (!isInside) {
      setOpenVar(null);
    }
  }

  const emptyCallback = useCallback(() => {}, [])

  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler)
    
    return () => {
      window.removeEventListener('click', clickOutsideHandler)
    }
  }, [])

  return (
    <>
      {isViewPopup && (
        createPortal(
          <ReplaceVariablesPopup 
            cancelCallback={() => {
              setViewPopup(false)
              setDuplicatesForPopup([])
            }}
            replaceCallback={convertToUnitVariable} 
            duplicates={duplicatesForPopup}
          />,
          document.body
        )
      )}
      <div className={styles.container}>
        <button 
          className={styles.button} 
          // style={{ opacity: selectedVariables.size === 0 ? 0.3 : 1 }}
          onClick={checkDuplicates}
          disabled={selectedVariables.size === 0 || rOnly}
          >
          <img src="/images/arrow_left_11x12.svg" alt="add" />
          Добавить в шаблон
        </button>

        <div className={styles.list}>
          {onlyReadyVariables.map(el => (
            <TemplateVarItem
              id={el.id}
              isEditVar={openVar === el.id}
              setEditVar={setOpenVar}
              required={el.required}
              value={el.value}
              title={el.title}
              hint={el.hint}
              defaultValue={el.defaultValue}
              name={el.name}
              type={el.type}
              changeVariable={emptyCallback}
              dropdown={el.dropdown}
              isApplies={el.isApplies}
              isTracked={el.isTracked}
              checkAndUpdateName={emptyCallback}
              isUp={varlist[0].id === el.id}
              isDown={varlist[varlist.length - 1].id === el.id}
              moveUp={emptyCallback}
              moveDown={emptyCallback}
              conditional={el.conditional}
              addOption={emptyCallback}
              moveUpOption={emptyCallback}
              moveDownOption={emptyCallback}
              cloneVariable={emptyCallback}
              removeVariable={emptyCallback}
              removeOption={emptyCallback}
              onChangeChecked={handleCheckedVariable}
              checked={selectedVariables.has(el.id)}
              readOnly={rOnly}
              key={el.id}
              />
          ))}
        </div>
      </div>
    </>
  )
}
