import React, { useState } from 'react';
import CSSModules from 'react-css-modules';
import styles from "./colorTooltip.css"
import { createPortal } from 'react-dom';

const ColorTooltip = ({children}) => {

    return (
        <>{createPortal(<div styleName='container'>
            {children}
        </div>, document.querySelector('#app-root'))}</>
    );
};

export default new CSSModules(ColorTooltip, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
