import React, { memo } from 'react'
import styles from '../analysis.css'
import CSSModules from 'react-css-modules';
import { transS } from '../../../../../services/helpers/lang';
import Button from '../../../../../components/button/Button';

function AnalysisListItemFooter({
  isActive,
  setRiskButNotResolve,
  deleteCallback,
  setActive,
  hideCallback,
  correctCallback
}) {
  return (
    <div styleName="itemsFooter">
      <div 
        styleName='itemFooter__item green' 
        onClick={() => {
          if (isActive) {
            setRiskButNotResolve()
          }

          if (!isActive) {
            correctCallback()
          }
        }}
      >
        <img src="/images/errorIcon_green_22x20.svg" alt="" />
        {isActive ? (transS("It's a risk") + '. ' + transS("Don't fix it")) : transS("It's a risk")}
      </div>

      <div 
        styleName='itemFooter__item red'
        onClick={deleteCallback}
      >
        <img src="/images/deleteIcon_10x12.svg" alt="" />
        {transS("It's not a risk")}
      </div>

      {isActive 
        ? (
          <div 
            styleName='itemFooter__item gray'
            onClick={hideCallback}
          >
            <img src="/images/eyeHide_gray_12x12.svg" alt="" />
            {transS('Hide')}
          </div>
        ) : (
          // <div 
          //   styleName='itemFooter__item blue'
          //   onClick={setActive}
          // >
          //   {transS('Details')}
          //   <img src="/images/arrow_10x6.svg" alt="open" styleName='arrow' />
          // </div>
          <Button 
            text={transS('Details')}
            mod={'send '}
            style={{ paddingLeft: 0, background: 'none', fontSize: 'var(--fontSize_frameNormal)' }}
            callback={setActive}
          />
        )
      }
    </div>
  )
}

export default memo(CSSModules(AnalysisListItemFooter, styles, {allowMultiple: true}))
