export const variableTypes = new Map([
  [
    'text', {
      transVar: 'Text',
      iconText: 'T',
      iconLink: null
    }
  ],
  [
    'number', {
      transVar: 'Number',
      iconText: '#',
      iconLink: null
    }
  ],
  [
    'if', {
      transVar: 'Condition',
      iconText: '?',
      iconLink: null
    }
  ],
  [
    'date', {
      transVar: 'Date',
      iconText: null,
      iconLink: '/images/dateIcon_13x12.svg'
    }
  ],
  [
    'dropdown', {
      transVar: 'Dropdown',
      iconText: null,
      iconLink: '/images/arrow-orange_10x6.svg'
    }
  ],
  [
    'group', {
      transVar: 'Group',
      iconText: null,
      iconLink: '/images/groupIcon_15x12.svg'
    }
  ],
])