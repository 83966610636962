import cookies from 'browser-cookies';
import { push } from 'connected-react-router';
import momentTZ from 'moment-timezone';
import { popUpWithInputReset, popUpReset } from '../helpers';
import { userConstants, projectConstants, dataConstants } from '../constants';
import api from '../api/api';
import store from '../store';
import {setCurrentProjectAndProjects} from '../actions/projectActions';
import {isEqual} from 'lodash'
import { setLanguage, transS } from '../helpers/lang';
import { cookiesConstants } from '../../../server/configs/appConfig';

const clearUserTimeout = () => {
  let state = store.getState();
  let current = state.userReducer.user;
  if (current && current.timeout) clearTimeout(current.timeout);
};

const prepareUserDocs = (user) => {
    if (user && user.data && user.data.data) {
      let xx = user.data.data;
      if (xx.docs) {
        let ass = {};
        xx.docs.forEach(el => ass[el.doc] = JSON.parse(el.udochanges));
        xx.docs = ass;
      }
    }
 };

const prepareUserTimer = (user) => {
    if (user && user.data && user.data.data) {
      let xx = user.data.data;
      clearUserTimeout(); 
      prepareUserDocs(user);
      if (xx.ticks <= 0) return; // only for potentially expired users
      let y = (xx.ticks > 2047483647) ? 2047483647: xx.ticks+5000;
      xx.loginTime = new Date();
      xx.timeout = setTimeout(()=>{
        
        api.getUserInfo()
            .then(resp => {
                setLanguage(resp.data?.data?.lang)
                if (resp && resp.data && resp.data.data) {
                    if (resp.data.data.ticks > 0) prepareUserTimer(resp);
                    else  { prepareUserDocs(resp);
                     store.dispatch({
                      type: userConstants.SHOW_EXPIRED,
                      showExpired: "It looks like your plan has expired.  You can see public version and comments, to see the internal versions and comments please upgrade."
                    })}; 
                } else store.dispatch({
                      type: userConstants.SHOW_EXPIRED,
                      showExpired: "It looks like your plan has expired or application problem. Try to reload this page"
                });
                store.dispatch({
                    type: projectConstants.GET_INVITE_PROJECT_POPUP,
                    projectId: '' ,
                    show: false
                });
                store.dispatch({
                    type: dataConstants.GET_POP_UP,
                    popUp: popUpReset()
                });
                store.dispatch({
                    type: dataConstants.GET_POP_UP_WITH_INPUT,
                    popUpWithInput: popUpWithInputReset()
                });
                store.dispatch({
                    type: userConstants.RENEW_USER,
                    user: resp && resp.data && resp.data.data 
                });
            })
            .catch( (resp) => {
                store.dispatch({
                    type: userConstants.RENEW_USER,
                    user: null 
                });
                store.dispatch({
                      type: userConstants.SHOW_EXPIRED,
                      showExpired: "It looks like your plan has expired or network problem. Try to reload this page later"
                });
            }); 
        }, y);    
   }
};

// Create|Change company
export const changeCompanyAction = (data) => async dispatch => {
    let res, usersRes
    try {
        res = await api.changeCompany(data);
        usersRes = await api.getCompanyMembers()
    } catch (e) {console.log(e)}

    if (!res || !usersRes) return toggleCompanyLoader(false)

    let company = res.data.data;
    let users = usersRes.data.data

    if (company) {
        company.knownCompanies = JSON.parse(company.knownCompanies)
        company.workflows = JSON.parse(company.workflows)
        company.knownCompanies[0].members = users
    }
    dispatch({
        type: userConstants.CHANGE_COMPANY,
        payload: company
    });
}

export const changeClause = clause => async dispatch => {
    dispatch({
        type: userConstants.CHANGE_CLAUSE,
        clause
    });
}

export const setGlobalVarlist = varlist => async dispatch => {
    dispatch({
        type: userConstants.SET_GLOBAL_VARLIST,
        varlist
    });
}

export const getCompanyMembersAction = () => async dispatch => {
    toggleCompanyLoader(true)
    let res
    try {
        res = await api.getCompanyMembers();
    } catch (e) {console.log(e)}

    const members = res.data.data;

    dispatch({
        type: userConstants.GET_COMPANY_MEMBERS,
        payload: members
    });
    toggleCompanyLoader(false)
}

export const inviteCompanyMembersAction = (members) => async dispatch => {
    toggleCompanyLoader(true)
    const name = store.getState().userReducer.user.company.knownCompanies[0].name
    const data = {name, members}
    let res, usersRes

    try {
        res = await api.inviteToCompany(data)
        usersRes = await api.getCompanyMembers()
    } catch (e) {console.log(e)}

    if (!res || !usersRes) return toggleCompanyLoader(false)

    let company =  res.data.data
    let users = usersRes.data.data

    if (company) {
        company.knownCompanies = JSON.parse(company.knownCompanies)
        company.workflows = JSON.parse(company.workflows)
        company.knownCompanies[0].members = users
    }
    dispatch({
        type: userConstants.INVITE_COMPANY_MEMBERS,
        payload: company
    });

    toggleCompanyLoader(false)
}

export const changeCompanyMemberPasswordAction = (memberId, newPassword) => async dispatch => {
    let res, error

    try {
        res = await api.changeCompanyMemberPassword({memberId, newPassword});
    } catch (e) {
        error = e
    }

    const popup = {
        success: {
            name: 'confirm',
            text: 'The member’s password has been changed.',
            confirm: {
                name: transS('Got it'),
                event: () => {
                    store.dispatch({
                        type: dataConstants.GET_POP_UP,
                        popUp: popUpReset()
                    });
                }
            },
        },
        error: {
            name: 'confirm',
            text: `The member’s password has not been changed ${error}`,
            confirm: {
                name: transS('Ok'),
                event: () => {
                    store.dispatch({
                        type: dataConstants.GET_POP_UP,
                        popUp: popUpReset()
                    });
                }
            },
        }
    }

    if (res) {
        store.dispatch({
            type: dataConstants.GET_POP_UP,
            popUp: popup.success
        });
    } else {
        store.dispatch({
            type: dataConstants.GET_POP_UP,
            popUp: popup.error
        });
    }
}

export const cancelCompanyInviteRequestAction = () => async dispatch => {
    try {
        await api.cancelCompanyInviteRequest()
    } catch (e) {}

    let check = null;
    let user = null;

    let token = cookies.get(cookiesConstants.COOKIE_TOKEN_KEY);
    if (token) {
        try {
            check = await api.checkLogin(token);
        } catch (err) { }

        if (check) {
            try {
                user = await api.getUserInfo();
                setLanguage(user.data?.data?.lang)
                prepareUserTimer(user);
            } catch (err) { }
        }
    }

    user = user && user.data && user.data.data
    if (user && user.company) {
        user.company.knownCompanies = JSON.parse(user.company.knownCompanies)
        user.company.workflows = JSON.parse(user.company.workflows)
        user.knownCompanies = JSON.parse(user.knownCompanies)
    }

    getUserWithTimeZone(dispatch, user);
}

const defaultPreset = {dateFormat: 1, is12hours: true, indexTZ:0, tz: ""};

const getUserWithTimeZone = async (dispatch, user, activationInProgress) => {
    let dateData = user && user.dateData ? JSON.parse(user.dateData) : {...defaultPreset},
      oldTZ = dateData.tz;
    if (!dateData.indexTZ) dateData.tz = momentTZ.tz.guess(true);
    if (user && oldTZ !== dateData.tz) api.setUserDateData(dateData);
    if (user) delete user.dateData;
    dispatch({
        type: userConstants.GET_USER,
        user: user,
        isLoaded: true,
        activationInProgress: activationInProgress,
        dateData: dateData
    });
};

export const activateCompanyMemberAction = (members) => async dispatch => {
    toggleCompanyLoader(true)
    const name = store.getState().userReducer.user.company.knownCompanies[0].name
    const data = {name, members}
    let res, usersRes

    try {
        res = await api.deactivateCompanyMember(data);
        usersRes = await api.getCompanyMembers()
    } catch (e) {console.log(e)}

    if (!res || !usersRes) return toggleCompanyLoader(false)

    let company = res.data.data
    let users = usersRes.data.data

    if (company) {
        company.knownCompanies = JSON.parse(company.knownCompanies)
        company.workflows = JSON.parse(company.workflows)
        company.knownCompanies[0].members = users
    }
    dispatch({
        type: userConstants.DELETE_COMPANY_MEMBERS,
        payload: company
    });
    toggleCompanyLoader(false)
}

export const deleteCompanyMemberAction = (members) => async dispatch => {
    toggleCompanyLoader(true)
    const name = store.getState().userReducer.user.company.knownCompanies[0].name
    const data = {name, members}
    let res, usersRes

    try {
        res = await api.deleteFromCompany(data);
        usersRes = await api.getCompanyMembers()
    } catch (e) {console.log(e)}

    if (!res || !usersRes) return toggleCompanyLoader(false)

    let company = res.data.data
    let users = usersRes.data.data

    if (company) {
        company.knownCompanies = JSON.parse(company.knownCompanies)
        company.workflows = JSON.parse(company.workflows)
        company.knownCompanies[0].members = users
    }
    dispatch({
        type: userConstants.DELETE_COMPANY_MEMBERS,
        payload: company
    });
    toggleCompanyLoader(false)
}

export const toggleCompanyLoader = (loader) => {
    store.dispatch({
        type: userConstants.TOGGLE_COMPANY_LOADER,
        payload: loader
    });
}

// export const changeCompanyMembersAction = (name, members) => async dispatch => {
//
// }

// check login action
export const checkLogin = () => async dispatch => {
  let check = null;
  let user = null;

  let token = cookies.get(cookiesConstants.COOKIE_TOKEN_KEY);
  if (token) {
    try {
      check = await api.checkLogin(token);
    } catch (err) { }

    if (check) {
      try {
        user = await api.getUserInfo();
        setLanguage(user.data?.data?.lang)
        prepareUserTimer(user);
      } catch (err) { }
    }
  }

    user = user && user.data && user.data.data
    if (user && user.company) {
        user.company.knownCompanies = JSON.parse(user.company.knownCompanies)
        user.company.workflows = JSON.parse(user.company.workflows)
        user.knownCompanies = JSON.parse(user.knownCompanies)
    }

  if (user) {
      await window.socketConnect(user._id)
      await setUserInterfaceData()      
  }  

  getUserWithTimeZone(dispatch, user);
  if (!token || !check) {
    throw new Error('Unauthorized');
  }
};

// login action
export const logInAction = data => async dispatch => {
  let user = null;

  try {
    await api.logIn(data);
  } catch (err) {
    return Promise.reject(err);
  }

  try {
    user = await api.getUserInfo();
    setLanguage(user.data?.data?.lang)
    prepareUserTimer(user);
  } catch (err) { }

    user = user && user.data && user.data.data
    if (user && user.company) {
        user.company.knownCompanies = JSON.parse(user.company.knownCompanies)
        user.company.workflows = user.company.workflows ? JSON.parse(user.company.workflows) : [];
        user.knownCompanies = JSON.parse(user.knownCompanies)
    }
  await window.socketConnect(user._id)
  await setUserInterfaceData()

    if (data.projectId) {
        let reProjectList = await api.getProjectDocList();

        let newItem = reProjectList.data.data.projects.find(item => item._id === data.projectId);
        setCurrentProjectAndProjects({project: newItem, projectList: reProjectList.data.data.projects});
        if (!newItem) dispatch(push('/not-found/404/'));
        else {
            if (data.documentId) {
                if (data.history)
                    dispatch(push(`/${data.projectId}/documents/${data.documentId}/history/${history}`));
                else dispatch(push(`/${data.projectId}/documents/${data.documentId}`));
            }
            else dispatch(push(`/${data.projectId}/documents/`));
        }
    }

    getUserWithTimeZone(dispatch, user);
};

// set temporary user
export const setTemporaryUserAction = data => async dispatch => {
    getUserWithTimeZone(dispatch, data);
}

export const temporaryUserLoginAction = data => async dispatch => {
    let user
    try {
        await api.temporaryUserLogin(data)

        user = await api.getUserInfo()
        user = user.data.data
        setLanguage(user.lang)
    } catch (e) {
        return e.response.data.err
    }

    dispatch(push('/'))
    getUserWithTimeZone(dispatch, user);

    return 0
}

// temporary user register action
export const temporaryUserRegisterAction = data => async dispatch => {
    let user
    try {
        await api.temporaryUserRegister(data)
    } catch (e) {
        return e.response.data.err
    }

    try {
        user = await api.getUserInfo()
        user = user.data.data
        setLanguage(user?.lang)
    } catch (e) {
        return e.response.data.err
    }


    dispatch(push('/'))
    getUserWithTimeZone(dispatch, user, true);
    
    return 0
}

// sign up action
export const signUpAction = data => async dispatch => {
  let response;
  try {
    response = await api.signUp(data);
  } catch (err) {
    return Promise.reject(err);
  }
  if (!response.data.data.isActivated)
  {
      getUserWithTimeZone(dispatch, null, true);
      dispatch(push('/'));
  }
};

export const scheduleAction = data => async dispatch => {
  let response;
  try {
    response = await api.schedule(data);
  } catch (err) {
    return Promise.reject(err);
  }
  
  dispatch(push('/scheduled'));
};

// logout action
export const logout = (redirectToLogin = false) => async dispatch => {
  await saveUserInterfaceData()

  clearUserTimeout();

  // dispatch({
  //   type: userConstants.GET_USER,
  //   user: null,
  //   isLoaded: true
  // });
  //
  // dispatch({
  //   type: projectConstants.GET_PROJECT_LIST,
  //   projectList: null,
  //   currentProject: null
  // });
    clearLocalUserInterfaceData()

  dispatch({
    type: userConstants.LOGOUT_USER
  });

  redirectToLogin ? dispatch(push('/login')) : dispatch(push('/'))

  try {
    await api.logout();
  } catch (err) {
      if (err.response.status === 401) window.socketDisconnect()
  }
};

export const setDateFormat = dateData => async dispatch => {
  api.setUserDateData(dateData);
  dispatch ({
  type: userConstants.SET_DATE_FORMAT,
  dateData
 }); };

const setUserInterfaceData = async () => {
    const res = await api.getUserInterfaceData()
    const data = res.data.data

    window.addEventListener('beforeunload', saveUserInterfaceData)
    window.addEventListener('blur', windowBlurHandler)

    if (!data) return 1

    window.initUserInterfaceData = {}
    if (data.commentDrafts) {
        localStorage.setItem('commentDrafts', data.commentDrafts)
        window.initUserInterfaceData.commentDrafts = data.commentDrafts
    }
    if (data.paragraphStateMenu) {
        localStorage.setItem('paragraphStateMenu', data.paragraphStateMenu)
        window.initUserInterfaceData.paragraphStateMenu = data.paragraphStateMenu
    }
    return 1
}
const windowBlurHandler = e => {
    saveUserInterfaceData()
}
const saveUserInterfaceData = async () => {
    setTimeout(() => {
        const dataObj = {}

        const commentDrafts = localStorage.getItem('commentDrafts')
        if (commentDrafts) dataObj.commentDrafts = commentDrafts

        const paragraphStateMenu = localStorage.getItem('paragraphStateMenu')
        if (paragraphStateMenu) dataObj.paragraphStateMenu = paragraphStateMenu

        if (!isEqual(window.initUserInterfaceData, dataObj)) {
            window.processSaveUID = true
            api.saveUserInterfaceData(dataObj)
            window.initUserInterfaceData = dataObj
        }
    }, 150)
}

const clearLocalUserInterfaceData = () => {
    localStorage.removeItem('commentDrafts')
    localStorage.removeItem('paragraphStateMenu')
    window.removeEventListener('beforeunload', saveUserInterfaceData)
    window.removeEventListener('blur', windowBlurHandler)
}

// get user group action
export const getUserGroup = userGroup => ({
  type: userConstants.GET_USER_GROUP,
  userGroup
});


export const setNewClause = (text, edited, shift, onExit) => ({
  type: userConstants.SET_EDIT_CLAUSE,
  editClause: text ? {
    clause:  [{
      id: Date.now(),
      edited,
      isDraft: true,
      content: text,
      title: '',
      hint: '',
      version: 1,
      customTag: null,
      tagId: null,
      isTracked: false,
      isActive: true,
    }],
    shift,
    rOnly: false,
    modifyClause: onExit
  }: null
})

// get external user list action
export const getExternalUserList = (externalUserList, internalUserList, approverUserList, hasExternals) => ({
  type: userConstants.GET_EXTERNAL_USER_LIST,
  externalUserList, internalUserList, approverUserList, hasExternals
});

export const setApproveEvents = (userId) => {

}

// set incorrect password status
export const setIncorrectPasswordStateAction = status => ({
  type: userConstants.INCORRECT_PASSWORD,
  incorrect: status
});

export const renewUserAction = data => async dispatch => {
  prepareUserTimer({'data':{'data':data}});
  dispatch({
    type: userConstants.RENEW_USER,
    user: data
  });
}
  
export const updateUserInfoAction = data => async dispatch => {
  let userCompany = store.getState().userReducer.user.company
  let user = null;
  try {
    user = await api.updateUserInfo(data);
    prepareUserTimer(user);
    user.data.data.company = userCompany
  } catch (err) {
    if (err.request.status === 401) {
      dispatch(setIncorrectPasswordStateAction(true));
      return;
    }
  }

  let cloneUser = {...user.data.data}
  delete user.data.data.emailChangeError
  let dateData = user.data.data.dateData ? JSON.parse(user.data.data.dateData) : {...defaultPreset};

  dispatch({
    type: userConstants.UPDATE_USERINFO,
    user: user.data.data,
    dateData: dateData
  });

  dispatch(setIncorrectPasswordStateAction(false));
  if (!cloneUser.emailChangeError) dispatch(showSidebarAction(false));

  return Promise.resolve(cloneUser);
};

export const showSidebarAction = showSidebar => ({
  type: userConstants.SHOW_SIDEBAR,
  showSidebar
});

export const showExpiredAction = showExpired => ({
  type: userConstants.SHOW_EXPIRED,
  showExpired
});

export const showPaymentAction = showPayment => ({
  type: userConstants.SHOW_PAYMENT,
  showPayment
});

export const updateUnknown = userList => ({
    type: userConstants.UPDATE_UNKNOWN_LIST,
    userList
})

export const updateApproverList = approverUserList => ({
    type: userConstants.UPDATE_APPROVERS_LIST,
    approverUserList
})
