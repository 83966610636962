import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './CommentRow.css'
import cx from 'classnames'
import UserLogo from "../userLogo/UserLogo"
import { getDateString } from '../../common/getDateString'
import Button from "../button/Button"
import Checkbox from "../checkbox/Checkbox"
import Tip from "../tip/Tip"
import {Tagging} from './Tagging'
import {connect} from "react-redux"
import { transS } from '../../services/helpers/lang'

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class CommentRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            textareaValue: '',
            mentions: [],
            isShowControlsPopup: false,
            sendTimer: null,
            isShowTaggingMenu: false,
        }
        this.sendTimerInterval = null
        this.selectionStart = null
    }

    componentDidMount() {
        if (this.props.isNewComment) this.setState({isEdit: true})

        if (this.props.content || this.props.initialValueTA) this.setState({textareaValue: this.props.content || this.props.initialValueTA})

        window.addEventListener('click', this.handleClickOutside)
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isEdit !== this.props.isEdit) this.setState({isEdit: this.props.isEdit})
        if (!this.state.isEdit && this.state.textareaValue !== this.props.content) this.setState({textareaValue: this.props.content})

        if (prevProps.isPendingReply && !this.props.isPendingReply) {
            this.props.blurHandler()
            this.setState({textareaValue: this.props.content || this.props.initialValueTA || ''})
        }
        this.textareaResize()

        if (this.selectionStart !== null) {
            this.refTextarea.setSelectionRange(this.selectionStart, this.selectionStart)
            this.selectionStart = null
        }
    }

    handleClickOutside = e => {
        if (this.refPopup && e.target !== this.refPopup && !this.refPopup.contains(e.target))
            this.setState({isShowControlsPopup: false})
    }

    textareaOnBlurHandler = () => {
        if (this.state.textareaValue) return

        this.setState({isShowTaggingMenu: false})

        if (this.props.blurHandler) return this.props.blurHandler()

        this.setState({isEdit: false, textareaValue: this.props.content || this.props.initialValueTA || ''})
    }

    clearCrossClickHandler = e => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({textareaValue: ''})
        this.refTextarea.focus()
    }

    textareaOnChangeHandler = e => {
        this.setState({textareaValue: e.target.value})
    }

    textareaOnKeyDownHandler = e => {
        if ((e.code === 'Enter' || e.key === 'Enter') && !this.props.isCoverMessage) {
            e.preventDefault()
            e.stopPropagation()
            this.sendClickHandler()
        }
        if (e.key === '@' || e.key === 'Backspace') {
            e.preventDefault()
            e.stopPropagation()
            let ta = e.target,
                start = ta.selectionStart,
                end = ta.selectionEnd,
                val = ta.value,
                newVal = ta.value
            if (e.key === 'Backspace') {
                if (start === 0 && end === 0) return

                if (start !== end) {
                    newVal = val.slice(0, start) + val.slice(end)
                    // ta.setSelectionRange(start, start)
                } else {
                    newVal = newVal.slice(0, start - 1) + newVal.slice(end)
                    start = start - 1 === -1 ? 0 : start - 1
                    // ta.setSelectionRange(start, start)
                }
                this.selectionStart = start

                if (newVal[start - 1] !== '@' || (newVal[start - 2] !== undefined && newVal[start - 2] !== ' ') )
                    return this.setState({isShowTaggingMenu: false, textareaValue: newVal})

            } else if (e.key === '@') {
                newVal = val.slice(0, start) + '@' + val.slice(end)
                ta.setSelectionRange(start + 1, start + 1)
                if (newVal[start - 1] && newVal[start - 1] !== ' ')
                    return this.setState({isShowTaggingMenu: false, textareaValue: newVal})
            }
            this.setState({isShowTaggingMenu: true, textareaValue: newVal})
        }
    }

    textareaResize = () => {
        const ta = this.refTextarea
        if (!ta) return

        if (ta.scrollHeight + 2 > ta.offsetHeight) { // увеличение
            if (!ta.classList.contains(styles.large)) ta.classList.add(styles.large)
            ta.style.height = `${ta.scrollHeight + 2}px`
        } else if (ta.scrollHeight + 2 <= ta.offsetHeight) { // уменьшение
            if (ta.classList.contains(styles.large) && ta.scrollHeight === 49) ta.classList.remove(styles.large)
            ta.style.height = `38px`
            if (ta.scrollHeight + 2 !== ta.offsetHeight) ta.classList.add(styles.large)
            ta.style.height = `${ta.scrollHeight + 2}px`
        }
    }

    sendClickHandler = () => {
        const {mentions} = this.state
        const {_id, func, isNewComment, isReply, user, when, blurHandler, recipients, isPublic, selectedRecipients} = this.props

        let content = this.state.textareaValue.trim()
        mentions.forEach(el => {
            content = content.replace(el.val, 
    `<span class="at" data-due-id="${el._id}" ${el.dueTime ? `data-due="${el.dueTime}"` : ''} >@${(el.firstname + el.lastname) ? `${el.firstname} ${el.lastname}` : el.email}</span>`)
        })

        if (!content) return

        if (isReply && isNewComment) func('addReply', {messageId: _id, content})
        else if (isNewComment) func('addMessage', {content, recipients, isPublic, selectedRecipients})
        else if (isReply) func('editReply', {messageId: _id, content, reply: when })
        else func('editMessage', {messageId: _id, content, recipients})

        if (isReply && isNewComment) return

        if (blurHandler) {
            blurHandler()
            this.setState({textareaValue: this.props.content || this.props.initialValueTA || ''})
        } else {
            this.setState({isEdit: false, textareaValue: this.props.content || this.props.initialValueTA || ''})
        }
    }

    deleteClickHandler = () => {
        const {func, isReply, when, _id} = this.props

        isReply ?
            func('deleteReply', {messageId: _id, reply: when}) : func('deleteMessage', {messageId: _id})
    }

    wrapperClickHandler = () => {
        const {_id, isUnread, func, isReply, when} = this.props

        if (this.props.isUnread)
            func('unreadCheck', {messageId: _id, reply: isReply ? when : null})
    }

    toggleSendTimer = (val) => {
        const {isReply, func, _id, when} = this.props
          if (val) {
              this.setState({sendTimer: 30000})
              this.sendTimerInterval = setInterval(() => {
                  let newTime = this.state.sendTimer - 1000
                  if (newTime < 1) {
                      this.setState({sendTimer: null})
                      clearInterval(this.sendTimerInterval)
                      return isReply ? func('publishReply', {messageId: _id, reply: when}) : func('publishMessage', {messageId: _id})
                  }
                  this.setState({sendTimer: newTime})
              }, 1000)
          } else {
              this.setState({sendTimer: null})
              clearInterval(this.sendTimerInterval)
          }
    }

    render() {
        const {textareaValue, isEdit, isShowControlsPopup, sendTimer, isShowTaggingMenu} = this.state
        const {_id, user, me, content, when, style, isNeedControls, edit, deleted, isUnread, isReply, type, canEdit, dateData,
            internalUserList, freeze, mainEditCallback, func, isSendFreeze, addThreadTitle, event, sendBtnText, canDelete, group} = this.props

        if (deleted) {
            return <div styleName='deleted'>Message Deleted</div>
        }

        return (
            <div styleName={cx('wrapper', {'_edit': isEdit, '_freeze': freeze})} style={style} onClick={this.wrapperClickHandler}>
                {isEdit ?
                    <React.Fragment>
                        <UserLogo {...me} mod='_small _border'/>
                        <div styleName='editPart'>
                            <textarea styleName='textarea'
                                      autoFocus={true} ref={e => this.refTextarea = e}
                                      onKeyDown={this.textareaOnKeyDownHandler}
                                      onChange={this.textareaOnChangeHandler}
                                      onBlur={this.textareaOnBlurHandler}
                                      placeholder={transS(isReply ? 'Type reply' : 'Start New Thread.')}
                                      value={textareaValue} />
                            {textareaValue && 
                                <div styleName='send' onClick={this.sendClickHandler}>{!textareaValue.trim() ? '' : (sendBtnText || transS('Send'))}
                                    <div className='close-cross' styleName='clear-cross' onClick={this.clearCrossClickHandler}>+</div>
                                </div>}                                
                            {isShowTaggingMenu && <Tagging internalUserList={internalUserList} textareaValue={textareaValue}
                                                           setTextAreaValue={(textareaValue, mentions) => this.setState({textareaValue, mentions})}
                                                           setShowTaggingMenu={isShowTaggingMenu => this.setState({isShowTaggingMenu})} textArea={this.refTextarea}
                                                           mentions={this.state.mentions} />}
                        </div>
                    </React.Fragment>

                    :
                    <React.Fragment>
                        <UserLogo {...user} mod='_small _border'/>
                        <div styleName='mainPart'>
                            <div styleName='head'>
                                <div styleName='userName'>{user && user.firstname} {user && user.lastname}</div>
                                <div styleName='gray'>{getDateString(when, 2, dateData)}</div>
                                {type === 1 && <div styleName='gray'>{transS('Approval Request')}</div>}
                                {addThreadTitle && <Button mod='gray' style={{marginRight: '10px'}} text={transS('Add Thread Title')} callback={addThreadTitle} />}
                                {type === 3 && event === 'SEND' && <div styleName='gray'>{transS('Cover Message for the sent version')}{freeze ? ` (${transS('Draft')})` : ''}</div>}
                                {type === 3 && event === 'COLLABORATE' && <div styleName='gray'>{transS('Cover Message for starting collaboration')}{freeze ? ` (${transS('Draft')})` : ''}</div>}
                                {type === 3 && event === 'FINALIZE' && <div styleName='gray'>{transS('Cover Message for starting finalisation')}{freeze ? ` (${transS('Draft')})` : ''}</div>}
                                {type === 3 && event === 'REQUEST_BACK' && <div styleName='gray'>{transS('Cover Message for requesting the document back')}{freeze ? ` (${transS('Draft')})` : ''}</div>}
                                {type === 3 && event === 'TAKE_OVER' && <div styleName='gray'>{transS('Cover Message for taking over the document')}{freeze ? ` (${transS('Draft')})` : ''}</div>}
                                {(type === 3 ? (group === me.userGroup && edit) : edit) && <div styleName='gray'>{transS('Edited')}</div>}
                            </div>
                            <div styleName='content' dangerouslySetInnerHTML={{__html: content}} />
                            {freeze && <div styleName='freezePart'>
                                <div>{transS(sendTimer ? 'This message is about to be sent'
                                  : 'This message will be sent with this version')}
                                </div>
                                {sendTimer ?
                                    <React.Fragment>
                                        <span style={{marginRight: '15px'}}>00:{sendTimer/1000}</span>
                                        <Button text={transS('Cancel')} mod='blue' style={{marginRight: '15px'}}
                                                callback={() => this.toggleSendTimer(0)} />
                                        <Button text={transS('Send now')} mod='blue' style={{marginRight: '15px'}}
                                                callback={() => {isReply ? func('publishReply', {messageId: _id, reply: when}) : func('publishMessage', {messageId: _id})}} />
                                    </React.Fragment>
                                    :
                                        <React.Fragment>
                                            {canEdit && <Button text={transS('Edit')} mod='blue' style={{marginRight: '15px'}}
                                                                  callback={() => {isReply ? this.setState({isEdit: true}) : mainEditCallback()}} />}
                                            {canDelete && <Button text={transS('Delete')} mod='blue' style={{marginRight: '15px'}}
                                                                  callback={() => {isReply ? this.deleteClickHandler() : func('deleteMessage', {messageId: _id})}} />}
                                            {!isSendFreeze && <Button text={transS('Send now')} mod='blue' style={{marginRight: '15px'}}
                                                                       callback={() => this.toggleSendTimer(1)} />}
                                        </React.Fragment>}
                            </div>}

                        </div>
                        {isUnread && <div styleName='unreadMark'/>}
                        {isNeedControls &&
                        <div styleName={cx('popup_wrapper', {'_open': isShowControlsPopup})} ref={e => this.refPopup = e}>
                            <div styleName='popup_showBtn' onClick={() => this.setState({isShowControlsPopup: !isShowControlsPopup})}><span/><span/><span/></div>
                            <div styleName='popup'>
                                <Button mod='blue margin0' text={transS('Edit')} callback={() => this.setState({isEdit: true, isShowControlsPopup: false})}/>
                                <Button mod='red margin0' text={transS('Delete')} callback={this.deleteClickHandler} />
                            </div>
                        </div>}
                    </React.Fragment>}
            </div>
        )
    }
}

export default CommentRow