import React, {Component} from 'react';
import cx from 'classnames';
import cssModules from 'react-css-modules';
import styles from './projectPage.css';
import {isEmail} from '../../../../common/validation';

import InputBox from '../../../../components/input/InputBox';
import Button from '../../../../components/button/Button';
import { transL, transS } from '../../../../services/helpers/lang';

@cssModules(styles, {
    allowMultiple: true,
    handleNotFoundStyleName: 'throw'
})

class AddCompany extends Component {
    state = {
        name: '',
        mail: '',
        fname: '',
        lname: '',
        mailError: '',
        nameError: '',
        role: '',
        country: '',
        state1: '',
        zip: '',
        aline1: '',
        aline2: '',
        isNew: true,
    };

    constructor(props) {
        super(props);
        this._name = this._name.bind(this);
    }

    _cancel = (e, param) => {
        let {func} = this.props;
        let {name, number} = this.state;
        e.preventDefault();
        e.stopPropagation();
        func(number, 'addcompanyclose', param ? this.state : false);

    }

    _name = (e, name, add) => {
        let res = {};
        res[name] = e.target.value;
        if (add) res[add] = '';
        this.setState(res);

    }

    _addMail = () => {
        let {mail, fname, lname, number} = this.state;
        let {func} = this.props;
        if (!isEmail(mail)) {
            if (func(number, 'addbymail', {mail, fname, lname}))
                this.setState({mailError: transL('invitationExistsError')});
            else this.setState({mail: '', fname: '', lname: ''});
        } else {
            this.setState({mailError: transL('errorEmail')});
        }
    }

    componentDidMount() {
        let {func} = this.props;
        let info = func(0, 'getcompanyinfo')
        this.setState(info);

        let scrollIntoViewOptions = {
            behavior: 'smooth',
            block: 'center'
        }
        this.divRef.scrollIntoView(scrollIntoViewOptions);

        if (info.name) {
            this.setState({isNew: false})
        }
    }

    render() {
        let {func} = this.props;
        let {name, mail, mailError, fname, lname, nameError, role,
            country, state1, zip, aline1, aline2, number, isNew} = this.state;

        isNew = this.state.members ? false : isNew
        let isYourFirm = (number === 0)

        return (
            <div styleName='screen addTeamPosition'>
                <div styleName='addTeam'
                     ref={(f) => {this.divRef = f;}}>
                    <span className='close-cross' onClick={e => this._cancel(e)}>+</span>
                    <div className="h1" styleName={cx('title', {'_woMargin': !isNew})}>
                        {isNew ? 'Add New Company' : 'Edit Company'}</div>
                    <div styleName='addTeamsCont'>
                        <div styleName='addTeamsPart'>
                            <div className='h2'>Company</div>
                            <InputBox placeholder="Company Name"
                                      func={e => this._name(e, 'name', 'nameError')} value={name} error={nameError}/>
                            <div className='space40'></div>
                            <div className='h2'>Role</div>
                            <InputBox placeholder="Role" func={e => this._name(e, 'role')} value={role}/>
                            <div className='space40'></div>
                            <div className='h2'>Details</div>
                            <InputBox placeholder="Country" func={e => this._name(e, 'country')} value={country}/>
                            <InputBox placeholder="State" func={e => this._name(e, 'state1')} value={state1}
                                      inputWidth='190px' margin='10px 10px 10px 0' display="inline-block"/>
                            <InputBox placeholder="Zip" func={e => this._name(e, 'zip')} value={zip}
                                      inputWidth='120px' margin='10px 0' display="inline-block"/>
                            <InputBox placeholder="Address Line 1" func={e => this._name(e, 'aline1')}
                                      value={aline1} margin='0 0 10px 0'/>
                            <InputBox placeholder="Address Line 2" func={e => this._name(e, 'aline2')} value={aline2}/>
                        </div>
                        <div styleName='addTeamsPart'>
                            <div className='h2'>Teams for this Project</div>
                            <div className='space40'></div>
                            <div className='h3'>Invite a New Participant</div>
                            <InputBox placeholder="Email" margin='10px 0 0 0'
                                      func={e => this._name(e, 'mail', 'mailError')} value={mail} error={mailError}/>
                            <InputBox placeholder="First Name" func={e => this._name(e, 'fname')} value={fname}
                                      inputWidth='155px' margin='10px 9px 20px 0' display="inline-block"/>
                            <InputBox placeholder="Last Name" func={e => this._name(e, 'lname')} value={lname}
                                      inputWidth='155px' margin='10px 0 20px 0' display="inline-block"/>
                            <div styleName='buttons'>
                                <Button text="Add to this Project"
                                        mod='blue arch' callback={() => this._addMail()}/>
                                {/*<Button text="Add a new team"*/}
                                {/*        mod='blue arch' callback={() => func(number, 'addCompanyteam')}/>*/}
                            </div>
                            <div className='space50'></div>
                            <div className='h2'>People added to the Team</div>
                            {func(number, 'getMembers', {type: 'listUser', isYourFirm})}
                        </div>
                    </div>
                    <Button text={transS('Save Changes')}
                            mod='blue fill' callback={e => this._cancel(e, 1)}/>
                </div>
            </div>
        );
    }

}

export default AddCompany;
