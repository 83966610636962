import { put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { paragraphConstants } from '../../services/constants';
import api from '../../services/api/api';
import { getParagraphListSuccess } from '../../services/actions/paragraphActions';
import { socketConstants } from '../../../server/configs/appConfig';

function * getParagraphList(action) {
  const state = yield select();

  let projectId = state.projectReducer.currentProject ?
    state.projectReducer.currentProject._id
    : action.projectId,
    documentId = state.document.currentDocument ?
      state.document.currentDocument.coreDocument
    : action.documentId;
  let user = state.userReducer.user;
  if (window.location.pathname.includes('/template'))
       projectId = null
  try {
  // todo briefly the same
    if (action.enterTheDocument) {
        yield window.socket.emit(socketConstants.ENTER_THE_DOCUMENT, { userGroup: state.userReducer.user.userGroup, projectId: projectId, documentId: documentId });
        //if (!state.document.currentDocument ) yield put(setCurrentDocumentAction(action.documentId));  
    }
  
    //let paragraphListResp = yield api.getParagraphList(action.historyDocumentId ? action.historyDocumentId : action.documentId, projectId, 0);
    let paragraphListResp = yield api.getParagraphList(action.documentId, projectId, action.historyDocumentId ? 1 : 0 );
      
    if (paragraphListResp) {
       yield put(getParagraphListSuccess(paragraphListResp.data));

        yield window.socket.emit(socketConstants.JOIN_TO_GROUP, state.userReducer.user.userGroup);
    }
    return;
  } catch (e) {
    console.log('getParagraphList error');
    console.dir(e);
    yield put(push('/not-found/404/'));
  }
}

export default function * paragraphSaga() {
  yield takeLatest(paragraphConstants.GET_PARAGRAPH_LIST, getParagraphList);
}
