import React, {Component} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import cssModules from 'react-css-modules';
import styles from './projectPage.css';
import api from '../../../../services/api/api';
import {cloneDeep, isEqual} from 'lodash';
import Button from '../../../../components/button/Button';
import Checkbox from '../../../../components/checkbox/Checkbox';
import {PlusSign} from '../../../../components/plusSign/PlusSign';
import Flow from './Flow';
import Team from './Team';
import SmallUser from './SmallUser';
import Workspace from './Workspace';
import AddTeam from './addTeam';
import AddCompany from './addCompany';
import EditableLabel from '../../../../components/input/Editable';
import UserListItem from '../createProjectDropDown/UserListItem';
import {RenamePopup} from '../../views/TemplatesList/RenamePopup';
import {
    addProjectAction,
    getInvitePopupAction,
    inviteToProject,
    updateProjectAction
} from '../../../../services/actions/projectActions';
import {showPaymentAction} from '../../../../services/actions/userActions';
import {getPopUpAction, toggleLoaderAction} from '../../../../services/actions/dataActions';
import SelectCompany from "./selectCompany";
import Dropzone from 'react-dropzone';
import {
    block5PermissionCheck,
    docs5PermissionCheck,
    fileFormatValidation,
    fileSizeValidation,
    oldPermissionCheck
} from '../../../../common/validation';
import Spinner from "../../../../components/spinner/Spinner";
import AddMembers from "./AddMembers"
import {eventSubtypes} from "../../../../services/helpers/logger"
import Hint from "../../../../components/button/Hint"
import {popUpReset, showPopupManyFiles, universalDrop} from "../../../../services/helpers"
import UploadPopup from '../../views/TemplatesList/UploadPopup'
import { transS } from '../../../../services/helpers/lang';

@connect(state => ({
    user: state.userReducer.user,
    knownCompaniesUser: state.userReducer.user.knownCompanies,
    currentProject: state.projectReducer.currentProject,
    projectList: state.projectReducer.projectList,
    invitePopup: state.projectReducer.invitePopup,
    showSidebar: state.userReducer.showSidebar,
}), {
    getInvitePopupAction,
    inviteToProject,
    addProjectAction,  
    updateProjectAction,
    showPaymentAction,
    toggleLoaderAction,
    getPopUpAction
})

@cssModules(styles, {
    allowMultiple: true,
    handleNotFoundStyleName: 'throw'
})

class ProjectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            selectedPage: 0,
            selectedFlow: 0,
            isNewProject: true,
            cProject: null,
            projectTitle: '',
            changed: false,
            showTeam: null,
            showCompany: null,
            showAddMembers: null,
            showSelectCompany: null,
            showTeamInCompany: null,
            lockRemove: false,
            dataReady: false,
            transferFiles: props.transfer ? [...props.transfer.files] : [],
            transferNames: props.transfer ? [...props.transfer.names] : [],
            admins: [],
            signee: [],
            isDragActive: false,
            justAddedUsers: [[], []],
            deletedUsers: [[], []],
            sendInvitesProcess: [],
            isOpenUploadPopup: false
        }
        this._titleChanged = this._titleChanged.bind(this);
        this._isSmthChanged = this._isSmthChanged.bind(this);
        this._maketeams = this._maketeams.bind(this);
        this._makeworkspaces = this._makeworkspaces.bind(this);
        this._teamFunc = this._teamFunc.bind(this);
        this._flow = this._flow.bind(this);
        this._make_clear_candidates = this._make_clear_candidates.bind(this);
        this.dropzoneTimeout = null;
        this._renamePopupClose = this._renamePopupClose.bind(this);
        this._renamePopupSave = this._renamePopupSave.bind(this);
    }
    
    _renamePopupClose() {
        this.setState({renamePopupOpen:null});
    }
    
    _renamePopupSave(newVal, oldVal, isTemplate) {
        let splitted = oldVal.split("."), 
           ext = (isTemplate || splitted.length === 1) ? '' : splitted.pop(),
           name = newVal + (ext ? '.' +  ext : '');
        if (this.state.transferNames.find(el => el === name)) {
           this.setState({renamePopupOpen:null});
           return;
        }
        let transferNames = this.state.transferNames.map (
           el => el === oldVal ? name : el);
        this.setState({renamePopupOpen:null, transferNames});
    }

    _titleChanged(data) {
        this.setState({
            projectTitle: data
        }, () => this._isSmthChanged());
    }

    _isSmthChanged() { //@recheck нужно ли???
        let {user} = this.props;
        let {
            userList, isNewProject, cProject, projectTitle, groupList,
            changed, selectedPage, selectedFlow, workflow, wf, errorString, admins, signee
        } = this.state;
        let calc = false, error = '';
        const isMember = (id, a) => a && a.find(item => (item._id === id && !item.deleted));
        if (projectTitle) {
            calc = (!cProject || cProject.title !== projectTitle);
            calc = calc || (cProject.admins && admins && !isEqual(cProject.admins, admins))
            calc = calc || (cProject.signee && signee && !isEqual(cProject.signee, signee))
        } else error = "Project title can't be empty";
        calc = calc || (workflow && wf && !isEqual(workflow, wf));
        if (calc && workflow) {
            if (!isMember(user._id, workflow.members[0]) && groupList.includes(0)
              || !groupList.includes(0) && !isMember(user._id, workflow.members[groupList[0]])) {
                if (!workflow.internals) error = "You are to be a member of project at Your Company";
                else if (workflow.abstractTeams.find((el, i) =>
                    (workflow.internals & (1 << i)) && isMember(user._id, workflow.members[i])) === undefined)
                    error = "You are to be a member of at least one team at Your Company";
            }
        }
        if (calc !== changed || errorString !== error)
            this.setState({changed: calc, errorString: error});
    }

    _cancel = e => {
        let {getInvitePopupAction} = this.props;
        e.preventDefault();
        e.stopPropagation();
        //this._updateProject()
        getInvitePopupAction(false);
    }
    
    _drop = (accepted, rejected) => {
        const { user, projectList, getPopUpAction, showPaymentAction } = this.props;
        this.setState({isOpenUploadPopup: false});
        let files = [...accepted, ...rejected];
        let count = docs5PermissionCheck(projectList);
        if ((user.ticks <= 0) && block5PermissionCheck(count+files.length+this.state.transferNames.length)) 
            return getPopUpAction(showPopupManyFiles(getPopUpAction, showPaymentAction));
        universalDrop(files, (tfiles, tnames) => {
            this.setState({
                   transferFiles:[...this.state.transferFiles, ...tfiles],
                   transferNames:[...this.state.transferNames, ...tnames]
               });
        }, this.state.transferNames);
    }
    
    _deleteDocumentFromUpload = (index) => {
        const transferFiles = JSON.parse(JSON.stringify(this.state.transferFiles))
            .filter((_, i) => i !== index);
        const transferNames = JSON.parse(JSON.stringify(this.state.transferNames))
            .filter((_, i) => i !== index);   
        this.setState({transferFiles, transferNames})
    }

    _renameDocumentInUpload = (index, newName) => {
        const transferNames = JSON.parse(JSON.stringify(this.state.transferNames))
        transferNames[index] = newName
        this.setState({transferNames})
    }

    _addDocumentFromTemplate = (template) => {
        const {workflow, wf} = this.state
        const {_id, title, team} = template
        
        if (this.state.transferNames.includes(template.title)) return;
        this.setState({transferNames: [...this.state.transferNames, template.title],
          transferFiles: [...this.state.transferFiles, template]})
        return;
        // todo remove unused

        const transferFiles = JSON.parse(JSON.stringify(this.state.transferFiles))
        transferFiles.push({templateId:_id, title})
        const transferNames = JSON.parse(JSON.stringify(this.state.transferNames))
        transferNames.push(title)

        const admins = JSON.parse(JSON.stringify(this.state.admins))
        const signee = JSON.parse(JSON.stringify(this.state.signee))
        const userList = JSON.parse(JSON.stringify(this.state.userList))
        let w = workflow ? workflow : wf
        if (!workflow) w = cloneDeep(w)
        team.filter(user => !w.members[0].find(el => el._id === user.user._id))
            .forEach(user => {
                w.members[0].push({_id: user.user._id, prove: user.approver, rep: false})
                if (!userList[user.user._id]) userList[user.user._id] = {...user.user, candidate: 1}
                if (user.admin) admins.push(user.user._id)
                if (user.signer) signee.push(user.user._id)
            })
        this.setState({wf: w, transferFiles, transferNames, userList, admins, signee})
    }

    _updateProject = (e) => {
       let {inviteToProject,
          updateProjectAction, toggleLoaderAction, currentProject, projectList, invitePopup, user} = this.props;
        let {cProject, wf, workflow, projectTitle, userList, admins, signee, deletedUsers} = this.state;

        let canTeam = cProject.canTeam;
        if (this.state.isNewProject || !admins.find(el => el === user._id) && !canTeam) return
        if (this.props.inSideBar && workflow == null && isEqual(cProject.admins, admins) && isEqual(cProject.signee, signee) && cProject.title === projectTitle) return

        const step2 = () => {
          if (!workflow) return step3();

          let checkedUsers = [[], []], addedUsers = [[], []], deletedExternals = [...deletedUsers[1]], deletedInternals = [...deletedUsers[0]],
            proved = [], provedAdded = [], changed = false;
          const scanFlow = (i, del) => {
            if (!!del.length) changed = true
            if (!workflow.members[i]) return;

            workflow.members[i].forEach(el => {
               let e = wf.members[i] && wf.members[i].find(f => f._id === el._id);
               if (el.deleted) {
                  if (e) {
                    del.push(el._id) ;
                    changed = true;
                  }
               } else {
                  if (e) {
                    if (el.prove) proved.push(el._id);                  
                    if (e.prove !== el.prove) {
                        checkedUsers[i].push(el._id);
                        changed = true;
                    }
                  } else {
                    // new memeber
                    changed = true;
                    if (el._id.includes('@')) {
                      let item = userList[el._id], uname = item.email;
                      if (item && (item.firstname || item.lastname))
                        uname = `${item.firstname} ${item.lastname} <${item.email}>`;
                      addedUsers[i].push(uname);
                      if (el.prove) provedAdded.push(el._id);
                    } else {
                      checkedUsers[i].push(el._id);
                      if (el.prove) proved.push(el._id);
                    }
                  }
               }
            });
          };

          if (!canTeam) scanFlow(0, deletedInternals);
          scanFlow(1, deletedExternals);
          if (!changed) return step3();
          if (this.props.inSideBar) toggleLoaderAction(true);

          inviteToProject({ projectId: cProject._id, checkedUsers, addedUsers, deletedExternals, 
             deletedInternals, proved, provedAdded, canTeam })
          .finally(resp => step3());  
        };
        const step3 = () => {

            if ( !(cProject.title === projectTitle && isEqual(cProject.admins, this.state.admins) && isEqual(cProject.signee, this.state.signee)) ) {
                updateProjectAction(cProject._id, projectTitle, this.state.admins, this.state.signee)
            } else {
                if (this.props.inSideBar) toggleLoaderAction(false)
            }


            if (this.props.inOneProject && window.location.hash.includes('companies&teams')) {
                let w = workflow ? cloneDeep(workflow) : cloneDeep(wf);
                let admins = this.state.admins.slice()
                let signee = this.state.signee.slice()
                let justAddedUsers = cloneDeep(this.state.justAddedUsers)
                w.members = w.members.map((members, group) => members.filter(user => {
                    if (user.deleted) {
                        admins = admins.filter(u => u !== user._id)
                        signee = signee.filter(u => u !== user._id)
                        justAddedUsers[group] = justAddedUsers[group].filter(u => u !== user._id)
                    }
                    return !user.deleted
                }))
                this.setState({workflow: w, admins, signee, justAddedUsers})
            }
        }

        if (this.props.inSideBar) toggleLoaderAction(true);
        step2();
    };
    
    _createProject = (e, sendInvitation) => {
        let {getInvitePopupAction, addProjectAction, toggleLoaderAction} = this.props;
        let {workflow, wf, projectTitle, userList, admins, transferFiles, transferNames, signee} = this.state;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        getInvitePopupAction(false);

        toggleLoaderAction(true);

        return addProjectAction({ 'title': projectTitle, userlist: userList, admins, signee,
          workflow: workflow ? workflow : wf, transferFiles, transferNames})
           .then(project => {
            if (sendInvitation != null) this.sendInvitationProject(sendInvitation, project._id)
            toggleLoaderAction(false);
          })
          .catch(resp => {
            const { 'data': { err } } = resp.response;
            this.setState({ errorString: err });
          });
    };

    _makeworkspaces() {
        let {workflow, wf, showTeam, showCompany, showSelectCompany, showTeamInCompany, isNewProject, changed, transferNames, errorString, showAddMembers} = this.state;
        let {user} = this.props;
        let w = workflow ? workflow : wf, result = [], i;
        if (!w.simplest) {
            for (i = 0; i < w.workspaces.length; i++)
              result.push(<Workspace key={i} number={i} flow={w} func={this._teamFunc}/>);
            result.push(
            <div styleName='teamCommandLine1' key={i}>
                <span styleName='teamCommand' onClick={() => this._teamFunc(0, 'addworkspace')}>Add a Workspace</span>
            </div>
        )}
        return (
            <React.Fragment>
            <div styleName='wsAbsolute'>
                {result}
            </div>
                {(showAddMembers !== null) && <AddMembers isNewProject={isNewProject}
                                                          //inOneProject={this.props.inOneProject}
                                                          func={this._teamFunc}
                                                          number={showAddMembers}
                                                          flow={w}
                                                          justAddedUsers={this._make_justAddedUsers(showAddMembers, true)}
                />}
                {(showTeam !== null) && <AddTeam func={this._teamFunc}
                                                 number={showTeam}
                                                 showTeamInCompany={showTeamInCompany}
                                                 flow={w} user={user}
                                                 isShowUpdateProjBtn={!isNewProject && (changed || !!transferNames.length) && !errorString}
                                                 isShowCreateProjBtn={isNewProject && !errorString }
                                                 updateProjBtnCallback={this._updateProject}
                                                 createProjBtnCallback={this._createProject}
                                                 errorString={errorString}
                />}
                {(showSelectCompany !== null && <SelectCompany func={this._teamFunc}/>)}
                {(showCompany !== null) && <AddCompany func={this._teamFunc}/>}
            </React.Fragment>
        );
    }

    _maketeams(canEdit, canTeam) {
        const {user, currentProject} = this.props
        let {workflow, wf, groupList, isNewProject, userList, admins, justAddedUsers} = this.state;
        let w = workflow ? workflow : wf, result = [], ints, intGroup = groupList.includes(0);

        const isExt = w.members[1] && w.members[1].length && w.members[1].find(el => user._id === el._id)
        let isAllInvitedByYou = !!justAddedUsers[1].length && justAddedUsers[1].map(el => userList[el].invitedBy === user._id).every(el => el)

        const sendInvitesPermissionInt = !!admins.find(el => el === user._id) && !justAddedUsers[0].find(el => el === user._id)
        const sendInvitesPermissionExt = isExt && isAllInvitedByYou && !justAddedUsers[1].find(el => el === user._id)
        const features = user.company?.features,
          link = features && sendInvitesPermissionInt && !isNewProject 
            && features.includes('link') 
            && `${document.location.origin}/api/projects/member/${currentProject._id
              }/${user._id}`

        for (let i = 0; i < w.abstractTeams.length; i++)
            if (!((w.deletedTeams | w.internals) & (1 << i))) {
                ints = [];
                if (!i && w.internals) {
                    for (let j = 0; j < w.abstractTeams.length; j++)
                        if (w.internals & (1 << j)) ints.push(j);
                }
                if (intGroup || groupList.includes(i))
                result.push(<Team number={i} internalTeams={ints} func={this._teamFunc} selectedFlow={this.state.selectedFlow}
                                  key={i} simplest={w.simplest} count={w.workspaces ? w.workspaces.length : 0}
                                  sendInvitationProject={this.sendInvitationProject}
                                  isSendInvitesProcess={this.state.sendInvitesProcess.find(e => e === i) !== undefined}
                                  justAddedUsers={this._make_justAddedUsers(i)}
                                  sendInvitesPermission={isExt ? sendInvitesPermissionExt : sendInvitesPermissionInt}
                                  isNewProject={isNewProject}
                                  link={!i && link}
                                  locked={!canEdit} canTeam={canTeam}/>);
            }
        return result;
    }

    _make_justAddedUsers = (i, isInPopup) => {
        const {justAddedUsers, wf, workflow, admins, userList, isNewProject, cProject, signee} = this.state
        const {user} = this.props
        const w = workflow || wf
        if (!this.state.justAddedUsers[i].length) return null

        const result = justAddedUsers[i].map(el => {
            const u = w.members[i].find(u => u._id === el)

            const canEdit = isNewProject || (cProject.admins && !!cProject.admins.find(i => i === user._id))
            if (!userList[el]) return ''
            return <SmallUser user={userList[el]}
                              project={this.props.currentProject}
                              resendInvite={() => api.sendInvitationProject(i, this.props.currentProject._id, el)}
                              userId={user._id}
                              myUserGroup={this.props.currentProject.group}
                              isJustAdded={true}
                              number={i}
                              isInPopup={isInPopup}
                              key={'ja' + el}
                              canEdit={canEdit}
                              prove={u ? u.prove : false}
                              admin={!!admins.find(uId => uId === el)}
                              signee={!!signee.find(uId => uId === el)}
                              func={this._teamFunc} />
        })
        return <div styleName='justAddedUsers_list'>{result}</div>
    }

    _make_clear_candidates(i, mask, w, filter) {
        let result = [];
        let {user} = this.props;
        let {userList, workflow} = this.state;
        for (let key in userList) {
            if (!userList.hasOwnProperty(key)) continue
            if ( !(userList[key].candidate & mask) && !(user && mask === 1 && key === user._id) ) continue

            let isInTeam = false
            w.members.forEach(members => isInTeam = isInTeam || !!members.find(el => el._id === key))
            if (isInTeam) continue

            const user = userList[key]
            if (!`${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`.includes(filter) && !user.email.toLowerCase().includes(filter)) continue

            result.push(
                <SmallUser user={user}
                           isAddListItem={true}
                           noCheck={true}
                           number={i}
                           func={this._teamFunc}
                           key={i + '-' + key}/>
            );

        }


        return result.length ? (<div styleName='listUser'>{result}</div>) : '';
    }

    _addCompanyMember(i, id, name) { // adds member (user or team) to editedCompany.checkedItem
        let {editedCompany} = this.state;
        const zpush = (cont, id) => {
            let item = cont.find(i => i._id === id);
            if (item) item.deleted = false;
            else cont.push({id});
        };
        const find_id = cont => !!cont.members.find(x => x._id === id);
        if (!editedCompany.members) editedCompany.members = [];
        if (!name && find_id(editedCompany.checkedItem ? editedCompany.checkedItem : editedCompany))
            return;
        let newName = name ? {
            name, members: [],
            _id: editedCompany.checkedItem
                ? editedCompany.checkedItem._id + '-' + editedCompany.checkedItem.members.length
                : editedCompany.members.length
        } : {_id: id};
        if (editedCompany.checkedItem) {
            editedCompany.checkedItem.members.push(newName);
            editedCompany.checkedItem.expanded = true;
        } else
            editedCompany.members.push(newName);
        this.setState({editedCompany});
    }

    _membersMap(container, removed, noWF) {
        let {workflow, wf, userList, editedCompany, lockRemove} = this.state;
        let w = workflow ? workflow : wf;
        const makeString = x =>
            !x ? '2' :
                x.name ? '0' + x.name :
                    '1' + (userList[x._id].firstname ? userList[x._id].firstname : '') + ' '
                    + (userList[x._id].lastname ? userList[x._id].lastname : '')
                    + ' ' + userList[x._id].email;
        const compareItems = (a, b) =>
            makeString(a).localeCompare(makeString(b));
        const isChecked = (item, isUser) =>
            noWF === undefined
                ? (isUser ? !item.deleted && !removed
                : item === editedCompany.checkedItem)
                : !!w.members[noWF] && !!w.members[noWF].find(el => !el.deleted && el._id === item._id);
        const changeChecked = (item, isUser) => {
            if (noWF === undefined) {
                if (isUser) {
                    if (removed) return;
                    item.deleted = !item.deleted;
                } else
                    editedCompany.checkedItem = (editedCompany.checkedItem === item)
                        ? null : item;
                this.setState({editedCompany});
            } else {
                this._teamFunc(noWF, 'removeUser', isUser ? userList[item._id] : item);
            }
        };
        return (
            <div styleName='listUser'>
                {!!container && !!container.members && container.members
                    .map(el => el)
                    .sort((a, b) => compareItems(a, b))
                    .map(item =>
                        (!item) ? false :
                            (item.name ?
                                (
                                    <div styleName='listCompany' key={item._id}>
                                        <label styleName='listCompanyLeft'>
                                            {(removed || item.deleted) ?
                                                <div styleName="SU_cross big_cross" onClick={() => {
                                                    if (removed) return;
                                                    delete item.deleted;
                                                    this.setState({editedCompany});
                                                }}>+</div>
                                                : <Checkbox checked={isChecked(item)}
                                                            callback={() => changeChecked(item)}
                                                />
                                            }
                                        </label>
                                        <div styleName='listCompanyRight'>
                                            <div styleName='listCompanyRightCont'>
                                                {noWF === undefined ?
                                                    <EditableLabel text={item.name}
                                                                   inputFontSize='14px'
                                                                   inputFontWeight='700'
                                                                   inputMaxLength='40'
                                                                   inputBorder='none'
                                                                   inputPlaceHolder='Enter the team name'
                                                                   onFocus={() => this.setState({lockRemove: true})}
                                                                   onFocusOut={data => {
                                                                       item.name = data;
                                                                       this.setState({
                                                                           editedCompany,
                                                                           lockRemove: false
                                                                       });
                                                                   }}
                                                    /> :
                                                    <div>{item.name}</div>
                                                }
                                                {!!item.members && !!item.members.length &&
                                                (<div styleName={cx('listCompanyDown', {'_up': item.expanded})}
                                                      onClick={() => {
                                                          item.expanded = !item.expanded;
                                                          this.setState({editedCompany});
                                                      }} />)}
                                                {!lockRemove && !removed && (noWF === undefined) &&
                                                <span onClick={() => {
                                                    // delete container.members[container.members.findIndex(i => i && i._id === item._id)];
                                                    if (item.deleted) delete item.deleted;
                                                    else item.deleted = true;
                                                    this.setState({editedCompany});
                                                }}>{item.deleted ? 'Undo remove' : 'Remove team'}</span>}
                                            </div>
                                            {!!item.members && !!item.members.length && !!item.expanded
                                            && this._membersMap(item, item.deleted || removed, noWF)}
                                        </div>
                                    </div>)
                                : (<UserListItem
                                    user={userList[item._id]}
                                    checked={isChecked(item, 1)}
                                    key={item._id}
                                    _checkUser={() => changeChecked(item, 1)}
                                />)))}
            </div>)
    }

    _teamFunc(i, action, param) {
        let {
            workflow, wf, groupList, userList, showCompany, editedCompany,
            knownCompanies, isNewProject, cProject,
        } = this.state;
        let {user} = this.props;
        let w = workflow ? workflow : wf, counter = 0, item;

        const teamObj = (path) => {
            let result = '', cont = knownCompanies[w.companies[i]], a = ('' + path).split('-');
            result = a.reduce((acc, el) => {
                cont = cont.members[el];
                let r = acc ? acc + ' / ' + cont.name : cont.name;
                return r
            }, '');
            return {_id: path, companyTeam: result};
        };
        switch (action) {
            case "getName":
                return (groupList.includes(0) || !w.simplest) ? transS(w.abstractTeams[i]) : transS('Your Firm');
            case "getCompany":
                return (!!w.companies && !!w.companies[i] && knownCompanies[w.companies[i]].name);
            case "getcompanyinfo":
                return {...editedCompany, ...{number: showCompany}};
            case "getCandidates":
                let realCo = !param || param.isInternal ? 0 : i,
                    no = w.companies && (w.companies[realCo] === 0 || w.companies[realCo] > 0)
                        ? w.companies[realCo] : -1,
                    membersExists = (no >= 0) && knownCompanies[no]
                        && knownCompanies[no].members && knownCompanies[no].members.length
                        && (!param || !param.isInternal || knownCompanies[no].members.length > 1);
                if (!param) {
                    if (membersExists)
                        this.setState({
                            editedCompany: cloneDeep(knownCompanies[no])
                        });
                    return;
                }
                if (!param.list)
                    return membersExists;
                let mask = (!param.isInternal && i) ? 2 : 1;
                if (membersExists) {
                    return this._membersMap(editedCompany, false, i);
                } else return this._make_clear_candidates(i, mask, w, param.filter);
            case "canAddSubTeams":
                return w.expandable && groupList.includes(i);
            case "teamChangeName":
                if (!param || param === w.abstractTeams[i]) return;
                if (!workflow) w = cloneDeep(w);
                w.abstractTeams[i] = param;
                this.setState({workflow: w}, () => this._isSmthChanged());
                return;
            case "removeTeam":
                if (w.simplest) return;
                if (!workflow) w = cloneDeep(w);
                if (i > wf.abstractTeams.length) {
                    let j;
                    for (j = i; j < w.abstractTeams.length - 1; j++) {
                        w.abstractTeams[j] = w.abstractTeams[j + 1];
                        w.members[j] = w.members[j + 1]
                    }
                    delete w.abstractTeams[j];
                    delete w.members[j];
                    w.internals -= (1 << j);
                } else {
                    if (w.deletedTeams) w.deletedTeams += (1 << i);
                    else w.deletedTeams = (1 << i);
                    w.internals -= (1 << i);
                }
                this.setState({workflow: w}, () => this._isSmthChanged());
                return;
            case "repUser": {
                if (!workflow) w = cloneDeep(w);
                const repUser = w.members[i].map(u => (u._id === param._id || u._id === param.email) ? u : null)
                    .filter(u => u ? u : null)[0]

                if (repUser) repUser.rep = !repUser.rep

                let users = [...w.members, [i].map(u => (u._id === repUser._id || u._id === param.email) ? repUser : u)]

                return this.setState({[w]: {...w, members: users}})
            }
            case "proveUser": {
                if (user.temporary) {
                    this.props.getPopUpAction({name: 'auth'})
                    break;
                }
                if (!workflow) w = cloneDeep(w);

                w.members[i] = w.members[i].map(el => (el._id === param._id || el._id === param.email) ? {...el, prove: !el.prove} : el)
                setTimeout(this._updateProject, 10)
                return this.setState({workflow: w}, () => this._isSmthChanged())
            }
            case "adminUser": {
                if (user.temporary) {
                    this.props.getPopUpAction({name: 'auth'})
                    break;
                }
                const {adminUser, changeTo} = param // changeTo: true = add admin rights; false - remove admin rights
                let admins = this.state.admins.slice()

                const checkAdminRights = admins.find(i => i === user._id)
                if (!checkAdminRights || user._id === adminUser._id) return

                if (changeTo) admins.push(adminUser._id ? adminUser._id : adminUser.email)
                else admins = admins.filter(i => i !== adminUser._id && i !== adminUser.email)
                setTimeout(this._updateProject, 10)
                return this.setState({admins}, () => this._isSmthChanged())
            }
            case 'signeeUser': {
                if (user.temporary) {
                    this.props.getPopUpAction({name: 'auth'})
                    break;
                }
                const {signeeUser, changeTo} = param // changeTo: true = add admin rights; false - remove admin rights
                let signee = this.state.signee.slice()

                const checkAdminRights = this.state.admins.find(i => i === user._id)
                if (!checkAdminRights) return

                if (changeTo) signee.push(signeeUser._id ? signeeUser._id : signeeUser.email)
                else signee = signee.filter(i => i !== signeeUser._id && i !== signeeUser.email)
                setTimeout(this._updateProject, 10)

                return this.setState({signee}, () => this._isSmthChanged())
            }
            case "checkUser":
                counter = 1;
                break
            case "removeUser":
                if (!workflow) w = cloneDeep(w);
                let admins = this.state.admins.slice()
                let signee = this.state.signee.slice()
                let deletedUsers = cloneDeep(this.state.deletedUsers)

                if (isNewProject || param.isNotUpdate) {
                    item = w.members[i] && w.members[i].find(el => el._id === param.user._id || el._id === param.user.email);
                    const justAddedUsers = cloneDeep(this.state.justAddedUsers)
                    w.members[i] = w.members[i].filter(i => i._id !== item._id)
                    justAddedUsers[i] = justAddedUsers[i].filter(i => i !== item._id)
                    admins = admins.filter(i => i !== item._id)
                    signee = signee.filter(i => i !== item._id)
                    if (!isNewProject) deletedUsers[i].push(item._id)
                    return this.setState({workflow: w, admins, justAddedUsers, signee, deletedUsers}, () => this._isSmthChanged());
                } else {
                    item = w.members[i] &&
                        w.members[i].find(el => el._id === param.user._id || el._id === param.user.email);
                    if (!item)
                        item = this._fillWF(w, i, param.user._id, false, true);
                    if (counter) {
                        if (item.deleted) return;
                        item.prove = !item.prove;
                    } else {
                        if (item.deleted) {
                            delete item.deleted;
                            item.prove = false;
                            admins = admins.filter(i => i !== item._id)
                            signee = signee.filter(i => i !== item._id)
                        } else {
                            item.deleted = true;
                            admins = admins.filter(i => i !== item._id)
                            signee = signee.filter(i => i !== item._id)
                        }
                    }
                    this.setState({workflow: w, admins, signee}, () => this._isSmthChanged());
                    return setTimeout(this._updateProject, 10)
                }
            case "getCompanyMembers":
                return this._membersMap(editedCompany);
            case "addCompanyteam":
                this._addCompanyMember(i, user._id, 'New Team');
                break
            case "getMembers":
                if (!w.members || !w.members[i]) return '';
                let canEdit = isNewProject || (cProject.admins && !!cProject.admins.find(i => i === user._id))
                if (param.type === 'listUser') {
                    return <div styleName='listUser'>
                        {w.members[i].map(item =>
                            <UserListItem
                                number={i}
                                user={userList[item._id] ? userList[item._id] : teamObj(item._id)}
                                userId={user._id}
                                isYourFirm={param.isYourFirm}
                                prove={item.prove}
                                rep={w.simplest ? null : item.rep}
                                checked={!item.deleted} disabled={false}
                                locked={false}
                                maxNameWidth={181}
                                key={i + '-' + item._id}
                                func={this._teamFunc}
                                _proved={() => this._teamFunc(i, 'checkUser', item)}
                                _checkUser={() => this._teamFunc(i, 'removeUser', item)}
                                adminCheck={!i && canEdit}
                                admin={!!this.state.admins.find(u => u === item._id)}
                                signee={!!this.state.signee.find(u => u === item._id)}
                                noCheck={!canEdit}
                            />)}
                    </div>
                } else if (param.type === 'smallUser') {
                    const ul = []
                    w.members[i].forEach(item => {
                        if (this.state.justAddedUsers[i].find(el => el === item._id)) return
                        ul.push(<SmallUser number={i}
                                           project={this.props.currentProject}
                                           resendInvite={() => api.sendInvitationProject(i, this.props.currentProject._id, item._id)}
                                           isYourFirm={param.isYourFirm}
                                           user={userList[item._id] ? userList[item._id] : teamObj(item._id)}
                                           userId={user._id}
                                           myUserGroup={this.props.currentProject.group}
                                           prove={item.prove} deleted={item.deleted}
                                           rep={w.simplest ? null : item.rep}
                                           adminCheck={!i && canEdit}
                                           admin={!!this.state.admins.find(u => u === item._id)}
                                           signee={!!this.state.signee.find(u => u === item._id)}
                                           key={i + '-' + item._id}
                                           canEdit={canEdit}
                                           func={this._teamFunc}/>)
                    })
                    return ul
                }
                break
            case "addworkspace":
                if (w.simplest) return;
                if (!workflow) w = cloneDeep(w);
                if (w.workspaces.includes(0)) return;
                w.workspaces.push(0);
                this.setState({workflow: w}, () => this._isSmthChanged());
                return;
            case "checkworkspace":
                if (w.simplest) return;
                if (!workflow) w = cloneDeep(w);
                w.workspaces[i] = w.workspaces[i] ^ (1 << param);
                this.setState({workflow: w}, () => this._isSmthChanged());
                return;
            case "addcompanyclose":
                if (param) {
                    if (!param.name) param.name = 'Unknown company';
                    if (!w.companies) w.companies = [0];
                    if (!knownCompanies) {
                        knownCompanies = [{members: [{_id: user._id}]}];
                    }
                    if (showCompany && !w.companies[showCompany])
                        w.companies[showCompany] = knownCompanies.length;
                    knownCompanies[w.companies[showCompany]] = {...editedCompany, ...param};
                    this.setState({knownCompanies, workflow: w, showCompany: null});
                    //@ save company through api and check members (del, expand new etc)
                } else {
                    this.setState({showCompany: null});
                }
                return;
            case "addteamclose":
                this.setState({showTeam: null}, () => this._isSmthChanged());
                if (!param) return;
                if (!workflow) w = cloneDeep(w);
                if (param.name) { // add team
                    i = w.abstractTeams.length;
                    if (!w.internals) {
                        w.abstractTeams.push('Team');
                        w.members[i] = cloneDeep(w.members[0]);
                        w.internals = (1 << i);
                        i++;
                    }
                    w.abstractTeams.push(param.name);
                    w.internals += (1 << i);
                    w.members[i] = w.members[-1];
                    delete w.members[-1];
                } else { // undo
                    if (i === -1) delete w.members[i]
                    else w.members[i] = param.saved;
                }
                this.setState({workflow: w}, () => this._isSmthChanged());
                return;
            case "addMembers":
                if (user.temporary) {
                    this.props.getPopUpAction({name: 'auth'})
                    break;
                }
                this.setState({showAddMembers: param || i})
                break;
            case "closeAddMembers":
                this.setState({showAddMembers: null})
                if (!param.isNewProject) this._updateProject()
                break;
            case "editteam":
                this.setState({showTeam: i}, () => this._isSmthChanged());
                if (param !== null) {
                    this.setState({showTeamInCompany: param})
                }
                return;
            case "editcompany":
                this.setState({
                    showCompany: i,
                    editedCompany: (w.companies && knownCompanies && knownCompanies[w.companies[i]])
                        ? cloneDeep(knownCompanies[w.companies[i]])
                        : (i) ? {} : {members: [{_id: user._id}]}
                });
                return;
            case "selectCompany":
                this.setState({showSelectCompany: true,});
                return;
            case "selectCompanyClose":
                this.setState({showSelectCompany: null})
                return;
            case "addCompanybymail":
                for (let key in userList) {
                    if (!userList.hasOwnProperty(key)) continue
                    if (userList[key].email !== param.mail.toLowerCase()) continue
                    this._addCompanyMember(i, userList[key]._id)
                    return 0;
                }
                api.getUserByMail({email: param.mail}).then(res => {
                    item = res.data.data;
                    if (!item._id) item = null;
                    if (item) userList[item._id] = item;
                    else userList[param.mail.toLowerCase()] = {
                        email: param.mail,
                        _id: param.mail.toLowerCase(),
                        firstname: param.fname,
                        lastname: param.lname,
                        isActivated: false
                    };
                    this.setState({userList});
                    this._addCompanyMember(i, item ? item._id : param.mail.toLowerCase());
                    return 0;
                });
                return 0;
            case "addbymail":
                item = w.members[i] && w.members[i].find(el => (el._id === param.mail.toLowerCase() ||
                    userList[el._id].email.toLowerCase() === param.mail.toLowerCase()));
                if (item) return 1;
                if (!workflow) w = cloneDeep(w);
                if (!w.members[i]) w.members[i] = [];
                for (let key in userList) {
                    if (!userList.hasOwnProperty(key)) continue
                    if (userList[key].email !== param.mail.toLowerCase()) continue;
                    w.members[i].push({_id: key, prove: false, rep: false});
                    this.setState({workflow: w}, () => this._isSmthChanged());
                    return 0;
                }
                api.getUserByMail({email: param.mail}).then(res => {
                    item = res.data.data;
                    if (!item._id) item = null;
                    w.members[i].push({_id: item ? item._id : param.mail.toLowerCase(), prove: false, rep: false});
                    if (item) userList[item._id] = item;
                    else userList[param.mail.toLowerCase()] = {
                        email: param.mail,
                        firstname: param.fname,
                        lastname: param.lname,
                        isActivated: false
                    };
                    this.setState({userList, workflow: w}, () => this._isSmthChanged());
                    return 0;
                });
                return 0;
            case "addMember": {
                if (!workflow) w = cloneDeep(w);
                const arr = cloneDeep(this.state.justAddedUsers)
                const deletedUsers = cloneDeep(this.state.deletedUsers)

                deletedUsers[i] = deletedUsers[i].filter(el => el !== param._id && el !== param.mail)
                this.setState({deletedUsers})
                if (w.members[i] && !!w.members[i].find(el => userList[el._id].email === (param.mail && param.mail.toLowerCase()) || (param._id && userList[el._id]._id === param._id))) return 0

                if (param._id) {
                    arr[i].push(param._id)
                    if (!w.members[i]) w.members[i] = []
                    w.members[i].push({_id: param._id, prove: false, rep: false})
                    userList[param._id].invitedBy = user._id
                    this.setState({justAddedUsers: arr, workflow: w})
                    return 0
                } else {
                    for (let key in userList) {
                        if (!userList.hasOwnProperty(key) || userList[key].email !== param.mail.toLowerCase()) continue

                        if (!w.members[i]) w.members[i] = [{_id: key, prove: false, rep: false}]
                        else w.members[i].push({_id: key, prove: false, rep: false})
                        userList[key].invitedBy = user._id

                        arr[i].push(key)
                        this.setState({workflow: w, justAddedUsers: arr})
                        return 0
                    }

                    api.getUserByMail({email: param.mail}).then(res => {
                        item = res.data.data
                        if (!item._id) item = null

                        if (!w.members[i]) w.members[i] = [{_id: item ? item._id : param.mail.toLowerCase(), prove: false, rep: false}]
                        else w.members[i].push({_id: item ? item._id : param.mail.toLowerCase(), prove: false, rep: false})
                        arr[i].push(item ? item._id : param.mail)

                        if (item) userList[item._id] = item;
                        else userList[param.mail.toLowerCase()] = {
                            email: param.mail,
                            firstname: param.fname || '',
                            lastname: param.lname || '',
                            invitedBy: user._id,
                            isActivated: false
                        };

                        this.setState({userList, workflow: w, justAddedUsers: arr})
                        return 0
                    });
                }
                break
            }
            case 'updateUser': {
                if (!workflow) w = cloneDeep(w);
                let arr = cloneDeep(this.state.justAddedUsers)
                let admins = this.state.admins.slice()
                let signee = this.state.signee.slice()
                let userList = cloneDeep(this.state.userList)
                if (!param.user) return

                if (!param.user._id) {

                    if (param.user.email !== param.newData.email) {
                        delete userList[param.user.email]
                        arr = arr.filter(i => i !== param.user.email)
                        let isAdmin = false, isSignee
                        if (admins.find(i => i === param.user.email )) {
                            admins = admins.filter(i => i !== param.user.email)
                            isAdmin = true
                        }
                        if (signee.find(i => i === param.user.email )) {
                            signee = signee.filter(i => i !== param.user.email)
                            isSignee = true
                        }

                        for (let key in userList) {
                            if (!userList.hasOwnProperty(key)) continue
                            if (userList[key].email !== param.newData.email.toLowerCase()) continue

                            w.members[i].find(el => el._id === param.user.email)._id = key
                            arr[i].push(key)
                            this.setState({workflow: w, justAddedUsers: arr, userList})
                            if (isAdmin) admins.push(key)
                            if (isSignee) signee.push(key)
                            return 0
                        }
                        api.getUserByMail({email: param.mail}).then(res => {
                            item = res.data.data

                            if (!item._id) {
                                arr.push(param.newData.email)
                                w.members[i].find(el => el._id === param.user.email)._id = param.newData.email
                                userList[param.newData.email] = param.newData
                            } else {
                                arr.push(item._id)
                                userList[item._id] = param.item
                            }
                            if (isAdmin) {
                                if (!item._id) admins.push(param.newData.email)
                                else admins.push(item._id)
                            }
                            if (isSignee) {
                                if (!item._id) signee.push(param.newData.email)
                                else signee.push(item._id)
                            }
                            return this.setState({justAddedUsers: arr, workflow: w, admins, userList, signee})
                        })
                    } else {
                        userList[param.user.email].firstname = param.newData.firstname
                        userList[param.user.email].lastname = param.newData.lastname
                    }

                    this.setState({justAddedUsers: arr, workflow: w, admins, userList, signee})
                    return 0
                }

                api.updateUnregisteredUserInfo(userList[param.user._id], param.newData, this.props.currentProject._id).then(res => {
                    const newUserData = res.data.data
                    userList[param.user._id].firstname = newUserData.firstname
                    userList[param.user._id].lastname = newUserData.lastname
                    this.setState({userList})
                })
            }
        }
    }

    _fillWF = (wf, group, id, prove, deleted, rep) => {
        let result = {_id: id, prove, rep};
        if (deleted) result.deleted = true;
        if (!wf.members) wf.members = [];
        if (!wf.members[group]) wf.members[group] = [];
        wf.members[group].push(result);
        return result;
    };

    dragHandler = e => {
        const {isDragActive} = this.state
        let isInside = e.target === this.refWrapper || this.refWrapper.contains(e.target)
        switch (e.type) {
            case 'dragover':
            case 'dragenter':
                if (!isDragActive && isInside ) {
                    this.setState({isDragActive: true})
                } else if (isDragActive && !isInside) {
                    this.setState({isDragActive: false})
                }
                clearTimeout(this.dropzoneTimeout)
                break;
            case 'drop':
                this.setState({isDragActive: false})
                break;
        }
        this.dropzoneTimeout = setTimeout(() => this.setState({isDragActive: false}), 200)
    }

    componentDidMount() {
        let {user, invitePopup, currentProject, projectList, knownCompaniesUser} = this.props;
        let wf, tempList = {}, groupList = [];
        const fin_query = () => {
            // prepare companies members
            if (user.company) {
                // all company members + other candidates
                api.getCompanyMembers()
                    .then(res => {
                        let companyMembers = res.data.data._general.members
                        api.getCandidates()
                            .then(res => {
                                companyMembers.forEach(u => {
                                    tempList[u._id] = {... tempList[u._id], ...u};
                                    tempList[u._id].candidate = 1;
                                })
                                res.data && res.data.data.result && res.data.data.result.forEach((u, i) => {
                                    if (!tempList[u._id]) tempList[u._id] = {... tempList[u._id], ...u};
                                    tempList[u._id].candidate = res.data.data.groups[i];
                                });
                                this.setState({userList: tempList});
                            });
                    })
            } else {
                api.getCandidates().then(res => {
                    res.data && res.data.data.result && res.data.data.result.forEach((u, i) => {
                        if (!tempList[u._id]) tempList[u._id] = u;
                        tempList[u._id].candidate = res.data.data.groups[i];
                    });
                    this.setState({userList: tempList});
                });
            }
        };
        tempList[user._id] = user;
        this.setState({knownCompanies: knownCompaniesUser})
        if (this.props.inOneProject || invitePopup.projectId) {
            let c = currentProject && currentProject._id
                ? currentProject
                : projectList.filter(item => item._id === invitePopup.projectId)[0];
            wf = cloneDeep(c.workflow ? {...user.workflows[c.workflow.parent], ...c.workflow} : user.workflows[0]);
            this.setState({
                isNewProject: false,
                cProject: c,
                projectTitle: c.title,
                wf: wf,
                admins: c.admins,
                signee: c.signee
            }, () => {
                api.getUserGroup({projectId: c._id}).then(res => {
                    const data = res.data.data;
                    wf.members = [];
                    const justAddedUsers = cloneDeep(this.state.justAddedUsers)
                    data.forEach(ug => {
                        if (ug.user.isWaitInvitationMail) {
                            justAddedUsers[ug.group].push(ug.user._id)
                        }
                        if (!ug.user) return;
                        this._fillWF(wf, ug.group, ug.user._id, ug.prove)
                        if (ug.subgroup) {
                            JSON.parse(ug.subgroup).forEach(item => {
                                this._fillWF(wf, item.group, ug.user._id, item.prove);
                                groupList.push(item.group);
                            });
                        }
                        //ug.user.invitedBy = ug.invitedBy;
                        if (ug.user._id === user._id) groupList.push(ug.group);
                        tempList[ug.user._id] = {...ug.user, projStats: ug.projStats};
                    });
                    this.setState({userList: tempList, groupList, wf: wf, dataReady: true, selectedPage: 1, justAddedUsers});
                    fin_query();
                });
            });
        } else {
            wf = cloneDeep(user.workflows[0]);
            wf.members = [];
            // ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ????? ?????
            // this._fillWF(wf, 0, user._id, true) // то что было раньше
            this._fillWF(wf, 0, user._id, false, false, true)
            groupList.push(0);
            user.isActivated = true;
            const standardName = transS('New Project')
            let pNumber = projectList.reduce((a, i) => {
                let x = (new RegExp(`${standardName} (\\d\\d*)`)).exec(i.title);
                return x && ((+x[1]) >= a) ? (+x[1] + 1) : a
            }, 1);
            this.setState({userList: tempList, groupList, wf, projectTitle: `${standardName} ${pNumber}`, dataReady: true, admins: [user._id], signee: []});
            fin_query();
        }

        if (!this.props.inOneProject) {
            document.body.style.overflowY = 'hidden';
        }

        if (!this.props.inOneProject && !invitePopup.projectId) {
            window.addEventListener('dragenter', this.dragHandler)
            window.addEventListener('dragover', this.dragHandler)
            window.addEventListener('drop', this.dragHandler)
        }
        window.addEventListener('beforeunload', this._updateProject)
    };

    componentWillUnmount() {
        if (this.props.inOneProject) this._updateProject()
        document.body.style.overflowY = '';
        window.removeEventListener('dragenter', this.dragHandler)
        window.removeEventListener('dragover', this.dragHandler)
        window.removeEventListener('drop', this.dragHandler)
        window.removeEventListener('beforeunload', this._updateProject)
    }

    componentDidUpdate() {
        if (!this.props.inOneProject)
            document.body.style.overflowY = 'hidden';

        if (this.props.currentProject && this.state.cProject && this.props.currentProject !== this.state.cProject) {
            this.setState({cProject: this.props.currentProject,
                projectTitle: this.props.currentProject.title,
                admins: this.props.currentProject.admins,
                signee: this.props.currentProject.signee})
        }
    }

    _page = (no) => {
        let {selectedPage, workflow} = this.state;
        if (no !== selectedPage)
            if (selectedPage === 1 && workflow) workflow.workspaces = validate_workspaces(workflow);
        this.setState({selectedPage: no});
    };
    _chgPage = (i) => {
        let {selectedPage, workflow} = this.state;
        if (selectedPage === 1 && workflow) workflow.workspaces = validate_workspaces(workflow);
        this.setState({selectedPage: selectedPage + i});
    };

    _flow = (no) => {
        //@ prevent select flow now

        // let {user} = this.props;
        // let {selectedFlow} = this.state;
        // if (no !== selectedFlow) {
        //     let wf = cloneDeep(user.workflows[no]);
        //     wf.members = [];
        //     this._fillWF(wf, wf.defaultTeam ? wf.defaultTeam : 0, user._id, true);
        //     this.setState({
        //         selectedFlow: no,
        //         wf: wf,
        //         workflow: null
        //     });
        // }
    };


    deleteDocumentFromDownload = (e, trg) => {
        let {transferNames, transferFiles} = this.state
        const target = trg || e.target.offsetParent.textContent.slice(0, -1)
        const index = transferNames.findIndex(el => el === target)
        transferNames.splice(index, 1)
        transferFiles.splice(index, 1)
        this.setState({transferNames, transferFiles})
    }

    sendInvitationProject = (userGroup, createdProjectId) => {
        const {isNewProject} = this.state
        this.setState({ sendInvitesProcess: [...this.state.sendInvitesProcess, userGroup] })

        if (isNewProject && !createdProjectId) return this._createProject(null, userGroup)
        api.sendInvitationProject(userGroup, createdProjectId || this.props.currentProject._id)
            .then(() => {
                const justAddedUsers = cloneDeep(this.state.justAddedUsers)
                justAddedUsers[userGroup] = []
                this.setState({justAddedUsers})

                this.setState({ sendInvitesProcess: this.state.sendInvitesProcess.filter(e => e !== userGroup) })
            })
    }

    createDocList = () => {
        const {transferNames, transferFiles} = this.state;

        return <div styleName='docList' onClick={e => {e.preventDefault();e.stopPropagation()}}>
            {transferNames.map((doc, index) => {
                const isTemplate = typeof(transferFiles[index]) === 'object',
                  title = isTemplate ? doc : doc.replace(/\.[^.]+$/,'');
                return (
            <div styleName='docList-item' key={doc}>
                <div styleName='docFlex'>
                    <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">
                        <path fill="none" fillRule="evenodd" stroke="#ACACAC"
                              d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />
                    </svg>
                    <span>{doc}</span>
                </div>
                <Button text={transS('Rename')} mod='blue margin0' callback={() => this.setState({renamePopupOpen: doc})} />                                    
                <div className='close-cross' styleName='deleteBtn' onClick={() => this.deleteDocumentFromDownload(null, doc)}>+</div>
                {this.state.renamePopupOpen === doc &&
                   <RenamePopup title={title}
                      style={{left: '-20px', top: '0', width: 'max(100%, 200px)'}}
                      closeHandler={this._renamePopupClose}
                      saveHandler={val => this._renamePopupSave(val, doc, isTemplate)} />}
            </div>)}
            )}
        </div>
    }

    render() {
        let {
            isNewProject, cProject, projectTitle, dataReady, transferNames,
            changed, selectedPage, selectedFlow, errorString, showTeam, showCompany, showSelectCompany, isDragActive, isOpenUploadPopup
        } = this.state;
        let {user, showPaymentAction, projectList} = this.props;
        let counter = 0,
            showFlows = false, //@ constant to temporary prevent show WorkFlows
            canEdit = isNewProject || (cProject.admins && !!cProject.admins.find(i => i === user._id)),
            canTeam = cProject && cProject.canTeam,
            isProjectOwner = isNewProject || (cProject.admins && !!cProject.admins.find(i => i === user._id));

        if (this.props.inOneProject) {
            if (!dataReady) return <div style={{position: 'relative', width: '500px', height: '250px'}}><Spinner withoutLogo={true}/></div>
            return (
                <React.Fragment>
                    {(showTeam !== null || showCompany !== null || showSelectCompany !== null) &&
                    <div styleName='screen' />}
                    {isNewProject && showFlows && <div className='h2'>Workflow</div>}
                    {isNewProject && showFlows && <div styleName='workflows'>
                        {user.workflows.map(item =>
                            <Flow selected={selectedFlow === counter} item={item}
                                  callback={this._flow} no={counter} key={counter++}/>
                        )}
                    </div>}
                    <div styleName='teams'>
                        {this._maketeams(canEdit, canTeam)}
                        {this._makeworkspaces()}
                    </div>
                    {!!errorString && <span styleName='errorString'>{errorString}</span>}
                </React.Fragment>
            )
        }

        if (this.props.inSideBar) {
            if (!dataReady) return (<React.Fragment>
                    <div styleName={'projectPage-screen'} />
                <div styleName='wrapper'><div style={{width: '802px'}} styleName='drop-down'><Spinner /></div></div>
                </React.Fragment>
            )

            return <React.Fragment>
                <div styleName={'projectPage-screen'} />
            <div styleName='wrapper'>
                <div style={{width: 'fit-content'}} styleName='drop-down'>
                    <span className='close-cross' onClick={e => this._cancel(e)}>+</span>
                        {(showTeam !== null || showCompany !== null || showSelectCompany !== null) &&
                        <div styleName='screen' />}
                        <div>
                            {isNewProject && showFlows && <div className='h2'>Workflow</div>}
                            {isNewProject && showFlows && <div styleName='workflows'>
                                {user.workflows.map(item =>
                                    <Flow selected={selectedFlow === counter} item={item}
                                          callback={this._flow} no={counter} key={counter++}/>
                                )}
                            </div>}
                            <div className='h2'>{transS('Teams')}</div>
                            <div styleName='teams'>
                                {this._maketeams(canEdit, canTeam)}
                                {this._makeworkspaces()}
                            </div>
                        </div>
                        {!!errorString && <span styleName='errorString'>{errorString}</span>}
                </div>
            </div>
            </React.Fragment>
        }

        if (isNewProject) {
            if (!dataReady) return <div style={{position: 'relative', width: '500px', height: '250px'}}><Spinner withoutLogo={true}/></div>
            return <React.Fragment>
                <div styleName={cx('wrapper newProject', {'_zIndHigh': this.state.showAddMembers !== null, '_zIndLow': this.props.showSidebar})}
                     ref={e => this.refWrapper = e}>
                    <div styleName='project-title'>
                        <input value={projectTitle}
                               placeholder={transS('Project name')}
                               maxLength='256'
                               onBlur={() => this._titleChanged(projectTitle)}
                               onChange={e => this.setState({projectTitle: e.target.value})}
                        />
                        <div styleName='project-title-hint'>
                            <Hint text={transS('Click to rename')} /></div>
                    </div>
                    <div styleName='row'>
                        <div styleName={'dropzone' + (this.state.overDropZone ? ' _active'  : '')}
                          onClick={() => this.setState({isOpenUploadPopup: true}) }
                          onDragOver = { e => {
                            this.setState({overDropZone: true});
                            e.stopPropagation();
                            e.preventDefault();  } }
                          onDragLeave = {() => this.setState({overDropZone: false}) }
                          onDrop={(e) => {e.stopPropagation();
                               e.preventDefault();
                               this.setState({overDropZone: false});
                               this._drop(e.dataTransfer.files, []);} } >
                          <div styleName="dropscreen">
                            <div styleName='dropzone-title'>{transS('Documents')}</div>
                            {this.createDocList()}
                            <div styleName='plus'>
                                <PlusSign />
                                {transS('Upload a document')}
                            </div>
                          </div>
                        </div>
                        {isOpenUploadPopup &&
                        <UploadPopup dropHandler={this._drop}
                                     closeHandler={() => this.setState({isOpenUploadPopup: false})}
                                     createTemplateFromTemplate={this._addDocumentFromTemplate}
                                     transferNames={transferNames}
                        />}                       
                        <React.Fragment>
                            {(showTeam !== null || showCompany !== null || showSelectCompany !== null) &&
                            <div styleName='screen' />}
                            {showFlows && <div className='h2'>Workflow</div>}
                            {showFlows && <div styleName='workflows'>
                                {user.workflows.map(item =>
                                    <Flow selected={selectedFlow === counter} item={item}
                                          callback={this._flow} no={counter} key={counter++}/>
                                )}
                            </div>}
                            <div styleName='teams'>
                                {this._maketeams(canEdit)}
                                {this._makeworkspaces()}
                            </div>
                            {!!errorString && <span styleName='errorString'>{errorString}</span>}
                        </React.Fragment>
                    </div>
                    {!errorString && <Button
                        text={transS('Create Project')}
                        mod={selectedPage === 0 ? 'blue arch' : 'blue fill'} callback={this._createProject}/>}
                     <Button text={transS('Cancel')} mod='gray' callback={this._cancel} />
                </div>
                </React.Fragment>
        }

        //TODO CHECK this code is unreachable
        if (!dataReady) return <div styleName='wrapper'><div styleName='drop-down'><Spinner /></div></div>

        return (
            <React.Fragment>
                <div styleName='projectPage-screen' />
                <div styleName='wrapper' ref={e => this.refWrapper = e}>
                    {(showTeam !== null || showCompany !== null || showSelectCompany !== null) &&
                    <div styleName='screen' />}
                    <div styleName='drop-down'>
                        <span className='close-cross' onClick={e => this._cancel(e)}>+</span>
                        {isNewProject && <div className="h1" styleName='title'>Create New Project</div>}
                        <div styleName='project-name' className='h2'>
                            {isProjectOwner ?
                                <input styleName='project-title'
                                       value={projectTitle}
                                       placeholder={transS('Project name')}
                                       maxLength='256'
                                       onBlur={() => this._titleChanged(projectTitle)}
                                       onChange={(e) => this.setState({projectTitle: e.target.value})}
                                />
                                : projectTitle
                            }
                        </div>
                        <div>
                            <div styleName={cx('pages', {'_isSelected': selectedPage === 0})}
                                 onClick={() => this._page(0)}>{transS('Documents')}
                            </div>
                            <div styleName={cx('pages', {'_isSelected': selectedPage === 1})}
                                 onClick={() => this._page(1)}>{transS('Teams')}
                            </div>
                        </div>
                        <div styleName='aline' />
                        <div styleName='interline'>
                            {selectedPage === 0 && (oldPermissionCheck() && (user.ticks <= 0) ?
                                <Button
                                    text='You need to Upgrade to paid plan for all features'
                                    mod='blue'
                                    callback={() => showPaymentAction(true)}
                                /> : <div>
                                    <div>
                                        <div className='h2'>{transS('Documents')}</div>
                                        <Dropzone styleName={cx('add-project', {'active': isDragActive})}
                                                  onDrop={this._drop}
                                                  activeClassName="greyback"
                                                  accept='.doc, .docx'
                                                  onDragOver={this.dragHandler}>
                                            <PlusSign />
                                            {transS('Upload a document')}
                                        </Dropzone>
                                    </div>
                                    {!!transferNames.length && <div>
                                        <div className='h2'>{transS('Documents to upload')}</div>
                                        {transferNames.map(el =>
                                            <div styleName='acme' key={el}>
                                                <img src="../../../../images/icons-doc.png" alt='document'/><span>{el}</span>
                                                <div onClick={e => this.deleteDocumentFromDownload(e)} styleName='disDoc'>+</div>
                                            </div>
                                        )}
                                    </div>}
                                </div>)}
                            {selectedPage === 1 && <div style={{marginBottom: '40px'}}>
                                {isNewProject && showFlows && <div className='h2'>Workflow</div>}
                                {isNewProject && showFlows && <div styleName='workflows'>
                                    {user.workflows.map(item =>
                                        <Flow selected={selectedFlow === counter} item={item}
                                              callback={this._flow} no={counter} key={counter++}/>
                                    )}
                                </div>}
                                <div className='h2'>{transS('Teams')}</div>
                                <div styleName='teams'>
                                    {this._maketeams(canEdit)}
                                    {this._makeworkspaces()}
                                </div>
                            </div>}
                        </div>
                        <div styleName='aline' />
                        <div styleName='buttons'>
                            {selectedPage > 0 && <Button
                                text={`Previous—${selectedPage === 1 ? 'Documents' : transS('Teams')}`}
                                mod='blue arch' callback={() => this._chgPage(-1)}/>}
                            {/*{selectedPage < 2 && <Button*/}
                            {/*    text={`Next—${selectedPage === 1 ? 'Project Data & Settings' : 'Teams'}`}*/}
                            {/*    mod='blue fill' callback={() => this._chgPage(1)}/>}*/}
                            {selectedPage < 2 && selectedPage === 0 ? <Button
                                    text={`Next—${selectedPage === 1 ? 'Project Data & Settings' : transS('Teams')}`}
                                    mod='blue fill' callback={() => this._chgPage(1)}/>
                                : ''
                            }
                            {isNewProject && !errorString && <Button
                                text={transS('Create Project')}
                                mod={selectedPage === 0 ? 'blue arch' : 'blue fill'} callback={this._createProject}/>}
                            {!isNewProject && (changed || !!transferNames.length) && !errorString &&
                            <Button
                                text="Update Project"
                                mod={selectedPage === 0 ? 'blue arch' : 'blue fill'} callback={e => this._updateProject(e)}/>}
                            {!!errorString && <span styleName='errorString'>{errorString}</span>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const validate_workspaces = w => {
    let mask = w.deletedTeams ? w.deletedTeams : 0, x, chgd = false, result = [];
    for (let i = 0; i < w.workspaces.length; i++) {
        x = w.workspaces[i] - (w.workspaces[i] & mask);
        for (let calc = x; calc; calc = calc >> 1)
            if (calc & 1) {
                if (!(calc - 1)) x = 0;
                break;
            }
        if (result.includes(x)) x = 0;
        if (x) result.push(x);
        else chgd = true;
    }
    return chgd ? result : w.workspaces;
};

export default ProjectPage;
