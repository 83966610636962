import React, { useState } from 'react';
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import ConditionalOption from './ConditionalOption';
import { transS } from '../../../../../../services/helpers/lang';

const Conditional = params => {
   let {el, varlist, changedVarList, kill, readOnly, isAdminPart} = params;
   const questions = varlist.filter(l => l.dropdown && l !== el),
    [showDD, setShowDD] = useState(0)

   
   const getConditionalQuestion = () => {
    const res =  questions.find(q => q.id === el.conditional?.condVar)?.title    
    return res ? res : transS('Select Question')
  }
 
  const getConditionalOption = () => {
    if (!el.conditional?.condOption) return transS('Select Option')
    const cVar = questions.find(q => q.id === el.conditional?.condVar),
      cOption = cVar?.dropdown.find(d => d.id === el.conditional?.condOption)
    return cOption ? cOption.option : transS('Select Option')
  }

   
   return (
    <span>
      <span styleName='cond-var'>
      <span styleName='cond-question' onClick={() => 
        !readOnly && questions.length && setShowDD(1)}>
        <span className='arrow' styleName='cond-arrow'></span>
        {getConditionalQuestion()}
      </span>
      {!!el.conditional?.condVar &&
      <span styleName='cond-option' onClick={() => !readOnly && setShowDD(2)}>
        <span className='arrow' styleName='cond-arrow'></span>
        {getConditionalOption(el.conditional)}
      </span>}
           
      {!!showDD && <ConditionalOption
        id={showDD === 1 ? el.conditional?.condVar : el.conditional.condOption}
        list={showDD === 1 ? questions : 
          questions.find(q => q.id === el.conditional.condVar).dropdown}
        title={transS(showDD === 1 ? 'Select Question' : 'Select Option')}
        callback = {res => { 
          if (res) {
            if (showDD === 1) {
              el.conditional = {condVar: res, condOption: null}
            } else el.conditional.condOption = res

            if (isAdminPart) {
              changedVarList({res, isCondVar: showDD === 1})
            } else {   
              changedVarList()
            }
          }
          setShowDD(0)
        }}
      />}
       </span>
      <span styleName="cross" onClick={!readOnly && kill}>
        <span></span>
      </span>
    </span> 
    )
}
   
export default CSSModules(Conditional, styles, {allowMultiple: true});

