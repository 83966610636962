import React, { useState, useRef } from 'react'
import cx from 'classnames'
import styles from './styles.module.css'
import { transS } from '../../../../../../services/helpers/lang'
import Tip from '../../../../../../components/tip/Tip'
import Button from '../../../../../../components/button/Button'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ChangesPopup } from './ChangesPopup/ChangesPopup'

export default function ChangesNew({setStateMenu, stateMenu, jumpChanges, isInternal }) {
  const [isOpen, setOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ x: 0 })
  const menuRef = useRef(null)
  const wrapperRef = useRef(null)
  const frameProgress = useSelector(store => store.paragraphReducer.frameProgress)

  const jumpChangesClickHandler = e => {
    e.preventDefault()
    if (!e.target.dataset.dir) return

    frameProgress.jumpDocChanges(+e.target.dataset.dir)
  }

  const frameUndo = () => {
    if (stateMenu.undo) frameProgress.sendToFrame({c1:'undo'});
  }

  const frameRedo = () => {
    if (stateMenu.redo) frameProgress.sendToFrame({c1:'redo'});
  }

  const getDescription = useMemo(() => {
    if (!stateMenu.showChanges) return transS('Showing all');
    if (stateMenu.showChanges === 1) return transS('Now: This Version Changes');
    if (stateMenu.showChanges === 2) return transS('Showing 2 Latest Versions');
    if (stateMenu.showChanges === 3) return transS('Showing Clean');
    return transS('Only New');
  }, [stateMenu.showChanges]);

  const clickOutsideHandler = e => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const changesMenuSetPosition = () => {
    if (!menuRef.current) return
    
    const btn = menuRef.current
    const block = btn.parentElement 
    const wrapper = block.parentElement
    let leftOffsetBtn = btn.offsetLeft + block.offsetLeft + wrapper.offsetLeft + 40
    let width = document.documentElement.clientWidth // ширина видимой области окна браузера (без учёта скроллбара)

    if (window.innerWidth <= 1366) {
      let scrollBarWidth = window.innerWidth - width
      width = 1366 - scrollBarWidth
    }

    let rightOffsetBtn = width - leftOffsetBtn // отступ от левой границы кнопки до правого края окна браузера
    let sendMenuPosition = rightOffsetBtn - 401

    if (sendMenuPosition >= -1) {
      setPopupPosition({ left: '' })
    } else {
      setPopupPosition({ left: `${sendMenuPosition}px` })
    }
  }

  const clickIntoFrame = (e) => {
    if (!e.data || (typeof(e.data) != "string") ) return;
    const  msg = JSON.parse(e.data);

    if (msg.c1 === 'click') {
      setOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler)
    window.addEventListener('resize', changesMenuSetPosition)
    window.addEventListener("message", clickIntoFrame)
    
    return () => {
      window.removeEventListener('click', clickOutsideHandler)
      window.removeEventListener('resize', changesMenuSetPosition)
      window.removeEventListener("message", clickIntoFrame)
    }
  }, [])

  return (
    <div 
      className={cx(
        styles.wrapper,
        {
          [styles.open]: isOpen, 
          [styles.jump]: jumpChanges && jumpChanges.vis
        }
      )} 
      ref={wrapperRef}
    >
      <div className={cx(styles.menu, { [styles.visible]: isOpen })} ref={menuRef}>
        <Button
          text={transS('Edits & View')} 
          mod={'changes leftArrow ' + (isOpen ? 'leftArrow_open changes_open' : '')}
          callback={() => setOpen(prev => !prev)}
        />

        {!isOpen && (
          <>
            {jumpChanges && jumpChanges.vis && (
              <div className={styles.changesJump} onClick={jumpChangesClickHandler}>
                  <div 
                    className={cx(
                      styles.left, 
                      { [styles.disabled]: jumpChanges.end === 1 || jumpChanges.end === 3 }
                    )} 
                    data-dir='1'
                  >
                      <Tip arrow='top left' text={transS(`Go to previous paragraph <br> with changes or comments`)} />
                  </div>

                  <div 
                    className={cx(
                      styles.right, 
                      { [styles.disabled]: jumpChanges.end === 2 || jumpChanges.end === 3 }
                    )} 
                    data-dir='2'
                  >
                      <Tip arrow='top left' text={transS(`Go to next paragraph <br> with changes or comments`)} />
                  </div>
              </div>
            )}

            {!!isInternal && (
              <div className={styles.changesUndo}>
                  <div 
                    className={cx(styles.undo, { [styles.disabled]: !stateMenu.undo })} 
                    onClick={frameUndo}
                  >
                      <img src='/images/icon-undo-active.svg'/>
                      <Tip arrow='top left' text={transS(`Undo changes`)} />
                  </div>

                  <div 
                    className={cx(
                      styles.undo, 
                      { 
                        [styles.reverse]: true, 
                        [styles.disabled]: !stateMenu.redo
                      }
                    )} 
                    onClick={frameRedo}
                  >
                      <img src='/images/icon-undo-active.svg'/>
                      <Tip arrow='top left' text={transS(`Redo changes`)} />
                  </div>
              </div>
            )}


            <div className={styles.desc}>
              {getDescription}
            </div>
          </>
        )}
      </div>

      <ChangesPopup stateMenu={stateMenu} setStateMenu={setStateMenu} isOpen={isOpen}/>
    </div>
  )
}
