import React from 'react'
import styles from './styles.module.css'
import Body from './parts/Body/Body.jsx'
import Advanced from './parts/Advanced/Advanced.jsx'
import Footer from './parts/Footer/Footer.jsx'

export default function OptionItem({ 
  idVariable,
  idOption,
  option, 
  tip, 
  isDefault, 
  isInput, 
  type,
  isUp,
  isDown,
  removeCallback,
  defaultValue,
  conditional,
  changeVariable,
  isShowAdvanced,
  setShowAdvanced,
  moveUpOption,
  moveDownOption,
  readOnly = false,
}) {
  
  return (
    <div className={styles.container}>
      <Body 
        isDefault={isDefault}
        option={option}
        tip={tip}
        changeVariable={changeVariable}
        variableId={idVariable}
        optionId={idOption}
        readOnly={readOnly}
      />
      {isShowAdvanced && (
        <Advanced 
          variableId={idVariable}
          optionId={idOption}
          defaultValue={defaultValue} 
          conditional={conditional}
          isInput={isInput}
          type={type}
          changeVariable={changeVariable}
          isUp={isUp}
          isDown={isDown}
          moveUp={moveUpOption}
          moveDown={moveDownOption}
          removeOption={removeCallback}
          readOnly={readOnly}
        />
      )}
      <Footer idOption={idOption} showAdvanced={isShowAdvanced} setShowAdvanced={setShowAdvanced}/>
    </div>
  )
}
