import React, { memo, useMemo, useRef } from "react";
import styles from "./styles.module.css";
import cx from "classnames";

function Checkbox({
  label,
  checked,
  onChange,
  disabled = false,
  color = "blue",
  size = "md",
  style,
  className,
  ...props
}) {
  const id = useRef(`id-${Math.random().toString(36).substr(2, 9)}`).current;
  const width = useMemo(() => {
    switch (size) {
      case "md":
        return "12px";
      case "lg":
        return "18px";
      default:
        return "12px";
    }
  }, [size]);

  return (
    <>
      <input
        style={{ display: "none" }}
        onChange={onChange}
        type="checkbox"
        checked={checked}
        id={id}
        {...props}
      />

      <label 
        onClick={(e) => e.stopPropagation()}
        htmlFor={id} 
        className={cx(styles.label, className)}
      >
        <div 
          className={cx(
            styles.checkbox, 
            {
              [styles.active]: checked,
              [styles.blue]: color === "blue",
              [styles.green]: color === "green",
            },
          )}
          style={{
            width,
            ...style,
          }}
        >
          <svg viewBox="0 0 11 8" width="100%" height="100%">
            <path
              d="M4.243 4.778L2.115 2.65a.993.993 0 0 0-1.408.007 1 1 0 0 0-.006 1.408l2.841 2.841c.193.193.446.29.7.29.258-.004.512-.101.706-.295l4.953-4.953A.996.996 0 0 0 9.9.536.999.999 0 0 0 8.487.534L4.243 4.778z"
              fill="#FFF"
              fillRule="evenodd"
            ></path>
          </svg>
        </div>

        {label && <p>{label}</p>}
      </label>
    </>
  );
}

export default memo(Checkbox);
