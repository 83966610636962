import keyMirror from 'keymirror';

export default keyMirror({
  GET_USER: null,
  DROP_FIRST_TIME: null,
  GET_EXTERNAL_USER_LIST: null,
  GET_APPROVER_USER_LIST: null,
  ACTIVATION_IN_PROGRESS: null,
  GET_USER_GROUP: null,
  LOGOUT_USER: null,
  UPDATE_USERINFO: null,
  SHOW_SIDEBAR: null,
  SHOW_PAYMENT: null,
  INCORRECT_PASSWORD: null,
  RENEW_USER: null,
  SHOW_EXPIRED: null,
  SET_DATE_FORMAT: null,

  TOGGLE_COMPANY_LOADER: null,
  GET_COMPANY_MEMBERS:null,
  CHANGE_COMPANY: null,
  CHANGE_CLAUSE: null,
  SET_GLOBAL_VARLIST: null,
  SET_CLOSED_FOLDERS: null,
  SET_EDIT_CLAUSE: null,
  INVITE_COMPANY_MEMBERS: null,
  DELETE_COMPANY_MEMBERS: null,

  UPDATE_UNKNOWN_LIST: null,
  UPDATE_APPROVERS_LIST: null
});
