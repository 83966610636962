import React from 'react'
import { transS } from '../../../../../../../../../services/helpers/lang'
import styles from './styles.module.css'

export default function Body({ duplicates }) {
  return (
    <div className={styles.container}>
      {duplicates.map(el => (
        <div className={styles.variableContainer} key={el.name}>
          <div className={styles.icon}>?</div>
          <p className={styles.variableTitle}>{el.title} <span className={styles.variableName}>({el.name})</span></p>
        </div>
      ))}

      {duplicates.length === 1 && (
        <p className={styles.text}>{transS('This variable is already added to the Template. Replacing it will overwrite all its settings with the standard ones.')}</p>
      )}

      {duplicates.length > 1 && (  
        <p className={styles.text}>{transS('These variables are already added to the Template. Replacing them will overwrite all their settings with the standard ones.')}</p>
      )}
      <p className={styles.text}>{transS('This cannot be undone.')}</p>
    </div>
  )
}
